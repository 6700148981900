import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom';

import genPath from '../routing/genPath';
import { lbl } from '../lbls';

import HeadCont from './ui/HeadCont';
import ContextInfo from './ui/ContextInfo';
import { MODULES_CAT } from '../config/const';
import ModCat from './dashboard/ModCat';
import TntInfoMain from './tnt-info/TntInfoMain';

import { Row, Col } from 'antd'
import TntNotesMain from './tnt-notes/TntNotesMain';

const _getRolesInfo = (prjData, roles) => {

    if (!prjData || !roles) {
        return ''
    }

    return prjData.roles && Object.keys(prjData.roles).map(r => (roles && roles[r] && roles[r].name) || '').sort((r1, r2) => r1 > r2).join(', ')
}

class Dashboard extends PureComponent {

    render = () => {

        const { myTntsByPrj, myJoinedCusts, custId, prjId, tntId, roles, custLicModules, prjPermissions, startModule, sa, custAdm } = this.props

        const _selCust = custId && myJoinedCusts && myJoinedCusts[custId]
        const _selPrjData = prjId && myTntsByPrj && myTntsByPrj[prjId]
        const _selTnt = tntId && prjId && myTntsByPrj && myTntsByPrj[prjId] && myTntsByPrj[prjId].tnts && myTntsByPrj[prjId].tnts[tntId]

        const _alphaTester = (custId === 'aa84be88-dc6d-4feb-8ab6-b9808fb6cd6c') || (custId === '34b0a152-d723-4ec9-96a9-e1927242ca8f') || (custId === 'cd9ddc84-89f3-469b-bd83-a36441d7db6c')

        return (
            <React.Fragment>
                <HeadCont title={lbl.main_dash} hideBack={true} showAdmin={true} />

                <div className="NRBody">

                    {<React.Fragment>
                        <ContextInfo infos={[
                            {
                                left: lbl.sel_cust, right: <React.Fragment>
                                    <div style={{ display: 'inline-block' }} className="mr-3">
                                        {_selCust && _selCust.name}
                                        {!_selCust && lbl.no_sel_cust}
                                    </div>

                                    <Link to={genPath('/sel-cust', null, null)} className="txt-act-color">{lbl.edit}</Link>
                                </React.Fragment>
                            },
                            {
                                left: lbl.prj,
                                right: (_selPrjData) ? _selPrjData.prjName : lbl.no_sel_prj,
                            },
                            {
                                left: lbl.roles,
                                right: <React.Fragment>{_getRolesInfo(_selPrjData, roles)}</React.Fragment>
                            },
                            {
                                left: lbl.sel_tnt, right: <React.Fragment>
                                    <div style={{ display: 'inline-block' }} className="mr-3">
                                        {_selTnt && _selTnt.myPathS}
                                        {!_selTnt && lbl.no_sel_tnt}
                                    </div>

                                    <Link to={genPath('/sel-tnt', null, null)} className="txt-act-color">{lbl.edit}</Link>
                                </React.Fragment>
                            }
                        ]} />

                        <Row className="pl-3 pr-3">
                            <Col xs={24} sm={16}>
                                {
                                    _selPrjData && _selTnt && MODULES_CAT.map(modCat => {
                                        return <ModCat alphaTester={_alphaTester} modCat={modCat} key={`modCat_${modCat.id}`} custLicModules={custLicModules} prjPermissions={prjPermissions} startModule={startModule} sa={sa} custAdm={custAdm} />
                                    })
                                }

                                {!_selCust && <div style={{ textAlign: 'center' }} className="mt-1">{lbl.pls_sel_cust}</div>}
                                {_selCust && !_selTnt && <div style={{ textAlign: 'center' }} className="mt-1">{lbl.pls_sel_tnt}</div>}
                            </Col>
                            <Col xs={24} sm={8}>
                                <div className="ml-sm-2">
                                    {
                                        _selTnt && false && <TntInfoMain key={`tntInfo_${prjId}_${tntId}`} prjId={prjId} tntId={tntId} />
                                    }
                                    {
                                        _selTnt && <TntNotesMain key={`tntNotes_${prjId}_${tntId}`} prjId={prjId} tntId={tntId} />
                                    }
                                </div>
                            </Col>
                        </Row>
                    </React.Fragment>
                    }
                </div>

                <footer className="NRFoot d-flex justify-content-around"></footer>
            </React.Fragment>
        )
    }
}

export default Dashboard