import React, {PureComponent} from 'react'

import { connect } from 'react-redux'
import { loadCustTenants } from '../../store/actions/tenantsActions';
import { loadCustProjects, loadProject } from '../../store/actions/projectsActions';
import { loadProjectMounts } from '../../store/actions/projectMountsActions';
import { prjMountsWithPath } from '../../store/selectors/prjs';
import { custAllTenantsPath } from '../../store/selectors/custs';

class TestMain extends PureComponent {

    constructor(){
        super()
        this.state={
            time: Date.now()
        }
    }

    componentDidMount() {

        const {loadCustTenants, loadCustProjects, loadProject, loadProjectMounts} = this.props

        loadCustTenants('5d9b646913ffda08c0035f89') // IB Pro...
        loadCustProjects('5d9b646913ffda08c0035f89') // Load Projs - IB Pro...
        loadProject('5d9b646913ffda08c0035f89', '95736c98-c925-4b44-be43-081d6ee3e988')
        loadProjectMounts('95736c98-c925-4b44-be43-081d6ee3e988')
        loadCustTenants('5d8d7b4373ab87ed8f0af176') // ASA...
        loadCustTenants('5d8d7b4373ab87ed8f0af179') // Krautsch...
    }

    render = () => {

        //const {custTntsWithPath, prjMountsWithPath} = this.props
        //console.log(custTntsWithPath)
        //console.log(prjMountsWithPath)

        return <div style={{padding: 10}}>
            TestMain: {this.state.time}<br/><br/>

            <button className="btn btn-primary" onClick={() => {
                this.setState({time: Date.now()})
            }}>Update state-time</button>
        </div>
    }
}

const mapStateToProps = (state, ownProps) => {

    const custId = state.session.cust

    return{
        auth: state.firebase.auth,
        custId,
        prjMountsWithPath: prjMountsWithPath(state, '5d9b646913ffda08c0035f89', '95736c98-c925-4b44-be43-081d6ee3e988'),
        custTntsWithPath: custAllTenantsPath(state, '5d8d7b4373ab87ed8f0af176'),
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        loadCustTenants: (custId) => dispatch(loadCustTenants(custId)),
        loadCustProjects: (custId) => dispatch(loadCustProjects(custId)), 
        loadProject: (custId, prjId) => dispatch(loadProject(custId, prjId)), 
        loadProjectMounts: (prjId) => dispatch(loadProjectMounts(prjId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TestMain)