/* eslint-disable default-case */
import produce, { original } from 'immer'
import * as actTypes from '../actions/actionTypes'

import has from 'lodash/has'

export default produce((draft, action) => {

    switch (action.type) {

        case actTypes.DISCARD_CUST_TXT_BLOCK:

            if (!action.itmId) {
                console.warn('action.itmId not set!', action)
                return
            }

            if (draft.byId[action.itmId]) {
                delete draft.byId[action.itmId]
            }
            break

        case actTypes.STORE_CUST_TXT_BLOCK:

            if (!action.item.id) {
                console.warn('action.item.id not set!', action.item)
                return
            }

            let _curVersion = draft.byId[action.item.id]

            if (!_curVersion) { // action.item.id not in store
                _curVersion = { custId: action.custId }
                draft.byId[action.item.id] = _curVersion
            }

            if (
                has(action.item, 'timestamp') &&
                (action.item.timestamp === _curVersion.timestamp)
            ) { // we have in state the same version as online. only the full-field might be missing in state
                if (
                    has(action.item, 'full') &&
                    !has(_curVersion, 'full')
                ) {
                    _curVersion['full'] = action.item['full']
                }
                return
            }

            ['id', 'compact', 'full', 'timestamp', 'new'].forEach(fld => {
                if (has(action.item, fld)) {
                    _curVersion[fld] = action.item[fld]
                }
                else {
                    if (has(_curVersion, fld)) {
                        delete _curVersion[fld]
                    }
                }
            });
            break

        case actTypes.SET_CUST_TXT_BLOCKS:

            const txtBlockIds = {}

            // Step1: make sure that all text-blocks sent in action are stored in state
            action.items && action.items.forEach(txtBlock => {

                txtBlockIds[txtBlock.id] = true

                let _curVersion = draft.byId[txtBlock.id]

                if (!_curVersion) { // txt-block not in store
                    draft.byId[txtBlock.id] = {
                        ...txtBlock,
                        custId: action.custId,
                    }
                }
                else { //txt-block already in store
                    if (_curVersion.timestamp === txtBlock.timestamp) { // state version and server version seems to be the same. the full-field might need to be added to state.
                        if (has(txtBlock, 'full') && !has(_curVersion, 'full')) {
                            draft.byId[txtBlock.id] = {
                                ...txtBlock,
                                custId: action.custId,
                            }
                        }
                    }
                    else { // the state-version seems to be older, replace it with the server-version
                        draft.byId[txtBlock.id] = {
                            ...txtBlock,
                            custId: action.custId,
                        }
                    }
                }
            })

            // Step2: make sure that all others txt-blocks (which are not in action + same customer) must be deleted
            const orig = original(draft.byId)
            orig && Object.values(orig).filter(txtBlock => txtBlock.custId === action.custId).forEach(txtBlock => {
                if (txtBlockIds[txtBlock.id]) { //txt-block also loaded from server, should keep it
                    return
                }
                if (txtBlock.new) { //new created by client, keep it
                    return
                }

                if (draft.byId[txtBlock.id]) { // it doesn't exist on the server -> unload it
                    delete draft.byId[txtBlock.id]
                }
            })

            break

        case actTypes.RESET_NEW_CUST_TXT_BLOCK:

            const _curVersNew = draft.byId[action.id]
            if (_curVersNew && _curVersNew.new) {
                delete _curVersNew.new
            }

            break;
    }
}, {
    byId: {}
})