/* eslint-disable default-case */
import produce, { original } from 'immer'
import * as actTypes from '../actions/actionTypes'
import has from 'lodash/has'
import { get } from 'lodash'

//const _flatFields = ['id', 'name', 'container', 'parent', 'timestamp']
//const _deepFields = ['compact', 'full']

export default produce((draft, action) => {

    switch (action.type) {
        case actTypes.DISCARD_TENANT:

            if (draft.byId[action.id]) {
                delete draft.byId[action.id]
                draft.allIds = original(draft.allIds).filter(id => id !== action.id)
            }

            break

        case actTypes.STORE_UPSERT_TENANTS:

            action.items && action.items.forEach(tnt => {

                const origTntsState = original(draft.byId)
                const _origTntInState = origTntsState[tnt.id]

                let _curTntInState = draft.byId[tnt.id]
                if (!_curTntInState) {
                    _curTntInState = { id: tnt.id }
                    draft.byId[tnt.id] = _curTntInState
                    draft.allIds.push(tnt.id)
                }

                if (
                    has(tnt, 'timestamp') &&
                    (tnt.timestamp === get(_origTntInState, 'timestamp'))
                ) { // we have in state the same version as online. only the full-field might be missing in state
                    if (
                        has(tnt, 'full') &&
                        !has(_origTntInState, 'full')
                    ) {
                        _curTntInState['full'] = tnt['full']
                    }
                    return
                }

                ['id', 'name', 'container', 'parent', 'timestamp', 'compact', 'full', 'new'].forEach(fld => {
                    if (has(tnt, fld)) {
                        _curTntInState[fld] = tnt[fld]
                    }
                    else {
                        if (has(_curTntInState, fld)) {
                            delete _curTntInState[fld]
                        }
                    }
                })

            })
    }
}, {
    allIds: [],
    byId: {}
})