/* eslint-disable default-case */
import produce from 'immer'
import * as actTypes from '../actions/actionTypes'

export default produce((draft, action) => {

    switch (action.type) {

        case actTypes.SET_ROLES: 

            draft.byId = action.roles
            break;
    }
}, {
    byId:{}
})