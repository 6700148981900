import React, { useCallback, useContext, useMemo } from 'react'

import { get } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Divider, Row, Col, Dropdown, Menu, Modal } from 'antd'
import { MenuOutlined } from '@ant-design/icons'

import AppContext from '../../AppContext'
import ReadMore from '../ui/ReadMore'
import { lbl } from '../../lbls'
import { fmtUnixTsByLng, getCurTimestamp } from '../../utils'

const { confirm } = Modal

function TntNote({ note, editable }) {
    const { lng } = useContext(AppContext)

    const _handleMenuClick = useCallback(({ key }) => {

        if (key === 'edt') {
            note.setIsEditing(true)
        }
        else if (key === 'del') {
            confirm({
                title: lbl.del_conf,
                onOk: (ev) => {
                    note.setDeleted(getCurTimestamp())
                    note.save()
                        .then(() => {
                            console.warn('deleted...')
                        })
                        .catch((err) => {
                            //TODO: err Behandlung!
                        })
                    Modal.destroyAll()
                }
            })
        }
    }, [note])

    const menu = useMemo(() => {
        return <Menu onClick={_handleMenuClick}>
            <Menu.Item key="edt">
                {lbl.edit}
            </Menu.Item>
            <Menu.Item key="del">
                {lbl.del}
            </Menu.Item>
        </Menu>
    }, [_handleMenuClick])

    return <>
        <Row wrap={false}>
            <Col flex="auto">
                <small>{fmtUnixTsByLng(note.infoDate, lng)}</small><br />
                <ReadMore text={get(note.text, lng)} />
            </Col>
            <Col flex="none">
                {editable && <div className="pr-2">
                    <Dropdown.Button overlay={menu} placement="bottomRight" icon={<MenuOutlined />} size="small" trigger="click" />
                </div>}
            </Col>
        </Row>
        <Divider className="nrDividerNoSpace mb-2 mt-2" />
    </>
}

export default observer(TntNote)