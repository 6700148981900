import React, { memo, useContext, useMemo } from 'react'

import { useSelector } from 'react-redux'

import { Style } from "react-style-tag";
import AppContext from '../../AppContext';

import get from 'lodash/get'

Style.setGlobalOptions({
  isCompressed: true,
  hasSourceMap: false,
  isMinified: true,
  isPrefixed: false
});

const mainRasterColor = '#AFAFAF';
const dividerColor = '#f0f0f0';
const titleColor = '#33332E';

const CustDynTheme = () => {

  const { custId } = useContext(AppContext)
  const _custsById = useSelector(state => state.custs.byId)

  const _custDesign = useMemo(() => {
    return get(_custsById, [custId, 'full', 'design'], {})
  }, [custId, _custsById])

  const _tintColor = get(_custDesign, ['tintColor'], '#FCC42D')

  const bodyBgColor = (custId === '777') ? '#EFEFEF' : '#FFFFFF'
  const actColor = _tintColor //(custId === '777') ? '#DE00DE' : '#FCC42D'
  const barBgColor = (custId === '777') ? 'red' : '#8E8E93'
  const headColor = (custId !== '5d9b646913ffda08c0035f89') ? 'white' : '#FFDDDD';

  return <Style>{`

  .txt-head-color{
    color: ${headColor};
  }
  .txt-title-color{
    color: ${titleColor};
  }
  .txt-act-color {
    color: ${actColor};
  }
  a.nrBreadcrumbItm {
    color: ${actColor} !important;
  }
  .bg-act-color {
    background-color: ${actColor};
  }
  body{
    background-color: ${bodyBgColor};
  }
  .NRHead {
    background-color: ${barBgColor};
    border-bottom-color: ${mainRasterColor};
  }
  .NRFoot {
    background-color: ${barBgColor};
    border-top-color: ${mainRasterColor};
  }
  .rasterRight{
    border-right-color: ${mainRasterColor};
  }
  .rasterLeft{
    border-left-color: ${mainRasterColor};
  }
  .dividerTop{
    border-top-color: ${dividerColor};
  }
  .dividerBottom{
    border-bottom-color: ${dividerColor};
  }
  .btn-outline-primary, .dx-button-mode-outlined{
    color: ${actColor};
    border-color: ${actColor};
  }
  .sel-row{
    color: ${actColor} !important;
  }
  .page-item.active .page-link{
    background-color: ${actColor} !important;  
  }

  .dash-modImg{
    background: ${_tintColor} !important;
  }

  .nrTabActive, .nrTabActive a{
    color: ${actColor} !important;
  }
  .nrTabs .ant-divider-vertical{
      border-left-color: ${actColor} !important;
  }
  `}</Style>
}

export default memo(CustDynTheme)