import { makeAutoObservable, flow, has } from "mobx"
import rootStore from ".."
import { BugCrash } from "../../services/BugCrash"
import axios from '../../services/Axios'
import Audit from "./Audit"


export default class TpcCompSpecAuditsStore {

    rootStore

    audits = {}

    isLoading = false

    constructor(rootStore) {
        makeAutoObservable(this, { rootStore: false })

        this.rootStore = rootStore
    }

    fetchOne = flow(function* (prjId, tntId, auditId, forceRefresh) {

        this.isLoading = true

        const taskId = rootStore.workProgressStore.startLoading(`Load tpc-comp-spec prjId=${prjId}, tntId=${tntId}, auditId=${auditId}`)

        try {
            const { status, data } = yield axios.get(`/projects/${prjId}/tenants/${tntId}/audits/topics-comp-specific/${auditId}`)
            if (status === 200) {
                if (forceRefresh || !has(this.audits, data.id)) {
                    this.audits[auditId] = new Audit(this)
                }

                this.audits[auditId].updateFromJson(data)
                return true
            }
        }
        catch (err) {
            BugCrash.notifyExtra(err, { prjId, tntId, auditId })
        }
        finally {
            this.isLoading = false
            rootStore.workProgressStore.doneLoading(taskId)
        }

        return false
    })

    updateFromLight = (itm) => {

        if (!has(this.audits, itm.id)) {
            this.audits[itm.id] = new Audit(this)
        }

        this.audits[itm.id].updateFromLight(itm)
    }
}