import React from 'react'
import PropTypes from 'prop-types';

function ContextInfo({infos}) {

    return <div className="p-3">
        <div className="dividerBottom pb-1">
            {
                infos.map((info) => {
                    return <div key={info.left} className="d-flex mb-2">
                        <div className="w-25 contextInfoLeft">{info.left}:</div> 
                        <div className="contextInfoRight">{info.right}</div>
                    </div>
                })
            }
        </div>
    </div>    
}

ContextInfo.propTypes = {
    infos: PropTypes.arrayOf(PropTypes.shape({
        left: PropTypes.string.isRequired,
        right: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    }))
}

export default ContextInfo