import { makeAutoObservable, flow, values } from "mobx"
import axios from '../../services/Axios'
import { BugCrash } from "../../services/BugCrash"
import Role from "./Role"

export default class RolesStore {

    rootStore

    isLoading = false
    roles = {}

    constructor(rootStore) {
        makeAutoObservable(this, { rootStore: false })

        this.rootStore = rootStore
    }

    fetchRoles = flow(function* () {

        this.isLoading = true

        const taskId = this.rootStore.workProgressStore.startLoading(`Load roles-perms info`)

        try {
            const { data } = yield axios.get(`/roles?startTimestamp=${this.lastKnownTimestamp + 1}`)

            data.list.forEach(json => {
                const _role = new Role()
                _role.updateFromJson(json)

                this.roles[_role.code] = _role
            })
        }
        catch (err) {
            BugCrash.notifyExtra(err, { lastKnownTimestamp: this.lastKnownTimestamp })
        }
        finally {
            this.isLoading = false
            this.rootStore.workProgressStore.doneLoading(taskId)
        }
    })

    get lastKnownTimestamp() {
        let _ret = 0

        values(this.roles).forEach((r) => {
            _ret = Math.max(_ret, r.timestamp)
        })

        return _ret
    }
}