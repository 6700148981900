export const AUTH_LOGIN_STARTED = 'AUTH_LOGIN_STARTED';
export const AUTH_LOGIN_READY_OK = 'AUTH_LOGIN_READY_OK';
export const AUTH_LOGIN_READY_PB = 'AUTH_LOGIN_READY_PB';

export const AUTH_LOGOUT_STARTED = 'AUTH_LOGOUT_STARTED';
export const AUTH_LOGOUT_READY_OK = 'AUTH_LOGOUT_READY_OK';
export const AUTH_LOGOUT_READY_PB = 'AUTH_LOGOUT_READY_PB';

export const CHG_REGISTER = 'CHG_REGISTER';
export const CHG_DISCARD = 'CHG_DISCARD';

export const FEEDBACK_REGISTER = 'FEEDBACK_REGISTER';
export const FEEDBACK_DISCARD = 'FEEDBACK_DISCARD';

export const SES_SET_CUST = 'SES_SET_CUST';
export const SES_SET_PROJ = 'SES_SET_PROJ';
export const SES_SET_TNT = 'SES_SET_TNT';
export const SES_SET_XS = 'SES_SET_XS';
export const SES_SET_LNG = 'SES_SET_LNG';
export const SES_SET_ADM_CUST = 'SES_SET_ADM_CUST';

export const SETTINGS_SET = 'SETTINGS_SET';

export const LOAD_AUTH_USER_DATA_STARTED = 'LOAD_AUTH_USER_DATA_STARTED';
export const LOAD_AUTH_USER_DATA_READY_OK = 'LOAD_AUTH_USER_DATA_READY_OK';
export const LOAD_AUTH_USER_DATA_READY_PB = 'LOAD_AUTH_USER_DATA_READY_PB';

export const LOAD_CUST_LICENSE_STARTED = 'LOAD_CUST_LICENSE_STARTED';
export const LOAD_CUST_LICENSE_READY_OK = 'LOAD_CUST_LICENSE_READY_OK';
export const LOAD_CUST_LICENSE_READY_PB = 'LOAD_CUST_LICENSE_READY_PB';

export const PATCH_CUST_LICENSE_STARTED = 'PATCH_CUST_LICENSE_STARTED';
export const PATCH_CUST_LICENSE_READY_OK = 'PATCH_CUST_LICENSE_READY_OK';
export const PATCH_CUST_LICENSE_READY_PB = 'PATCH_CUST_LICENSE_READY_PB';

// --------------------------------------------------------
export const ACTION_STARTED = 'ACTION_STARTED'
export const ACTION_READY_OK = 'ACTION_READY_OK'
export const ACTION_READY_PB = 'ACTION_READY_PB'

export const STORE_UPSERT_TENANTS = 'STORE_UPSERT_TENANTS'
export const DISCARD_TENANT = 'DISCARD_TENANT'

export const STORE_UPSERT_SHARES = 'STORE_UPSERT_SHARES'
export const STORE_UPSERT_CUSTS = 'STORE_UPSERT_CUSTS'
export const STORE_UPSERT_PRJS = 'STORE_UPSERT_PRJS'


export const SET_CUST_PRJS_BY_ADM = 'SET_CUST_PRJS_BY_ADM'
export const ASSIGN_CUST_PRJS_BY_ADM = 'ASSIGN_CUST_PRJS_BY_ADM'
export const DETACH_CUST_PRJS_BY_ADM = 'DETACH_CUST_PRJS_BY_ADM'

export const SET_CUST_PRJS_BY_AUTH_USR = 'SET_CUST_PRJS_BY_AUTH_USR'
export const ASSIGN_CUST_PRJS_BY_AUTH_USR = 'ASSIGN_CUST_PRJS_BY_AUTH_USR'
export const DETACH_CUST_PRJS_BY_AUTH_USR = 'DETACH_CUST_PRJS_BY_AUTH_USR'


export const ASSIGN_CUST_TNTS = 'ASSIGN_CUST_TNTS'

export const STORE_REMOVE_PRJ_MOUNT = 'STORE_REMOVE_PRJ_MOUNT'
export const STORE_ASSOC_PRJ_MOUNT = 'STORE_ASSOC_PRJ_MOUNT'
export const STORE_REMOVE_PRJ = 'STORE_REMOVE_PRJ'


export const STORE_UPSERT_USERS = 'STORE_UPSERT_USERS'

export const ASSIGN_CUST_USRS = 'ASSIGN_CUST_USRS'
export const DETACH_CUST_USRS = 'DETACH_CUST_USRS'
export const SET_CUST_USRS = 'SET_CUST_USRS'

export const ASSIGN_CUST_ADMS = 'ASSIGN_CUST_ADMS'
export const DETACH_CUST_ADMS = 'DETACH_CUST_ADMS'
export const SET_CUST_ADMS = 'SET_CUST_ADMS'

export const SET_USR_JOINED_CUSTS = 'SET_USR_JOINED_CUSTS'
export const SET_USR_ADM_CUSTS = 'SET_USR_ADM_CUSTS'

export const ADD_CUST_USRS_DATA = 'ADD_CUST_USRS_DATA'
export const SET_CUST_USRS_DATA = 'SET_CUST_USRS_DATA'
export const DEL_CUST_USRS_DATA = 'DEL_CUST_USRS_DATA'

export const SET_PRJ_USR_ROLES = 'SET_PRJ_USR_ROLES'
export const SET_USR_PRJS_ROLES = 'SET_USR_PRJS_ROLES'
export const SET_PRJ_USRS_ROLES = 'SET_PRJ_USRS_ROLES'

export const SET_ROLES = 'SET_ROLES'

export const SET_PRJ_TNTS = 'SET_PRJ_TNTS'
export const SET_PRJ_ROOTS = 'SET_PRJ_ROOTS'

export const SET_CUSTS_LICENSES = 'SET_CUSTS_LICENSES'

export const SET_USR_PRJS_PERMISSIONS = 'SET_USR_PRJS_PERMISSIONS'

export const SET_LAST_LOAD_TIME = 'SET_LAST_LOAD_TIME'

export const SET_TNT_WKEQ_SHELVES = 'SET_TNT_WKEQ_SHELVES'
export const STORE_TNT_WKEQ_SHELF = 'STORE_TNT_WKEQ_SHELF'
export const DISCARD_WKEQ_SHELF = 'DISCARD_WKEQ_SHELF'
export const RESET_NEW_WKEQ_SHELF = 'RESET_NEW_WKEQ_SHELF'
export const STORE_WKEQ_SHELF_REGAL = 'STORE_WKEQ_SHELF_REGAL'
export const DISCARD_WKEQ_SHELF_REGAL = 'DISCARD_WKEQ_SHELF_REGAL'

export const STORE_WKEQ_SHELF_MANGEL = 'STORE_WKEQ_SHELF_MANGEL'
export const DISCARD_WKEQ_SHELF_MANGEL = 'DISCARD_WKEQ_SHELF_MANGEL'

export const SET_TNT_WKEQ_LADDERS = 'SET_TNT_WKEQ_LADDERS'
export const DISCARD_WKEQ_LADDER = 'DISCARD_WKEQ_LADDER'
export const STORE_TNT_WKEQ_LADDER = 'STORE_TNT_WKEQ_LADDER'
export const RESET_NEW_WKEQ_LADDER = 'RESET_NEW_WKEQ_LADDER'

export const SET_TNT_WKEQ_GATES = 'SET_TNT_WKEQ_GATES'
export const DISCARD_WKEQ_GATE = 'DISCARD_WKEQ_GATE'
export const STORE_TNT_WKEQ_GATE = 'STORE_TNT_WKEQ_GATE'
export const RESET_NEW_WKEQ_GATE = 'RESET_NEW_WKEQ_GATE'

export const SET_TNT_WKEQ_FORKLIFTS = 'SET_TNT_WKEQ_FORKLIFTS'
export const DISCARD_WKEQ_FORKLIFT = 'DISCARD_WKEQ_FORKLIFT'
export const STORE_TNT_WKEQ_FORKLIFT = 'STORE_TNT_WKEQ_FORKLIFT'
export const RESET_NEW_WKEQ_FORKLIFT = 'RESET_NEW_WKEQ_FORKLIFT'

export const SET_TNT_WKEQ_PALETTRUCKS = 'SET_TNT_WKEQ_PALETTRUCKS'
export const DISCARD_WKEQ_PALETTRUCK = 'DISCARD_WKEQ_PALETTRUCK'
export const STORE_TNT_WKEQ_PALETTRUCK = 'STORE_TNT_WKEQ_PALETTRUCK'
export const RESET_NEW_WKEQ_PALETTRUCK = 'RESET_NEW_WKEQ_PALETTRUCK'

export const SET_TNT_WKEQ_HYDR_RAMPS = 'SET_TNT_WKEQ_HYDR_RAMPS'
export const DISCARD_WKEQ_HYDR_RAMP = 'DISCARD_WKEQ_HYDR_RAMP'
export const STORE_TNT_WKEQ_HYDR_RAMP = 'STORE_TNT_WKEQ_HYDR_RAMP'
export const RESET_NEW_WKEQ_HYDR_RAMP = 'RESET_NEW_WKEQ_HYDR_RAMP'

export const SET_TNT_WKEQ_LOAD_RAMPS = 'SET_TNT_WKEQ_LOAD_RAMPS'
export const DISCARD_WKEQ_LOAD_RAMP = 'DISCARD_WKEQ_LOAD_RAMP'
export const STORE_TNT_WKEQ_LOAD_RAMP = 'STORE_TNT_WKEQ_LOAD_RAMP'
export const RESET_NEW_WKEQ_LOAD_RAMP = 'RESET_NEW_WKEQ_LOAD_RAMP'

export const SET_TNT_WKEQ_WORK_PLATFS = 'SET_TNT_WKEQ_WORK_PLATFS'
export const DISCARD_WKEQ_WORK_PLATF = 'DISCARD_WKEQ_WORK_PLATF'
export const STORE_TNT_WKEQ_WORK_PLATF = 'STORE_TNT_WKEQ_WORK_PLATF'
export const RESET_NEW_WKEQ_WORK_PLATF = 'RESET_NEW_WKEQ_WORK_PLATF'

export const OPEN_POPUP = 'OPEN_POPUP'
export const CLOSE_POPUP = 'CLOSE_POPUP'

export const STORE_WKEQ_SHELF_TYPE = 'STORE_WKEQ_SHELF_TYPE'
export const DISCARD_WKEQ_SHELF_TYPE = 'DISCARD_WKEQ_SHELF_TYPE'

export const STORE_WKEQ_SHELF_LADUNGSTRAEGER = 'STORE_WKEQ_SHELF_LADUNGSTRAEGER'
export const DISCARD_WKEQ_SHELF_LADUNGSTRAEGER = 'DISCARD_WKEQ_SHELF_LADUNGSTRAEGER'

export const STORE_WKEQ_SHELF_BEDIENUNG = 'STORE_WKEQ_SHELF_BEDIENUNG'
export const DISCARD_WKEQ_SHELF_BEDIENUNG = 'DISCARD_WKEQ_SHELF_BEDIENUNG'

export const SET_TNT_SPEC_AUDITS = 'SET_TNT_SPEC_AUDITS'
export const DISCARD_SPEC_AUDIT = 'DISCARD_SPEC_AUDIT'
export const STORE_TNT_SPEC_AUDIT = 'STORE_TNT_SPEC_AUDIT'
export const RESET_NEW_SPEC_AUDIT = 'RESET_NEW_SPEC_AUDIT'

export const SET_CUST_QUEST_CATS = 'SET_CUST_QUEST_CATS'
export const STORE_CUST_QUEST_CAT = 'STORE_CUST_QUEST_CAT'
export const RESET_NEW_CUST_QUEST_CAT = 'RESET_NEW_CUST_QUEST_CAT'

export const SET_SYS_QUEST_CATS = 'SET_SYS_QUEST_CATS'
export const STORE_SYS_QUEST_CAT = 'STORE_SYS_QUEST_CAT'
export const RESET_NEW_SYS_QUEST_CAT = 'RESET_NEW_SYS_QUEST_CAT'

export const SET_CUST_TXT_BLOCKS = 'SET_CUST_TXT_BLOCKS'
export const STORE_CUST_TXT_BLOCK = 'STORE_CUST_TXT_BLOCK'
export const RESET_NEW_CUST_TXT_BLOCK = 'RESET_NEW_CUST_TXT_BLOCK'
export const DISCARD_CUST_TXT_BLOCK = 'DISCARD_CUST_TXT_BLOCK'

export const SET_SYS_TXT_BLOCKS = 'SET_SYS_TXT_BLOCKS'
export const STORE_SYS_TXT_BLOCK = 'STORE_SYS_TXT_BLOCK'
export const RESET_NEW_SYS_TXT_BLOCK = 'RESET_NEW_SYS_TXT_BLOCK'
export const DISCARD_SYS_TXT_BLOCK = 'DISCARD_SYS_TXT_BLOCK'

export const SET_TNT_TPC_BASIC_CARES = 'SET_TNT_TPC_BASIC_CARES'
export const DISCARD_TPC_BASIC_CARE = 'DISCARD_TPC_BASIC_CARE'
export const STORE_TNT_TPC_BASIC_CARE = 'STORE_TNT_TPC_BASIC_CARE'
export const RESET_NEW_TPC_BASIC_CARE = 'RESET_NEW_TPC_BASIC_CARE'

export const SET_TNT_TPC_BASIC_CARE_MEDICS = 'SET_TNT_TPC_BASIC_CARE_MEDICS'
export const DISCARD_TPC_BASIC_CARE_MEDIC = 'DISCARD_TPC_BASIC_CARE_MEDIC'
export const STORE_TNT_TPC_BASIC_CARE_MEDIC = 'STORE_TNT_TPC_BASIC_CARE_MEDIC'
export const RESET_NEW_TPC_BASIC_CARE_MEDIC = 'RESET_NEW_TPC_BASIC_CARE_MEDIC'

export const SET_TNT_TPC_COMP_SPECS = 'SET_TNT_TPC_COMP_SPECS'
export const DISCARD_TPC_COMP_SPEC = 'DISCARD_TPC_COMP_SPEC'
export const STORE_TNT_TPC_COMP_SPEC = 'STORE_TNT_TPC_COMP_SPEC'
export const RESET_NEW_TPC_COMP_SPEC = 'RESET_NEW_TPC_COMP_SPEC'

export const SET_TNT_MEETINGS = 'SET_TNT_MEETINGS'
export const DISCARD_MEETING = 'DISCARD_MEETING'
export const STORE_TNT_MEETING = 'STORE_TNT_MEETING'
export const RESET_NEW_MEETING = 'RESET_NEW_MEETING'

export const SET_TNT_QUEST_CAT_TNTS = 'SET_TNT_QUEST_CAT_TNTS'
export const DISCARD_QUEST_CAT_TNT = 'DISCARD_QUEST_CAT_TNT'
export const STORE_TNT_QUEST_CAT_TNT = 'STORE_TNT_QUEST_CAT_TNT'
export const RESET_NEW_QUEST_CAT_TNT = 'RESET_NEW_QUEST_CAT_TNT'

export const SET_TNT_PERSONS = 'SET_TNT_PERSONS'
export const DISCARD_PERSON = 'DISCARD_PERSON'
export const STORE_TNT_PERSON = 'STORE_TNT_PERSON'
export const RESET_NEW_PERSON = 'RESET_NEW_PERSON'

export const SET_TNT_EMPLS = 'SET_TNT_EMPLS'
export const DISCARD_EMPL = 'DISCARD_EMPL'
export const STORE_TNT_EMPL = 'STORE_TNT_EMPL'
export const RESET_NEW_EMPL = 'RESET_NEW_EMPL'

export const SET_TNT_REPRESENTS = 'SET_TNT_REPRESENTS'
export const DISCARD_REPRESENT = 'DISCARD_REPRESENT'
export const STORE_TNT_REPRESENT = 'STORE_TNT_REPRESENT'
export const RESET_NEW_REPRESENT = 'RESET_NEW_REPRESENT'

export const SET_TNT_FUNCS = 'SET_TNT_FUNCS'
export const DISCARD_FUNC = 'DISCARD_FUNC'
export const STORE_TNT_FUNC = 'STORE_TNT_FUNC'
export const RESET_NEW_FUNC = 'RESET_NEW_FUNC'

export const SET_TNT_DEPARTS = 'SET_TNT_DEPARTS'
export const DISCARD_DEPART = 'DISCARD_DEPART'
export const STORE_TNT_DEPART = 'STORE_TNT_DEPART'
export const RESET_NEW_DEPART = 'RESET_NEW_DEPART'

export const SET_TNT_MATERNITYS = 'SET_TNT_MATERNITYS'
export const DISCARD_MATERNITY = 'DISCARD_MATERNITY'
export const STORE_TNT_MATERNITY = 'STORE_TNT_MATERNITY'
export const RESET_NEW_MATERNITY = 'RESET_NEW_MATERNITY'

export const SET_TNT_PSYCHES = 'SET_TNT_PSYCHES'
export const DISCARD_PSYCHE = 'DISCARD_PSYCHE'
export const STORE_TNT_PSYCHE = 'STORE_TNT_PSYCHE'
export const RESET_NEW_PSYCHE = 'RESET_NEW_PSYCHE'

export const SET_TNT_ACCIDENT_ANALYSES = 'SET_TNT_ACCIDENT_ANALYSES'
export const DISCARD_ACCIDENT_ANALYSE = 'DISCARD_ACCIDENT_ANALYSE'
export const STORE_TNT_ACCIDENT_ANALYSE = 'STORE_TNT_ACCIDENT_ANALYSE'
export const RESET_NEW_ACCIDENT_ANALYSE = 'RESET_NEW_ACCIDENT_ANALYSE'

export const SET_TNT_PERF_CONFS = 'SET_TNT_PERF_CONFS'
export const DISCARD_PERF_CONF = 'DISCARD_PERF_CONF'
export const STORE_TNT_PERF_CONF = 'STORE_TNT_PERF_CONF'
export const RESET_NEW_PERF_CONF = 'RESET_NEW_PERF_CONF'

export const STORE_TNT_PERF_CONF_CHECK = 'STORE_TNT_PERF_CONF_CHECK'
export const DISCARD_TNT_PERF_CONF_CHECK = 'DISCARD_TNT_PERF_CONF_CHECK'

export const SET_TNT_QUEST_CATS = 'SET_TNT_QUEST_CATS'
export const DISCARD_QUEST_CAT = 'DISCARD_QUEST_CAT'
export const STORE_TNT_QUEST_CAT = 'STORE_TNT_QUEST_CAT'
export const RESET_NEW_QUEST_CAT = 'RESET_NEW_QUEST_CAT'

export const SET_TNT_TXT_BLOCKS = 'SET_TNT_TXT_BLOCKS'
export const DISCARD_TXT_BLOCK = 'DISCARD_TXT_BLOCK'
export const STORE_TNT_TXT_BLOCK = 'STORE_TNT_TXT_BLOCk'
export const RESET_NEW_TXT_BLOCK = 'RESET_NEW_TXT_BLOCK'

export const STORE_TNT_QUEST_CAT_ACT = 'STORE_TNT_QUEST_CAT_ACT'
export const STORE_TNT_QUEST_CAT_QUEST = 'STORE_TNT_QUEST_CAT_QUEST'
export const STORE_TNT_QUEST_CAT_CAT = 'STORE_TNT_QUEST_CAT_CAT'
export const STORE_TNT_QUEST_CAT_VERS = 'STORE_TNT_QUEST_CAT_VERS'

export const STORE_CUST_QUEST_CAT_ACT = 'STORE_CUST_QUEST_CAT_ACT'
export const STORE_CUST_QUEST_CAT_QUEST = 'STORE_CUST_QUEST_CAT_QUEST'
export const STORE_CUST_QUEST_CAT_CAT = 'STORE_CUST_QUEST_CAT_CAT'
export const STORE_CUST_QUEST_CAT_VERS = 'STORE_CUST_QUEST_CAT_VERS'

export const STORE_SYS_QUEST_CAT_ACT = 'STORE_SYS_QUEST_CAT_ACT'
export const STORE_SYS_QUEST_CAT_QUEST = 'STORE_SYS_QUEST_CAT_QUEST'
export const STORE_SYS_QUEST_CAT_CAT = 'STORE_SYS_QUEST_CAT_CAT'
export const STORE_SYS_QUEST_CAT_VERS = 'STORE_SYS_QUEST_CAT_VERS'

/*
export const SET_TNT_S = 'SET_TNT_S'
export const DISCARD_ = 'DISCARD_'
export const STORE_TNT_ = 'STORE_TNT_'
export const RESET_NEW_ = 'RESET_NEW_'
*/

export const REP_SHELF_INSPECT_DONE = 'REP_SHELF_INSPECT_DONE'
export const REP_PERF_CONF_DONE = 'REP_PERF_CONF_DONE'

export const MERGE_ENTITIES = 'MERGE_ENTITIES'

export const SYNC_ACT_STS_RUNNING = 'SYNC_ACT_STS_RUNNING'
export const SYNC_ACT_STS_OK = 'SYNC_ACT_STS_OK'
export const SYNC_ACT_STS_ERR = 'SYNC_ACT_STS_ERR'
export const SYNC_ACT_NEW = 'SYNC_ACT_NEW'
export const SYNC_ACT_DISCARD = 'SYNC_ACT_DISCARD'