import { makeAutoObservable } from "mobx"

export default class AuthStore {

    uid = null

    rootStore

    constructor(rootStore) {
        makeAutoObservable(this, { rootStore: false })

        this.rootStore = rootStore
    }

    updateFromFirebaseUser = (usr) => {
        this.uid = (usr) ? usr.uid : null
    }
}