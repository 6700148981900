import { notification } from "antd"
import { autorun, makeAutoObservable, set, toJS, values, remove, has } from "mobx"
import rootStore from ".."
import { lbl } from "../../lbls"
import AuditAnswer from "./AuditAnswer"

export default class AuditFull {

    audit

    title = ''

    answers = {}
    images = {}
    excludedCategoryIDs = {}
    participantIDs = {}
    departmentIDs = {}

    questionnaireCatalogueID = ''
    questionnaireCatalogueType = ''
    usedQuestionniareVersion = 0

    creationDate = 0
    beginPerformance = 0

    answeredProgress = 0

    isDirty = false

    constructor(audit) {
        makeAutoObservable(this, { audit: false })

        this.audit = audit

        autorun(() => {
            if (this.questionnaireCatalogueID && this.questionnaireCatalogueType && this.usedQuestionniareVersion) {
                (async () => {
                    try {
                        if (!await rootStore.qcVersionsStore.fetchOneByVersNb('cust-id', this.questionnaireCatalogueID, this.questionnaireCatalogueType, this.usedQuestionniareVersion, false)) {
                            throw new Error('Could not load the Question-Catalog!')
                        }
                    }
                    catch (err) {
                        notification.error({
                            message: lbl.err_title,
                            description: lbl.err_load_data,
                            placement: 'bottomRight',
                            duration: 0,
                        })
                    }
                })()
            }
        })
    }

    includeCatId = (catId) => {
        if (has(this.excludedCategoryIDs, catId)) {
            remove(this.excludedCategoryIDs, catId)
            this.isDirty = true
        }
    }

    excludeCatId = (catId) => {
        set(this.excludedCategoryIDs, catId, {})
        this.isDirty = true
    }

    toJson = () => {

        const _ret = {
            answers: {}
        }

        for (const fld of ['title', 'departmentIDs', 'participantIDs', 'questionnaireCatalogueID', 'questionnaireCatalogueType', 'usedQuestionniareVersion', 'images', 'creationDate', 'beginPerformance', 'excludedCategoryIDs']) {
            _ret[fld] = toJS(this[fld])
        }

        values(this.answers).forEach(answ => {
            if (answ.isNew && !answ.isDirty) {
                return
            }

            _ret.answers[answ.id] = answ.toJson()
        })

        return _ret
    }

    updateFromLight = (light) => {
        //console.log('AuditFull::updateFromLight', light)

        set(this, 'title', light['title'])
        set(this, 'beginPerformance', light['date'])
        set(this, 'answeredProgress', light['answeredProgress'])

        //this.questionnaireCatalogueID = light['qcId']
        //this.questionnaireCatalogueType = light['qcType']
        //this.usedQuestionniareVersion = light['qcVers']
    }

    updateFromJson = (json) => {
        //console.log('AuditFull::updateFromJson', json)

        const _answers = {}
        json.answers && Object.values(json.answers).forEach(itm => {
            const _answ = new AuditAnswer(this)
            _answ.updateFromJson(itm)
            _answers[_answ.id] = _answ
        })
        set(this.answers, _answers)

        for (const fld of ['title', 'answeredProgress', 'departmentIDs', 'participantIDs', 'images', 'creationDate', 'beginPerformance', 'excludedCategoryIDs']) {
            set(this, fld, json[fld])
        }

        this.questionnaireCatalogueID = json['questionnaireCatalogueID']
        this.questionnaireCatalogueType = json['questionnaireCatalogueType']
        this.usedQuestionniareVersion = json['usedQuestionniareVersion']
    }

    setTitle = (val) => {
        this.title = val
        this.isDirty = true
    }

    setBeginPerformance = (val) => {
        this.beginPerformance = val
        this.isDirty = true
    }

    get dirty() {

        if (this.isDirty) {
            return true
        }

        for (const _answ of values(this.answers)) {
            if (_answ.isDirty) {
                return true
            }
        }

        return false
    }
}