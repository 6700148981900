const resources = {
    "coreData": "Stammdaten",
    "captureAndEvaluate": "Erfassen und Beurteilen",
    "documentationAndLogs": "Dokumentation und Protokolle",
    "consultants": "Berater",
    "consultant": "Berater",
    "dataServiceProvider": "Daten des Dienstleisters",
    "consultantGroup": "Gruppe",
    "clients": "Mandanten",
    "client": "Mandant",
    "showClientsSelection": "Mandantenauswahl anzeigen",
    "audits": "Audits",
    "specificAudit": "Spezifische Audits",
    "planingAndExecution": "Planung und Durchführung",
    "questionaires": "Fragenkataloge",
    "forAudits": "Für Audits",
    "forEvaluation": "Für Beurteilungen",
    "textModules": "Textbausteine",
    "forAuditreport": "Für Auditbericht",
    "freeAudits": "Freie Audits",
    "creationOfPlans": "Erstellung von Maßnahmeplänen mit Wirkungskontrolle",
    "forFreeAudits": "Für freie Audits",
    "deletion": "Löschen",
    "removing": "Entfernen",
    "delete": "Löschen",
    "deleteAll": "Alles löschen",
    "remove": "Entfernen",
    "abort": "Abbrechen",
    "edit": "Bearbeiten",
    "sureToDeleteEntry": "Soll der Eintrag gelöscht werden?",
    "sureRemovingQuestionnaireFormAudit": "Soll der Fragenkatalog vom Audit entfernt werden?",
    "name": "Name",
    "lastName": "Name",
    "firstName": "Vorname",
    "email": "E-Mail",
    "company": "Firma",
    "street": "Straße",
    "postcode": "PLZ",
    "city": "Ort",
    "website": "Website",
    "heads": "in Köpfen",
    "convertedToFullTime": "umgerechnet auf Vollzeit",
    "phone": "Telefon",
    "headPerson": "Leiter der Betriebsstätte  Haupt-Ansprechpartner",
    "companyData": "Unternehmensdaten",
    "numberEmployees": "Mitarbeiteranzahl",
    "headOfFactoryContactPerson": "Leitung der Betriebsstätte",
    "contract": "Vertrag",
    "contractDateBeginning": "Beginn",
    "status": "Status",
    "auditplaning": "Auditplanung",
    "auditperformance": "Auditdurchführung",
    "auditreport": "Auditbericht",
    "title": "Titel",
    "text": "Text",
    "planing": "Planung",
    "performance": "Durchführung",
    "documentation": "Dokumentation",
    "typeOfAudit": "Art des Audits",
    "purpusesOfAudit": "Auditziele",
    "scope": "Bereich",
    "auditors": "Berater",
    "referencingDocuments": "Referenzdokumente",
    "site": "Ort",
    "creator": "Ersteller",
    "creatorSignature": "Unterschrift des Erstellers",
    "siteAndDateOfCreation": "Ort und Datum der Erstellung",
    "auditSequences": "Auditsequenzen",
    "sequences": "Begutachtungsplan",
    "date": "Datum",
    "timeFrom": "Uhrzeit von",
    "timeTill": "Uhrzeit bis",
    "timeTo": "Uhrzeit bis",
    "organisationalParts": "Auditierte Org.-einheit  Personen",
    "elemtensComments": "Element(e)  Erläuterung",
    "jugedScope": "Beurteilter Bereich",
    "referenceYear": "Bezugsjahr",
    "beginPerformance": "Beginn",
    "endPerformance": "Ende",
    "selection": "Auswahl",
    "titleAndDate": "Titel und Datum",
    "standardsAndQuestionnaires": "Normen und Fragenkataloge",
    "headAuditor": "Leitender Berater",
    "auditor": "Berater",
    "startAudit": "Beurteilung starten",
    "catalog": "Katalog",
    "standards": "Normen",
    "questionnaires": "Fragenkataloge",
    "legalBasis": "Rechtsgrundlage",
    "textModule": "Textbaustein",
    "creationDate": "Erstellungsdatum",
    "scopes": "Bereiche",
    "includedDocuments": "Eingesehene Dokumente",
    "participant": "Teilnehmer",
    "coverImage": "Titelbild",
    "notes": "Notizen",
    "reportAudit": "Bericht",
    "choose": "Wählen",
    "settings": "Einstellungen",
    "categories": "Kategorien",
    "category": "Kategorie",
    "categoryName": "Kategoriename",
    "questions": "Fragen",
    "reportPDF": "Bericht als PDF",
    "reportPDFLong": "Bericht als PDF (lang)",
    "reportPDFShort": "Bericht als PDF (kurz)",
    "report": "Bericht",
    "number": "Nummer",
    "position": "Funktion",
    "availability": "Verfügbarkeit",
    "constantly": "Dauernd",
    "atTimes": "Zeitweise",
    "participants": "Teilnehmer",
    "minimumCriterion": "Mindestkriterium",
    "question": "Frage",
    "answer": "Antwort",
    "notAnswered": "unbeantw.",
    "notFulfilled": "Unerfüllt",
    "actions": "Maßnahmen",
    "comment": "Notiz",
    "potential": "Interne Notiz",
    "images": "Bilder",
    "smsNumber": "SMS-Nummer",
    "menu": "Menü",
    "consultantsModuleDescription": "Daten des Dienstleisters",
    "clientsModuleDescription": "Mandantenauswahl anzeigen",
    "auditsModuleDescription": "Beurteilungen",
    "questionnairesModuleDescription": "Für spezifische Audits",
    "textModulesModuleDescription": "Für Begehung  Audit  KVM",
    "entries": "Einträge",
    "details": "Details",
    "headConsultant": "Leitender Auditor",
    "yes": "Ja",
    "no": "Nein",
    "first": "Erst",
    "repeat": "Wiederholung",
    "logo": "Logo",
    "document": "Dokument",
    "timing": "Zeitliche Gestaltung",
    "topics": "Themen",
    "topic": "Thema",
    "result": "Ergebnis",
    "consultation": "Beratung",
    "active": "Aktuell",
    "archived": "Archiviert",
    "archive": "Archivieren",
    "restore": "Wiederherstellen",
    "duration": "Zeitraum",
    "notRelevant": "Irrelevant",
    "openActionsPDF": "Offene Maßnahmen als PDF",
    "workEquipmentInspection": "Arbeitsmittelprüfung",
    "shelfInspections": "Regalprüfungen",
    "InspectionforShelfs": "Prüfung der Regalanlagen",
    "inspectionDate": "Prüfungszeitpunkt",
    "reportDate": "Datum der Ausfertigung",
    "today": "Heute",
    "signHere": "Hier unterzeichnen",
    "signatureShelfInspector": "Unterschrift Regalprüfer",
    "wrapUpOfResults": "Zusammenfassung der Ergebnisse",
    "shelfId": "Regalidentifikation",
    "shelfInspection": "Regalprüfung",
    "shelfs": "Regale",
    "shelf": "Regal",
    "shelfInfo": "Angaben zum Regal",
    "identification": "Identifikation",
    "constructionYear": "Baujahr",
    "location": "Standort",
    "locationBauhaus": "Lagerstelle",
    "shelfType": "Regalart bzw. Sonderausführung",
    "chargeCarrier": "Ladungsträger, Hilfsmittel",
    "shelfLevels": "Anzahl der Fachebenen",
    "sitesPerLevel": "Anzahl der Plätze pro Regalfach",
    "areasPerRow": "Anzahl der Felder pro Regalzeile",
    "operation": "Regalbedienung",
    "safetyCommissioner": "Sicherheitsbeauftragter",
    "safetyCommissionerShelf": "Sicherheitsbeauftragter für Regale",
    "safetyCommissionerForShelf": "Sicherheitsbeauftragte(r) für̈ dieses Regal",
    "overViewDetailImages": "Übersichts- und Detailfotos",
    "shelfInspectionIncomplete": "Die Regalprüfung hat noch offene Punkte. Möchten Sie dennoch einen Bericht erstellen?\n\nFolgende Punkte sind noch offen:\n",
    "shelfMakerDetails": "Angaben zum Hersteller",
    "manufacturer": "Hersteller",
    "makerNote": "Notizen",
    "manufactureDocsAvailable": "Herstellerunterlagen vorhanden",
    "constructedAccordingToSpecification": "Aufbau nach Herstellervorgaben erfolgt",
    "additionalNotes": "Ergänzende Angaben zum Regal oder zur Aufstellung",
    "defects": "Mängel",
    "notCheckable": "Nicht überprüfbar",
    "true": "Ja",
    "false": "Nein",
    "handledBy": "Erledigung durch",
    "toHandleUntil": "Erledigungsdatum",
    "areaInShelf": "Stelle im Regal",
    "shelfCorridor": "Regalgang Regalreihe",
    "shelfField": "Regalfeld",
    "fieldIdentification": "Lagerstelle Platzidentifikation",
    "selectConsultant": "Berater wählen",
    "deselectConsultant": "Berater abwählen",
    "selectClient": "Mandant wählen",
    "deselectClient": "Mandant abwählen",
    "removeAccount": "Account entfernen",
    "sureToRemoveAccount": "Möchten Sie diesen Account wirklich entfernen?",
    "home": "Home",
    "moduleMembership": "Modulzugehörigkeit",
    "clientShelfInspection": "Kunde",
    "consultantShelfInspection": "Regalprüfer",
    "auditSystemID": "SMS-Nr.",
    "empty": "Leer",
    "selected": "Gewählt",
    "select": "Wähle",
    "selectFirst": "Wähle zuerst",
    "inclusive": "Inklusive",
    "licenceUntil": "Lizenziert bis",
    "noLicence": "Nicht Lizenziert",
    "testmode": "Muster",
    "testmodeUntil": "Musterbetrieb bis",
    "testModeNoReports": "Das Versenden von Berichten ist im Testmodus nicht möglich.",
    "generalLetter": "Sehr geehrte Damen und Herren,\n\nanbei finden Sie die bei unserem letzten Termin vereinbarte Dokumentation.\nFür Rückfragen oder Anmerkungen bitten wir um entsprechende Rückmeldung.\n\nMit freundlichen Grüßen",
    "customerID": "Kundennummer",
    "unlimited": "Unbefristet",
    "function": "Funktion",
    "functions": "Funktionen",
    "functionsSubtitle": "Tätigkeiten und Arbeitsbereiche",
    "contractYearBeginning": "Vertragsjahr von",
    "contractYearEnding": "Vertragsjahr bis",
    "additionalContactPersons": "Weitere Ansprechpartner",
    "precedingShelfInspection": "Vorangegangene Prüfung",
    "duplicateShelfInspection": "Regalprüfung duplizieren",
    "toDoUntil": "Bis",
    "setDate": "Datum angeben",
    "coming": "Kommt",
    "topicsBasicCare": "Themen der Grundbetreuung",
    "topicsBasicCareMedic": "Themen der Arbeitsmedizin",
    "topicsCompanySpecific": "Themen der Betriebsspezifik",
    "soonLicenceable": "Bald lizenzierbar",
    "meetings": "Sitzungen",
    "instruction": "Unterweisung",
    "instructions": "Unterweisungen",
    "instructionsDescription": "Unterweisungen, Revisionen, Schulungen",
    "activitySpecificRiskAssessment": "Begehung  Audit  KVM",
    "performance_Confirmation": "Leistungs-\\nbestätigungen",
    "performanceConfirmations": "Leistungsbestätigungen",
    "performanceConfirmation": "Leistungs-Bestätigung",
    "statusReport": "Statusbericht  Jahresbericht",
    "statusReports": "Statusberichte  Jahresberichte",
    "map": "Karte",
    "testmodeEnded": "Abgelaufen",
    "notLicenced": "Nicht lizenziert",
    "licenced": "Lizenziert",
    "performanceModule": "Leistungen",
    "accountOverview": "Accountübersicht",
    "usePasscode": "Zugriffskontrolle aktivieren",
    "customTextModules": "Eigene Textbausteine",
    "defaultTextModules": "System Textbausteine",
    "inspections": "Begehungen",
    "basisAccess": "Medisoft Basis",
    "url": "Adresse (URL)",
    "user": "Nutzername",
    "password": "Passwort",
    "begin": "Beginn",
    "end": "Ende",
    "subClient": "Abteilung  Niederlassung",
    "recID": "Eintrag Nr.",
    "employeeProbed": "Anzahl untersuchter Beschäftigter",
    "employeeUnprobed": "Anzahl nicht untersuchter Beschäftigter",
    "inclReturnJourney": "Inkl. Rückfahrt",
    "summary": "Zusammenfassung",
    "remark": "Anmerkungen",
    "signature": "Unterschrift",
    "hasSigned": "Unterschrift geleistet",
    "performingUser": "Durchführender Berater",
    "responsibleStaff": "Verantwortlicher zuständiger Mitarbeiter",
    "staff": "Mitarbeiter",
    "visibility": "Sichtbarkeit",
    "visibilityConsultant": "Sichtbar für Berater",
    "onlySelectedConsultant": "Gewählter",
    "allConsultants": "Alle",
    "contactPerson": "Ansprechpartner",
    "shortName": "Kürzel",
    "sureToDeleteInclClients": "Beachten Sie, dass auch alle diesem Berater exklusiv zugeordneten Mandanten entfernt werden.",
    "duplicateAudit": "Audit duplizieren",
    "basisSettings": "Basis-Schnittstelle",
    "logoutBasis": "Basis-Sitzung beenden",
    "lastUpload": "Letzter Basis Upload",
    "activities": "Aktivitäten",
    "keyword": "Stichwort",
    "reloadBasis": "Basisdaten aktualisieren",
    "clearAndReloadBasis": "Basisdaten neu laden",
    "restorePurchases": "Käufe wiederherstellen",
    "documents": "Dokumente",
    "others": "Sonstiges",
    "uploadStatus": "Status Upload",
    "filename": "Dateiname",
    "general": "Allgemein",
    "defaultTextModuleCategories": "System Textbausteingruppen",
    "customTextModuleCategories": "Eigene Textbausteingruppen",
    "defaultTextModuleCategoriesShort": "System",
    "customTextModuleCategoriesShort": "Eigene",
    "textModuleCategories": "Textbausteingruppen",
    "sequenceDays": "Planungstage",
    "defaultQuestionnaireCatalogues": "System Fragenkataloge",
    "customQuestionnaireCatalogues": "Eigene Fragenkataloge",
    "questionnaireCatalogues": "Fragenkataloge",
    "versions": "Versionen",
    "version": "Version",
    "dateCreated": "Erstellt",
    "sortPrefix": "Sortierungsnr.",
    "legalbasis": "Grundlage  Norm",
    "regulations": "Regelwerke",
    "predefinedActions": "Vordefinierte Maßnahmen",
    "isMinimumCriterion": "Mindestkriterium",
    "involvedPersons": "Beteiligte Personen",
    "auditScopes": "Scopes  Fachbereiche",
    "questionnaire": "Fragenkatalog",
    "auditReportBasicCare": "Gefährdungsanalyse (Grundthemen)",
    "auditReportBasicCareMedic": "Gefährdungsanalyse (Arbeitsmedizin)",
    "auditReportCompanySpecific": "Gefährdungsanalyse (Betriebsspezifische Themen)",
    "upload": "Hochladen  versenden",
    "riskTopics": "Gefährdungen  Themen",
    "type": "Art",
    "priority": "Priorität",
    "technicalTypeEG": "Technisch (z.B. Instandsetzung)",
    "organizationalTypeEG": "Organisatorisch (z.B. Unterweisungen planen)",
    "personalTypeEG": "Persönlich (z.B. Schutzbrille bereitstellen)",
    "lowPrioEG": "Hoch (Abweichung)",
    "midPrioEG": "Mittel (Feststellung)",
    "highPrioEG": "Gering (Hinweis)",
    "notRated": "Nicht beurteilt",
    "noInfo": "Keine Angabe",
    "relevant": "Relevant",
    "approved": "Bestätigt",
    "responsibleForImplementation": "Verantwortlich für die Umsetzung",
    "toCompleteBy": "Erledigung durch",
    "completionDate": "Erledigungsdatum",
    "backTemplateDate": "Wiedervorlage",
    "extremeRare": "Extrem gering",
    "rare": "Sehr gering",
    "unlikely": "Eher gering",
    "possible": "Mittel",
    "high": "Hoch",
    "veryHigh": "Sehr hoch",
    "extremeHigh": "Extrem hoch",
    "levelOfRisk": "Schadensschwere",
    "likelihoodOfRisk": "Eintrittswahrscheinlichkeit",
    "riskRatingNow": "Risikoabschätzung (Ist-Zustand)",
    "riskRatingAfterCompletion": "Risikoabschätzung (Nach Umsetzung)",
    "riskResult": "Risikobewertung",
    "riskResultNohl": "Risikobewertung nach Nohl",
    "riskResultAfterCompletion": "Risikobewertung (Nach Umsetzung)",
    "rareRisk": "praktisch unmöglich",
    "unlikelyRisk": "Vorstellbar",
    "possibleRisk": "Durchaus möglich",
    "likelyRisk": "Zu erwarten",
    "certainRisk": "Fast gewiss",
    "negligibleRiskLikelihood": "Keine gesundheitlichen Folgen",
    "minorRiskLikelihood": "Bagatellfolgen",
    "moderateRiskLikelihood": "mäßig schwere Folgen",
    "significantRiskLikelihood": "schwere Folgen",
    "severeRiskLikelihood": "Tödliche Folgen",
    "classificationOfRisk": "Einstufung",
    "auditDefaultReport": "Gefährdungsbeurteilung",
    "auditDefaultReportLong": "Gefährdungsbeurteilung (lang)",
    "auditDefaultReportShort": "Gefährdungsbeurteilung (kurz)",
    "auditDefaultKVM": "KVM",
    "auditDefaultKVMLong": "KVM (lang)",
    "auditDefaultKVMShort": "KVM (kurz)",
    "reportRiskAssesment": "Gefährdungsbeurteilung",
    "reportRiskAssesments": "Gefährdungsbeurteilungen",
    "reportDefault": "Bericht",
    "reportDefaultLong": "Bericht (lang)",
    "reportDefaultShort": "Bericht (kurz)",
    "reportKVM": "Korrektur- und Vorbeugemaßnamenplan",
    "reportDefaultDescription": "Der Bericht dient als Checkliste zur Abfrage der Anforderungen.",
    "reportRiskAssesmentDescription": "Die Gefährdungsbeurteilung dient als Checkliste zur Abfrage der Anforderungen im Arbeits- und Gesundheitsschutz.",
    "reportKVMDescription": "Der Korrektur- und Vorbeugemaßnahmenplan (KVM) stellt das Ergebnis dar.",
    "occupationalSafetyOrganization": "Arbeitsschutz-Organisation",
    "occupationalSafetyOrganizationUnsplitted": "Arbeitsschutzorganisation",
    "organizationalPosition": "Organisationsstellung",
    "auditPreselectionFootnote": "Hier legen Sie die Relevanz der Kategorien fest. Ausgeschaltete Kategorien erscheinen entsprechend weder bei der Befragung noch dem Bericht.",
    "auditRelevanceCategories": "Relevanz der Kategorien",
    "signs": "Beschilderung",
    "signsModule": "Beschilderung",
    "signsModuleSubtitle": "Gefahr-, Verbot-, Warn-, Rettungs-Gebotszeichen, Brandklassen",
    "referenceModule": "Nachschlagewerke",
    "firstAidKitModule": "Verbandkästen",
    "firstAidKitModuleSubtitle": "Inhalte der Verbandkästen",
    "webSiteModule": "Rechtliches und Links",
    "webSiteModuleSubtitle": "Regeln, Gesetze, Vorschriften im Web",
    "portASModule": "Dokumenten-Management",
    "portASModuleSubtitle": "Port.AS",
    "ok": "OK",
    "cancel": "Abbrechen",
    "copy": "(Duplikat)",
    "copyQuestionaire": "Editierbares Duplikat der Fragen anlegen",
    "copyQuestionnaireTitle": "Duplikat anlegen",
    "copyQuestionnaireMessage": "Möchten Sie ein Duplikat des Fragenkatalogs anlegen? Dieses Duplikat ist dann in den eigenen Fragenkatalogen zugänglich und kann dort bearbeitet werden.",
    "representative": "Betrieblich Beauftragter",
    "representatives": "Beauftragte",
    "representativesLong": "Betrieblich Beauftragte",
    "representativeSubtitle": "Pflichtenübertragung",
    "representativeContract": "Schriftliche Beauftragung liegt vor",
    "representativeQualified": "Qualifikationsnachweis liegt vor",
    "representativeResponsibility": "Verantwortungsbereich",
    "representativeAddResponsibility": "Verantwortungsbereich hinzufügen",
    "representativeInternExtern": "Int. Mitarbeiter\nExt. Beauftragter",
    "intern": "Intern",
    "extern": "Extern",
    "shouldSaveAsRepresentative": "Soll diese Person als betrieblich Beauftragter gespeichert werden?",
    "wasteRep": "Abfallbeauftragter",
    "medicinRep": "Arbeits-Betriebsmedizin",
    "personelRep": "Beauftragter für leistungsgewandelte Personen",
    "qualityRep": "Beauftragter für Qualitätsmanagement",
    "workSafetyRep": "Betrieblicher Ansprechpartner für Arbeitssicherheit",
    "fireRep": "Brandschutzbeauftragter",
    "fireHelpRep": "Brandschutzhelfer",
    "dataProtectionRep": "Datenschutzbeauftragter",
    "1stAidRep": "Ersthelfer",
    "explosivesRep": "Explosionsschutzbeauftragter",
    "hazardRepresentativeRep": "Gefahrgutbeauftragter",
    "hazardSubstanceRepresentativeRep": "Gefahrstoffbeauftragter",
    "resourcesRep": "Geräte-Betriebsmittelbeauftragter",
    "hygieneRepresentativeRep": "Hygienebeauftragter",
    "hygieneProfessionalRep": "Hygienefachkraft",
    "laserRepresentativeRep": "Laserschutzbeauftragter",
    "laserResponsibleRep": "Laserschutzverantwortlicher",
    "safetyRep": "Sicherheitsbeauftragter",
    "safetyMedicRep": "Sicherheitsbeauftragter Medizinprodukte",
    "safetyOfficerRep": "Sicherheitsfachkraft (SiFa)  Fachkraft für Arbeitssicherheit (FASi)",
    "sterializationRep": "Sterilisationassistent",
    "radiationRepresentativeRep": "Strahlenschutzbeauftragter",
    "radiationResponsibleRep": "Strahlenschutzverantwortlicher",
    "environmentRep": "Umweltschutzbeauftragter",
    "sort": "Sortierung",
    "ascending": "Aufsteigend",
    "descending": "Absteigend",
    "unsorted": "Unsortiert",
    "reportsModule": "Berichte",
    "reportsModuleSubtitle": "Export",
    "reportType": "Berichtart",
    "fileNameSpecificAudit": "Spezifischer Audit",
    "fileNameBasicCareAudit": "Audit Grundbetreuung",
    "fileNameCompanySpecificAudit": "Betriebsspezifischer Audit",
    "fileNameSpecificRiskAssessmentAudit": "Begehung",
    "fileNameAudit": "Audit",
    "createReports": "Erzeuge Berichte",
    "waitingForReports": "Bitte warten Sie, bis die Berichte fertig sind.",
    "auditDefaultTitle": "BegehungAuditKVM",
    "maternity": "Mutterschutz",
    "maternitySubtitle": "Gefährdungsbeurteilung bei Schwangerschaft",
    "generalMaternityAudits": "Tätigkeitsspezifische Gefährdungsbeurteilungen",
    "individualMaternityAudits": "Individuelle Gefährdungsbeurteilungen",
    "generalMaternityAuditsLongDescription": "Tätigkeitsspezifische Gefährdungsbeurteilungen durch Unternehmer  seine Beauftragten",
    "individualMaternityAuditsLongDescription": "Individuelle Gefährdungsbeurteilungen durch Vorgesetzte mit der Schwangeren",
    "generalMaternityAudit": "Tätigkeitsspezifische Gefährdungsbeurteilung",
    "individualMaternityAudit": "Individuelle Gefährdungsbeurteilung",
    "maternityAuditType": "Art der Gefährdungsbeurteilungen",
    "generalMaternityAuditShortDescription": "Tätigkeitsspezifische GB",
    "individualMaternityAuditShortDescription": "Individuelle GB",
    "reportMaternityAuditShortDescription": "Meldung Schwangerschaft",
    "maternityPreselectionFootnote": "Hier legen Sie die Relevanz der Kategorien fest. Bei ausgeschalteten Kategorien werden alle Einzelfragen als irrelevant gekennzeichnet.",
    "maternityLetter": "Sehr geehrte Damen und Herren,\n\nanbei finden Sie die Meldung einer Schwangerschaft in unserem Unternehmen inklusive der Gefährdungsbeurteilung.",
    "maternityResultNoAction": "Es sind aufgrund der aktuellen Einschätzung sowie Beurteilung der Arbeitsbedingungen gem. §5 ArbSchG keine besonderen Maßnahmen erforderlich. Die Schwangere kann unter den gegebenen Bedingungen ohne besondere, tätigkeitsbedingte Gefährdung weiter beschäftigt werden.",
    "maternityResultActionG02": "Der Arbeitgeber hat die Arbeitsbedingungen für die schwangere oder stillende Frau durch Schutzmaßnahmen nach Maßgabe des §9 Absatz 2 umzugestalten.",
    "maternityResultActionG03": "Kann der Arbeitgeber unverantwortbare Gefährdungen für die schwangere oder stillende Frau nicht durch die Umgestaltung der Arbeitsbedingungen nach Nummer 1 ausschließen oder ist eine Umgestaltung wegen des nachweislich unverhältnismäßigen Aufwandes nicht zumutbar, hat der Arbeitgeber die Frau an einem anderen geeigneten Arbeitsplatz einzusetzen, wenn er einen solchen Arbeitsplatz zur Verfügung stellen kann und dieser Arbeitsplatz der schwangeren oder stillenden Frau zumutbar ist.",
    "maternityResultActionG04": "Kann der Arbeitgeber unverantwortbare Gefährdungen für die schwangere oder stillende Frau weder durch Schutzmaßnahmen nach Nummer 1 noch durch einen Arbeitsplatzwechsel nach Nummer 2 ausschließen, darf er die schwangere oder stillende Frau nicht weiter beschäftigen.",
    "rater": "Beurteiler",
    "optionalDate": "Datum, falls relevant",
    "optionalText": "Text, falls relevant",
    "pregnantPerson": "Schwangere",
    "generalStoreModule": "Allgemeiner Store",
    "allInclusive": "Vollversion mit allen Modulen",
    "allInclusiveDescription": "",
    "insuranceCompanyNumber": "Berufsgenossen-\nschaftUnfallver-\nsicherungsträger",
    "insuranceMemberNumber": "Mitgliedsnummer",
    "gender": "Anrede",
    "male": "Herr",
    "female": "Frau",
    "expert": "Fachkraft",
    "experts": "Fachkräfte",
    "siFa": "Fachkraft für Arbeitssicherheit",
    "siFa2": "Fachkraft für Arbeitssicherheit 2",
    "BA": "Betreuender Betriebsarzt",
    "BA2": "Betreuender Betriebsarzt 2",
    "BA2AsA": "AMD",
    "lastSchedule": "Letzter Termin",
    "lastInspection": "Letzte Begehung",
    "hoursNeeded": "Einsatzzeit vor Ort = Soll (Stunden)",
    "hoursMade": "Einsatzzeit vor Ort = Ist (Stunden)",
    "hoursRemaining": "Einsatzzeit vor Ort = Rest (Stunden)",
    "personID": "Fachkraft",
    "employeesCommercial": "Anzahl Mitarbeiter gewerblich",
    "employeesNotCommercial": "Anzahl Mitarbeiter nicht gewerblich",
    "reportClientOverview": "Mandanten-Stammblatt",
    "reportClientList": "Mandantenliste",
    "import": "Import",
    "export": "Export",
    "questionnaireImport": "Möchten Sie den Fragenkatalog importieren?",
    "questionnaireImportFinished": "Der Fragenkatalog wurde erfolgreich importiert!",
    "questionnaireImportFailed": "Der Fragenkatalog konnte nicht importiert werden!",
    "psyche": "Psychische Belastung",
    "psyche-subTitle": "",
    "psycheImportAudits": "Möchten Sie die Befragungen zur GB \"Psychische Belastung importieren\"?",
    "psycheImportAuditsFinished": "Es wurden ## Befragungen erfolgreich importiert!",
    "psycheImportAuditsSkipped": "## Befragungen wurden übersprungen, da Sie schon vorhanden waren.",
    "importNotLoggedInError": "Es ist kein Nutzer angemeldet, es kann daher kein Datenimport stattfinden!",
    "psycheEvaluation": "Auswertung",
    "psycheAudit": "Befragung",
    "psycheAudits": "Befragungen",
    "startPsycheAudit": "Befragung starten",
    "psycheResponse": "Trifft die Aussage der Frage zu?",
    "psycheResponseGoalAnalysis": "Wie würden Sie die Aussage bewerten, wenn die Situation im Unternehmen genauso wäre, wie Sie diese gerne hätten?",
    "hint": "Hinweis",
    "fullyFalse": "Nein",
    "partlyFalse": "Eher nein",
    "partlyTrue": "Eher ja",
    "fullyTrue": "Ja",
    "psycheSection": "Abteilung",
    "psycheFunction": "Tätigkeit",
    "psycheSections": "Abteilungen",
    "psycheFunctions": "Tätigkeiten",
    "psycheSelectAudits": "Auswahl der Befragungen",
    "createPsycheEvaluation": "Auswertung starten",
    "psycheActions": "Aktionen",
    "startDate": "Datum von",
    "endDate": "Datum bis",
    "filter": "Filter",
    "attachments": "Anhänge",
    "enclosures": "Anlagen",
    "priorityList": "Gewichtete Fragen-Liste",
    "detailList": "Detaillierte Auswertung der Fragen",
    "psycheGraph": "Grafische Auswertung",
    "psycheNumberAudits": "Anzahl der Befragungen",
    "psycheLegalDefaultValue": "§5 Arbeitsschutzgesetz",
    "psycheAuditDefaultTitle": "Beurteilung der Arbeitsbedingungen zu psychischen Belastungsfaktoren",
    "psycheEvaluationDefaultTitle": "Auswertung der Befragung",
    "psycheLegalBasis": "Grundlage ist das Arbeitsschutzgesetz<br>§5 Beurteilung der Arbeitsbedingungen, Punkt 3.6",
    "psychePrioritySummaryDescription": "Wichtigste Themen, die Maßnahmen erfordern",
    "psycheSummaryTotalDescription": "Zusammengefasstes Gesamtergebnis über alle Fragebögen, Durchschnitt über alle Antwortmöglichkeiten",
    "psycheSummaryDetailDescription": "Gesamtergebnis über alle Fragebögen, getrennt nach Antwortmöglichkeiten",
    "psycheRatingBad": "Die Arbeitsbedingung wird als ungünstig oder mangelhaft gestaltet bewertet. Die arbeitswissenschaftlichen Gestaltungsempfehlungen sind nicht erreicht. Maßnahmen der Arbeitsgestaltung sind erforderlich.",
    "psycheRatingMedium": "Die Arbeitsbedingung wird als mittelmäßig gestaltet bewertet. Die arbeitswissenschaftlichen Gestaltungsempfehlungen sind noch nicht erreicht. Es sind entsprechende Maßnahmen erforderlich, um daraus eine gesundheitsfördernde Ressource zu entwickeln.",
    "psycheRatingGood": "Die Arbeitsbedingung wird im Mittel sehr positiv bewertet. Die arbeitswissenschaftlichen Gestaltungsempfehlungen sind erreicht. Der Aspekt hat den Charakter einer gesundheitsfördernden Ressource.",
    "psycheGoal": "Soll",
    "psycheCurrent": "Ist",
    "psycheGoalHeading": "Auswertung bzgl. Soll-Zustand",
    "psycheCurrentHeading": "Auswertung bzgl. Ist-Zustand",
    "psycheEvaluationReportTitle": "Auswertung \"Psychische Belastungsfaktoren bei der Arbeit\"",
    "startInput": "Eingabe starten",
    "all": "Alle",
    "noDateLimit": "Keine Beschränkung",
    "reportPDFNotes": "AnmerkungenNotizen (nur zum internen Gebrauch)",
    "fullyTrueReport": "Trifft voll zu",
    "partlyTrueReport": "Trifft eher zu",
    "partlyFalseReport": "Trifft eher nicht zu",
    "fullyFalseReport": "Trifft nicht zu",
    "trueReport": "Trifft zu",
    "falseReport": "Trifft nicht zu",
    "anserwedWith": "Beantwortet mit",
    "unansweredReport": "Unbeantwortet",
    "psycheExportQuestionnaire": "Fragebogen exportieren",
    "psycheResultPositive": "Positiv (keine Maßnahmen erforderlich)",
    "psycheResultPartlyPositive": "Bedingt positiv (nur wenige Maßnahmen sind erforderlich)",
    "psycheResultPartlyNegative": "Bedingt negativ (einige Maßnahmen sind erforderlich)",
    "psycheResultFullyNegative": "Negativ (erhebliche Maßnahmen sind erforderlich)",
    "psycheResultNegative": "negativ (Maßnahmen sind erforderlich)",
    "psycheMatchingAudits": "Anzahl der zu den Kriterien passenden Befragungen",
    "psycheExportMatchingAudits": "Export der zu den Kriterien passenden Befragungen",
    "purchaseAllSuccess": "Kauf war erfolgreich. Ihre Lizenzlaufzeit wurde für alle Module aktualisiert.",
    "purchaseSuccess": "Kauf war erfolgreich. Ihre Lizenzlaufzeit wurde aktualisiert.",
    "purchaseFailed": "Fehler beim Kauf - Versuchen Sie es zu einem späteren Zeitpunkt erneut.",
    "purchaseConsultantsSuccess": "Kauf war erfolgreich. Sie verfügen dauerhaft über eine unbegrenzte Anzahl von Beratern.",
    "purchaseClientsSuccess": "Kauf war erfolgreich. Sie verfügen dauerhaft über eine unbegrenzte Anzahl von Mandanten.",
    "purchaseAppStoreFailure": "Fehler vom App Store erhalten.",
    "purchasesRestored": "Käufe wiederhergestellt",
    "purchasesRestoredFailure": "Fehler beim Wiederherstellen der Käufe.",
    "licenseForModule": "Lizenz für Modul:",
    "shelfInspectionInfo": "Die Regalprüfung ist gem. den Forderungen der EN 15635 aufgebaut.",
    "cientsModuleInfo": "Unbegrenzte Anzahl Mandanten.",
    "allModulesInfo": "Lizenz für alle Module.",
    "purchaseAllInfo": "Der einmalige Kauf verlängert Ihre Lizenz für alle Module um den angegeben Zeitraum (kein Abo - keine automatische Verlängerung).",
    "purchaseClientsInfo": "Mit diesem einmaligen Kauf schalten Sie dauerhaft eine unbegrenzte Anzahl an Mandanten frei.",
    "purchaseConsultantsInfo": "Mit diesem einmaligen Kauf schalten Sie dauerhaft eine unbegrenzte Anzahl an Beratern frei.",
    "consultantsModuleInfo": "Unbegrenzte Anzahl Berater.",
    "purchaseModuleInfo": "Der einmalige Kauf verlängert Ihre Lizenz um den angegeben Zeitraum (kein Abo - keine automatische Verlängerung).",
    "dateSigned": "Zeitpunkt der Unterschrift",
    "checkDone": "Leistung erbracht",
    "timespan": "Einsatzzeit",
    "counter": "Anzahl",
    "shelfCounter": "Lfd. Meter  Felder",
    "timeStart": "Ankunft an der Betriebsstätte",
    "timeEnd": "Verlassen der Betriebsstätte",
    "lastVisit": "Mit dem heutigen Besuch ist die Betreuung für das laufende Vertragsjahr abgeschlossen.",
    "feedback": "Rückmeldungen, Feedback",
    "suggestions": "Verbesserungsvorschläge des Kunden",
    "suggestions2": "Verbesserungs-\nvorschläge des Kunden",
    "introduction": "Erläuterungen für Bericht",
    "rating": "Bewertung durch den Kunden",
    "ratingPerformance": "Bewertung der Betreuung vor Ort",
    "ratingCommunication": "Bewertung der zentralen Dienstleistung",
    "reportAccounting": "Abrechnungsformular",
    "reportAsaShort": "Leistungsübersicht",
    "performanceConfirmationInfoIncluded": "Der Ersteller der übermittelten Dokumentation wurde beauftragt, gem. ArbSchG §5 über eine Beurteilung die mit ihrer Arbeit verbundenen Gefährdungen für Beschäftigte zu ermitteln und aufzuzeigen, welche Maßnahmen des Arbeitsschutzes erforderlich sind. Es handelt sich dabei um eine Beratungsleistung, deren Umsetzung immer in der Verantwortung der jeweiligen Führungskraft liegt. Sie, die Führungskräfte entscheiden, ob Sie die Maßnahmen wie empfohlen umsetzen, später umsetzen, nicht oder anders umsetzen. Eine Weisungsbefugnis hat der Ersteller der Dokumentation nicht.",
    "performanceConfirmationInfoExtraOther": "Die aufgeführten Leistungen wurden gemäß Beauftragung bzw. Absprache durchgeführt.\nHinweis = aufgrund von Vorleistungen oder Nachbearbeitung können die Beratungen einen zusätzlichen Aufwand auslösen, der im Einzelfall abgestimmt und zusätzlich abgerechnet wird.",
    "performanceConfirmationInfoExtraTechnology": "Alle aufgeführten Arbeitsmittelprüfungen wurden gemäß Beauftragung nach den Rahmenbedingungen aus Normen, BG Vorschriften, Betriebssicherheitsverordnung oder entsprechender, einschlägiger Vorgaben nach den anerkannten Regeln der Technik durchgeführt.",
    "performanceConfirmationInfoExtraMedicine": "Die aufgeführten, arbeitsmedizinischen Leistungen wurden auftragsgemäß im Rahmen der ArbMedVV, DGUV Grundsätze oder sonstiger rechtlich geregelter Rahmenbedingungen durchgeführt.",
    "performanceConfirmationInfoExtraConsulting": "Die Dokumentation in Bezug auf betriebsspezifische Themen wurde gesondert beauftragt. Mit der Übermittlung obliegt die Beachtung und Umsetzung eventuell erforderlicher oder empfohlener Maßnahmen beim Unternehmer bzw. der von ihm eingesetzten Führungskräfte.",
    "performanceConfirmationSummary": "Wir führten die vom Gesetzgeber, der Berufsgenossenschaft bzw. der Aufsichtsbehörde geforderten Maßnahmen für Sie durch und haben die jeweils gesetzlich geforderte Dokumentation in Ihrem Namen erstellt und gespeichert.\nDer Unternehmer  seine Führungskräfte sind zur Beurteilung und Dokumentation verpflichtet.\nEs wurden folgende Leistungen durch unseren Dienst  unsere Fachkraft gemäß Auftrag  Vertrag erbracht:",
    "performanceExclusive": "Leistungen außerhalb der Einsatzzeit",
    "performanceInclusive": "Leistungen im Rahmen der Einsatzzeit",
    "performanceExclusiveShort": "Außerhalb der Einsatzzeit",
    "performanceInclusiveShort": "Im Rahmen der Einsatzzeit",
    "performanceCategoryConsulting": "Betreuung und Beratung",
    "performanceCategoryTechnology": "Arbeitsmittelprüfung",
    "performanceCategoryMedicine": "Arbeitsmedizin",
    "performanceCategoryOther": "Sonstige spezifische Beratung",
    "care": "Betreuung",
    "time": "Zeit",
    "timeAtLocation": "Zeit vor Ort",
    "remarkInternal": "Hinweise an Innendienst",
    "consultantGeneral": "Dienstleister",
    "clientFirm": "Betriebsstätte",
    "reportForClient": "Bericht für Kunde",
    "visits": "Besuche",
    "visitsNeeded": "Einsatzzeit vor Ort = Soll (Besuche)",
    "visitsMade": "Einsatzzeit vor Ort = Ist (Besuche)",
    "visitsRemaining": "Einsatzzeit vor Ort = Rest (Besuche)",
    "sum": "Summe:",
    "meetingTypeAsA": "Arbeitsschutzausschuss-Sitzung",
    "meetingTypeMeeting": "Sitzung",
    "meetingTypeDiscussion": "Besprechung",
    "meetingItemRecap": "Gründungssitzung oder Besprechung des letzten Protokolls",
    "meetingItemSpecialist": "Bericht der Fachkraft für Arbeitssicherheit",
    "meetingItemSafety": "Bericht des  der Sicherheitsbeauftragten",
    "meetingItemEmployeeRepresentation": "Themen der Mitarbeitervertretung",
    "meetingItemDoctor": "Bericht des Betriebsarztes  AMD",
    "meetingItemManagement": "Themen der Unternehmensleitung",
    "meetingItemOrganizational": "Organisatorische Maßnahmen (KVM)",
    "meetingItemLegal": "Rechtliche Neuerungen",
    "meetingItemOther": "Sonstiges",
    "draft": "Entwurf",
    "final": "Final",
    "dateOfNextMeeting": "Datum der nächsten Sitzung",
    "locationOfNextMeeting": "Ort der nächsten Sitzung",
    "nextMeeting": "Nächste Sitzung",
    "participateVia": "Teilnahme",
    "meetingParticipant": "Teilnehmer",
    "meetingMember": "Mitglied",
    "startMeeting": "Sitzung starten",
    "meetingStatusPersonal": "Persönlich",
    "meetingStatusPhone": "Telefonkonferenz",
    "meetingStatusVideo": "Videokonferenz",
    "meetingStatusMissingExcused": "Fehlt entschuldigt",
    "meetingStatusMissingUnexcused": "Fehlt unentschuldigt",
    "meetingStatusPersonalShort": "Persönlich",
    "meetingStatusPhoneShort": "Telefon-\nkonferenz",
    "meetingStatusVideoShort": "Video-\nkonferenz",
    "meetingStatusMissingExcusedShort": "Fehlt\nentschuldigt",
    "meetingStatusMissingUnexcusedShort": "Fehlt\nunentschuldigt",
    "meetingSignatures": "Foto der Unterschriften  Anlagen",
    "meetingDuplicate": "Sitzung duplizieren",
    "meetingDuplicateFootnote": "Die Sitzungsdaten (Teilnehmer und TOP) werden für eine neue Sitzung übernommen.",
    "low": "Niedrig",
    "normal": "Normal",
    "technical": "Technisch",
    "organizational": "Organisatorisch",
    "personal": "Persönlich",
    "finishedDate": "Erledigt am",
    "dueDate": "Zu erledigen bis",
    "action": "Maßnahme",
    "actionDoneBy": "Maßnahme durchgeführt von",
    "actionDoneAt": "Maßnahme durchgeführt am",
    "signatures": "Unterschriften",
    "meetingTopics": "Themen  TOP (Vorbereitung)",
    "announcement": "Aushang",
    "announcements": "Aushänge",
    "announcementsSubtitle": "Aushänge der Ansprechpartner",
    "announcementReport": "Aushang der Ansprechpartner (PDF)",
    "announcementTitle": "Ihr externer Anbieter für Arbeitssicherheit und Arbeitsmedizin",
    "announcementNote": "Wir betreuen und beraten Sie u.a. im Rahmen der Rechts- und Verwaltungsvorschriften nach Arbeitssicherheitsgesetz (ASiG), DGUV Vorschrift 2 und Betriebssicherheitsverordnung. Bei Rückfragen wenden Sie sich bitte vertrauensvoll an die oben genannten Personen.",
    "photo": "Foto",
    "photos": "Fotos",
    "organigramNode": "Organigramm-Knoten",
    "organigram": "Organigramm",
    "organigrams": "Organigramme",
    "editNode": "Knoten bearbeiten",
    "addChild": "Kind-Knoten hinzufügen",
    "removeNode": "Knoten löschen",
    "grey": "Grau",
    "red": "Rot",
    "green": "Grün",
    "blue": "Blau",
    "cyan": "Zyan",
    "violet": "Lila",
    "yellow": "Gelb",
    "appearance": "Layout, Farbe",
    "rectangle": "Rechteck",
    "roundedCorners": "Abgerundete Ecken",
    "oval": "Oval",
    "compact": "Kompakt",
    "small": "Klein",
    "large": "Groß",
    "nodes": "Knoten",
    "width": "Breite",
    "height": "Höhe",
    "fontSize": "Schriftgröße",
    "holdDownToEdit": "Zum Bearbeiten eines Knotens, diesen bitte lange berühren",
    "label": "Legende",
    "labels": "Legende",
    "ladders": "Leitern",
    "ladder": "Leiter",
    "ladderInspection": "Prüfung von Leitern und Tritten",
    "ladderInspections": "Leitern und Tritte",
    "consultantLadderInspection": "Leiterprüfer",
    "signatureLadderInspector": "Unterschrift Leiterprüfer",
    "ladderSteps": "StufenSprossen",
    "ladderLength": "Länge (m)",
    "articleNumber": "Artikel-Typnummer",
    "material": "Material",
    "ladderAcquisitionSegregation": "AnschaffungAussonderung",
    "acquisitionDate": "Datum der Anschaffung",
    "segregationDate": "Datum der Aussonderung",
    "nextInspectionDate": "Nächste Prüfung",
    "ladderInfo": "Angaben zur Leiter",
    "duplicateLadderInspection": "Leiterprüfung duplizieren",
    "ladderBookReport": "Leiterbuch",
    "ladderReport": "Bericht zur Leiterprüfung",
    "good": "Gut",
    "medium": "Mäßig",
    "bad": "Schlecht",
    "replace": "Ersetzen",
    "repair": "Reparieren",
    "recommendation": "Empfehlung",
    "shouldReplace": "AustauschAusmustern der Leiter",
    "shouldRepair": "Reparatur der Leiter",
    "ladderOk": "Leiter ist OK",
    "notChecked": "Noch nicht geprüft",
    "defectPhotos": "Fotos der Defekte und Mängel",
    "infoModule": "App-Information",
    "infoModuleSubtitle": "Datenschutz, Modul-Kurzbeschreibung",
    "instructionTemplatesModule": "Unterweisungs-\\nvorlagen",
    "instructionTemplates": "Unterweisungsvorlagen",
    "instructionTemplatesSubtitle": "Inhalte und Vorlagen",
    "subtitle": "Untertitel",
    "optionalEndDate": "Enddatum (optional)",
    "department": "Abteilung",
    "departments": "Abteilungen",
    "organizer": "Veranstalter",
    "lecturer": "Referent",
    "occasion": "Anlass-\nbezogen",
    "newStaffMember": "Arbeitsauf-\nnahme neuer Mitarbeiter",
    "jobChange": "Versetzung auf anderen Arbeitsplatz",
    "safetyInstructions": "Regelmäßige Sicherheitsbelehrung",
    "reason": "Anlass",
    "interval": "Intervall (Monate)",
    "instructionTopics": "Themen",
    "personnumber": "Personalnummer",
    "certificate": "Zertifikat",
    "certificates": "Zertifikate",
    "certificateCity": "Ausstellungsort",
    "certificateDate": "Ausstellungsdatum",
    "organizerSignature": "Unterschrift Veranstalter",
    "lecturerSignature": "Unterschrift Referent",
    "seminarManager": "Seminarleitung",
    "employee": "Mitarbeiter",
    "employees": "Mitarbeiter",
    "employeesSubtitle": "Zuordnung GB, Arbeitsbereiche, Untersuchungen",
    "preventiveExaminations": "Vorsorge, Untersuchungen",
    "workingArea": "Arbeitsbereich",
    "workingAreas": "Arbeitsbereiche",
    "workAreaDefault": "Gewerblich",
    "workAreaMedic": "Gesundheitswesen",
    "birthday": "Geburtstag",
    "reportEmployeeOverview": "Datenblatt  Mitarbeiter",
    "reportEmployeeSubtitle": "Zuordnungen im Rahmen der Gefährdungsbeurteilungen",
    "reportEmployeeListSubtitle": "Zuordnung der Gefährdungsbeurteilungen",
    "reportEmployeeList": "Mitarbeiterliste",
    "basisUploads": "Basis-Uploads",
    "basisUploadsStopAll": "Alles stoppen",
    "basisUploadsStartAll": "Jetzt hochladen",
    "basisUploadPendingError": "Das Hochladen der Daten ist derzeit nicht möglich (ggf. besteht keine Internet-Verbindung). Die App wird das Hochladen der Daten später automatisch erneut versuchen.",
    "dateOfEmployment": "MonatJahr der Einstellung",
    "yearsEmploymentFrom": "Betriebszugehörigkeit ab (Jahre)",
    "yearsEmploymentTo": "Betriebszugehörigkeit bis (Jahre)",
    "epilogue": "Nachwort",
    "prologue": "Vorwort",
    "statusReportInterval": "Zeitraum für Bericht",
    "statusReportCreator": "Autor des Berichts",
    "reportStatusReportOverview": "Statusbericht",
    "statusReportNoRisk": "kein Handlungsbedarf",
    "statusReportLowRisk": "Nach Nohl ohne oder mit geringem Handlungsbedarf",
    "statusReportMediumRisk": "Nach Nohl mit mittlerem, mittelfristigen Handlungsbedarf",
    "statusReportHighRisk": "Nach Nohl mit hohem, kurzfristigen Handlungsbedarf",
    "statusReportFinished": "Fristgerecht bearbeitete Maßnahmen",
    "statusReportLate": "Nicht fristgerecht bearbeitete Maßnahmen",
    "statusReportPending": "Noch zu bearbeitende Maßnahmen (laufend, Frist nicht überschritten)",
    "statusReportOverdue": "Überfällige Maßnahmen",
    "toc": "Inhaltsverzeichnis",
    "finished": "Erledigt (fristgerecht)",
    "late": "Erledigt (nicht fristgerecht)",
    "pending": "Offen",
    "overdue": "Offen (überfällig)",
    "statisticsQuestionsDescription": "Übersicht über die Beurteilungen bzgl. der Fragen aller Gefährdungsbeurteilungen und Begehungen innerhalb des Zeitraumes des Berichts.",
    "statisticsRiskDescription": "Übersicht über die ermittelten Risiken aller Gefährdungsbeurteilungen und Begehungen innerhalb des Zeitraumes des Berichts.",
    "statisticsPerformanceConfirmationDescription": "Die häufigsten Themen, die in den Leistungsbestätigungen behandelt wurden.",
    "statisticsExaminationDescription": "Übersicht über die häufigsten Vorsorgen und Untersuchungen",
    "statisticsInstructionsDescription": "Übersicht über die häufigsten Unterweisungsthemen.",
    "statisticsMeetingsDescription": "Übersicht über die häufigsten Themen in den Sitzungen.",
    "statisticsShelfDescription": "Übersicht über die am häufigsten festgestellten Defekte bei Regalpüfungen",
    "statisticsLadderDescription": "Übersicht über die am häufigsten festgestellten Defekte bei Leiterpüfungen",
    "statusReportCustomReport": "Sonstige ThemenBerichte",
    "showContent": "In den Bericht aufnehmen",
    "statistics": "Statistiken",
    "functionsSetDefaults": "Standard-Werte setzen",
    "functionsMedical": "Gesundheitswesen",
    "functionsCompany": "Unternehmen",
    "total": "Gesamt",
    "unknown": "Unbekannt",
    "injury": "Verletzung  Erkrankung",
    "accidentsSubtitle": "Unfallstatistik",
    "accidents": "Unfallanalyse",
    "workRelatedAccident": "Unfall-\nmeldung",
    "bandageBook": "Verband-\nbuch-\nEintrag",
    "externalAccident": "Wegeunfall",
    "workRelatedIllness": "Berufskrankheit",
    "almostAccident": "Beinahe-\nUnfall",
    "workRelatedAccidentLong": "Unfallmeldung",
    "bandageBookLong": "Verbandbuch-Eintrag",
    "externalAccidentLong": "Wegeunfall",
    "workRelatedIllnessLong": "Berufskrankheit",
    "almostAccidentLong": "Beinahe-Unfall",
    "injuredPerson": "Verletzte Person",
    "accidentType": "Art des Unfalls",
    "injuryDuration": "Ausfallzeit (Tage)",
    "accidentReportOverview": "Unfallbericht",
    "accidentTitleDefault": "Bericht zum Unfallgeschehen",
    "accidentTitleWorkIllness": "Bericht zu potenziell berufsbedingter Erkrankung",
    "1AidHelper": "Ersthelfer",
    "1AidDate": "Datum der ersten Hilfe",
    "1AidReport": "Erste-Hilfe-Maßnahmen",
    "witness": "Zeuge",
    "witnesses": "Zeugen",
    "accidentReportDepartmentTitle": "Unternehmensteil",
    "accidentReportRouteTitle": "Wegstrecke",
    "actionsAndTopics": "Maßnahmen und Themen",
    "topicsAndContent": "Themen und Inhalte",
    "selectAll": "Alles auswählen",
    "deselectAll": "Auswahl entfernen",
    "photoQuality": "Fotoqualität (Berichte)",
    "lowQuality": "Niedrig",
    "mediumQuality": "Mittel",
    "highQuality": "Hoch",
    "fullQuality": "Original",
    "photoCaptureQuality": "Fotoqualität (Kamera)",
    "showModules": "Module aktivieren",
    "modulesFor": "Module für …",
    "modulesExpert": "Fachkräfte für Arbeitssicherheit",
    "modulesMedic": "Arbeitsmedizinischer Dienst",
    "modulesAuditor": "Auditoren",
    "calculatorsModule": "Rechner",
    "calculatorsModuleSubtitle": "Feuerlöscher, Erste-Hilfe, Einsatzzeiten",
    "calculator": "Rechner",
    "fireExtinguisher": "Feuerlöscher",
    "fireExtinguishers": "Feuerlöscher",
    "firstAidKit": "Erste-Hilfe-Kasten",
    "firstAidKits": "Erste-Hilfe-Kästen",
    "schedule": "Einsatzzeiten",
    "schedules": "Einsatzzeiten",
    "association": "Berufsgenossenschaft",
    "associations": "Berufsgenossenschaften",
    "branch": "Branche",
    "branches": "Branchen",
    "totalHours": "Grundbetreuung gesamt",
    "basicWorkSafetyHours": "Sicherheitstechnische Grundbetreuung",
    "basicMedicHours": "Arbeitsmedizinische Grundbetreuung",
    "costMedic": "Stundensatz für arbeitsmed. Grundbetreuung",
    "costWorkSafety": "Stundensatz für sicherheitstechn. Grundbetreuung",
    "currency": "Währung",
    "totalCosts": "Gesamtkosten",
    "regularSpecificWorkSafetyHours": "Sicherheitstechnische betriebsspezifische Betreuung (regelmäßig)",
    "regularSpecificMedicHours": "Arbeitsmedizinische betriebsspezifische Betreuung (regelmäßig)",
    "occasionSpecificWorkSafetyHours": "Sicherheitstechnische betriebsspezifische Betreuung (anlassbezogen)",
    "occasionSpecificMedicHours": "Arbeitsmedizinische betriebsspezifische Betreuung (anlassbezogen)",
    "workSafetyHours": "Sicherheitstechnische Betreuung gesamt",
    "medicHours": "Arbeitsmedizinische Betreuung gesamt",
    "fractionWorkSafety": "Anteil sicherheitstechn. Grundbetreuung (%)",
    "fullEmployees": "Anzahl Mitarbeiter (auf volle Stellen umgerechnet)",
    "firstAidKitBranchDistribution": "Verwaltung\nHandel",
    "firstAidKitBranchManufacturing": "Herstellung\nVerarbeitung",
    "firstAidKitBranchConstruction": "Baustellen",
    "firstAidKitRequired": "Benötigte Anzahl von Verbandkästen",
    "smallFirstAidKit": "Kleiner Verbandkasten",
    "largeFirstAidKit": "Großer Verbandkasten",
    "smallFirstAidKits": "Kleine Verbandkästen",
    "largeFirstAidKits": "Große Verbandkästen",
    "smallFirstAidKitRegulation": "Nach DIN 13157",
    "largeFirstAidKitRegulation": "Nach DIN 13169",
    "fireHighRisk": "Erhöhte Brandgefährdung",
    "companySize": "Betriebsgröße (m²)",
    "fireClasses": "Brandklassen",
    "fireClass0": "Brandklasse A\nBrände fester Stoffe (hauptsächlich organischer Natur)",
    "fireClass1": "Brandklasse B\nBrände von flüssigen oder flüssig werdenden Stoffen",
    "fireClass2": "Brandklasse C\nBrände von Gasen",
    "fireClass3": "Brandklasse D\nBrände von Metallen",
    "fireClass4": "Brandklasse F\nBrände von Speiseölen und -fetten",
    "fireAdditionalAction": "Maßnahmen bei erhöhter Brandgefährdung",
    "requiresAdditionalAction": "Bitte beachten Sie die zusätzlichen Maßnahmen wegen der erhöhten Brandgefährdung!",
    "requiredExtinguishUnits": "Insgesamt benötigte LE",
    "availableExtinguishUnits": "Bereits vorhandene LE",
    "noExtinguishUnitsNeeded": "Es werden keine weiteren LE benötigt",
    "additionalExtinguishUnitsNeeded": "Sie benötigen noch zusätzliche LE",
    "availableUnits": "Bereits vorhandene Feuerlöscheinrichtungen",
    "availableUnits0": "mit 6 LE\n(21 A  113 B)",
    "availableUnits1": "mit 9 LE\n(27 A  144 B)",
    "availableUnits2": "mit 10 LE\n(34 A)",
    "availableUnits3": "mit 12 LE\n(43 A  183 B)",
    "availableUnits4": "mit 15 LE\n(55 A  233 B)",
    "or": "oder",
    "language": "Sprache",
    "german": "Deutsch",
    "english": "Englisch",
    "value": "Wert",
    "medicalExamination": "Medizinische Untersuchungen",
    "medicalExaminationDescription": "Vorsorgen, Untersuchungen",
    "medicalExaminationQuestionnaire": "Untersuchung",
    "startExamination": "Befundung starten",
    "patient": "Proband",
    "doctor": "AMD",
    "nextExamination": "Nächste Untersuchung",
    "dateEmployment": "Beschäftigt seit",
    "medicalExaminationRequired": "Pflicht-\nvorsorge",
    "medicalExaminationEmployment": "Einstellungsuntersuchung",
    "medicalExaminationOffer": "Angebots-\nvorsorge",
    "medicalExaminationVoluntary": "Wunsch-\nvorsorge",
    "medicalExaminationFirst": "Erstuntersuchung",
    "medicalExaminationFollowup": "Nachuntersuchung",
    "content": "Inhalt",
    "medicalInfo": "Hinweis = Grundsätze der DGUV\nAuf vielfachen Wunsch verwenden wir zur Vereinfachung und im Sinne der Kunden noch die Bezeichnungen für Untersuchungen und Vorsorgen gem. den Grundsätzen der DGUV. Die angepasste Rechtslage ist uns und den meisten Anwendern bekannt, obgleich die Grundsätze formell immer noch als anerkannte Regel der Arbeitsmedizin gelten.\nWeitere Infos finden Sie auch im Modul \"Rechtliches und Links\" unter \"DGUV Grundsätze\".",
    "riskRating": "Risikoabschätzung",
    "calculators": "Rechner",
    "carryLift": "Leitmerkmalmethode",
    "carryLiftModule": "Leitmerkmal-\\nmethode",
    "carryLiftSubtitle": "Heben, Tragen, Halten, Schieben, Ziehen",
    "carryLiftHold": "Heben, Tragen, Halten",
    "pushPull": "Schieben, Ziehen",
    "carryLiftGender": "Geschlecht",
    "man": "Mann",
    "woman": "Frau",
    "carryLiftTime": "Zeit",
    "carryLiftWeight": "Wirksame Last",
    "carryLiftWeightDescription": "Mit der 'wirksamen Last' ist die Gewichtskraft bzw. Zug-Druckkraft gemeint, die der Beschäftigte tatsächlich bei der Lastenhandhabung ausgleichen muss. Sie entspricht nicht immer der Lastmasse. Beim Kippen eines Kartons wirken nur etwa 50 %, bei der Verwendung einer Schubkarre oder Sackkarre nur 10 % der Lastmasse.",
    "carryLiftMass": "Masse",
    "carryLiftMassDescription": "Werte größer als auswählbar sind grundsätzlich zu vermeiden, da die erforderlichen Aktionskräfte leicht die maximalen Körperkräfte übersteigen können.",
    "carryLiftPosture": "Haltung",
    "carryLiftExecution": "Ausführungsbedingungen",
    "carryLiftPositioning": "Positionier­genauigkeit, -geschwindigkeit",
    "carryLiftRolling": "Rollend bewegen",
    "carryLiftSliding": "Gleitend bewegen",
    "carryLiftNoAid": "Ohne Hilfsmittel, Last wird gerollt",
    "carryLiftCart": "Karren",
    "carryLiftTrolley": "Wagen, Roller, Trol­leys ohne Bockrollen",
    "carryLiftCar": "Gleiswagen, Handwa­gen, Handhubwagen, Rollenbahnen, Wagen mit Bockrollen",
    "carryLiftBalancer": "Manipulatoren, Seilbalancer",
    "carryLiftTotalDistance": "Gesamtweg am Arbeitstag",
    "carryLiftTotalTime": "Gesamtdauer am Arbeitstag",
    "carryLiftTotalCount": "Anzahl am Arbeitstag",
    "carryLiftLowSlow": "Gering,\nlangsam\n(< 0,8 ms)",
    "carryLiftLowFast": "Gering,\nschnell\n(≥ 0,8 ms)",
    "carryLiftHighSlow": "Hoch,\nlangsam\n(< 0,8 ms)",
    "carryLiftHighFast": "Hoch,\nschnell\n(≥ 0,8 ms)",
    "carryLiftAccuracy": "Genauigkeit",
    "carryLiftSpeed": "Geschwindigkeit",
    "carryLiftLow": "Gering",
    "carryLiftHigh": "Hoch",
    "carryLiftSlow": "Langsam (< 0,8 ms)",
    "carryLiftFast": "Schnell (≥ 0,8 ms)",
    "carryLiftLowSlowDescription": "Genauigkeit gering. Geschwindigkeit langsam. Keine Vorgabe des Fahrweges, Last kann ausrollen oder wird an Anschlag gestoppt.",
    "carryLiftLowFastDescription": "Genauigkeit gering. Geschwindigkeit schnell. Keine Vorgabe des Fahrweges, Last kann ausrollen oder wird an Anschlag gestoppt.",
    "carryLiftHighSlowDescription": "Genauigkeit hoch. Geschwindigkeit langsam. Last ist exakt zu positionieren und anzuhalten, Fahrweg ist exakt einzuhalten, häufige Richtungsänderungen.",
    "carryLiftHighFastDescription": "Genauigkeit hoch. Geschwindigkeit schnell. Last ist exakt zu positionieren und anzuhalten, Fahrweg ist exakt einzuhalten, häufige Richtungsänderungen.",
    "carryLiftMaxMass": "Maximales Gewicht",
    "carryLiftMovementAid": "Hilfsmittel",
    "carryLiftMovement": "Bewegung",
    "carryLiftPosture0": "Rumpf aufrecht, keine Verdrehung. Last am Körper.",
    "carryLiftPosture1": "Rumpf leicht vorgeneigt undoder leicht verdreht (einseitiges Ziehen). Last am Körper oder körpernah.",
    "carryLiftPosture2": "Stärkere Neigung des Körpers in Bewegungsrichtung Hocken, Knien, Bücken. Last körperfern oder über Schulterhöhe.",
    "carryLiftPosture3": "Kombination von Bücken und Verdrehen. Last körperfern.",
    "pushPullPosture0": "Rumpf aufrecht, keine Verdrehung",
    "pushPullPosture1": "Rumpf leicht vorgeneigt undoder leicht verdreht (einseitiges Ziehen)",
    "pushPullPosture2": "Stärkere Neigung des Körpers in Bewegungsrichtung Hocken, Knien, Bücken",
    "pushPullPosture3": "Kombination von Bücken und Verdrehen",
    "carryLiftPostureDescription": "Für die Bestimmung der Haltungswichtung ist die bei der Lastenhandhabung eingenommene charakteristische Körperhaltung einzusetzen; z.B. bei unterschiedlichen Körperhaltungen mit der Last sind mittlere Werte zu bilden – keine gelegentlichen Extremwerte verwenden!",
    "pushPullPostureDescription": "Es ist die typische Körperhaltung zu berücksichtigen. Die beim Anfahren, Abbremsen und Rangieren möglicherweise deutlichere Rumpfneigung ist zu vernachlässigen, wenn sie nur gelegentlich auftritt.",
    "shortLifts": "Hebe-Umsetzvorgä­nge\n(< 5 s)",
    "longLifts": "Halten\n(> 5 s)",
    "carry": "Tragen\n(> 5 m)",
    "shortDistance": "kurze Distanzhäufiges Anhalten (< 5 m)",
    "longDistance": "Längere Distanz (> 5 m)",
    "shortLiftsDescription": "Beispiele: Setzen von Mauersteinen, Einlegen von Werkstücken in eine Maschine, Pakete aus einem Container entnehmen und auf ein Band legen, …",
    "longLiftsDescription": "Beispiele: Halten und Führen eines Gussrohlings bei der Bearbeitung an einem Schleifbock, Halten einer Handschleifmaschine, Führen einer Motorsense, …",
    "carryDescription": "Beispiele: Möbeltransport, Tragen von Gerüstteilen vom Lkw zum Aufstellort, …",
    "shortDistanceDescription": "Beispiele: Bedienen von Manipulatoren, Bestücken von Maschinen, Essensverteilung im Krankenhaus, …",
    "longDistanceDescription": "Beispiele: Müllabfuhr, Möbeltransport in Gebäuden auf Rollern, Aus- und Umladen von Containern, …",
    "carryLiftGood": "Gute ergonomische Bedingungen",
    "carryLiftLimited": "Einschränkung der Bewegungsfreiheit und ungünstige ergonomische Bedingungen",
    "carryLiftVeryLimited": "Stark eingeschränkte Bewegungsfreiheit undoder Instabilität des Lastschwerpunktes",
    "pushPullGood": "Gut",
    "pushPullLimited": "Eingeschränkt",
    "pushPullDifficult": "Schwierig",
    "pushPullComplicated": "Kompliziert",
    "carryLiftGoodDescription": "z.B. ausreichend Platz, keine Hindernisse im Arbeitsbereich, ebener rutschfester Boden, ausreichend beleuchtet, gute Griffbedingungen",
    "carryLiftLimitedDescription": "z.B. Bewegungsraum durch zu geringe Höhe oder durch eine Arbeitsfläche unter 1,5 m² eingeschränkt oder Standsicherheit durch unebenen, weichen Boden eingeschränkt",
    "carryLiftVeryLimitedDescription": "z.B. Patiententransfer",
    "pushPullGoodDescription": "z.B. Fußboden oder andere Fläche eben, fest, glatt, trocken; ohne Neigung; keine Hindernisse im Bewegungsraum; Rollen oder Räder leichtgängig, kein erkennbarer Verschleiß der Radlager",
    "pushPullLimitedDescription": "z.B. Fußboden verschmutzt, etwas uneben, weich; geringe Neigung bis 2°; Hindernisse im Bewegungsraum, die umfahren werden müssen; Rollen oder Räder verschmutzt, nicht mehr ganz leichtgängig, Lager ausgeschlagen",
    "pushPullDifficultDescription": "z.B. unbefestigter oder grob gepflasterter Fahrweg, Schlaglöcher, starke Verschmutzung; Neigungen 2 bis 5°; Flurförderzeuge müssen beim Anfahren „losgerissen“ werden; Rollen oder Räder verschmutzt, schwergängig",
    "pushPullComplicatedDescription": "Stufen, Treppen, Absätze; Neigungen > 5°; Kombinationen der Merkmale von „Eingeschränkt“ und „Schwierig“",
    "carryLiftRiskLevel1": "Geringe Belastung, Gesundheitsgefährdung durch körperliche Überbeanspruchung ist unwahrscheinlich.",
    "carryLiftRiskLevel2": "Erhöhte Belastung, eine körperliche Überbeanspruchung ist bei vermindert belastbaren Personen (älter als 40 Jahre, jünger als 21 Jahre, Neulinge, usw.) möglich. Für diesen Personenkreis sind Gestaltungsmaßnahmen sinnvoll.",
    "carryLiftRiskLevel3": "Wesentlich erhöhte Belastung, körperliche Überbeanspruchung ist auch für normal belastbare Personen möglich. Gestaltungsmaßnahmen sind angezeigt.",
    "carryLiftRiskLevel4": "Hohe Belastung, körperliche Überbeanspruchung ist wahrscheinlich. Gestaltungsmaßnahmen sind erforderlich.",
    "sibe": "SiBe-Push",
    "sibeDescription": "Gefahrenmeldungen",
    "sibeAudit": "Gefahrenmeldung",
    "sibeAudits": "Gefahrenmeldungen",
    "sibeSafetyCommissioner": "Sicherheitsbeauftragter",
    "sibePush": "Meldung verschicken",
    "sibeCoordinates": "Koordinaten",
    "lowRisk": "Gering",
    "mediumRisk": "Mittel",
    "highRisk": "Hoch",
    "receivers": "Empfänger",
    "done": "Fertig",
    "dataProtection": "Datenschutz",
    "dataProtectionAudit": "Datenschutzbegehung",
    "dataProtectionAudits": "Datenschutzbegehungen",
    "solution": "Lösungsansätze",
    "valuation": "Bewertungen",
    "overview": "Übersicht",
    "info": "Information",
    "fulfilled": "Erfüllt",
    "dataProtectionInquiry": "Auskünfte seitens des Auftraggebers erteilen",
    "owner": "Inhaber",
    "corporateAim": "Unternehmensgegenstand",
    "corporate_Aim": "Unternehmensgegenstand",
    "foundingDate": "Gründung",
    "dataProtectionDescription": "",
    "mailUnverified": "Mailadresse nicht verifiziert",
    "licenseValidUntil": "Lizenz gültig bis",
    "licenseExpiredOn": "Lizenz abgelaufen am",
    "duplicate": "Duplizieren",
    "gateInspection": "Torprüfung",
    "gateInspections": "Torprüfungen",
    "gateInspectionsDescription": "Rolltore, Rollgitter, Schiebetore, Falttore, Sektionaltore, Hubtore",
    "sectionGate": "Sektionaltor",
    "liftingGate": "Hubtor",
    "slidingGate": "Schiebetor",
    "foldingGate": "Falttor",
    "rollingGate": "Rolltor",
    "gridGate": "Rollgitter",
    "gateBookReport": "Torverzeichnis",
    "gateInfo": "Information zum Tor",
    "gateReport": "Bericht zum Tor",
    "commissioningDate": "Inbetriebnahme",
    "gateInspectionGateTypes": "Torarten",
    "gateOk": "Keine Maßnahme erforderlich",
    "notApplicable": "nicht anwendbar",
    "shouldRepairGate": "Instandsetzung nötig",
    "gate": "Tor",
    "gates": "Tore",
    "consultantGateInspection": "Torprüfer",
    "gateDetailReport": "Stammblatt für Tor",
    "gateWings": "Flügel",
    "gateOperation": "Steuerung",
    "wingWeight": "Gewicht für Flügel, die zum Öffnen angehoben werden (N)",
    "operationVoltage": "Betriebsspannung (V)",
    "operationRevolutions": "Betriebsdrehzahl (ms)",
    "operationSpeed": "Betriebsgeschwindigkeit (ms)",
    "controlVoltage": "Steuerspannung (V)",
    "gateWindow": "Durchsichtsfenster",
    "gateProfile": "ProfilGitterFüllungen",
    "powerLimit": "Kraftbegrenzung",
    "nonContactDevice": "Berührungslos wirkende Schutzeinrichtung, z.B. Lichtgitter",
    "gateSecureEdge": "Schaltleiste, Schließkantensicherung",
    "wingFailure": "Antrieb ist imstande, den Flügel auch bei Ausfall einer Gewichtsausgleichsfeder zu halten.",
    "safetyGear": "Fangvorrichtung",
    "testNumber": "Prüfnummer",
    "gateAdditionalSafety": "Zusätzliche Sicherung gegen Sachbeschädigung, z.B. Lichtschranke",
    "otherFallProtection": "Andere Art der Absturzsicherung",
    "changes": "Änderungen",
    "otherNotes": "Sonstige Angaben",
    "gateDrive": "Antrieb",
    "gateCrash": "Sicherung gegen Abstürzen senkrecht bewegter Flügel",
    "other": "Sonstiges",
    "safetyGearForce": "Fangkraft (N) oder Fangmoment (Nm)",
    "safetyEdge": "Schaltleiste, Schließkantensicherung",
    "drivePower": "Leistung (kW)",
    "driveNumber": "Antriebszahl (min⁻¹)",
    "forkliftInspections": "Prüfung von Flurförderzeugen",
    "forkliftInspection": "Flurförderzeugprüfung",
    "forklifts": "Flurförderzeuge",
    "forklift": "Flurförderzeug",
    "model": "Modell",
    "loadCapacity": "Nenntragfähigkeit (t)",
    "additionalTests": "Weitere Prüfungen",
    "forkliftBookReport": "Verzeichnis der Flurförderzeuge",
    "forkliftInspectionsDescription": "Flurförderzeuge, Gabelstapler",
    "operatingHours": "Betriebsstunden",
    "forkliftReport": "Bericht zur Flurförderzeugprüfung",
    "forkliftOk": "Am Tag der Prüfung wurden keine Mängel festgestellt.",
    "shouldRepairForklift": "Reparatur des Flurförderzeugs nötig",
    "shouldReplaceForklift": "Austausch des Flurförderzeugs",
    "consultantForkliftInspection": "Flurförderzeugprüfer",
    "pallettruck": "Handhubwagen",
    "pallettrucks": "Handhubwagen",
    "pallettruckInspection": "Handhubwagenprüfung",
    "pallettruckInspections": "Prüfung von Handhubwagen",
    "pallettruckInspectionsDescription": "Handhubwagen, Palettenhubwagen",
    "consultantPallettruckInspection": "Handhubwagenprüfer",
    "shouldRepairPallettruck": "Reparatur des Handhubwagens nötig",
    "pallettruckOk": "Am Tag der Prüfung wurden keine Mängel festgestellt.",
    "shouldReplacePallettruck": "Austausch des Handhubwagens",
    "pallettruckReport": "Bericht zur Handhubwagenprüfung",
    "pallettruckBookReport": "Verzeichnis der Handhubwagen",
    "baseData": "Stammdaten",
    "pallettruckDetailReport": "Stammblatt für Handhubwagen",
    "forkliftDetailReport": "Stammblatt für Flurförderzeug",
    "needRepair": "Reparatur nötig",
    "shouldReplaceGate": "Austausch des Tors",
    "hydraulicrampInspection": "Hebebühnenprüfung",
    "hydraulicrampInspections": "Prüfung von Hebebühnen",
    "hydraulicramp": "Hebebühne",
    "hydraulicrampBookReport": "Verzeichnis der Hebebühnen",
    "hydraulicrampDetailReport": "Stammblatt für Hebebühne",
    "consultantHydraulicrampInspection": "Hebebühnenprüfer",
    "hydraulicrampReport": "Bericht zur Hebebühnenprüfung",
    "shouldReplaceHydraulicramp": "Austausch der Hebebühne",
    "shouldRepairHydraulicramp": "Reparatur der Hebebühne nötig",
    "hydraulicrampOk": "Am Tag der Prüfung wurden keine Mängel festgestellt.",
    "partial": "Teilweise",
    "needsAction": "Maßnahmen erforderlich",
    "workplatformInspection": "Hubarbeitsbühnenprüfung",
    "workplatformInspections": "Prüfung von Hubarbeitsbühnen",
    "loadingrampInspection": "Laderampenprüfung",
    "loadingrampInspections": "Prüfung von Laderampen",
    "vcheck": "Wartung von Arbeitsmitteln",
    "consultantWorkplatformInspection": "Hubarbeitsbühnenprüfer",
    "consultantLoadingrampInspection": "Laderampenprüfer",
    "workplatformOk": "Am Tag der Prüfung wurden keine Mängel festgestellt.",
    "loadingrampOk": "Keine Maßnahme erforderlich",
    "hydraulicramps": "Hebebühnen",
    "workplatforms": "Hubarbeitsbühnen",
    "loadingramps": "Laderampen",
    "workplatformBookReport": "Verzeichnis der Hubarbeitsbühnen",
    "workplatform": "Hubarbeitsbühne",
    "workplatformDetailReport": "Stammblatt zur Hubarbeitsbühne",
    "workplatformReport": "Bericht zur Hubarbeitsbühnenprüfung",
    "loadingramp": "Laderampe",
    "loadingrampBookReport": "Verzeichnis der Laderampen",
    "loadingrampDetailReport": "Stammblatt der Laderampe",
    "loadingrampReport": "Bericht zur Laderampenprüfung",
    "shouldReplaceWorkplatform": "Austausch der Hubarbeitsbühne",
    "shouldRepairWorkplatform": "Reparatur der Hubarbeitsbühne nötig",
    "needsActionLoadingRamp": "Maßnahmen erforderlich",
    "weeklyReport": "Wochenbericht",
    "weeklyReports": "Wochenberichte",
    "needsActionLoadingramp": "Instandsetzung nötig",
    "login": "Login",
    "meetingID": "Meeting Nummer",
    "optional": "Optional",
    "enter": "Enter",
    "webMeetingModule": "Videokonferenz",
    "webMeetingModuleSubtitle": "",
    "unknownError": "Unbekannter Fehler",
    "invalidMeetingID": "Unbekannte Meeting Nummer",
    "invalidPassword": "Passwort falsch",
    "meetingNotYetStarted": "Meeting hat noch nicht begonnen",
    "meetingOver": "Meeting bereits beendet",
    "meetingFull": "Meeting voll",
    "meetingLocked": "Meeting gesperrt",
    "hostNeedsToJoinFirst": "Meeting Host muss erst beitreten",
    "error": "Fehler",
    "communicationModule": "Kommunikation",
    "hostInstantMeeting": "Starte neues Meeting",
    "logout": "Logout",
    "invalidCredentials": "Zugangsdaten falsch",
    "meetingEndedBySelfLeave": "Das Meeting wurde beendet.",
    "meetinEndedByHost": "Der Veranstalter hat das Meeting beendet.",
    "meetingEndedByNetworkIssue": "Das Meeting wurde aufgrund von Netzwerkproblemen beendet.",
    "meetingEndedByUnknownIssue": "Das Meeting wurde aus unbekannten Gründen beendet.",
    "cancelMeeting": "Meeting beenden",
    "meetingInviteMessagePlusMeetingID": "Ihre Sprechstunden-Nummer lautet",
    "videoConsultationClientSubModule": "Videosprech-\nstunde Klient",
    "videoConsultationClientSubModuleSubtitle": "Sprechstunde beitreten",
    "videoConsultationModeratorSubModule": "Videosprech-\\nstunde Moderator",
    "videoConsultationModeratorSubModuleSubtitle": "Sprechstunde leiten",
    "webConferenceAttendeeSubModule": "Webkonferenz Teilnehmer",
    "webConferenceAttendeeSubModuleSubtitle": "An Webkonferenz teilnehmen",
    "webConferenceHostSubModule": "Webkonferenz Veranstalter",
    "webConferenceHostSubModuleSubtitle": "Webkonferenz abhalten",
    "join": "Beitreten",
    "consultationID": "Sprechstunden-Nummer",
    "eLearningSubModule": "eLearning",
    "eLearning": "eLearning",
    "eLearningSubModuleSubtitle": "Schulungsprogramm",
    "medicalExaminationQualification": "Eignungs-\nuntersuchung",
    "medicalExaminationBEM": "BEM",
    "medicalExaminationReport": "Gutachten",
    "medicalExaminationG": "G",
    "externalStaff": "Externe Mitarbeiter",
    "serviceHandbook": "Dienstleistungs-Handbuch",
    "showInServiceHandbook": "Daten im Handbuch",
    "camera": "Kamera",
    "imageQuality": "Bildqualität",
    "reportsExtendedSelection": "Erweiterte Berichtswahl",
    "extendedReportsDescription": "Bei Aktivierung können Sie versch. Versionen der Fragenkataloge  Berichte unabhängig von der Länder- bzw. Sprachauswahl wählen.",
    "extendedQuestionnaireSelection": "Erweiterte Wahl des Fragenkatalogs",
    "Germany": "Deutschland",
    "Austria": "Österreich",
    "Switzerland": "Schweiz",
    "catogryAuditType": "Kategorie  Auditart",
    "scopesExtended": "Scopes  Fachbereiche",
    "locationDesc": "Bereich  Standort",
    "participantsListDesc": "Beteiligte Personen",
    "categoryLegalbasisDesc": "Grundlage",
    "risk": "Gefährdung",
    "actionsRequiered": "Maßnahmen erforderlich",
    "riskTopicDesc": "Gefährdung  Thema",
    "basicRisk": "Basis-Risiko",
    "resubmissonUntil": "Wiedervorlage bisVerantwortlicher",
    "prio": "Priorität",
    "until": "Bis",
    "ratingAfterActions": "Bewertung nach Umsetzung der Maßnahmen",
    "noActionsReportDesc": "Es wurden am heutigen Termin keine Maßnahmen festgestellt bzw. offene Themen ergänzt.<br>\nDas Dokument ist daher ohne Auflistung von Details.",
    "accounts": "Accounts",
    "credentials": "Zugangsdaten",
    "signIn": "Anmelden",
    "signUp": "Registrieren",
    "signInUpDescription": "Registrieren Sie sich einmalig oder loggen Sie sich mit ihrem bestehenden Konto ein, um alle Services nutzen zu können.",
    "forgotPassword": "Passwort vergessen?",
    "change": "Ändern",
    "project": "Projekt",
    "tenant": "Mandant",
    "roles": "Rollen",
    "data-manager": "Daten-Manager",
    "gf": "Geschäftsführer",
    "tenants": "Mandanten",
    "communication": "Kommunikation",
    "performances": "Leistungen",
    "references": "Nachschlagewerke",
    "webSites": "Rechtliches und Links",
    "appInfo": "App-Information",
    "firstAid": "Verbandkästen",
    "forkliftInspection-subTitle": "Flurförderzeuge, Gabelstapler",
    "employees-SubTitle": "Zuordnung GB, Arbeitsbereiche, Untersuchungen",
    "functions-subTitle": "Tätigkeiten und Arbeitsbereiche",
    "announcements-subTitle": "Aushänge der Ansprechpartner",
    "medicalExamination-subTitle": "Vorsorgen, Untersuchungen",
    "maternity-SubTitle": "Gefährdungsbeurteilung bei Schwangerschaft",
    "searchTenants": "Mandanten suchen",
    "save": "Speichern",
    "state": "Status",
    "flowRack": "Durchlaufregal",
    "driveInRack": "Einfahrregal",
    "pushBackRack": "Einschubregal",
    "shelving": "Fachbodenregal",
    "cassetteShelf": "Kassettenregal",
    "cantileverRack": "Kragarmregal",
    "palletRack": "Palettenregal",
    "paternosterShelf": "Paternosterregal",
    "specialBuildingRack": "Sonderbauregal",
    "towerShelf": "Turmregal",
    "carouselRack": "Umlaufregal",
    "bookends": "Buchstützen",
    "chemicalPallets": "Chemiepaletten",
    "oneWayPallets": "Einwegpaletten",
    "euroPallets": "Euro-Paletten",
    "meshBoxes": "Gitterboxen",
    "hangingRegister": "Hängeregister",
    "ibc": "Intermediate Bulk Container (IBC)",
    "boxes": "Kästen",
    "crates": "Kisten",
    "pallets": "Paletten",
    "drawers": "Schubladen",
    "specialCarriers": "Sonderladungsträger",
    "depthSupports": "Tiefenauflagen",
    "automaticStorageRetrievalMachine": "Automatisches Regalbediengerät",
    "forkLiftTruck": "Gabelstapler",
    "stackers": "Hochhubwagen",
    "platform": "Hubarbeitsbühne",
    "orderPicker": "Kommissioniergerät",
    "byHand": "Von Hand",
    "shelfTypes": "Regalart bzw. Sonderausführung",
    "chargeCarrieres": "Ladungsträger, Hilfsmittel",
    "shelfOperations": "Regalbedienung",
    "shelfInspection-subTitle": "Prüfung der Regalanlagen",
    "customTenantQuestionnaireCatalogues": "Mandanten Fragenkataloge",
    "customCustomerQuestionnaireCatalogues": "Globale Fragenkataloge",
    "prefix": "Präfix",
    "questionnaireCatalogueType": "Geltungsbereich",
    "customer": "Gesellschaft",
    "account": "Account",
    "specficAudit-subTitle": "Beurteilungen",
    "specficAudit": "Spezifische Audits",
    "externalSales": "Außendienst",
    "constructionSites": "Baustellen",
    "screenWork": "Bildschirmarbeit",
    "biologicalAgents": "Biologische Arbeitsstoffe",
    "roofWork": "Dacharbeiten",
    "drivingSteeringActivities": "Fahr- und Steuertätigkeit",
    "hazardousSubstances": "Gefahrstoffe",
    "scaffolding": "Gerüste",
    "handPalletTruck": "Handhubwagen",
    "handMachines": "Handmaschinen",
    "housekeeping": "Hauswirtschaft",
    "woodworking": "Holzbearbeitung",
    "homeOffice": "Home-Office",
    "kitchen": "Küche",
    "laboratoryActivities": "Labortätigkeiten",
    "warehouseWork": "Lagerarbeiten",
    "laserWorkstation": "Laserarbeitsplatz",
    "truckDriverOver3": "LKW Fahrer über 3,5 t",
    "truckDriverOver7": "LKW Fahrer über 7,5 t",
    "metalworking": "Metallbearbeitung",
    "production": "Produktion",
    "cleaningActivities": "Reinigungstätigkeiten",
    "technicalService": "Technischer Dienst",
    "handlingElectricalEquipment": "Umgang elektrische Geräte",
    "administration": "Verwaltung",
    "laundry": "Wäscherei",
    "startFailed": "Systemstart fehlgeschlagen",
    "systemClockChanged": "Systemzeit wurde geändert. Bitte stellen Sie die richtige Uhrzeit in den Systemeinstellungen Ihres Gerätes ein.",
    "representatives-subTitle": "Pflichtenübertragung",
    "employees-subTitle": "Zuordnung GB, Arbeitsbereiche, Untersuchungen",
    "questionnaires-subTitle": "Für Beurteilungen",
    "topicsBasicCare-subTitle": " ",
    "topicsBasicCareMedic-subTitle": " ",
    "topicsCompanySpecific-subTitle": " ",
    "textModules-subTitle": "Verschiedene Bereiche",
    "meetings-subTitle": " ",
    "signs-subTitle": "Gefahr-, Verbot-, Warn-, Rettungs-Gebotszeichen, Brandklassen",
    "firstAid-subTitle": "Inhalte der Verbandkästen",
    "calculators-subTitle": "Feuerlöscher, Erste-Hilfe, Einsatzzeiten",
    "webSites-subTitle": "Regeln, Gesetze, Vorschriften im Web",
    "instructionTemplates-subTitle": "Inhalte und Vorlagen",
    "appInfo-subTitle": "Datenschutz, Modul-Kurzbeschreibung",
    "reports": "Berichte",
    "reports-subTitle": "Export",
    "updateingProjectsAndTenants": "Aktualisiere Projekte und Mandanten",
    "updating": "Aktualisiere",
    "lastSyncDate": "Zuletzt Aktualisiert",
    "registeredUserMail": "Registrierte Mailadresse",
    "emailVerification": "E-Mail-Bestätigung",
    "emailNotYetVerified": "Ihre E-Mail-Adresse wurde noch nicht bestätigt. Bitte folgen Sie dem Verifizierungslink, der an Ihre Adresse geschickt wurde.",
    "sendVerificationRequest": "Erneut senden",
    "checkAgain": "Erneut prüfen",
    "clients-subTitle": "Hinzufügen, Bearbeiten",
    "imageSourceDescription": "Wie möchten Sie ein Foto hinzufügen?",
    "editText": "Text editieren",
    "langDownload": "Sprachpaket",
    "langPackDownloadNeeded": "Gewählte Sprache noch nicht für die automatische Übersetzung verfügbar.",
    "downloadNow": "Jetzt herunterladen",
    "downloadNextTime": "Später fragen",
    "performanceConfirmation-subTitle": " ",
    "dataManager": "Daten-Manager",
    "SiBe": "SiBe",
    "sifa-tech": "Sicherheitstechnisch",
    "ba-tech": "Arbeitsmedizinisch",
    "passwordRepeat": "Passwort (wdh)",
    "address": "Adresse",
    "safety_shouldHours": "Soll-Zeit Arbeitssicherheit",
    "medicine_shouldHours": "Soll-Zeit Arbeitsmedizin",
    "schedules_sifa": "Einsatzzeiten Arbeitssicherheit",
    "schedules_ba": "Einsatzzeiten Arbeitsmedizin",
    "should_times": "Soll Zeit",
    "is_times": "Ist Zeit",
    "remaining_times": "Rest Zeit",
    "colleagues": "Kollegen",
    "colleagues-subTitle": "Kollegen im eigenen Unternehmen",
    "performanceCheck": "Leistung",
    "mail": "E-Mail",
    "login_success": "Anmeldung erfolgreich!"
}

export default resources