import { makeAutoObservable, flow } from "mobx"
import rootStore from ".."
import { generateReport } from "../../libs/reporting"
import { BugCrash } from "../../services/BugCrash"

export default class Rpt {

    prjId
    tntId
    itmId
    rptType
    lng
    kvm
    shortReport

    generatedAt

    url
    expiresAt
    isLoading = false

    constructor(prjId, tntId, itmId, rptType, lng, kvm, shortReport) {
        makeAutoObservable(this, { rootStore: false })

        this.prjId = prjId
        this.tntId = tntId
        this.itmId = itmId
        this.rptType = rptType
        this.lng = lng
        this.kvm = kvm
        this.shortReport = shortReport
    }

    get hasValidUrl() {
        //console.log('hasValidUrl', this.expiresAt, Date.now())
        return !this.isLoading && this.url && (Date.now() + 3000 < this.expiresAt)
    }

    generate = flow(function* () {

        this.isLoading = true
        this.generatedAt = null
        this.url = null

        const taskId = rootStore.workProgressStore.startLoading(`Loading report-url prjId=${this.prjId}, tntId=${this.tntId}, itmId=${this.itmId}, rptType=${this.rptType}, kvm=${this.kvm}, shortReport=${this.shortReport}`)

        try {
            const { url, expiresAt } = yield generateReport(this.prjId, this.tntId, this.itmId, this.lng, this.rptType, this.kvm, this.shortReport)

            this.url = url
            this.expiresAt = expiresAt * 1000
            this.generatedAt = Date.now()

            return true
        }
        catch (err) {
            BugCrash.notifyExtra(err, { prjId: this.prjId, tntId: this.tntId, itmId: this.itmId, rptType: this.rptType, kvm: this.kvm, shortReport: this.shortReport, lng: this.lng })
        }
        finally {
            this.isLoading = false
            rootStore.workProgressStore.doneLoading(taskId)
        }
    })
}