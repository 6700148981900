import { makeAutoObservable, has, set } from "mobx"
import { getUuid } from "../../services/IdGenerator"
import { getCurTimestamp } from "../../utils"
import TntNote from "./TntNote"

export default class TntNotesByIdStore {

    rootStore

    notes = {}

    constructor(rootStore) {
        makeAutoObservable(this, { rootStore: false })

        this.rootStore = rootStore
    }

    upsertFromJson = (json, forceRefresh) => {
        //console.log('TntNotesByIdStore::upsertFromJson', json, forceRefresh)

        if (forceRefresh || !has(this.notes, json.id)) {
            set(this.notes, json.id, new TntNote())
        }

        this.notes[json.id].updateFromJson(json, forceRefresh)
    }

    createNote = () => {

        const _newItm = new TntNote(this)
        _newItm.setId(getUuid())
        _newItm.setIsNew(true)
        _newItm.setInfoDate(getCurTimestamp())

        set(this.notes, _newItm.id, _newItm)

        return _newItm
    }
}