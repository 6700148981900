/* eslint-disable default-case */
import produce from 'immer'
import * as actTypes from '../actions/actionTypes'

export default produce((draft, action) => {

    switch (action.type) {

        case actTypes.SET_CUST_USRS_DATA:

            draft.byId[action.custId]=action.usrsData
            break

        case actTypes.ADD_CUST_USRS_DATA:

            let _curVers = draft.byId[action.custId]
            if(!_curVers){ // custId not yet in store
                _curVers={}
            }

            draft.byId[action.custId]={
                ..._curVers,
                ...action.usrsData,
            }
            break

        case actTypes.DEL_CUST_USRS_DATA:

            const _curVers2 = draft.byId[action.custId]
            if(!_curVers2){ // custId not yet in store
                return
            }

            action.usrIds && action.usrIds.forEach(id => {
                delete _curVers2[id]
            })

            draft.byId[action.custId]=_curVers2
            break
    }
}, {
    byId:{}
})