export const isNotFoundErr = (err) => {

    if (err && err.response) {
        const { status } = err.response
        return status === 404
    }

    return false
}

export const isTimestampOldErr = (err) => {

    if (err && err.response) {
        const { status, data } = err.response

        if (status === 500) {
            if (data.code === 'db-old-timestamp') {
                return data.currentTimestamp
            }
        }
    }

    return null
}