/* eslint-disable default-case */
import produce from 'immer'
import * as actTypes from '../actions/actionTypes'

export default produce((draft, action) => {

    switch (action.type) {

        case actTypes.OPEN_POPUP:

            draft.byId[action.id] = action.val
            break

        case actTypes.CLOSE_POPUP:

            if (draft.byId[action.id]){
                delete draft.byId[action.id]
            }
            break
    }
}, {
    byId:{}
})