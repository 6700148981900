import {createSelector} from 'reselect'
import parse from 'date-fns/parse'
import format from 'date-fns/format'
import { createDeepEqualSelector } from './createDeepEqualSelector'
import { paramCustId, paramLimitDate4License } from './params'

const _custLicenses = (state, custId) => state.custLicenses && state.custLicenses[custId]

const _custLicensedModules = createSelector(
    [_custLicenses],
    (license) => {

        if(!license || (license.actions && license.actions.load && license.actions.load.running)){
            return null
        }

        const _licModules = {}

        license.data && license.data.modules && license.data.modules.forEach((m) => {
            const {mod, validUntil, ...rest} = m
            _licModules[mod]={
                validUntil,
                ...rest
            }

            if(validUntil){
                _licModules[mod].validUntilDate = parse(validUntil, 'yyyyMMdd', new Date())
                _licModules[mod].validUntilFmt = format(_licModules[mod].validUntilDate, 'dd.MM.yyyy', null)
            }
        })

        return _licModules
    }
)

const _custLicModules = createDeepEqualSelector(
    state => state.custsLic.byId,
    paramCustId,
    paramLimitDate4License,
    (custsLic, custId, limitDate) => {

        if(!custId || !limitDate || !custsLic){
            return {}
        }

        const _selCustLic = custsLic[custId]
        if(!_selCustLic || !_selCustLic.modules){
            return {}
        }

        const _ret={}

        Object.keys(_selCustLic.modules).forEach(modId => {
            const _validTill = _selCustLic.modules[modId]

            if(_validTill===null){
                _ret[modId]=true
                return
            }

            if(_validTill>=limitDate){
                _ret[modId]=true
                return
            }
        })

        return _ret
    }
)

export {
    _custLicensedModules as custLicensedModules,
    _custLicModules as custLicModules,
}