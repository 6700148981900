import { generatePath } from "react-router"

const _main = '/p/:prjId/t/:tntId/meetings-v3'
const _det = `${_main}/det/:meetingId`
const _area = `${_det}/a/:area`

const nue = (v) => {
    if (!v) {
        return '-'
    }

    return v
}

const routes = {
    main: _main,
    det: _det,
    area: _area,

    url: {
        main: (prjId, tntId) => generatePath(_main, { prjId: nue(prjId), tntId: nue(tntId) }),
        det: (prjId, tntId, meetingId) => generatePath(_det, { prjId: nue(prjId), tntId: nue(tntId), meetingId: nue(meetingId) }),
        area: (prjId, tntId, meetingId, area) => generatePath(_area, { prjId: nue(prjId), tntId: nue(tntId), meetingId: nue(meetingId), area: nue(area) })
    }
}

export default routes