import { makeAutoObservable, set, toJS, values } from "mobx"
import AuditAction from "./AuditAction"
import { orderBy } from "lodash"

export default class AuditAnswer {

    auditFull

    id = ''
    note = {}
    images = {}
    actions = {}

    questionID = ''

    answerValue = -1
    answerValueIdeally = -1

    backTemplateDate = null
    completionDate = null

    departmentIDs = {}
    internalNote = {}

    levelOfRisk = null
    levelOfRiskAfterCompletion = null
    likelihoodOfRisk = null
    likelihoodOfRiskAfterCompletion = null

    responsibleForImplementationPersonID = null
    toCompleteByPersonID = null

    isDirty = false
    isNew = false

    constructor(auditFull) {
        makeAutoObservable(this, { auditFull: false })

        this.auditFull = auditFull
    }

    updateFromJson = (json) => {
        //console.log('AuditAnswer::updateFromJson', json)

        const _actions = {}
        json.actions && Object.values(json.actions).forEach(itm => {
            const _act = new AuditAction(this)
            _act.updateFromJson(itm)
            _actions[_act.id] = _act
        })
        set(this.actions, _actions)

        for (const fld of ['id', 'note', 'images', 'questionID', 'answerValue', 'answerValueIdeally'
            , 'departmentIDs', 'backTemplateDate', 'completionDate', 'internalNote', 'levelOfRisk', 'levelOfRiskAfterCompletion'
            , 'likelihoodOfRisk', 'likelihoodOfRiskAfterCompletion', 'responsibleForImplementationPersonID', 'toCompleteByPersonID']) {
            set(this, fld, json[fld])
        }
    }

    toJson = () => {

        const _ret = {
            actions: {}
        }

        values(this.actions).forEach(act => {
            _ret.actions[act.id] = act.toJson()
        })

        for (const fld of ['id', 'note', 'images', 'questionID', 'answerValue', 'answerValueIdeally'
            , 'departmentIDs', 'backTemplateDate', 'completionDate', 'internalNote', 'levelOfRisk', 'levelOfRiskAfterCompletion'
            , 'likelihoodOfRisk', 'likelihoodOfRiskAfterCompletion', 'responsibleForImplementationPersonID', 'toCompleteByPersonID']) {

            _ret[fld] = toJS(this[fld])
        }
        return _ret
    }

    setAnswerValue = (val) => {
        this.answerValue = val
        this.setIsDirty(true)
    }

    setLevelOfRisk = (val) => {
        this.levelOfRisk = val
        this.setIsDirty(true)
    }
    setLikelihoodOfRisk = (val) => {
        this.likelihoodOfRisk = val
        this.setIsDirty(true)
    }

    setIsNew = (val) => {
        this.isNew = val
    }

    setIsDirty = (val) => {
        if (this.isDirty !== val) {
            this.isDirty = val
        }
    }

    get sortedActions() {
        return orderBy(values(this.actions), ['sortID'])
    }
}