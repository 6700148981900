import {createSelector} from 'reselect'
import { MEASURE_TIME } from '../../config/const';
import { custAllTenantIds, custAllTenantsPath, custAllTenants } from './custs';
import { tntAncestors } from './tnts';

const _isOneOfAncestorInPrjMounts = (ancestors, prjMountIds) =>  {

    const _ar = ancestors && ancestors.ancest && ancestors.ancest.ar
    if(!_ar){
        return false
    }

    for (const _ancest of _ar) {
        if(prjMountIds[_ancest]){
            return true
        }
    }

    return false
}

const _prjMountIds = (state, custId, prjId) => state.prjs && state.prjs.byId && state.prjs.byId[prjId] && state.prjs.byId[prjId].mntIds

const _prjTenantIds = createSelector(
    [_prjMountIds, custAllTenantIds, tntAncestors],
    (prjMountIds, custAllTenantIds, tntAncestors) => {

        if(!prjMountIds || !custAllTenantIds){
            return {}
        }

        const _actionTimeLbl = `PRJ-TENANT-IDS Start=${Date.now()}`
        if(MEASURE_TIME){
            console.time(_actionTimeLbl)
        }

        const _allPrjTnts = {}

        Object.keys(custAllTenantIds).forEach(custTntId => {
            const _ancestors=tntAncestors[custTntId]
            if(!_ancestors){
                return
            }

            if(_isOneOfAncestorInPrjMounts(_ancestors, prjMountIds)){
                _allPrjTnts[custTntId]=true
            }
        })

        if(MEASURE_TIME){
            console.timeEnd(_actionTimeLbl)
        }

        return _allPrjTnts
    }
)

const _prjTenantsIdsCandidatesToBeMounted = createSelector(
    [_prjTenantIds, custAllTenantIds],
    (prjTenantIds, custAllTenantIds) => {

        if(!custAllTenantIds || !prjTenantIds){
            return {}
        }

        const _candidates = {}

        for (const tntId of Object.keys(custAllTenantIds)) {
            if(!prjTenantIds[tntId]){
                _candidates[tntId]=true
            }
        }

        return _candidates
    }
)

const _prjMounts = createSelector(
    [_prjMountIds, custAllTenants],
    (prjMountIds, custAllTnts) => {
        if(!prjMountIds || !custAllTnts){
            return {}
        }

        const _prjMounts = {}
        Object.keys(prjMountIds).forEach(tntId => {
            _prjMounts[tntId]=custAllTnts[tntId]
        })

        return _prjMounts
    }
)

const _prjMountsWithPath = createSelector(
    [_prjMounts, custAllTenantsPath],
    (prjMounts, custAllTenantsPath) => {

        if(!prjMounts || !custAllTenantsPath){
            return {}
        }

        const _prjMountsExt = {}
        Object.keys(prjMounts).forEach(tntId => {
            _prjMountsExt[tntId]={
                ...prjMounts[tntId],
                ...custAllTenantsPath[tntId]
            }
        })

        return _prjMountsExt
    }
)

const _prjTenantsCandidatesToBeMounted = createSelector(
    [_prjTenantsIdsCandidatesToBeMounted, custAllTenants],
    (prjTenantsIdsCandidatesToBeMounted, custAllTnts) => {

        if(!prjTenantsIdsCandidatesToBeMounted || !custAllTnts){
            return {}
        }

        const _selMounts = {}
        Object.keys(prjTenantsIdsCandidatesToBeMounted).forEach(tntId => {
            _selMounts[tntId]=custAllTnts[tntId]
        })

        return _selMounts
    }
)

const _prjTenantsCandidatesToBeMountedWithPath = createSelector(
    [_prjTenantsCandidatesToBeMounted, custAllTenantsPath],
    (prjTenantsCandidatesToBeMounted, custAllTenantsPath) => {

        if(!prjTenantsCandidatesToBeMounted || !custAllTenantsPath){
            return {}
        }

        const _prjMountsExt = {}
        Object.keys(prjTenantsCandidatesToBeMounted).forEach(tntId => {
            _prjMountsExt[tntId]={
                ...prjTenantsCandidatesToBeMounted[tntId],
                ...custAllTenantsPath[tntId]
            }
        })

        return _prjMountsExt
    }
)

export {
    _prjMountIds as prjMountIds,
    _prjTenantIds as prjAllTenantIds,
    _prjTenantsIdsCandidatesToBeMounted as prjTenantsIdsCandidatesToBeMounted,
    _prjMounts as prjMounts,
    _prjMountsWithPath as prjMountsWithPath,
    _prjTenantsCandidatesToBeMounted as prjTenantsCandidatesToBeMounted,
    _prjTenantsCandidatesToBeMountedWithPath as prjTenantsCandidatesToBeMountedWithPath,
}