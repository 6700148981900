import { makeAutoObservable, flow } from "mobx"
import { BugCrash } from "../../services/BugCrash"
import axios from '../../services/Axios'
import { isNumber } from "lodash"

export default class UiQcCustModStore {

    rootStore

    custId = null

    search = ''
    page = 1
    pageSize = 15

    total = 0
    lng = 'de'

    ids = []

    isLoading = false

    constructor(rootStore) {
        makeAutoObservable(this, { rootStore: false })

        this.rootStore = rootStore
    }

    setSearch = (val) => {
        if (this.search !== val) {
            this.search = val
            this.page = 1
            this.load()
        }
    }

    setPage = (val) => {

        if (!isNumber(val) || (val < 1)) {
            return this.setPage(1)
        }
        if (this.page !== val) {
            this.page = val
            this.load()
        }
    }

    setTotal = (val) => {

        if (!isNumber(val) || (val < 1)) {
            return this.setPage(1)
        }
        if (this.total !== val) {
            this.total = val
        }
    }

    start = (custId) => {

        this.custId = custId

        this.page = 1
        this.total = 0
        this.ids = []

        this.load()
    }

    stop = (custId) => {

        if (this.custId === custId) {
            this.custId = null

            this.page = 1
            this.total = 0
            this.ids = []
        }
    }

    load = flow(function* () {

        this.isLoading = true

        const taskId = this.rootStore.workProgressStore.startLoading(`Loading qc-cust light custId=${this.custId}`)
        const _qcStore = this.rootStore.qcStore

        try {
            const { data } = yield axios.get(`/customers/${this.custId}/questionnaires/light?lng=${this.lng}&page=${this.page}&maxPerPage=${this.pageSize}&search=` + encodeURIComponent(this.search))

            this.total = data.total
            this.ids = data.list.map(itm => itm.id)

            data.list.forEach(itm => {
                _qcStore.upsertFromJson(itm, 'G', false)
            })

            return true
        }
        catch (err) {
            BugCrash.notifyExtra(err, { custId: this.custId })
        }
        finally {
            this.isLoading = false
            this.rootStore.workProgressStore.doneLoading(taskId)
        }

        return false
    })

    addId = (id) => {
        this.ids.push(id)
    }
}