import { makeAutoObservable, flow } from "mobx"

import get from 'lodash/get'
import set from 'lodash/set'
import merge from "lodash/merge"
import rootStore from ".."
import { BugCrash } from "../../services/BugCrash"
import { isTimestampOldErr } from "../../libs/actErrors"
import axios from '../../services/Axios'

export default class Tnt {

    id = ''
    container = false
    parent_id = null
    name = ''

    deleted = 0
    isDirty = false

    timestamp = 0

    compact = {}
    full = {}

    isSaving = false

    constructor() {
        makeAutoObservable(this)
    }

    get isDeleted() {
        return (get(this, ['deleted'], 0) > 0)
    }

    get dirty() {

        if (this.isDirty) {
            return true
        }

        return false
    }

    get isNew() {
        return this.timestamp === 0
    }

    updateFromJson = (json, forceRefresh) => {
        //console.log('Tnt::updateFromJson', json, forceRefresh)

        if (!forceRefresh && (json.timestamp <= this.timestamp)) { //no change + no need to refresh
            return
        }

        //TODO: do we need to use "set" for compact + full ?
        merge(this, json)

        if (json.hasOwnProperty('parent')) {
            this.parent_id = json['parent']
        }

        //merge(this, pick(json, ['id', 'container', 'parent_id', 'name', 'timestamp', 'deleted']))
        //merge(this, pick(json, ['compact', 'full']))

        // maybe omit('full', 'compact')

        /*
        for (const _fld of ['id', 'container', 'parent_id', 'name', 'timestamp', 'deleted']) {
            if (!json.hasOwnProperty(_fld)) {
                continue
            }

            if (this[_fld] !== json[_fld]) {
                set(this, _fld, json[_fld])
            }
        }*/
    }

    setStreet = (val) => {
        set(this, ['compact', 'address', 'street'], val)
        this.isDirty = true
    }

    setZip = (val) => {
        set(this, ['compact', 'address', 'zip'], val)
        this.isDirty = true
    }

    setCity = (val) => {
        set(this, ['compact', 'address', 'city'], val)
        this.isDirty = true
    }

    setShortName = (val) => {
        this.compact.shortName = val
        this.isDirty = true
    }

    setCrmId = (val) => {
        this.compact.basisId = val
        this.isDirty = true
    }

    setOpNumber = (val) => {
        this.full.operationNumber = val
        this.isDirty = true
    }

    setName = (val) => {
        this.name = val
        this.isDirty = true
    }

    setParent = (val) => {
        this.parent_id = val
        this.isDirty = true
    }

    setContainer = (val) => {
        this.container = val
        this.isDirty = true
    }

    setEmail = (val) => {
        set(this, ['full', 'contact', 'email'], val)
        this.isDirty = true
    }

    setPhone = (val) => {
        set(this, ['full', 'contact', 'phone'], val)
        this.isDirty = true
    }

    setPhone2 = (val) => {
        set(this, ['full', 'contact', 'phone2'], val)
        this.isDirty = true
    }

    setWebsite = (val) => {
        set(this, ['full', 'contact', 'website'], val)
        this.isDirty = true
    }

    save4Admin = flow(function* (custId, fromCustId) {

        this.isSaving = true

        const taskId = rootStore.workProgressStore.startPushing(`save tenant (admin) tntId=${this.id}, custId=${custId}, fromCustId=${fromCustId}`)

        try {
            const { status } = yield axios.patch(`/customers/${custId}/tenants-from-cust/${fromCustId}/${this.id}`, {
                name: this.name,
                short_name: get(this, ['compact', 'shortName'], ''),
                container: !!this.container,
                tree_parent_id: this.parent_id,

                crm_id: get(this, ['compact', 'basisId'], ''),
                operation_nr: get(this, ['full', 'operationNumber'], ''),

                street: get(this, ['compact', 'address', 'street'], ''),
                city: get(this, ['compact', 'address', 'city'], ''),
                zip: get(this, ['compact', 'address', 'zip'], ''),

                phone: get(this, ['full', 'contact', 'phone'], ''),
                phone2: get(this, ['full', 'contact', 'phone2'], ''),
                email: get(this, ['full', 'contact', 'email'], ''),
                website: get(this, ['full', 'contact', 'website'], ''),
            })

            if (status === 200) {
                return yield rootStore.tntsStore.fetchOne4Admin(custId, fromCustId, this.id)
            }
        }
        catch (err) {

            const _newTs = isTimestampOldErr(err)
            if (_newTs) {
                rootStore.oldTsStore.addConflict(this.full.title, this.timestamp, _newTs, (newTs) => {
                    (async () => {
                        this.setTimestamp(newTs)
                        await this.save4Admin(custId, fromCustId)
                    })()
                }, () => {
                    (async () => {
                        await rootStore.tntsStore.fetchOne4Admin(custId, fromCustId, this.id, true)
                    })()
                }, null)
            }
            else {
                BugCrash.notifyExtra(err, { tntId: this.id, custId, fromCustId })
            }
        }
        finally {
            this.isSaving = false
            rootStore.workProgressStore.donePushing(taskId)
        }

        return false
    })
}