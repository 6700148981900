import { makeAutoObservable, flow } from "mobx"
import { BugCrash } from "../../services/BugCrash"
import axios from '../../services/Axios'
import { isNumber } from "lodash"
import { myDxDefaultPageSize } from "../../config/const"

export default class UiTpcCompSpecModStore {

    rootStore
    prjId
    tntId

    search = ''
    page = 1
    pageSize = myDxDefaultPageSize

    total = 0

    ids = []

    isLoading = false

    constructor(rootStore, prjId, tntId) {
        makeAutoObservable(this, { rootStore: false })

        this.rootStore = rootStore
        this.prjId = prjId
        this.tntId = tntId
    }

    setSearch = (val) => {
        if (this.search !== val) {
            this.search = val
            this.page = 1
            this.load()
        }
    }

    setPage = (val) => {

        if (!isNumber(val) || (val < 1)) {
            return this.setPage(1)
        }
        if (this.page !== val) {
            this.page = val
            this.load()
        }
    }

    setTotal = (val) => {

        if (!isNumber(val) || (val < 1)) {
            return this.setPage(1)
        }
        if (this.total !== val) {
            this.total = val
        }
    }

    start = (prjId, tntId) => {

        this.prjId = prjId
        this.tntId = tntId

        this.page = 1
        this.total = 0
        this.ids = []

        this.load()
    }

    stop = (prjId, tntId) => {

        if ((this.prjId === prjId) && (this.tntId === tntId)) {
            this.prjId = null
            this.tntId = null

            this.page = 1
            this.total = 0
            this.ids = []
        }
    }

    load = flow(function* () {

        this.isLoading = true

        const taskId = this.rootStore.workProgressStore.startLoading(`Loading tpc-comp-spec light prjId=${this.prjId}, tntId=${this.tntId}`)

        try {
            const { status, data } = yield axios.get(`/projects/${this.prjId}/tenants/${this.tntId}/audits/topics-comp-specific/light?page=${this.page}&maxPerPage=${this.pageSize}&search=` + encodeURIComponent(this.search))
            if (status === 200) {
                this.total = data.total
                this.ids = data.list.map(itm => itm.id)

                data.list.forEach(itm => {
                    this.rootStore.tpcCompSpecAuditsStore.updateFromLight(itm)
                })
            }
            return true
        }
        catch (err) {
            BugCrash.notifyExtra(err, { prjId: this.prjId, tntId: this.tntId })
        }
        finally {
            this.isLoading = false
            this.rootStore.workProgressStore.doneLoading(taskId)
        }

        return false
    })
}