import * as actTypes from './actionTypes'

export const setCustPrjsByAdm = (custId, prjIds) => {

    return {
        type: actTypes.SET_CUST_PRJS_BY_ADM,
        custId,
        prjIds: Array.isArray(prjIds)?prjIds:Object.keys(prjIds),
    }
}

export const assignCustPrjsByAdm = (custId, prjIds) => {

    return {
        type: actTypes.ASSIGN_CUST_PRJS_BY_ADM,
        custId,
        prjIds: Array.isArray(prjIds)?prjIds:Object.keys(prjIds),
    }
}

export const detachCustPrjsByAdm = (custId, prjIds) => {

    return {
        type: actTypes.DETACH_CUST_PRJS_BY_ADM,
        custId,
        prjIds: Array.isArray(prjIds)?prjIds:Object.keys(prjIds),
    }
}

export const setCustPrjsByAuthUsr = (custId, prjIds) => {

    return {
        type: actTypes.SET_CUST_PRJS_BY_AUTH_USR,
        custId,
        prjIds: Array.isArray(prjIds)?prjIds:Object.keys(prjIds),
    }
}

export const assignCustPrjsByAuthUsr = (custId, prjIds) => {

    return {
        type: actTypes.ASSIGN_CUST_PRJS_BY_AUTH_USR,
        custId,
        prjIds: Array.isArray(prjIds)?prjIds:Object.keys(prjIds),
    }
}

export const detachCustPrjsByAuthUsr = (custId, prjIds) => {

    return {
        type: actTypes.DETACH_CUST_PRJS_BY_AUTH_USR,
        custId,
        prjIds: Array.isArray(prjIds)?prjIds:Object.keys(prjIds),
    }
}
