import {createSelector} from 'reselect'
import createCachedSelector from 're-reselect';
import { paramUsrId } from './params';

const _usrs = (state) => state.usrs.byId
const _custs = (state) => state.custs.byId
const _custUsrIds = (state, custId) => state.custsUsrs.byId[custId]
const _custAdmIds = (state, custId) => state.custsAdms.byId[custId]


const _custUsrs = createSelector(
    [_custUsrIds, _custAdmIds, _usrs],
    (custUsrIds, custAdmIds, usrs) => {

        const _ret = {}

        custUsrIds && Object.keys(custUsrIds).forEach(_usrId => {
            _ret[_usrId]={
                ...usrs[_usrId],
                adm: (custAdmIds && custAdmIds[_usrId]) || false,
            }
        })

        return _ret
    }
)

const _usrJoinedCustIds = createCachedSelector(
    [
        state => state.custsUsrs.byId,
        paramUsrId
    ],
    (allCustsUsrs, usrId) => {
        const _ret = {}

        allCustsUsrs && Object.keys(allCustsUsrs).forEach(_custId => {
            if(allCustsUsrs[_custId][usrId]){
                _ret[_custId]=true    
            }
        })

        return _ret
    }
)(
    (_, {usrId}) => usrId
)

const _usrJoinedCusts = createCachedSelector(
    [
        _usrJoinedCustIds,
        _custs
    ],
    (usrJoinedCustIds, custs) => {

        const _ret = {}

        for (const _custId of Object.keys(usrJoinedCustIds)) {
            _ret[_custId] = custs[_custId]
        }

        return _ret
    }
)(
    (_, {usrId}) => usrId
)

const _usrAdminCustIds = createCachedSelector(
    [
        state => state.custsAdms.byId,
        paramUsrId
    ],
    (admCustsUsrs, usrId) => {
        const _ret = {}

        admCustsUsrs && Object.keys(admCustsUsrs).forEach(_custId => {
            if(admCustsUsrs[_custId][usrId]){
                _ret[_custId]=true    
            }
        })

        return _ret
    }
)(
    (_, {usrId}) => usrId
)

const _usrAdminCusts = createCachedSelector(
    [
        _usrAdminCustIds,
        _custs
    ],
    (usrAdmCustIds, custs) => {

        const _ret = {}

        for (const _custId of Object.keys(usrAdmCustIds)) {
            _ret[_custId] = custs[_custId]
        }

        return _ret
    }
)(
    (_, {usrId}) => usrId
)


export {
    _custUsrs as custUsrs,
    _usrJoinedCustIds as usrJoinedCustIds,
    _usrJoinedCusts as usrJoinedCusts,
    _usrAdminCusts as usrAdminCusts,
}