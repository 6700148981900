import * as Sentry from '@sentry/browser';
import { lgError, lgInfo } from '../utils/logging';

const _handler = {

    notifyExtra: (err, extra) => {
        lgError(err)

        if (process.env.NODE_ENV === 'production') {
            Sentry.captureException(err, { extra })
        }
    },

    notify: (err) => {
        lgError(err)

        if (process.env.NODE_ENV === 'production') {
            Sentry.captureException(err)
        }
    },

    notifyMsg: (msg) => {
        lgInfo(msg)

        if (process.env.NODE_ENV === 'production') {
            Sentry.captureMessage(msg)
        }
    },

    notifyExt: (msg, err) => {
        lgInfo(msg)
        lgError(err)

        if (process.env.NODE_ENV === 'production') {
            Sentry.captureMessage(msg)
            Sentry.captureException(err)
        }
    },

    setContext: (name, context) => {
        if (process.env.NODE_ENV === 'production') {
            Sentry.setContext(name, context)
        }
    },

    //TODO: check where to call the setUsrId to don't loose the user-id
    setUsrId: (usrId) => {
        if (process.env.NODE_ENV === 'production') {
            Sentry.setUser({
                id: usrId,
                ip_address: "{{auto}}",
            })
        }
    },

    clearUsr: () => {
        if (process.env.NODE_ENV === 'production') {
            Sentry.configureScope(scope => scope.setUser(null))
        }
    },

    //TODO: call setTntId + setPrjId after prj//tenant - change
    setTntId: (tntId) => {
        if (process.env.NODE_ENV === 'production') {
            Sentry.setTag("tntId", tntId)
        }
    },
    setPrjId: (prjId) => {
        if (process.env.NODE_ENV === 'production') {
            Sentry.setTag("prjId", prjId)
        }
    },

    //TODO: check if module is registered in Sentry
    setModule: (modKey) => {
        if (process.env.NODE_ENV === 'production') {
            Sentry.setTag("mod", modKey)
        }
    }
}

export const BugCrash = _handler