import {createSelector} from 'reselect'
import { MEASURE_TIME } from '../../config/const';

const _requireAncestors = (tntCollect, tntId) => {

    const _curTnt=tntCollect[tntId]

    if(!_curTnt){ // possible for shared tenants which are not root tenants in the other customer
        return null
    }
    if(_curTnt.ancest){
        return _curTnt.ancest
    }

    const _parentAncestors = _curTnt.parent && _requireAncestors(tntCollect, _curTnt.parent)

    if(_parentAncestors){
        _curTnt.ancest={
            dict: {[tntId]: true, ..._parentAncestors.dict},
            ar: [tntId, ..._parentAncestors.ar]
        }
    }
    else{
        _curTnt.ancest={
            dict: {[tntId]: true},
            ar: [tntId]
        }
    }

    return _curTnt.ancest
}

const _collectOneTntPath = (collector, tntId, tntsById) => {

    const _cache = collector[tntId]
    if(_cache){
        return _cache
    }

    const _tnt = tntsById[tntId]
    if(!_tnt){
        return null
    }

    let parentPath=''
    if(_tnt.parent){
        const _parentInfo = _collectOneTntPath(collector, _tnt.parent, tntsById)
        if(_parentInfo){
            parentPath=_parentInfo.myPathS
        }
    }

    const n = _tnt.name
    const sn = (_tnt.compact && _tnt.compact.shortName) || n

    const _tntData = {
        n,
        sn,
        parentPath,
        myPathS: `${parentPath}/${sn}`,
        myPathL: `${parentPath}/${n}`
    }

    collector[tntId]=_tntData

    return _tntData
}

const _calcAllTntsPath = (tntsById) => {

    const _timeLbl = `CALC-TENANTS-PATH Start=${Date.now()}`
    if(MEASURE_TIME){
        console.time(_timeLbl)
    }

    const _collector = {}

    Object.keys(tntsById).forEach(tntId => _collectOneTntPath(_collector, tntId, tntsById))

    if(MEASURE_TIME){
        console.timeEnd(_timeLbl)
    }

    return _collector
}

const _tntParent = createSelector(
    state => state.tnts.byId,
    (tntsById) => {
        if(!tntsById){
            return {}
        }

        const _tntParents = {}
        Object.values(tntsById).forEach(tnt => {
            if(!tnt){
                return
            }

            _tntParents[tnt.id]=tnt.parent
        })

        return _tntParents
    }
)

const _tntAncestors = createSelector(
    _tntParent,
    (tntParent) => {

        const _timeLbl = `TENANTS-ANCESTORS-SEL Start=${Date.now()}`
        if(MEASURE_TIME){
            console.time(_timeLbl)
        }

        const _tntIds = Object.keys(tntParent)
        const _tntCollect = {}

        _tntIds.forEach(tntId => {
            _tntCollect[tntId]={parent: tntParent[tntId], ancest: null}
        })

        _tntIds.forEach(tntId => {
            _requireAncestors(_tntCollect, tntId)
        })

        if(MEASURE_TIME){
            console.timeEnd(_timeLbl)
        }

        return _tntCollect
    }
)

export {
    _tntParent as tntParent,
    _tntAncestors as tntAncestors,
    _calcAllTntsPath as calcAllTntsPath,
}