import React, { useMemo } from 'react'
import { lbl } from '../../lbls'
import Mod from './Mod'
import { MODULES } from '../../config/const'
import { lgWarn } from '../../utils/logging'

function ModCat({ alphaTester, modCat, custLicModules, prjPermissions, startModule, sa, custAdm }) {

    const containsAtLeastOneActiveModule = useMemo(() => {

        for (const modId of modCat.modules) {
            if (!custLicModules[modId]) { // modId not licensed => ignore it
                continue
            }

            const _mod = MODULES[modId]
            if (!_mod) {
                lgWarn('Unknown module', modId)
                continue
            }
            if (_mod.hidden) {
                continue
            }

            if (custAdm && _mod.permissions.custAdm) { //auth user is cust-admin, and module is free for cust-admins even with no other rights set
                return true
            }
            if (sa && _mod.permissions.sa) { //auth user is sa, and module is free for sa even with no other rights set
                return true
            }

            for (const permSetKey of ['ro', 'rw']) {
                const _permSet = _mod.permissions[permSetKey]
                if (!_permSet || (_permSet.length === 0)) {
                    return true
                }

                for (const _perm of _permSet) {
                    if (prjPermissions && prjPermissions[_perm]) {
                        return true
                    }
                }
            }
        }

        return false

    }, [modCat, custLicModules, prjPermissions, sa, custAdm])

    if (!containsAtLeastOneActiveModule) {
        return null
    }

    return <div className="mb-3">
        <h6 className="mb-2">{lbl[modCat.id]}</h6>

        <div className="pl-3 pt-3 d-flex flex-wrap dash-grp">
            {
                modCat.modules.map(modId => {
                    if (!custLicModules[modId]) { // modId not licensed
                        return null
                    }

                    const _mod = MODULES[modId]
                    return <Mod key={modId} modId={modId} prjPermissions={prjPermissions} startModule={startModule}
                        disabled={!(_mod.ready || (alphaTester && _mod.alpha))}
                        sa={sa}
                        custAdm={custAdm}
                    />
                })
            }
        </div>
    </div>
}

export default React.memo(ModCat)