import React from 'react';
import { useLocation } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import {parse} from 'query-string';
import { createSelector } from 'reselect';
import has from 'lodash/has'
import { chgSelectedCustomer, chgSelectedCustProject, chgSelectedProjTenant } from '../../store/actions/sessionActions';
import PlsWait from './PlsWait';

const selCPTSelector = createSelector(
    [
        state => state.session.cust, 
        state => state.session.projByCust,
        state => state.session.tntByProj,
    ],
    (custId, projByCust, tntByProj) => {

        const _prjId = custId && projByCust && projByCust[custId]
        const _tntId = _prjId && tntByProj && tntByProj[_prjId]

        return {
            c: custId,
            p: _prjId,
            t: _tntId,
        }
    }
)

const actGrp = 'sync-url-ctp-with-state'

export default function SyncURLContextMainParams2State({children}){

    const _location = useLocation()
    const _qs = parse(_location.search) || {}

    const dispatch = useDispatch();
    const _cptState = useSelector(state => selCPTSelector(state))

    //console.warn(`SyncURLContextMainParams2State`, Date.now(), _qs, _cptState)

    if((_location.pathname!=="/sel-tnt") && has(_qs, 'c')){
        if(_qs['c']!==_cptState.c){
            //console.log('must change customer')
            dispatch(chgSelectedCustomer(_qs['c'], true, actGrp))
            return <PlsWait />
        }

        if(has(_qs, 'p')){
            if(_qs['p']!==_cptState.p){
                //console.log('must change customer-proj')
                dispatch(chgSelectedCustProject(_qs['c'], _qs['p'], true, actGrp))
                return <PlsWait />
            }

            if(has(_qs, 't')){
                if(_qs['t']!==_cptState.t){
                    //console.log('must change tnt')
                    dispatch(chgSelectedProjTenant(_qs['p'], _qs['t'], actGrp))
                    return <PlsWait />
                }
            }
        }
    }

    return <>
      {children}
    </>
}