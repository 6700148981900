import React from 'react'
import rootStore from '../../mobx'
import { keys } from 'mobx'

import { observer } from 'mobx-react-lite'

/*
const Test2 = observer(({ val }) => {

    return <div style={{ borderBottom: '1px dashed aqua' }}>
        {val.id} :: {val.updatedAt} :: {val.updatedBy} :: {val.text.de} :: {val.text.en}
    </div>
})

const Test1 = observer(({ typ, notesByType }) => {

    const _values = get(notesByType, 'values')

    return <div style={{ backgroundColor: 'yellow', border: '1px solid red' }}>
        {typ} {notesByType.timestamp}
        <br />
        ===============
        <br />
        {
            entries(_values).map(ent => {
                return <Test2 key={ent[0]} val={ent[1]} />
            })
        }
    </div>
})


function TntInfoMainOld({ prjId, tntId }) {

    const _tntNotes = get(rootStore.tntNotesStore.tntsNotes, tntId)

    return <div>
        {prjId} - {tntId} - {rootStore.tntNotesStore.isLoading ? "Loading..." : ""}

        {
            _tntNotes && keys(_tntNotes).map(typ => {
                return <Test1 key={typ} typ={typ} notesByType={get(_tntNotes, typ)} />
            })
        }

        <button onClick={() => {
            (async () => {
                await rootStore.tntNotesStore.fetchTntNotes(prjId, tntId)
            })()
        }}>!</button>
    </div>
}
*/

const Test3 = observer(({ notes }) => {

    return <div style={{ border: '1px solid red', marginBottom: 10 }}>
        {
            notes.map(note => {
                return <div key={note.id} className="mb-1 pb-1" style={{ borderBottom: '1px dashed #330066', backgroundColor: '#EEEEEE' }}>
                    {note.infoDate} - {note.text.de} - {note.deleted} - {note.id}

                    {!note.deleted && <button onClick={() => {
                        //note.setTextByLng('de', 'Test RP!')
                        note.setInfoDate(1632129229)
                        note.setDeleted(1632129229)
                    }}>!!</button>}
                </div>
            })
        }
    </div>
})
Test3.displayName = 'My-Test3'

function TntInfoMain({ prjId, tntId }) {

    return <div>
        {prjId} - {tntId} - {rootStore.tntNotesStore.isLoading ? "Loading..." : ""}

        {
            keys(rootStore.tntNotesStore.tntsNotes2).map(itmKey => {
                return <Test3 key={itmKey} notes={rootStore.tntNotesStore.tntsNotes2[itmKey].sortedNotes} />
            })
        }

        <button onClick={() => {
            (async () => {
                await rootStore.tntNotesStore.fetchTntNotes(prjId, tntId)
            })()
        }}>!</button>
    </div>
}

export default observer(TntInfoMain)