import { actionsActs } from "../store/reducers/actionsReducer";
import { lbl } from "../lbls";

export const analyzeActGroupSts = (actGrpSts) => {

    if(actGrpSts && (actGrpSts[actionsActs.del]>0)){
        return {
            active: true,
            txt: lbl.del_pls_wait
        }
    }

    if(actGrpSts && (actGrpSts[actionsActs.save]>0)){
        return {
            active: true,
            txt: lbl.save_pls_wait
        }
    }

    if(actGrpSts && (actGrpSts[actionsActs.load]>0)){
        return {
            active: true,
            txt: lbl.load_pls_wait
        }
    }

    return {
        active: false,
        txt: ''
    }
}