/* eslint-disable default-case */
import produce, { original } from 'immer'
import * as actTypes from '../actions/actionTypes'
import has from 'lodash/has'

const func = (dataType, customExtensions) => {

    return produce((draft, action) => {

        switch (action.type) {
            case actTypes[`DISCARD_${dataType}`]:

                if (draft.byId[action.id]) {
                    delete draft.byId[action.id]
                }
                break;

            case actTypes[`RESET_NEW_${dataType}`]:

                const _curVersNew = draft.byId[action.id]
                if (_curVersNew && _curVersNew.new) {
                    delete _curVersNew.new
                }

                break;

            case actTypes[`STORE_TNT_${dataType}`]:

                if (!action.item.id) {
                    console.warn('action.item.id not set!', action.item)
                    return
                }

                let _curVersion = draft.byId[action.item.id]

                if (!_curVersion) { // action.item.id not in store
                    _curVersion = { tntId: action.tntId }
                    draft.byId[action.item.id] = _curVersion
                }

                if (
                    has(action.item, 'timestamp') &&
                    (action.item.timestamp === _curVersion.timestamp)
                ) { // we have in state the same version as online. only the full-field might be missing in state
                    if (
                        has(action.item, 'full') &&
                        !has(_curVersion, 'full')
                    ) {
                        _curVersion['full'] = action.item['full']
                    }
                    return
                }

                ['id', 'compact', 'full', 'timestamp', 'new'].forEach(fld => {
                    if (has(action.item, fld)) {
                        _curVersion[fld] = action.item[fld]
                    }
                    else {
                        if (has(_curVersion, fld)) {
                            delete _curVersion[fld]
                        }
                    }
                });

                break;

            case actTypes[`SET_TNT_${dataType}S`]:

                const tntObjIds = {}

                // Step1: make sure that all items sent in action are stored in state
                action.items && action.items.forEach(itm => {

                    tntObjIds[itm.id] = true

                    let _curVersion = draft.byId[itm.id]

                    if (!_curVersion) { // itm not in store
                        draft.byId[itm.id] = { ...itm, tntId: action.tntId }
                    }
                    else { //itm already in store
                        if (_curVersion.timestamp === itm.timestamp) { // state version and server version seems to be the same. the full-field might need to be added to state.
                            if (has(itm, 'full') && !has(_curVersion, 'full')) {
                                draft.byId[itm.id] = { ...itm, tntId: action.tntId }
                            }
                        }
                        else { // the state-version seems to be older, replace it with the server-version
                            draft.byId[itm.id] = { ...itm, tntId: action.tntId }
                        }
                    }
                })

                // Step2: make sure that in the tenant specified in action are only the items from step1 + the new created and not saved yet. all others should be deleted
                const orig = original(draft.byId)
                orig && Object.values(orig).filter(item => item.tntId === action.tntId).forEach(itm => {
                    if (tntObjIds[itm.id]) { //itm also loaded from server, should keep it
                        return
                    }
                    if (itm.new) { //new created by client, keep it
                        return
                    }

                    if (draft.byId[itm.id]) { // it doesn't exist on the server -> unload it
                        delete draft.byId[itm.id]
                    }
                })

                break

            default:

                if (customExtensions) {
                    customExtensions(draft, original, action)
                }
                break
        }
    }, {
        byId: {}
    })
}

export default func