import * as actTypes from './actionTypes'

export const setUsrPrjsRoles = (usrId, prjsRoles) => {

    return {
        type: actTypes.SET_USR_PRJS_ROLES,
        usrId,
        prjsRoles,
    }
}

export const setPrjUsrRoles = (prjId, usrId, roles) => {
    return {
        type: actTypes.SET_PRJ_USR_ROLES,
        prjId,
        usrId,
        roles: Array.isArray(roles)?roles:Object.keys(roles)
    }
}