import { lbl } from "../lbls";
import sortBy from "lodash/sortBy";

import routing from "../routing";

export const MEASURE_TIME = false;

export const PERMISSIONS = {
    tntAdmin: "tenant_verwaltung",

    roShelfInspect: "tro_wk_eqp_shelf",
    rwShelfInspect: "trw_wk_eqp_shelf",
    roLadderInspect: "tro_wk_eqp_ladder",
    rwLadderInspect: "trw_wk_eqp_ladder",
    roGateInspect: "tro_wk_eqp_gate",
    rwGateInspect: "trw_wk_eqp_gate",
    roForkliftInspect: "tro_wk_eqp_forklift",
    rwForkliftInspect: "trw_wk_eqp_forklift",
    roPalettruckInspect: "tro_wk_eqp_palettruck",
    rwPalettruckInspect: "trw_wk_eqp_palettruck",
    roHydraulicRampInspect: "tro_wk_eqp_hydraulic_ramp",
    rwHydraulicRampInspect: "trw_wk_eqp_hydraulic_ramp",
    roLoadingRampInspect: "tro_wk_eqp_loading_ramp",
    rwLoadingRampInspect: "trw_wk_eqp_loading_ramp",
    roWorkPlatformInspect: "tro_wk_eqp_work_platform",
    rwWorkPlatformInspect: "trw_wk_eqp_work_platform",
    roVCheck: "tro_wk_eqp_vcheck",
    rwVCheck: "trw_wk_eqp_vcheck",

    roAuditSpecific: "tro_audits_specific",
    rwAuditSpecific: "trw_audits_specific",

    roTpcBasicCare: "tro_audits_tpc_basic_care",
    rwTpcBasicCare: "trw_audits_tpc_basic_care",
    roTpcBasicCareMedic: "tro_audits_tpc_basic_care_medic",
    rwTpcBasicCareMedic: "trw_audits_tpc_basic_care_medic",
    roMedicExams: "tro_audits_medic_exams",
    rwMedicExams: "trw_audits_medic_exams",
    roTpcCompSpec: "tro_audits_topics_comp_specific",
    rwTpcCompSpec: "trw_audits_topics_comp_specific",
    roMaternity: "tro_audits_maternity",
    rwMaternity: "trw_audits_maternity",
    roPsyche: "tro_audits_psyche",
    rwPsyche: "trw_audits_psyche",
    roAccident: "tro_audits_accident_analysis",
    rwAccident: "trw_audits_accident_analysis",

    roTntQuestCat: "tro_quest_tnt",
    rwTntQuestCat: "trw_quest_tnt",
    roTntTxtBlock: "tro_txt_block_tnt",
    rwTntTxtBlock: "trw_txt_block_tnt",

    roPersons: "tro_persons",
    rwPersons: "trw_persons",

    roOcupatSafety: "tro_org_safety_org",
    rwOcupatSafety: "trw_org_safety_org",
    roEmpl: "tro_org_employee",
    rwEmpl: "trw_org_employee",
    roRepresent: "tro_org_representative",
    rwRepresent: "trw_org_representative",
    roFunc: "tro_org_function",
    rwFunc: "trw_org_function",
    roDepart: "tro_org_department",
    rwDepart: "trw_org_department",

    roOrganigram: "tro_org_organigram",
    rwOrganigram: "trw_org_organigram",
    roAnnouncement: "tro_org_announcement",
    rwAnnouncement: "trw_org_announcement",

    roCalc: "tro_calculators",
    rwCalc: "trw_calculators",

    roPerfConf: "tro_perform_confirms",
    rwPerfConf: "trw_perform_confirms",
    roPerfStsRpt: "tro_perform_sts_reports",
    rwPerfStsRpt: "trw_perform_sts_reports",

    roActSpecRisk: "tro_doc_prot_act_spec_risks",
    rwActSpecRisk: "trw_doc_prot_act_spec_risks",
    roSibe: "tro_doc_prot_sibe",
    rwSibe: "trw_doc_prot_sibe",
    roDataProtection: "tro_doc_prot_data_protection",
    rwDataProtection: "trw_doc_prot_data_protection",
    roMeetings: "tro_doc_prot_meetings",
    rwMeetings: "trw_doc_prot_meetings",
    roInstructions: "tro_doc_prot_instructions",
    rwInstructions: "trw_doc_prot_instructions",

    roCarryLift: "tro_doc_prot_carry_lift",
    rwCarryLift: "trw_doc_prot_carry_lift",

    roEffectivenessExam: "tro_effectiveness_exam",
    rwEffectivenessExam: "trw_effectiveness_exam",

    roPsycheReport: "tro_psyche_report",
    rwPsycheReport: "trw_psyche_report",

    roTntNotes: "tro_tnt_notes",
    rwTntNotes: "trw_tnt_notes",

    roTestPersons: "tro_test_persons",
    rwTestPersons: "trw_test_persons",

    roComputerWorkstations: "tro_comp_workstations",
    rwComputerWorkstations: "trw_comp_workstations",

    roGeneralRisk: "tro_doc_prot_general_risks",
    rwGeneralRisk: "trw_doc_prot_general_risks",

    roEhsAudits: "tro_ehs_audits",
    rwEhsAudits: "trw_ehs_audits",

    roOrgComp: "tro_org_companies",
    rwOrgComp: "trw_org_companies",

    roOrgObj: "tro_org_objects",
    rwOrgObj: "trw_org_objects",
};

export const MODULES_CAT = [
    {
        id: "baseModulesCat",
        modules: [
            "serviceHandbook",
            "persons",
            "coleagues",
            "testPersons",
            "announcements",
            "organigram",
            "questionnaires",
            "textModules",
            "functions",
            "departments",
        ],
    },
    {
        id: "captureAndEvaluate",
        modules: [
            "activitySpecificRiskAssessment",
            "topicsBasicCare",
            "topicsCompanySpecific",
            "specficAudit",
            "effectivenessExamination",
            "computerWorkStationInspection",
            "generalRiskAssessment",
            "ehsaudit",
        ],
    },
    {
        id: "specialEvaluate",
        modules: ["psyche", "maternity", "carryLift"],
    },
    {
        id: "documentationAndLogs",
        modules: [
            "meetings",
            "instructions",
            "instructionTemplates",
            "eLearning",
        ],
    },
    {
        id: "amDienstModulesCat",
        modules: ["topicsBasicCareMedic", "medicalExaminations"],
    },
    {
        id: "organizationalPosition",
        modules: ["occupationalSafetyOrganization", "accidentAnalysis", "sibe"],
    },
    {
        id: "performances",
        modules: [
            "performanceConfirmation",
            "statusReport",
            "documentManagement",
        ],
    },
    {
        id: "workEquipmentInspection",
        modules: [
            "shelfInspection",
            "ladderInspection",
            "gateInspection",
            "forkliftInspection",
            "pallettruckInspection",
            "hydraulicRampInspection",
            "workPlatformInspection",
            "loadingRampInspection",
            "vcheck",
        ],
    },
    {
        id: "references",
        modules: ["appInfo", "calculators", "webSites", "signs", "firstAid"],
    },
];

export const PERMISSIONS_GRP = {
    tntAdmin: {
        name: lbl.tnt_admin,
        sortID: 10,
        type: "yesNo",
        val: PERMISSIONS.tntAdmin,
    },

    occupationalSafetyOrganization: {
        name: lbl.occupationalSafetyOrganization,
        sortID: 45,
        ro: PERMISSIONS.roOcupatSafety,
        rw: PERMISSIONS.rwOcupatSafety,
    },

    functions: {
        name: lbl.functions,
        sortID: 70,
        ro: PERMISSIONS.roFunc,
        rw: PERMISSIONS.rwFunc,
    },

    departments: {
        name: lbl.departments,
        sortID: 71,
        ro: PERMISSIONS.roDepart,
        rw: PERMISSIONS.rwDepart,
    },

    announcements: {
        name: lbl.announcements,
        sortID: 72,
        ro: PERMISSIONS.roAnnouncement,
        rw: PERMISSIONS.rwAnnouncement,
    },

    organigrams: {
        name: lbl.organigrams,
        sortID: 73,
        ro: PERMISSIONS.roOrganigram,
        rw: PERMISSIONS.rwOrganigram,
    },

    topicsBasicCare: {
        name: lbl.topicsBasicCare,
        sortID: 82,
        ro: PERMISSIONS.roTpcBasicCare,
        rw: PERMISSIONS.rwTpcBasicCare,
    },

    topicsBasicCareMedic: {
        name: lbl.topicsBasicCareMedic,
        sortID: 84,
        ro: PERMISSIONS.roTpcBasicCareMedic,
        rw: PERMISSIONS.rwTpcBasicCareMedic,
    },

    medicalExaminations: {
        name: lbl.medicalExamination,
        sortID: 85,
        ro: PERMISSIONS.roMedicExams,
        rw: PERMISSIONS.rwMedicExams,
    },

    effectivenessExamination: {
        name: lbl.effectivenessExamination,
        sortID: 87,
        ro: PERMISSIONS.roEffectivenessExam,
        rw: PERMISSIONS.rwEffectivenessExam,
    },

    topicsCompanySpecific: {
        name: lbl.topicsCompanySpecific,
        sortID: 88,
        ro: PERMISSIONS.roTpcCompSpec,
        rw: PERMISSIONS.rwTpcCompSpec,
    },

    specficAudit: {
        name: lbl.specificAudit,
        sortID: 89,
        ro: PERMISSIONS.roAuditSpecific,
        rw: PERMISSIONS.rwAuditSpecific,
    },

    ehsaudit: {
        name: lbl.ehsAudits,
        sortID: 89,
        ro: PERMISSIONS.roEhsAudits,
        rw: PERMISSIONS.rwEhsAudits,
    },

    computerWorkStationInspection: {
        name: lbl.computerWorkStationInspection,
        sortID: 90,
        ro: PERMISSIONS.roComputerWorkstations,
        rw: PERMISSIONS.rwComputerWorkstations,
    },

    questionaires: {
        name: lbl.questionaires,
        sortID: 91,
        ro: PERMISSIONS.roTntQuestCat,
        rw: PERMISSIONS.rwTntQuestCat,
    },

    maternity: {
        name: lbl.maternity,
        sortID: 92,
        ro: PERMISSIONS.roMaternity,
        rw: PERMISSIONS.rwMaternity,
    },

    psyche: {
        name: lbl.psyche,
        sortID: 93,
        ro: PERMISSIONS.roPsyche,
        rw: PERMISSIONS.rwPsyche,
    },

    accidents: {
        name: lbl.accidents,
        sortID: 94,
        ro: PERMISSIONS.roAccident,
        rw: PERMISSIONS.rwAccident,
    },

    shelfInspect: {
        name: lbl.shelfInspections,
        sortID: 100,
        ro: PERMISSIONS.roShelfInspect,
        rw: PERMISSIONS.rwShelfInspect,
    },

    ladderInspect: {
        name: lbl.ladderInspections,
        sortID: 110,
        ro: PERMISSIONS.roLadderInspect,
        rw: PERMISSIONS.rwLadderInspect,
    },

    gateInspect: {
        name: lbl.gateInspections,
        sortID: 120,
        ro: PERMISSIONS.roGateInspect,
        rw: PERMISSIONS.rwGateInspect,
    },

    forkliftInspect: {
        name: lbl.forkliftInspections,
        sortID: 130,
        ro: PERMISSIONS.roForkliftInspect,
        rw: PERMISSIONS.rwForkliftInspect,
    },

    pallettruckInspect: {
        name: lbl.pallettruckInspections,
        sortID: 140,
        ro: PERMISSIONS.roPalettruckInspect,
        rw: PERMISSIONS.rwPalettruckInspect,
    },

    hydraulicRampInspect: {
        name: lbl.hydraulicrampInspections,
        sortID: 150,
        ro: PERMISSIONS.roHydraulicRampInspect,
        rw: PERMISSIONS.rwHydraulicRampInspect,
    },

    workPlatformInspect: {
        name: lbl.workplatformInspections,
        sortID: 160,
        ro: PERMISSIONS.roWorkPlatformInspect,
        rw: PERMISSIONS.rwWorkPlatformInspect,
    },

    loadingRampInspect: {
        name: lbl.loadingrampInspections,
        sortID: 170,
        ro: PERMISSIONS.roLoadingRampInspect,
        rw: PERMISSIONS.rwLoadingRampInspect,
    },

    vcheck: {
        name: lbl.vcheck,
        sortID: 180,
        ro: PERMISSIONS.roVCheck,
        rw: PERMISSIONS.rwVCheck,
    },

    activitySpecificRiskAssessment: {
        name: lbl.activitySpecificRiskAssessment,
        sortID: 430,
        ro: PERMISSIONS.roActSpecRisk,
        rw: PERMISSIONS.rwActSpecRisk,
    },

    generalRiskAssessment: {
        name: lbl.generalRiskAssessment,
        sortID: 435,
        ro: PERMISSIONS.roGeneralRisk,
        rw: PERMISSIONS.rwGeneralRisk,
    },

    sibe: {
        name: lbl.sibe,
        sortID: 440,
        ro: PERMISSIONS.roSibe,
        rw: PERMISSIONS.rwSibe,
    },

    meetings: {
        name: lbl.meetings,
        sortID: 460,
        ro: PERMISSIONS.roMeetings,
        rw: PERMISSIONS.rwMeetings,
    },

    instructions: {
        name: lbl.instructions,
        sortID: 470,
        ro: PERMISSIONS.roInstructions,
        rw: PERMISSIONS.rwInstructions,
    },

    carryLift: {
        name: lbl.carryLift,
        sortID: 471,
        ro: PERMISSIONS.roCarryLift,
        rw: PERMISSIONS.rwCarryLift,
    },

    textModules: {
        name: lbl.textModules,
        sortID: 475,
        ro: PERMISSIONS.roTntTxtBlock,
        rw: PERMISSIONS.rwTntTxtBlock,
    },

    performanceConfirmations: {
        name: lbl.performanceConfirmations,
        sortID: 600,
        ro: PERMISSIONS.roPerfConf,
        rw: PERMISSIONS.rwPerfConf,
    },

    perfStsRpt: {
        name: lbl.statusReports,
        sortID: 620,
        ro: PERMISSIONS.roPerfStsRpt,
        rw: PERMISSIONS.rwPerfStsRpt,
    },

    calculators: {
        name: lbl.calculators,
        sortID: 700,
        ro: PERMISSIONS.roCalc,
        rw: PERMISSIONS.rwCalc,
    },

    persons: {
        name: lbl.persons,
        sortID: 800,
        ro: PERMISSIONS.roPersons,
        rw: PERMISSIONS.rwPersons,
    },

    testPersons: {
        name: lbl.testPersons,
        sortID: 801,
        ro: PERMISSIONS.roTestPersons,
        rw: PERMISSIONS.rwTestPersons,
    },

    psycheEvaluation: {
        name: "Psyche-Auswertung",
        sortID: 802,
        ro: PERMISSIONS.roPsycheReport,
        rw: PERMISSIONS.rwPsycheReport,
    },

    tntNotes: {
        name: "Mandant-Notizen",
        sortID: 803,
        ro: PERMISSIONS.roTntNotes,
        rw: PERMISSIONS.rwTntNotes,
    },

    orgComp: {
        name: lbl.orgComp,
        sortID: 804,
        ro: PERMISSIONS.roOrgComp,
        rw: PERMISSIONS.rwOrgComp,
    },

    orgObj: {
        name: lbl.orgObj,
        sortID: 805,
        ro: PERMISSIONS.roOrgObj,
        rw: PERMISSIONS.rwOrgObj,
    },
};

export const PERMISSIONS_GRP_ARR = sortBy(
    Object.keys(PERMISSIONS_GRP).map((key) => {
        return {
            id: key,
            ...PERMISSIONS_GRP[key],
        };
    }),
    "sortID"
);

export const MODULES = {
    psycheReport: {
        name: "Psyche-Report",
        desc: "Psyche-Report Beschreibung",

        permissions: {
            ro: [PERMISSIONS.roPsycheReport],
            rw: [PERMISSIONS.rwPsycheReport],
        },

        cat: "organizationalPosition",
        url: "psyche-report",
        img: "/img/modules-v2/colleagues@3x.png",
    },

    occupationalSafetyOrganization: {
        name: lbl.occupationalSafetyOrganization,
        desc: "",

        permissions: {
            ro: [PERMISSIONS.roOcupatSafety],
            rw: [PERMISSIONS.rwOcupatSafety],
        },
        cat: "organizationalPosition",
        url: "ocup-safety-org",
        img: "/img/modules-v2/occupationalSafetyOrganization@3x.png",
    },

    coleagues: {
        free: 1,

        name: "Kollegen",
        desc: "Kollegen-Beschreibung",

        cat: "organizationalPosition",
        url: "coleagues",
        img: "/img/modules-v2/colleagues@3x.png",
    },

    testPersons: {
        name: "Probanden",
        desc: "Probanden-Beschreibung",
        permissions: {
            ro: [PERMISSIONS.roTestPersons],
            rw: [PERMISSIONS.rwTestPersons],
        },
        cat: "organizationalPosition",
        url: "test-persons",
        img: "/img/modules-v2/testPersons@3x.png",
    },

    announcements: {
        name: lbl.announcements,
        desc: lbl.announcementsSubtitle,
        permissions: {
            ro: [PERMISSIONS.roAnnouncement],
            rw: [PERMISSIONS.rwAnnouncement],
        },
        cat: "organizationalPosition",
        url: "announcements",
        img: "/img/modules-v2/announcements@3x.png",
    },

    organigram: {
        name: lbl.organigrams,
        desc: "",
        permissions: {
            ro: [PERMISSIONS.roOrganigram],
            rw: [PERMISSIONS.rwOrganigram],
        },
        cat: "organizationalPosition",
        url: "organigrams",
        img: "/img/modules-v2/organigram@3x.png",
        hidden: true,
    },

    functions: {
        free: 1,

        name: lbl.functions,
        desc: lbl.functionsSubtitle,

        permissions: {
            ro: [PERMISSIONS.roFunc],
            rw: [PERMISSIONS.rwFunc],
        },
        cat: "organizationalPosition",
        url: "func",
        img: "/img/modules-v2/functions@3x.png",
    },

    departments: {
        free: 1,

        name: lbl.departments,
        desc: lbl.departmentsSubtitle,

        permissions: {
            ro: [PERMISSIONS.roDepart],
            rw: [PERMISSIONS.rwDepart],
        },
        cat: "organizationalPosition",
        url: "depart",
        img: "/img/modules-v2/departments@3x.png",
    },

    topicsBasicCare: {
        name: lbl.topicsBasicCare,
        desc: "",

        permissions: {
            ro: [PERMISSIONS.roTpcBasicCare],
            rw: [PERMISSIONS.rwTpcBasicCare],
        },

        cat: "captureAndEvaluate",
        url: routing.audits.url.main("-prjId-", "-tntId-", "topics-basic-care"),

        img: "/img/modules-v2/topicsBasicCare@3x.png",
        ready: true,
    },

    topicsBasicCareMedic: {
        name: lbl.topicsBasicCareMedic,
        desc: "",

        permissions: {
            ro: [PERMISSIONS.roTpcBasicCareMedic],
            rw: [PERMISSIONS.rwTpcBasicCareMedic],
        },

        cat: "captureAndEvaluate",
        url: routing.audits.url.main(
            "-prjId-",
            "-tntId-",
            "topics-basic-care-medic"
        ),

        img: "/img/modules-v2/topicsBasicCareMedic@3x.png",
        ready: true,
    },

    medicalExaminations: {
        name: lbl["medicalExamination"],
        desc: lbl["medicalExamination-subTitle"],

        permissions: {
            ro: [PERMISSIONS.roMedicExams],
            rw: [PERMISSIONS.rwMedicExams],
        },

        cat: "captureAndEvaluate",
        url: routing.audits.url.main("-prjId-", "-tntId-", "medic-exams"),

        img: "/img/modules-v2/topicsBasicCare@3x.png",
        ready: false,
    },

    // TODO: Labels Modul effectivenessExamination kontrollieren!
    effectivenessExamination: {
        name: lbl.effectivenessExamination,
        desc: lbl.effectivenessExamination,

        permissions: {
            ro: [PERMISSIONS.roEffectivenessExam],
            rw: [PERMISSIONS.rwEffectivenessExam],
        },
        cat: "captureAndEvaluate",
        url: "effectiv-exams",
        img: "/img/modules-v2/effectivenessExamination@3x.png",
    },

    topicsCompanySpecific: {
        name: lbl.topicsCompanySpecific,
        desc: "",

        permissions: {
            ro: [PERMISSIONS.roTpcCompSpec],
            rw: [PERMISSIONS.rwTpcCompSpec],
        },
        cat: "captureAndEvaluate",
        url: routing.audits.url.main("-prjId-", "-tntId-", "topics-comp-spec"),
        img: "/img/modules-v2/topicsCompanySpecific@3x.png",
        ready: true,
    },

    ehsaudit: {
        name: lbl.ehsAudits,
        desc: lbl.ehsAuditsModuleDescription,

        permissions: {
            ro: [PERMISSIONS.roEhsAudits],
            rw: [PERMISSIONS.rwEhsAudits],
        },

        cat: "captureAndEvaluate",
        url: routing.audits.url.main("-prjId-", "-tntId-", "ehs-audits"),

        img: "/img/modules-v2/specficAudit@3x.png",
        ready: false,
    },

    specficAudit: {
        name: lbl.specificAudit,
        desc: lbl.auditsModuleDescription,

        permissions: {
            ro: [PERMISSIONS.roAuditSpecific],
            rw: [PERMISSIONS.rwAuditSpecific],
        },

        cat: "captureAndEvaluate",
        url: routing.audits.url.main("-prjId-", "-tntId-", "spec-audits"),

        img: "/img/modules-v2/specficAudit@3x.png",
        ready: true,
    },

    computerWorkStationInspection: {
        name: lbl.computerWorkStationInspection,
        desc: "",

        permissions: {
            ro: [PERMISSIONS.roComputerWorkstations],
            rw: [PERMISSIONS.rwComputerWorkstations],
        },
        cat: "captureAndEvaluate",
        url: "computer-ws",
        img: "/img/modules-v2/computerWorkstations@3x.png",
    },

    questionnaires: {
        free: 1,

        name: lbl.questionnaires,
        desc: lbl.questionnairesModuleDescription,

        permissions: {
            sa: true,
            custAdm: true,
            ro: [PERMISSIONS.roTntQuestCat],
            rw: [PERMISSIONS.rwTntQuestCat],
        },

        cat: "captureAndEvaluate",
        url: "quest",
        img: "/img/modules-v2/questionnaires@3x.png",
    },

    maternity: {
        name: lbl.maternity,
        desc: lbl.maternitySubtitle,
        permissions: {
            ro: [PERMISSIONS.roMaternity],
            rw: [PERMISSIONS.rwMaternity],
        },
        cat: "specialEvaluate",
        url: "maternity",
        img: "/img/modules-v2/maternity@3x.png",
    },

    psyche: {
        name: lbl.psyche,
        desc: lbl.psycheSubtitle,
        permissions: {
            ro: [PERMISSIONS.roPsyche],
            rw: [PERMISSIONS.rwPsyche],
        },
        cat: "specialEvaluate",
        url: "psyche",
        img: "/img/modules-v2/psyche@3x.png",
    },

    carryLift: {
        name: lbl.carryLift,
        desc: lbl.carryLiftSubtitle,

        permissions: {
            ro: [PERMISSIONS.roCarryLift],
            rw: [PERMISSIONS.rwCarryLift],
        },
        cat: "specialEvaluate",
        url: "carry-lift",
        img: "/img/modules-v2/carryLift@3x.png",
    },

    accidentAnalysis: {
        name: lbl.accidents,
        desc: lbl.accidentsSubtitle,

        permissions: {
            ro: [PERMISSIONS.roAccident],
            rw: [PERMISSIONS.rwAccident],
        },
        cat: "captureAndEvaluate",
        url: "accidents",
        img: "/img/modules-v2/accidentAnalysis@3x.png",
    },

    shelfInspection: {
        name: lbl.shelfInspections,
        desc: lbl.InspectionforShelfs,
        permissions: {
            ro: [PERMISSIONS.roShelfInspect],
            rw: [PERMISSIONS.rwShelfInspect],
        },
        cat: "workEquipmentInspection",
        url: "wkeq-shelf",
        img: "/img/modules-v2/shelfInspection@3x.png",
        ready: true,
    },

    ladderInspection: {
        name: lbl.ladderInspections,
        desc: lbl.ladderInspection,
        permissions: {
            ro: [PERMISSIONS.roLadderInspect],
            rw: [PERMISSIONS.rwLadderInspect],
        },
        cat: "workEquipmentInspection",
        url: "wkeq-ladder",
        img: "/img/modules-v2/ladderInspection@3x.png",
    },

    gateInspection: {
        name: lbl.gateInspections,
        desc: lbl.gateInspectionsDescription,
        permissions: {
            ro: [PERMISSIONS.roGateInspect],
            rw: [PERMISSIONS.rwGateInspect],
        },
        cat: "workEquipmentInspection",
        url: "wkeq-gate",
        img: "/img/modules-v2/gateInspection@3x.png",
    },

    forkliftInspection: {
        name: lbl.forkliftInspections,
        desc: lbl.forkliftInspectionsDescription,
        permissions: {
            ro: [PERMISSIONS.roForkliftInspect],
            rw: [PERMISSIONS.rwForkliftInspect],
        },
        cat: "workEquipmentInspection",
        url: "wkeq-forklift",
        img: "/img/modules-v2/forkliftInspection@3x.png",
    },

    pallettruckInspection: {
        name: lbl.pallettruckInspections,
        desc: lbl.pallettruckInspectionsDescription,
        permissions: {
            ro: [PERMISSIONS.roPalettruckInspect],
            rw: [PERMISSIONS.rwPalettruckInspect],
        },
        cat: "workEquipmentInspection",
        url: "wkeq-palettruck",
        img: "/img/modules-v2/pallettruckInspection@3x.png",
    },

    hydraulicRampInspection: {
        name: lbl.hydraulicrampInspections,
        desc: "",

        permissions: {
            ro: [PERMISSIONS.roHydraulicRampInspect],
            rw: [PERMISSIONS.rwHydraulicRampInspect],
        },
        cat: "workEquipmentInspection",
        url: "wkeq-hydr-ramp",
        img: "/img/modules-v2/hydraulicRampInspection@3x.png",
    },

    workPlatformInspection: {
        name: lbl.workplatformInspections,
        desc: "",
        permissions: {
            ro: [PERMISSIONS.roWorkPlatformInspect],
            rw: [PERMISSIONS.rwWorkPlatformInspect],
        },
        cat: "workEquipmentInspection",
        url: "wkeq-work-platf",
        img: "/img/modules-v2/workPlatformInspection@3x.png",
    },

    loadingRampInspection: {
        name: lbl.loadingrampInspections,
        desc: "",
        permissions: {
            ro: [PERMISSIONS.roLoadingRampInspect],
            rw: [PERMISSIONS.rwLoadingRampInspect],
        },
        cat: "workEquipmentInspection",
        url: "wkeq-load-ramp",
        img: "/img/modules-v2/loadingRampInspection@3x.png",
    },

    vcheck: {
        name: lbl.vcheck,
        desc: "",
        permissions: {
            ro: [PERMISSIONS.roVCheck],
            rw: [PERMISSIONS.rwVCheck],
        },
        cat: "workEquipmentInspection",
        url: "vcheck",
        img: "/img/modules-v2/appInfo@3x.png",
    },

    activitySpecificRiskAssessment: {
        name: lbl.activitySpecificRiskAssessment,
        desc: "",

        permissions: {
            ro: [PERMISSIONS.roActSpecRisk],
            rw: [PERMISSIONS.rwActSpecRisk],
        },

        cat: "captureAndEvaluate",
        url: routing.audits.url.main(
            "-prjId-",
            "-tntId-",
            "actv-spec-risk-asses"
        ),
        img: "/img/modules-v2/activitySpecificRiskAssessment@3x.png",

        ready: true,
    },

    generalRiskAssessment: {
        name: lbl.generalRiskAssessment,
        desc: "",

        permissions: {
            ro: [PERMISSIONS.roGeneralRisk],
            rw: [PERMISSIONS.rwGeneralRisk],
        },

        cat: "captureAndEvaluate",
        url: routing.audits.url.main(
            "-prjId-",
            "-tntId-",
            "general-risk-asses"
        ),
        img: "/img/modules-v2/activitySpecificRiskAssessment@3x.png",

        ready: false,
    },

    sibe: {
        name: lbl.sibe,
        desc: lbl.sibeDescription,

        permissions: {
            ro: [PERMISSIONS.roSibe],
            rw: [PERMISSIONS.rwSibe],
        },
        cat: "documentationAndLogs",
        url: "sibe",
        img: "/img/modules-v2/sibe@3x.png",
    },

    meetings: {
        name: lbl.meetings,
        desc: "",

        permissions: {
            ro: [PERMISSIONS.roMeetings],
            rw: [PERMISSIONS.rwMeetings],
        },
        cat: "documentationAndLogs",
        url: routing.meetings.url.main("-prjId-", "-tntId-", "meetings-v3"),
        img: "/img/modules-v2/meetings@3x.png",

        ready: true,
    },

    instructions: {
        name: lbl.instructions,
        desc: lbl.instructionsDescription,

        permissions: {
            ro: [PERMISSIONS.roInstructions],
            rw: [PERMISSIONS.rwInstructions],
        },

        cat: "documentationAndLogs",
        url: "instruct",
        img: "/img/modules-v2/instructions@3x.png",
    },

    textModules: {
        free: 1,

        name: lbl.textModules,
        desc: lbl.textModules,

        permissions: {
            sa: true,
            custAdm: true,
            ro: [PERMISSIONS.roTntTxtBlock],
            rw: [PERMISSIONS.rwTntTxtBlock],
        },

        cat: "documentationAndLogs",
        url: "txt-blocks",
        img: "/img/modules-v2/textModules@3x.png",
    },

    documentManagement: {
        free: 1,

        permissions: {},

        name: lbl.documentManagement,
        desc: lbl.documentManagementSubtitle,

        url: "doc-management",
        img: "/img/modules-v2/documentManagement@3x.png",
    },
    reports: { free: 1 },

    eLearning: {
        free: 1,

        name: lbl.eLearning,
        desc: lbl.eLearningSubModuleSubtitle,

        permissions: {
            ro: [],
            rw: [],
        },

        cat: "organizationalPosition",
        url: "e-learning",
        img: "/img/modules-v2/eLearning@3x.png",
    },

    performanceConfirmation: {
        name: lbl.performanceConfirmations,
        desc: "",

        permissions: {
            ro: [PERMISSIONS.roPerfConf],
            rw: [PERMISSIONS.rwPerfConf],
        },

        cat: "performances",
        url: routing.perfConf.url.main("-prjId-", "-tntId-"),

        img: "/img/modules-v2/performanceConfirmation@3x.png",
        ready: true,
    },

    statusReport: {
        name: lbl.statusReport,
        desc: "",

        permissions: {
            ro: [PERMISSIONS.roPerfStsRpt],
            rw: [PERMISSIONS.rwPerfStsRpt],
        },

        cat: "performances",
        url: "sts-rpt",

        img: "/img/modules-v2/statusReport@3x.png",
    },

    serviceHandbook: {
        free: 1,

        name: lbl.serviceHandbook,
        desc: "",

        permissions: {
            ro: [],
            rw: [],
        },

        cat: "organizationalPosition",
        url: "handbook",
        img: "/img/modules-v2/serviceHandbook@3x.png",
    },

    persons: {
        free: 1,

        name: lbl.persons,
        desc: "",

        permissions: {
            ro: [],
            rw: [],
        },

        cat: "organizationalPosition",
        url: "persons",
        img: "/img/modules-v2/persons@3x.png",
    },

    signs: {
        free: 1,

        name: lbl.signsModule,
        desc: lbl.signsModuleSubtitle,

        permissions: {
            ro: [],
            rw: [],
        },

        cat: "references",
        url: "signs",
        img: "/img/modules-v2/signs@3x.png",
    },

    firstAid: {
        free: 1,

        name: lbl.firstAidKitModule,
        desc: lbl.firstAidKitModuleSubtitle,

        permissions: {
            ro: [],
            rw: [],
        },

        cat: "references",
        url: "first-aid",
        img: "/img/modules-v2/firstAid@3x.png",
    },

    calculators: {
        free: 1,

        name: lbl.calculatorsModule,
        desc: lbl.calculatorsModuleSubtitle,

        permissions: {
            ro: [PERMISSIONS.roCalc],
            rw: [PERMISSIONS.rwCalc],
        },

        cat: "references",
        url: "calc",
        img: "/img/modules-v2/calculators@3x.png",
    },

    webSites: {
        free: 1,

        name: lbl.webSiteModule,
        desc: lbl.webSiteModuleSubtitle,

        permissions: {
            ro: [],
            rw: [],
        },

        cat: "references",
        url: "websites",
        img: "/img/modules-v2/webSites@3x.png",
    },

    instructionTemplates: {
        free: 1,

        name: lbl.instructionTemplatesModule,
        desc: lbl.instructionTemplatesSubtitle,

        permissions: {
            ro: [],
            rw: [],
        },

        cat: "references",
        url: "instruct-tmpl",
        img: "/img/modules-v2/instructionTemplates@3x.png",
    },

    appInfo: {
        free: 1,

        name: lbl.infoModule,
        desc: lbl.infoModuleSubtitle,

        permissions: {
            ro: [],
            rw: [],
        },

        cat: "references",
        url: "app-info",
        img: "/img/modules-v2/appInfo@3x.png",
    },
};

export const myDxDefaultPageSize = 10;
export const myDxAllowedPageSizes = [5, 10, 20];

export const headHeight = 50;
export const footHeight = 40;
export const navWidth = 350;

export const appVersion = "0.1.2";

//export const appEnvironment = 'production'
export const appEnvironment = "dev";

export const autoSizeTextArea = { minRows: 2, maxRows: 10 };
