import { autorun } from 'mobx'
import RootStore from './RootStore'

import { startRolesFetcherCron, stopRolesFetcherCron } from './crons'

const rootStore = new RootStore()

const doAfterLogin = async () => {

    startRolesFetcherCron()

    await Promise.all([
        rootStore.rolesStore.fetchRoles()
    ])
}

const doAfterLogout = async () => {

    stopRolesFetcherCron()

    console.debug('//TODO: Implement doAfterLogout...')
}

autorun(() => {

    if (rootStore.authStore.uid) {
        doAfterLogin()
    }
    else {
        doAfterLogout()
    }
})

autorun(() => {
    console.debug('Sel cust-prj-tnt', rootStore.uiStore.selCustId, rootStore.uiStore.selPrjId, rootStore.uiStore.selTntId)
})

autorun(() => { /* on project change => load auth user's roles */
    if (rootStore.uiStore.selPrjId) {
        rootStore.authRolesStore.fetchPrjRoles(rootStore.uiStore.selPrjId)
    }
})

export default rootStore