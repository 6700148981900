import { makeAutoObservable } from "mobx"

export default class Role {

    code = ''
    timestamp = 0
    perms = {}

    constructor() {
        makeAutoObservable(this)
    }

    updateFromJson = (json) => {
        //console.debug('Role::updateFromJson', json)

        if (this.timestamp >= json.timestamp) {
            return
        }

        this.code = json.id
        this.timestamp = json.timestamp

        json.permissions.forEach(p => {
            this.perms[p] = true
        })
    }
}