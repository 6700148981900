
const _actionTypes = {}
const _getActionType = (area, act, grp, suffix) => {

    const type=`ACTSTS~${area}~${act}~${(grp)?'G':'S'}~${suffix}`

    if(!_actionTypes[type]){
        _actionTypes[type]=type
    }

    return _actionTypes[type]
}

export const actStarted = (area, grp, tgt, act) => {
    return {
        type: _getActionType(area, act, grp, 'STARTED'),
        area,
        tgt,
        act,
        grp,
        start:true,
    }
}

export const actReadyOk = (area, grp, tgt, act) => {
    return {
        type: _getActionType(area, act, grp, 'READY_OK'),
        area,
        tgt,
        act,
        grp,
    }
}

export const actReadyPb = (area, grp, tgt, act, err) => {
    return {
        type: _getActionType(area, act, grp, 'READY_PB'),
        area,
        tgt,
        act,
        grp,
        err,
    }
}