import * as actTypes from '../actions/actionTypes'
import { BugCrash } from '../../services/BugCrash'

import Axios from '../../services/Axios'
import { lbl } from '../../lbls'
import { registerFeedback } from './feedbackActions';
import { lgDebug } from '../../utils/logging';
import { actStarted, actReadyOk, actReadyPb } from './actionsActions';
import { actionsAreas, actionsActs } from '../reducers/actionsReducer';
import { upsertCusts } from './custActions';

import pick from 'lodash/pick'
import { isNotFoundErr } from '../../libs/actErrors';

export const loadCustTenants = (custId, actGrp) => {
    return async (dispatch, getState) => {

        const _actTgt = `C-${custId}`

        try {
            dispatch(actStarted(actionsAreas.tnts, actGrp, _actTgt, actionsActs.load))

            const resp = await Axios.get(`/customers/${custId}/tenants`)
            if (resp && (resp.status === 200)) {
                //console.log('loadCustTenants::', resp.data)

                const _tnts2Save = [...resp.data.own]
                const _shrs2Save = []
                const _custData = {
                    id: custId,
                    shrIds: {},
                    tntIds: {},
                }

                resp.data.own && resp.data.own.forEach(ownTnt => {
                    _tnts2Save.push(ownTnt)
                    _custData.tntIds[ownTnt.id] = true
                })

                resp.data.shares && resp.data.shares.forEach(shr => {

                    _custData.shrIds[shr.id] = true

                    const _shrData = {
                        id: shr.id,
                        rootTntId: shr.rootTenantId,
                        tenant: shr.tenant ? shr.tenant : shr.tenants.find(t => t.id === shr.rootTenantId),
                        fromCustId: shr.fromCust && shr.fromCust.id,
                        fromCustName: shr.fromCust && shr.fromCust.name,
                        toCustId: custId,
                        tntIds: {}
                    }

                    shr.tenants && shr.tenants.forEach(tnt => {
                        _tnts2Save.push(tnt)
                        _shrData.tntIds[tnt.id] = true
                    })

                    _shrs2Save.push(_shrData)
                })

                dispatch(upsertCusts([_custData]))
                if (_shrs2Save.length > 0) {
                    dispatch({ type: actTypes.STORE_UPSERT_SHARES, items: _shrs2Save })
                }

                dispatch(upsertTenants(_tnts2Save))
                dispatch(actReadyOk(actionsAreas.tnts, actGrp, _actTgt, actionsActs.load))

                return
            }

            throw new Error(lbl.err_load_cust_tnts)
        }
        catch (err) {
            BugCrash.notify(err)
            dispatch(actReadyPb(actionsAreas.tnts, actGrp, _actTgt, actionsActs.load, err))
        }
    }
}

export const upsertTenants = (tnts) => {
    return {
        type: actTypes.STORE_UPSERT_TENANTS,
        items: Array.isArray(tnts) ? tnts : Object.values(tnts),
    }
}

export const delTenant = (custId, tntId, actGrp) => {
    return async (dispatch, getState) => {

        const _actTgt = `del-tnt-${tntId}`

        try {
            const _state = await getState()
            const _tnt = _state.tnts.byId[tntId]

            if (_tnt && _tnt.new) {
                lgDebug(`Der Mandant ${tntId} ist neu -> nichts zu löschen serverseitig!`)
                dispatch(discardTenant(tntId))
                return
            }

            dispatch(actStarted(actionsAreas.tnts, actGrp, _actTgt, actionsActs.del))

            const resp = await Axios.delete(`/customers/${custId}/tenants/${tntId}`)
            if (resp && (resp.status === 200)) {
                dispatch(discardTenant(tntId))
                dispatch(actReadyOk(actionsAreas.tnts, actGrp, _actTgt, actionsActs.del))
                dispatch(registerFeedback(lbl.feedback_del_tnt.replace('%name', _tnt && _tnt.name), false))
                return
            }

            throw new Error(lbl.err_del_tnt)
        }
        catch (err) {
            BugCrash.notify(err)
            dispatch(actReadyPb(actionsAreas.tnts, actGrp, _actTgt, actionsActs.del, err))
        }
    }
}

export const loadTenant = (custId, tntId, actGrp) => {
    return async (dispatch, getState) => {

        const _actTgt = `load-tnt-${tntId}`

        try {
            const _state = await getState()
            if (_state.tnts.byId[tntId] && _state.tnts.byId[tntId].new) {
                lgDebug(`Der Mandant ${tntId} ist neu -> laden vom Server kann nichts bringen!`)
                return
            }

            dispatch(actStarted(actionsAreas.tnts, actGrp, _actTgt, actionsActs.load))

            const resp = await Axios.get(`/customers/${custId}/tenants/${tntId}`)
            if (resp && (resp.status === 200)) {

                dispatch(upsertTenants([resp.data]))
                dispatch({ type: actTypes.ASSIGN_CUST_TNTS, custId, tntIds: [resp.data.id] })
                dispatch(actReadyOk(actionsAreas.tnts, actGrp, _actTgt, actionsActs.load))

                return
            }

            throw new Error(lbl.err_load_tnt)
        }
        catch (err) {
            BugCrash.notify(err)
            dispatch(actReadyPb(actionsAreas.tnts, actGrp, _actTgt, actionsActs.load, err))
        }
    }
}

export const saveTenant = (custId, tntId, values, actGrp) => {
    return async (dispatch, getState) => {

        const _actTgt = `save-tnt-${tntId}`

        try {
            dispatch(actStarted(actionsAreas.tnts, actGrp, _actTgt, actionsActs.save))

            const _state = await getState()
            const _origVersion = _state.tnts.byId[tntId]

            const _tntData = {
                ...pick(values, ['id', 'parent', 'compact', 'full', 'name', 'container']),
                timestampBefore: (_origVersion && _origVersion.timestamp) || 0,
            }

            //console.warn(_origVersion, values, _tntData)

            const resp = await Axios.post(`/customers/${custId}/tenants/${tntId}`, _tntData)
            if (resp && (resp.status === 200)) {

                dispatch(registerFeedback(lbl.feedback_save_tnt_ok.replace('%tnt', _tntData.name), false))
                dispatch(upsertTenants([{ id: tntId, new: false }]))
                dispatch(loadTenant(custId, tntId, actGrp))

                dispatch(actReadyOk(actionsAreas.tnts, actGrp, _actTgt, actionsActs.save))
                return
            }

            throw new Error(lbl.err_save_tnt)
        }
        catch (err) {
            if (isNotFoundErr(err)) {
                dispatch(discardTenant(values.id))
            }
            else {
                BugCrash.notify(err)
                dispatch(registerFeedback(lbl.feedback_save_tnt_err, true).replace("%later", lbl.try_again_later))
            }

            dispatch(actReadyPb(actionsAreas.tnts, actGrp, _actTgt, actionsActs.save))
        }
    }
}

export const addTenant = (custId, parentTntId, tntId, name, afterCreate) => {
    return async (dispatch, getState) => {

        dispatch(upsertTenants([{ id: tntId, parent: parentTntId, name, compact: {}, full: {}, new: true }]))
        dispatch({ type: actTypes.ASSIGN_CUST_TNTS, custId, tntIds: [tntId] })

        if (afterCreate) {
            setTimeout(() => { afterCreate() }, 100)
        }
    }
}

export const discardTenant = (tntId) => {
    return {
        type: actTypes.DISCARD_TENANT,
        id: tntId,
    }
}