import { combineReducers } from 'redux'
//import produce from 'immer'
//import * as actTypes from '../actions/actionTypes'

//import set from 'lodash/set'
import merge from 'lodash/merge'

/*
const _exercisesRouter = (state = {}, action) => {
    switch (action.type) {
        case 'CREATE_EXERCISE':
            return {
                ...state,
                [action.id]: {
                    ...action.exercise
                }
            };
        case 'UPDATE_EXERCISE':
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    ...action.exercise
                }
            };
        default:
            if (action.entities && action.entities.exercises) {
                return merge({}, state, action.entities.exercises);
            }
            return state;
    }
}*/

const _topicBasicCareAnswerActionsReducer = (state = {}, action) => {
    switch (action.type) {
        default:
            if (action.entities && action.entities.topicBasicCareAnswerActions) {
                return merge({}, state, action.entities.topicBasicCareAnswerActions)
            }
            return state;
    }
}

const _topicBasicCareAnswersReducer = (state = {}, action) => {
    switch (action.type) {
        default:
            if (action.entities && action.entities.topicBasicCareAnswers) {
                return merge({}, state, action.entities.topicBasicCareAnswers)
            }
            return state;
    }
}

const _topicBasicCaresReducer = (state = {}, action) => {
    switch (action.type) {
        default:
            if (action.entities && action.entities.topicBasicCares) {
                return merge({}, state, action.entities.topicBasicCares)
            }
            return state;
    }
}

const _storageImagesReducer = (state = {}, action) => {
    switch (action.type) {
        default:
            if (action.entities && action.entities.storageImages) {
                return merge({}, state, action.entities.storageImages)
            }
            return state;
    }
}

const _sysQcVersionCategQuestionActionsReducer = (state = {}, action) => {
    switch (action.type) {
        default:
            if (action.entities && action.entities.sysQcVersionCategQuestionActions) {
                return merge({}, state, action.entities.sysQcVersionCategQuestionActions)
            }
            return state;
    }
}

const _sysQcVersionCategQuestionRegulationsReducer = (state = {}, action) => {
    switch (action.type) {
        default:
            if (action.entities && action.entities.sysQcVersionCategQuestionRegulations) {
                return merge({}, state, action.entities.sysQcVersionCategQuestionRegulations)
            }
            return state;
    }
}

const _sysQcVersionCategQuestionsReducer = (state = {}, action) => {
    switch (action.type) {
        default:
            if (action.entities && action.entities.sysQcVersionCategQuestions) {
                return merge({}, state, action.entities.sysQcVersionCategQuestions)
            }
            return state;
    }
}

const _sysQcVersionCategsReducer = (state = {}, action) => {
    switch (action.type) {
        default:
            if (action.entities && action.entities.sysQcVersionCategs) {
                return merge({}, state, action.entities.sysQcVersionCategs)
            }
            return state;
    }
}

const _sysQcVersionsReducer = (state = {}, action) => {
    switch (action.type) {
        default:
            if (action.entities && action.entities.sysQcVersions) {
                return merge({}, state, action.entities.sysQcVersions)
            }
            return state;
    }
}

export default combineReducers({
    // exercises: _exercisesRouter,
    storageImages: _storageImagesReducer,
    topicBasicCares: _topicBasicCaresReducer,
    topicBasicCareAnswers: _topicBasicCareAnswersReducer,
    topicBasicCareAnswerActions: _topicBasicCareAnswerActionsReducer,

    sysQcVersionCategQuestionActions: _sysQcVersionCategQuestionActionsReducer,
    sysQcVersionCategQuestionRegulations: _sysQcVersionCategQuestionRegulationsReducer,
    sysQcVersionCategQuestions: _sysQcVersionCategQuestionsReducer,
    sysQcVersionCategs: _sysQcVersionCategsReducer,
    sysQcVersions: _sysQcVersionsReducer,
})