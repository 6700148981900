import { makeAutoObservable, observable, remove, set } from "mobx"
import { getUuid } from "../../services/IdGenerator"

export default class WorkProgressStore {

    rootStore

    loadingTasks = observable.map()
    pushingTasks = observable.map()

    constructor(rootStore) {
        makeAutoObservable(this, { rootStore: false, sortedNotesByTntId: false })

        this.rootStore = rootStore
    }

    startLoading = (taskInfo) => {

        const id = getUuid()
        set(this.loadingTasks, id, {
            id,
            ti: taskInfo,
        })

        return id
    }

    doneLoading = (id) => {
        remove(this.loadingTasks, id)
    }

    startPushing = (taskInfo) => {

        const id = getUuid()
        set(this.pushingTasks, id, {
            id,
            ti: taskInfo,
        })

        return id
    }

    donePushing = (id) => {
        remove(this.pushingTasks, id)
    }

    get hasPushingTasks() {
        return this.pushingTasks.size > 0
    }
    get hasLoadingTasks() {
        return this.loadingTasks.size > 0
    }
}