/* eslint-disable default-case */
import produce from 'immer'
import * as actTypes from '../actions/actionTypes'

export default produce((draft, action) => {

    switch (action.type) {

        case actTypes.STORE_UPSERT_USERS:
            action.items && action.items.forEach(usr => {

                const _curVersion = draft.byId[usr.id]
                
                if(!_curVersion){ // user not in store
                    draft.allIds.push(usr.id)
                    draft.byId[usr.id] = usr
                }
                else{ //user in store
                    draft.byId[usr.id] = {..._curVersion, ...usr}
                }
            })
            break
    }
}, {
    allIds:[], 
    byId:{}
})