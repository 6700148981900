export const SyncActTypes = {
    'loadSysCatVersByNb': 'loadSysCatVersByNb',
    'loadAudit': 'loadAudit',
}

export const SyncActOpType = {
    'load': 'load',
    'save': 'save',
    'del': 'del',
}

export const SyncActSts = {
    'pending': 'pending',
    'running': 'running',
    'done': 'done',
    'err': 'err',
}