import * as actTypes from '../actions/actionTypes'

const initState = {
    lng: 'de',
    xs: null,
    cust: null,
    admCust: null,
    projByCust: {},
    tntByProj: {},
}

const sessionReducer = (state = initState, action) => {

    switch(action.type){

        case actTypes.SES_SET_XS:
            return {
                ...state,
                xs: action.xs,
            }

        case actTypes.SES_SET_LNG:
            return {
                ...state,
                lng: action.lng,
            }

        case actTypes.SES_SET_CUST:
            return {
                ...state,
                cust: action.custId,
            }

        case actTypes.SES_SET_ADM_CUST:
            return {
                ...state,
                admCust: action.custId,
            }

        case actTypes.SES_SET_PROJ:
            return {
                ...state,
                projByCust: {
                    ...state.projByCust,
                    [action.custId]: action.prjId,
                }
            }

        case actTypes.SES_SET_TNT:
            return {
                ...state,
                tntByProj: {
                    ...state.tntByProj,
                    [action.prjId]: action.tntId,
                }
            }

        default:
            return state
    }
}

export default sessionReducer;