import { makeAutoObservable, has, flow, runInAction, keys, remove, observable } from "mobx"
import { BugCrash } from "../../services/BugCrash"
import axios from '../../services/Axios'
import { computedFn } from "mobx-utils"

export default class AuthRolesStore {

    rootStore

    byPrj = {}
    isLoading = false

    constructor(rootStore) {
        makeAutoObservable(this, { rootStore: false })

        this.rootStore = rootStore
    }

    fetchPrjRoles = flow(function* (prjId) {

        this.isLoading = true
        const taskId = this.rootStore.workProgressStore.startLoading(`Load usr-roles in prj ${prjId}`)

        try {
            const { data } = yield axios.get(`/my/projects/${prjId}/roles`)

            runInAction(() => {

                if (!has(this.byPrj, prjId)) {
                    this.byPrj[prjId] = observable({ tx: true })
                }
                Object.keys(data).forEach(r => {
                    if (!has(this.byPrj[prjId], r)) {
                        this.byPrj[prjId][r] = true
                    }
                })

                keys(this.byPrj[prjId]).forEach(k => {
                    if (!data[k]) {
                        remove(this.byPrj[prjId], k)
                    }
                })
            })
        }
        catch (err) {
            BugCrash.notifyExtra(err, { prjId })
        }
        finally {
            this.isLoading = false
            this.rootStore.workProgressStore.doneLoading(taskId)
        }
    })

    get permsInSelPrj() {

        const _prjId = this.rootStore.uiStore.selPrjId
        if (!has(this.byPrj, _prjId)) {
            return {}
        }

        return this.getPermsInPrj(_prjId)

    }

    getPermsInPrj = computedFn((prjId) => {

        const _perms = {}

        if (!prjId || !has(this.byPrj, prjId)) {
            return _perms
        }

        keys(this.byPrj[prjId]).forEach(r => {
            if (!has(this.rootStore.rolesStore.roles, r)) {
                return
            }

            keys(this.rootStore.rolesStore.roles[r].perms).forEach(p => {
                _perms[p] = true
            })
        })

        return _perms
    })
}