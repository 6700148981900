import { makeAutoObservable, set, toJS } from "mobx"
import { getUuid } from "../../services/IdGenerator"

export default class QcAction {

    quest

    id = ''
    sortID = -1
    name = {}
    hint = {}

    type = 0
    priority = 0

    isDirty = false

    constructor(quest) {
        makeAutoObservable(this, { quest: false })

        this.quest = quest
    }

    updateFromJson = (json, cloneIds) => {
        //console.log('QcAction::', json)

        //for (const fld of ['id', 'sortID', 'prefix', 'name', 'priority', 'toDoUntil', 'type', 'isSelected']) {
        for (const fld of ['id', 'sortID', 'name', 'hint', 'type', 'priority']) {
            if (!json.hasOwnProperty(fld)) {
                continue
            }

            set(this, fld, json[fld])
        }

        if (cloneIds) {
            if (this.name) {
                this.name.id = getUuid()
            }
            if (this.hint) {
                this.hint.id = getUuid()
            }
        }
    }

    get srvJson() {

        const _json = {}

        for (const _fld of ['id', 'sortID', 'name', 'hint', 'type', 'priority']) {
            _json[_fld] = toJS(this[_fld])
        }

        if (_json.name && !_json.name.id) {
            _json.name.id = getUuid()
        }
        if (_json.hint && !_json.hint.id) {
            _json.hint.id = getUuid()
        }

        return _json
    }

    get dirty() {

        if (this.isDirty) {
            return true
        }

        return false
    }

    setSortID = (val) => {
        this.sortID = val
        this.isDirty = true
    }

    setName = (lng, val) => {
        set(this.name, lng, val)
        this.isDirty = true
    }

    setHint = (lng, val) => {
        set(this.hint, lng, val)
        this.isDirty = true
    }

    setType = (val) => {
        this.type = val
        this.isDirty = true
    }

    setPriority = (val) => {
        this.priority = val
        this.isDirty = true
    }
}