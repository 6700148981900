import { makeAutoObservable, values, set } from "mobx"
import TntNote from "./TntNote"

import { orderBy } from 'lodash'
import { getUuid } from "../../services/IdGenerator"
import { getCurTimestamp } from "../../utils"


export default class TntNotes {

    prjId
    tntId
    typ

    timestamp
    notes = {}

    constructor(prjId, tntId, typ) {
        makeAutoObservable(this)

        this.prjId = prjId
        this.tntId = tntId
        this.typ = typ
    }

    updateFromJson = (json) => {
        //console.log('TntNotes::updateFromJson', json)

        if (this.timestamp && json.timestamp && (this.timestamp >= json.timestamp)) { //current-vers is newer as json-vers
            return
        }

        this.timestamp = json.timestamp

        json.notes && Object.values(json.notes).forEach(val => {
            if (!this.notes[val.id]) {
                this.notes[val.id] = new TntNote(this)
            }

            this.notes[val.id].updateFromJson(val)
        })
    }

    get sortedNotes() {
        return orderBy(values(this.notes).filter(nt => !nt.deleted), ['infoDate'], ['desc'])
    }

    createNote = () => {

        const _newItm = new TntNote(this)
        _newItm.setId(getUuid())
        _newItm.setIsNew(true)
        _newItm.setInfoDate(getCurTimestamp())

        set(this.notes, _newItm.id, _newItm)

        return _newItm
    }
}