/* eslint-disable default-case */
import produce, {original} from 'immer'
import * as actTypes from '../actions/actionTypes'
import isEmpty from 'lodash/isEmpty'

const _setCustLic = (draft, custId, license) => {

    let _custLicVers = draft.byId[custId]
    if(!_custLicVers){
        _custLicVers={modules: {}}
        draft.byId[custId]=_custLicVers
    }

    const _allLicModules={}

    // Step1: make sure that all modules included in license are also in state
    license && license.modules && license.modules.forEach(m => {
        if(_custLicVers[m.mod]!==m.validUntil){
            _allLicModules[m.mod]=true
            _custLicVers.modules[m.mod]=m.validUntil
        }
    })

    // Step2: check if the existing-state-license included before some more modules that don't exist any more in license
    const _oldState=original(draft.byId)
    const _oldCustLic = _oldState && _oldState[custId]

    if(!_oldCustLic || !_oldCustLic.modules || isEmpty(_oldCustLic.modules)){ // no modules till in old-license or old-license unknown
        return
    }

    Object.keys(_oldCustLic.modules).forEach(modId => {
        if(_allLicModules[modId]){ // mod still licensed
            return
        }

        delete _custLicVers.modules[modId] //module not licensed any more -> remove it from license
    })
}

export default produce((draft, action) => {

    switch (action.type) {

        case actTypes.SET_CUSTS_LICENSES:
            if(!action.custsLicenses){
                return
            }

            Object.keys(action.custsLicenses).forEach(custId => {
                _setCustLic(draft, custId, action.custsLicenses[custId])
            })

            break
    }
}, {
    byId:{}
})