import React, { useContext, Suspense, lazy } from 'react';
import { Route, withRouter } from 'react-router-dom'
import { parse } from 'query-string';

import PlsWait from './ui/PlsWait';
import SignIn from './auth/SignIn'
import DashboardCont from './DashboardCont';
import AppContext from '../AppContext';
import TestMain from './test/TestMain';

import get from 'lodash/get'
import routing from '../routing'

//const AdmTntMain = lazy(() => import('./admin/tenants/AdmTntMain'))
//const AdmPrjMain = lazy(() => import('./admin/projects/AdmPrjMain'))

const AdmUsrMain = lazy(() => import('./admin/users/AdmUsrMain'))

const AdmPrjV2Main = lazy(() => import('./admin/projects-v2/AdmPrjV2Main'))
const AdmTntV3Main = lazy(() => import('./admin/tenants-v3/AdmTntV3Main'))
const AdmCustDataMain = lazy(() => import('./admin/cust-data/AdmCustDataMain'))

const SelCustMain = lazy(() => import('./sel-cust/SelCustMain'))
const SelTntMain = lazy(() => import('./sel-tnt/SelTntMain'))

const AdmCustMain = lazy(() => import('./admin/cust/AdmCustMain'))
const AdmRolesMain = lazy(() => import('./admin/roles/AdmRolesMain'))
const AdmShrsMain = lazy(() => import('./admin/shares/AdmSharesMain'))

const AdmQcMain = lazy(() => import('./admin/qc/AdmQcMain'))

const AdmDashboardMain = lazy(() => import('./admin/dashboard/AdmDashboardMain'))
const SelAdmCustMain = lazy(() => import('./admin/sel-adm-cust/SelAdmCustMain'))

const AuditsMain = lazy(() => import('./audits-v3/AuditsMain'))

const WkeqShelfMain = lazy(() => import('./wkeq-shelf/WkeqShelfMain'))
const WkeqLadderMain = lazy(() => import('./wkeq-ladder/WkeqLadderMain'))
const WkeqGateMain = lazy(() => import('./wkeq-gate/WkeqGateMain'))
const WkeqForkliftMain = lazy(() => import('./wkeq-forklift/WkeqForkliftMain'))
const WkeqPalettruckMain = lazy(() => import('./wkeq-pallettruck/WkeqPalettruckMain'))
const WkeqHydrRampMain = lazy(() => import('./wkeq-hydr-ramp/WkeqHydrRampMain'))
const WkeqLoadRampMain = lazy(() => import('./wkeq-load-ramp/WkeqLoadRampMain'))
const WkeqWorkPlatfMain = lazy(() => import('./wkeq-work-platf/WkeqWorkPlatfMain'))

const SpecAuditMain = lazy(() => import('./spec-audit/SpecAuditMain'))
const TpcBasicCareMain = lazy(() => import('./tpc-basic-care/TpcBasicCareMain'))
const TpcBasicCareMedicMain = lazy(() => import('./tpc-basic-care-medic/TpcBasicCareMedicMain'))

const EmplMain = lazy(() => import('./empl/EmplMain'))
const RepresentMain = lazy(() => import('./represent/RepresentMain'))
const FuncMain = lazy(() => import('./func/FuncMain'))

const OcupSafetyOrgMain = lazy(() => import('./ocup-safety-org/OcupSafetyOrgMain'))

const OrganigramMain = lazy(() => import('./organigram/OrganigramMain'))
const AnnouncementMain = lazy(() => import('./announcement/AnnouncementMain'))

const TpcCompSpecMain = lazy(() => import('./tpc-comp-spec/TpcCompSpecMain'))
const MaternityMain = lazy(() => import('./maternity/MaternityMain'))
const PsycheMain = lazy(() => import('./psyche/PsycheMain'))

const AccidentsMain = lazy(() => import('./accidents/AccidentsMain'))
const AccidentsMainV2 = lazy(() => import('./accidents-v2/AccidentsMainV2'))

const SibeMain = lazy(() => import('./sibe/SibeMain'))
const DataProtectMain = lazy(() => import('./data-protect/DataProtectMain'))
const ActvSpecRiskAssesMain = lazy(() => import('./actv-spec-risk-asses/ActvSpecRiskAssesMain'))
const InstructMain = lazy(() => import('./instruct/InstructMain'))

const PerfConfMain = lazy(() => import('./perf-conf-v2/PerfConfMain'))
const StsRptMain = lazy(() => import('./sts-rpt/StsRptMain'))

const CalcMain = lazy(() => import('./calc/CalcMain'))
const FirstAidMain = lazy(() => import('./first-aid/FirstAidMain'))
const WebsitesMain = lazy(() => import('./websites/WebsitesMain'))
const SignsMain = lazy(() => import('./signs/SignsMain'))
const AppInfoMain = lazy(() => import('./app-info/AppInfoMain'))
const InstructTmplMain = lazy(() => import('./instruct-tmpl/InstructTmplMain'))

const TxtBlocksMain = lazy(() => import('./txt-blocks/TxtBlocksMain'))

const QuestMain = lazy(() => import('./quest/QuestMain'))

const QcCatMain = lazy(() => import('./quest-v2/QcCatMain'))
const QcQuestMain = lazy(() => import('./quest-v2/QcQuestMain'))
const QcQuestARMain = lazy(() => import('./quest-v2/QcQuestARMain'))
const QcCatalogMain = lazy(() => import('./quest-v2/QcCatalogMain'))
const QcVersMain = lazy(() => import('./quest-v2/QcVersMain'))

const MeetingMain = lazy(() => import('./meetings-v3/MeetingMain'))

function RouteDispatcher({ location }) {

    const _context = useContext(AppContext)
    const _qs = parse(location.search) || {}

    const { xs } = _context

    //lgDebug(`RouteDispatcher::${xs} %o`, _qs )

    return <Suspense fallback={<PlsWait />}>
        <Route exact path='/' component={DashboardCont} ></Route>
        <Route path='/login' component={SignIn} ></Route>
        <Route path='/sel-cust' component={SelCustMain} ></Route>
        <Route path='/sel-tnt' render={(props) => <SelTntMain xs={xs} qs={_qs} {...props} />}></Route>

        <Route exact path='/admin' component={AdmDashboardMain} ></Route>
        <Route path='/sel-adm-cust' component={SelAdmCustMain} ></Route>

        <Route path='/wkeq-shelf' component={WkeqShelfMain} ></Route>
        <Route path='/wkeq-ladder' component={WkeqLadderMain} ></Route>
        <Route path='/wkeq-gate' component={WkeqGateMain} ></Route>
        <Route path='/wkeq-forklift' component={WkeqForkliftMain} ></Route>
        <Route path='/wkeq-palettruck' component={WkeqPalettruckMain} ></Route>
        <Route path='/wkeq-hydr-ramp' component={WkeqHydrRampMain} ></Route>
        <Route path='/wkeq-load-ramp' component={WkeqLoadRampMain} ></Route>
        <Route path='/wkeq-work-platf' component={WkeqWorkPlatfMain} ></Route>

        <Route path='/spec-audits' component={SpecAuditMain} ></Route>

        <Route path='/tpc-basic-care' component={TpcBasicCareMain} ></Route>
        <Route path='/tpc-basic-care-medic' component={TpcBasicCareMedicMain} ></Route>

        <Route path='/empl' component={EmplMain} ></Route>
        <Route path='/represent' component={RepresentMain} ></Route>
        <Route path='/func' component={FuncMain} ></Route>

        <Route path='/ocup-safety-org' component={OcupSafetyOrgMain} ></Route>
        <Route path='/organigrams' component={OrganigramMain} ></Route>
        <Route path='/announcements' component={AnnouncementMain} ></Route>
        <Route path='/tpc-comp-spec' component={TpcCompSpecMain} ></Route>
        <Route path='/maternity' component={MaternityMain} ></Route>
        <Route path='/psyche' component={PsycheMain} ></Route>

        <Route path='/accidents' component={AccidentsMain} ></Route>
        <Route path='/accidents-v2' render={(props) => <AccidentsMainV2 key={`AccidentsMainV2-${_qs['t']}`} xs={xs} qs={_qs} {...props} />}></Route>

        <Route path='/sibe' component={SibeMain} ></Route>
        <Route path='/data-protect' component={DataProtectMain} ></Route>
        <Route path='/actv-spec-risk-asses' component={ActvSpecRiskAssesMain} ></Route>
        <Route path='/instruct' component={InstructMain} ></Route>

        <Route path='/sts-rpt' component={StsRptMain} ></Route>

        <Route path='/first-aid' component={FirstAidMain} ></Route>
        <Route path='/calc' component={CalcMain} ></Route>
        <Route path='/websites' component={WebsitesMain} ></Route>
        <Route path='/signs' component={SignsMain} ></Route>
        <Route path='/app-info' component={AppInfoMain} ></Route>
        <Route path='/instruct-tmpl' component={InstructTmplMain} ></Route>
        <Route path='/txt-blocks' component={TxtBlocksMain} ></Route>

        <Route path='/quest' component={QuestMain} ></Route>
        <Route path='/quest-v2' component={QuestMain} ></Route>

        <Route path='/qc-catalog/:qcType' component={QcCatalogMain} ></Route>

        <Route path='/qc-cat/:qcType/:qcId?/:versId?/:catId?' component={QcCatMain} ></Route>
        <Route path='/qc-vers/:qcType/:qcId?/:versId?' component={QcVersMain} ></Route>
        <Route path='/qc-quest/:qcType/:qcId?/:versId?/:catId?/:qId?' component={QcQuestMain} ></Route>
        <Route path='/qc-quest-ar/:qcType/:qcId?/:versId?/:catId?/:qId?' component={QcQuestARMain} ></Route>

        <Route path={routing.audits.main} render={(props2) => {
            const rParams = get(props2, ['match', 'params'], {})
            return <AuditsMain key={`${rParams.prjId || ''}_${rParams.tntId || ''}_${rParams.auditArt || ''}`} />
        }} />

        <Route path='/admin/users' render={(props) => <AdmUsrMain key={`AdmUsrMain-${_qs['c']}`} xs={xs} qs={_qs} {...props} />} ></Route>
        <Route path='/admin/shares' render={(props) => <AdmShrsMain key={`AdmShrsMain-${_qs['c']}`} xs={xs} qs={_qs} {...props} />} ></Route>

        <Route path='/admin/cust' render={(props) => <AdmCustMain key={`AdmCustMain`} qs={_qs} {...props} />} ></Route>
        <Route path='/admin/roles' render={(props) => <AdmRolesMain key={`AdmRolesMain`} qs={_qs} {...props} />} ></Route>

        <Route path='/admin/projects-v2' render={(props) => <AdmPrjV2Main key={`AdmPrjV2Main-${_qs['c']}`} xs={xs} qs={_qs} {...props} />}></Route>

        <Route path={routing.admTnts.main} render={(props2) => {
            const rParams = get(props2, ['match', 'params'], {})
            return <AdmTntV3Main key={`${rParams.custId || ''}`} />
        }} />

        <Route path='/admin/cust-data' render={(props) => <AdmCustDataMain key={`AdmCustDataMain-${_qs['c']}`} xs={xs} qs={_qs} {...props} />}></Route>

        <Route path={routing.qc.main} render={(props2) => {
            const rParams = get(props2, ['match', 'params'], {})
            return <AdmQcMain key={`qc_${rParams.type || ''}_${rParams.ownerId || ''}`} type={rParams.type} ownerId={rParams.ownerId} />
        }} ></Route>

        <Route path={routing.perfConf.main} render={(props2) => {
            const rParams = get(props2, ['match', 'params'], {})
            return <PerfConfMain key={`${rParams.prjId || ''}_${rParams.tntId || ''}`} />
        }} />

        <Route path={routing.meetings.main} render={(props2) => {
            const rParams = get(props2, ['match', 'params'], {})
            return <MeetingMain key={`${rParams.prjId || ''}_${rParams.tntId || ''}`} />
        }} />

        <Route path='/testt' component={TestMain} ></Route>
    </Suspense>
}

export default withRouter(RouteDispatcher)

// <Route path='/admin/tenants' render={(props) => <AdmTntMain key={`AdmTntMain-${_qs['c']}`} qs={_qs} {...props} />} ></Route>
// <Route path='/admin/projects' render={(props) => <AdmPrjMain key={`AdmPrjMain-${_qs['c']}`} xs={xs} qs={_qs} {...props} />} ></Route>
