import { makeAutoObservable, set, flow } from "mobx"
import rootStore from ".."

import MeetingFull from "./MeetingFull"

import axios from '../../services/Axios'
import { BugCrash } from "../../services/BugCrash"
import { isTimestampOldErr } from "../../libs/actErrors"

import get from 'lodash/get'

export default class Meeting {

    meetingStore = null

    id
    tntId
    compact = null
    full = null

    timestamp = 0

    isEditing = false
    isNew = false
    isSaving = false

    constructor(meetingStore) {
        makeAutoObservable(this, { meetingStore: false })

        this.meetingStore = meetingStore
    }

    setIsEditing = (val) => {
        this.isEditing = val
    }

    setIsNew = (val) => {
        this.isNew = val
    }

    setTimestamp = (val) => {
        this.timestamp = val
    }

    updateFromLight = (light) => {
        //console.log('Meeting::updateFromLight', light)

        if (!this.id) {
            this.id = light.id
        }
        if (!this.full) {
            this.full = new MeetingFull(this)
        }
        this.full.updateFromLight(light)
    }

    updateFromJson = (json) => {
        //console.log('Meeting::updateFromJson', json)

        if (this.timestamp && json.timestamp && (this.timestamp >= json.timestamp)) {
            return
        }

        for (const fld of ['id', 'timestamp', 'compact']) {
            set(this, fld, json[fld])
        }

        this.full = new MeetingFull(this)
        this.full.updateFromJson(json.full)
    }

    get isDeleted() {
        return (get(this, ['full', 'deleted'], 0) > 0)
    }

    save = flow(function* (prjId, tntId) {

        this.isSaving = true

        const taskId = rootStore.workProgressStore.startPushing(`save meeting prjId=${prjId}, tntId=${tntId}, meetingId=${this.id}`)

        try {
            const { status } = yield axios.post(`/projects/${prjId}/tenants/${tntId}/doc-prot/meetings/${this.id}`, {
                timestampBefore: this.timestamp,
                full: this.full.toJson()
            })

            if (status === 200) {
                return yield this.meetingStore.fetchOne(prjId, tntId, this.id)
            }
        }
        catch (err) {

            const _newTs = isTimestampOldErr(err)
            if (_newTs) {
                rootStore.oldTsStore.addConflict(this.full.title, this.timestamp, _newTs, (newTs) => {
                    (async () => {
                        this.setTimestamp(newTs)
                        await this.save(prjId, tntId)
                    })()
                }, () => {
                    (async () => {
                        await this.meetingStore.fetchOne(prjId, tntId, this.id, true)
                    })()
                }, null)
            }
            else {
                BugCrash.notifyExtra(err, { prjId, tntId: this.tntId, meetingId: this.id })
            }
        }
        finally {
            this.isSaving = false
            rootStore.workProgressStore.donePushing(taskId)
        }

        return false
    })

    get dirty() {
        return this.full && this.full.dirty
    }
}