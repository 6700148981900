/* eslint-disable default-case */
import produce, {original} from 'immer'
import * as actTypes from '../actions/actionTypes'

export default produce((draft, action) => {

    switch (action.type) {

        case actTypes.SET_PRJ_TNTS:

            let _curPrjTnts = draft.byId[action.prjId]
            if(!_curPrjTnts){ // prjId not yet in store
                _curPrjTnts={}
                draft.byId[action.prjId]=_curPrjTnts
            }

            const _tntIdsByAct = {}

            // Step1: make sure that all tnts from action are set in _curPrjTnts
            action.tntIds && action.tntIds.forEach(tntId => {
                _tntIdsByAct[tntId]=true

                if(!_curPrjTnts[tntId]){
                    _curPrjTnts[tntId]=true
                }
            })

            const _origPrjState = original(draft.byId[action.prjId])

            // Step2: if _curPrjTnts contains also other tenants als in action specified, those relations must be deleted
            _origPrjState && Object.keys(_origPrjState).forEach(tntId => {
                if(_tntIdsByAct[tntId]){ // tntId is still there -> nothing to do
                    return
                }

                if(_curPrjTnts[tntId]){
                    delete _curPrjTnts[tntId]
                }
            })
            break
    }
}, {
    byId:{}
})
