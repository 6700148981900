/* eslint-disable default-case */
import produce, { original } from 'immer'
import * as actTypes from '../actions/actionTypes'

import has from 'lodash/has'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'

export default produce((draft, action) => {

    switch (action.type) {

        case actTypes.STORE_ASSOC_PRJ_MOUNT:

            let _curVersion1 = draft.byId[action.prjId]
            if (!_curVersion1) {
                _curVersion1 = { id: action.mntId, mntIds: {} }
                draft.allIds.push(action.mntId)
            }

            if (!_curVersion1.mntIds) {
                _curVersion1.mntIds = {}
            }
            _curVersion1.mntIds[action.mntId] = action.tntSrc

            draft.byId[action.prjId] = _curVersion1

            break;

        case actTypes.STORE_REMOVE_PRJ_MOUNT:

            const _orig = original(draft.byId)
            const _curVersion = _orig[action.prjId]

            if (_curVersion && _curVersion.mntIds && _curVersion.mntIds[action.mntId]) {
                draft.byId[action.prjId].mntIds = omit(_curVersion.mntIds, action.mntId)
            }

            break;

        case actTypes.STORE_REMOVE_PRJ:

            delete draft.byId[action.prjId]
            draft.allIds = draft.allIds.filter(id => id !== action.prjId)

            break;

        case actTypes.STORE_UPSERT_PRJS:

            action.items && action.items.forEach(prj => {

                let _curVersion = draft.byId[prj.id]
                if (!_curVersion) { // project not in store
                    _curVersion = {}
                    draft.byId[prj.id] = _curVersion
                    draft.allIds.push(prj.id)
                }

                ['id', 'name', 'new'].forEach(fld => {
                    if (has(prj, fld) && (prj[fld] !== _curVersion[fld])) { //only update if a change is detected
                        _curVersion[fld] = prj[fld]
                    }
                });

                ['mntIds'].forEach(fld => {
                    if (has(prj, fld) && !isEqual(_curVersion[fld], prj[fld])) { //only update if a change is detected
                        _curVersion[fld] = prj[fld]
                    }
                });
            })
            break;
    }
}, {
    allIds: [],
    byId: {}
})