import { makeAutoObservable, flow, values, has } from "mobx"
import axios from '../../services/Axios'
import TntNotes from "./TntNotes"

import { orderBy } from 'lodash'
import { computedFn } from "mobx-utils"
import { BugCrash } from "../../services/BugCrash"
import rootStore from ".."

export default class TntNotesStore {

    rootStore

    tntsNotes = {}
    tntsNotes2 = {}

    isLoading = false

    constructor(rootStore) {
        makeAutoObservable(this, { rootStore: false, sortedNotesByTntId: false })

        this.rootStore = rootStore
    }

    fetchTntNotes = flow(function* (prjId, tntId) {

        this.isLoading = true

        const taskId = rootStore.workProgressStore.startLoading(`Load tnt-notes tnt-id=${tntId}`)

        try {
            const { data } = yield axios.get(`/projects/${prjId}/tenants/${tntId}/note`)

            //console.log(data)

            Object.keys(data).forEach(ownerType => {
                const _tntId = data[ownerType].id
                const _typ = (ownerType === 'self') ? 'own' : 'tree'
                const _key = `${_tntId}_${_typ}`

                //console.log('fetchTntNotes-tntId-ownerType', _tntId, ownerType, data[ownerType])
                //console.log('_key_key', _key)

                if (!this.tntsNotes2[_key]) {
                    this.tntsNotes2[_key] = new TntNotes(prjId, _tntId, _typ)
                }

                this.tntsNotes2[_key].updateFromJson(data[ownerType])
            })
        }
        catch (err) {
            BugCrash.notifyExtra(err, { prjId, tntId })
        }
        finally {
            this.isLoading = false
            rootStore.workProgressStore.doneLoading(taskId)
        }
    })

    sortedNotesByTntId = computedFn((tntId) => {

        const _notes = []

        for (const _typ of ['own', 'tree']) {
            const _key = `${tntId}_${_typ}`

            if (has(this.tntsNotes2, _key)) {
                _notes.push(
                    ...(values(this.tntsNotes2[_key].notes).filter(nt => !nt.deleted))
                )
            }
        }

        return orderBy(_notes, ['infoDate'], ['desc'])
    })

    sortedNotesByTntIdAndTyp = computedFn((tntId, typ) => {

        const _notes = []
        const _key = `${tntId}_${typ}`

        if (has(this.tntsNotes2, _key)) {

            //console.log(values(this.tntsNotes2[_key].notes))
            const _filtered = values(this.tntsNotes2[_key].notes).filter(nt => {
                if (nt.deleted > 0) {
                    return false
                }
                return true
            })

            _notes.push(
                ..._filtered
            )
        }

        return orderBy(_notes, ['infoDate'], ['desc'])
    })

    createNote = (prjId, tntId, typ) => {
        const _key = `${tntId}_${typ}`

        if (!has(this.tntsNotes2, _key)) {
            if (!this.tntsNotes2[_key]) {
                this.tntsNotes2[_key] = new TntNotes(prjId, tntId, typ)
            }
        }

        return this.tntsNotes2[_key].createNote()
    }
}