import axios from '../services/Axios'
import { BugCrash } from "../services/BugCrash"
import { REPORTING_BASE_URL_FRA } from "../config/api"

const _generateReportUrl = (prjId, tntId, itmId, lng, rptType, kvm, shortReport) => {

    let _urlRpt = ``

    if ((rptType === 'lb_normal') || (rptType === 'lb_no_times')) {
        _urlRpt = 'perform-confirm'
        const noTimes = (rptType === 'lb_no_times') ? 1 : 0
        return `${REPORTING_BASE_URL_FRA}projects/${prjId}/tenants/${tntId}/${_urlRpt}/${itmId}?lng=${lng}&noTimes=${noTimes}`
    }

    if ((rptType === 'meeting_long') || (rptType === 'meeting_short')) {
        _urlRpt = 'doc-prot/meetings'
        return `${REPORTING_BASE_URL_FRA}projects/${prjId}/tenants/${tntId}/${_urlRpt}/${itmId}?lng=${lng}&shortReport=${shortReport}`
    }


    if (rptType === 'topics-basic-care') {
        _urlRpt = `audits/topics-basic-care`
    }
    else if (rptType === 'topics-basic-care-medic') {
        _urlRpt = `audits/topics-basic-care-medic`
    }
    else if (rptType === 'spec-audits') {
        _urlRpt = `audits/specific`
    }
    else if (rptType === 'topics-basic-care-medic') {
        _urlRpt = `audits/topics-basic-care-medic`
    }
    else if (rptType === 'actv-spec-risk-asses') {
        _urlRpt = `doc-prot/act-spec-risk-assets`
    }
    else if (rptType === 'topics-comp-spec') {
        _urlRpt = `audits/topics-comp-specific`
    }
    else {
        throw new Error(`_generateReport not implemented for ${rptType}`)
    }

    return `${REPORTING_BASE_URL_FRA}projects/${prjId}/tenants/${tntId}/${_urlRpt}/${itmId}?lng=${lng}&kvm=${kvm}&shortReport=${shortReport}`
}

const _generateReport = async (prjId, tntId, itmId, lng, rptType, kvm, shortReport) => {

    try {
        const _url = _generateReportUrl(prjId, tntId, itmId, lng, rptType, kvm, shortReport)

        const { data } = await axios.get(_url)
        return data
    }
    catch (err) {
        BugCrash.notifyExtra(err, { prjId, tntId, itmId, lng, rptType, kvm, shortReport })
    }

    return null
}

export {
    _generateReport as generateReport,
}