import * as actTypes from '../../actions/actionTypes'
import set from 'lodash/set'
import get from 'lodash/get'
import omit from 'lodash/omit'

export const perfConfExt = (draft, original, action) => {

    //console.warn('perfConfExt::IN', action)

    // eslint-disable-next-line default-case
    switch (action.type) {

        case actTypes.STORE_TNT_PERF_CONF_CHECK:

            set(draft, ['byId', action.id, 'full', action.checkType, action.checkData.id], action.checkData)

            if (action.needSave) {
                set(draft, ['byId', action.id, 'compact', 'needSave'], Date.now())
            }

            break

        case actTypes.DISCARD_TNT_PERF_CONF_CHECK:

            const orig = original(draft.byId)
            const _checks = get(orig, [action.id, 'full', action.checkType], {})
            const _newChecks = omit(_checks, action.checkId)

            set(draft, ['byId', action.id, 'full', action.checkType], _newChecks)
            set(draft, ['byId', action.id, 'compact', 'needSave'], Date.now())

            break
    }
}