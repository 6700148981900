import React, { useCallback, useContext, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import rootStore from '../../mobx'

import AppContext from '../../AppContext'

import { Modal, Button } from 'antd'
import { lbl } from '../../lbls'
import { isObject } from 'lodash'

const _bodyStyle = {
    maxHeight: 500,
    overflowY: 'auto'
}

function OldTsConflictResolver() {
    const { lng } = useContext(AppContext)

    const _conflict = rootStore.oldTsStore.conflicts.values().next().value

    const _onCancel = useCallback(() => {
        if (_conflict) {
            if (_conflict.onCancel) {
                _conflict.onCancel()
            }
            rootStore.oldTsStore.removeConflict(_conflict.id)
        }
    }, [_conflict])

    const _onRefresh = useCallback(() => {
        if (_conflict) {
            if (_conflict.onRefresh) {
                _conflict.onRefresh()
            }
            rootStore.oldTsStore.removeConflict(_conflict.id)
        }
    }, [_conflict])

    const _onTryAgain = useCallback(() => {
        if (_conflict) {
            if (_conflict.onTryAgain) {
                _conflict.onTryAgain(_conflict.serverTs)
            }
            rootStore.oldTsStore.removeConflict(_conflict.id)
        }
    }, [_conflict])

    const _objInfo = useMemo(() => {

        if (!_conflict) {
            return ''
        }

        if (isObject(_conflict.actionInfo)) {
            return _conflict.actionInfo[lng]
        }
        else if (_conflict.actionInfo) {
            return _conflict.actionInfo
        }

        return ''
    }, [lng, _conflict])

    if (rootStore.oldTsStore.conflicts.size === 0) {
        return null
    }

    return <Modal width="90%" zIndex={9998} visible title={lbl.old_ts_err_title} onCancel={_onCancel} closable={false} centered cancelText={lbl.close} footer={[
        <Button key="forceSave" danger onClick={_onTryAgain}>
            {lbl.old_ts_err_but_force_save}
        </Button>,
        <Button key="refresh" onClick={_onRefresh}>
            {lbl.old_ts_err_but_load_srv}
        </Button>,
        <Button key="cancel" onClick={_onCancel}>
            {lbl.cancel}
        </Button>
    ]}
        bodyStyle={_bodyStyle}
    >
        <>
            {lbl.old_ts_err_body_info1.replace('%info', _objInfo)}<br />
            <br />
            {lbl.old_ts_err_body}
        </>
    </Modal>
}

export default observer(OldTsConflictResolver)