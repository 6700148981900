import React, { memo, useCallback, useState } from 'react'

import { Modal, Button } from 'antd'
import TruncateMarkup from "react-truncate-markup"
import { lbl } from '../../lbls'

const _bodyStyle = {
    maxHeight: 400,
    overflowY: 'auto'
}

export const escapedNewLineToLineBreakTag = (string) => string.split('\n').map((item, index) => (index === 0) ? item : [<br key={index} />, item])


function ReadMore({ text }) {
    const [isMoreVisible, setIsMoreVisible] = useState(false)

    const _onCancel = useCallback(() => {
        setIsMoreVisible(false)
    }, [])
    const _toggleTruncate = useCallback(() => {
        setIsMoreVisible(!isMoreVisible)
    }, [isMoreVisible])

    const readMoreEllipsis = (
        <span>
            ...{" "}
            <Button type="link" onClick={_toggleTruncate}>{lbl.more}</Button>
        </span>
    )

    return <>
        <TruncateMarkup lines={5} ellipsis={readMoreEllipsis}>
            <div>{escapedNewLineToLineBreakTag(text || '')}</div>
        </TruncateMarkup>

        <Modal width="75%" visible={isMoreVisible} title={null} onCancel={_onCancel} closable={false} centered cancelText={lbl.close} footer={[
            <Button key="back" onClick={_onCancel}>
                {lbl.close}
            </Button>]} bodyStyle={_bodyStyle}>
            <div>{escapedNewLineToLineBreakTag(text || '')}</div>
        </Modal>
    </>
}

export default memo(ReadMore)