import * as actTypes from '../actions/actionTypes'
import { BugCrash } from '../../services/BugCrash'

import Axios from '../../services/Axios'
import {lbl} from '../../lbls'

import { discardChgs } from './changesActions';
import { registerFeedback } from './feedbackActions';
import { lgDebug } from '../../utils/logging';
import { actStarted, actReadyOk, actReadyPb } from './actionsActions';
import { actionsAreas, actionsActs } from '../reducers/actionsReducer';
import { setCustPrjsByAdm, assignCustPrjsByAdm, detachCustPrjsByAdm } from './custsPrjsActions';

export const loadCustProjects = (custId, actGrp) => {
    return async (dispatch, getState) => {

        const _actTgt=`projs-cust-${custId}`

        try{
            dispatch(actStarted(actionsAreas.prjs, actGrp, _actTgt, actionsActs.load))

            const resp = await Axios.get(`/customers/${custId}/projects`)
            if(resp && (resp.status===200)){

                const _prjs = []
                resp.data && resp.data.list && resp.data.list.forEach(prj => {
                    _prjs.push(prj)
                })

                if(_prjs.length>0){
                    dispatch(upsertPrjs(_prjs))
                }
                dispatch(setCustPrjsByAdm(custId, _prjs.map(p => p.id)))

                dispatch(actReadyOk(actionsAreas.prjs, actGrp, _actTgt, actionsActs.load))
                return 
            }

            throw new Error(lbl.err_load_cust_prjs)
        }
        catch(err){
            BugCrash.notify(err)
            dispatch(actReadyPb(actionsAreas.prjs, actGrp, _actTgt, actionsActs.load, err))
        }
    }
}

export const addProject = (custId, prjId, name, afterCreate) => {
    return async (dispatch, getState) => {

        dispatch(upsertPrjs([
            {
                id: prjId,
                name,
                new: true,
            }
        ]))
        dispatch(assignCustPrjsByAdm(custId, [prjId]))

        if(afterCreate){
            setTimeout(()=>{afterCreate()}, 50)
        }
    }
}

export const loadProject = (custId, prjId, actGrp) => {
    return async (dispatch, getState) => {

        const _actTgt=`load-prj-${prjId}`

        try{
            const _rootState = await getState()
            if(!_rootState['prjs'].byId[prjId] || _rootState['prjs'].byId[prjId].new){
                lgDebug(`Das Projekt ${prjId} ist neu -> laden vom Server kann nichts bringen!`)
                return
            }

            dispatch(actStarted(actionsAreas.prjs, actGrp, _actTgt, actionsActs.load))

            const resp = await Axios.get(`/customers/${custId}/projects/${prjId}`)
            if(resp && (resp.status===200)){

                dispatch(assignCustPrjsByAdm(custId, [prjId]))

                //TODO: upsertPrjs pick only some of the fields...
                dispatch(upsertPrjs([resp.data]))
                dispatch(actReadyOk(actionsAreas.prjs, actGrp, _actTgt, actionsActs.load))
                return
            }

            throw new Error(lbl.err_load_prj)
        }
        catch(err){
            BugCrash.notify(err)
            dispatch(actReadyPb(actionsAreas.prjs, actGrp, _actTgt, actionsActs.load, err))
        }
    }
}

const _finalizeDelProjectOk = (dispatch, custId, prjId, prjName, actGrp, actTgt, afterDelOk) => {

    dispatch({type: actTypes.STORE_REMOVE_PRJ, prjId})

    dispatch(detachCustPrjsByAdm(custId, [prjId]))
    dispatch(actReadyOk(actionsAreas.prjs, actGrp, actTgt, actionsActs.del))
    dispatch(registerFeedback(lbl.feedback_del_prj_ok.replace('%prj', prjName), false))

    if(afterDelOk){
        setTimeout(
            () => {
                afterDelOk()
            }, 50
        )    
    }
}

export const delProject = (custId, prjId, prjName, actGrp, afterDelOk) => {
    return async (dispatch, getState) => {

        const _actTgt=`del-prj-${prjId}`

        try{
            dispatch(actStarted(actionsAreas.prjs, actGrp, _actTgt, actionsActs.del))

            const _rootState = await getState()
            if(!_rootState['prjs'].byId[prjId] || _rootState['prjs'].byId[prjId].new){
                //new project -> there is nothing on the server to be deleted!
                _finalizeDelProjectOk(dispatch, custId, prjId, prjName, actGrp, _actTgt, afterDelOk)
                return
            }

            const resp = await Axios.delete(`/customers/${custId}/projects/${prjId}`)
            if(resp && (resp.status===200)){
                _finalizeDelProjectOk(dispatch, custId, prjId, prjName, actGrp, _actTgt, afterDelOk)
                return
            }

            throw new Error(lbl.err_del_prj)
        }
        catch(err){
            BugCrash.notify(err)
            dispatch(actReadyPb(actionsAreas.prjs, actGrp, _actTgt, actionsActs.load, err))
        }
    }
}

export const saveProject = (custId, prjId, changes, actGrp) => {
    return async (dispatch, getState) => {

        const _actTgt=`save-prj-${prjId}`

        try{
            dispatch(actStarted(actionsAreas.prjs, actGrp, _actTgt, actionsActs.save))

            const _state = await getState()
            const _origVersion = _state['prjs'].byId[prjId]

            const _prjData = {
                name: (changes.name)?changes.name.val:(_origVersion.name || ''),
            }

            const resp = await Axios.post(`/customers/${custId}/projects/${prjId}`, _prjData)
            if(resp && (resp.status===200)){

                dispatch(upsertPrjs([{id: prjId, new: false}]))
                dispatch(loadProject(custId, prjId, actGrp))
                dispatch(discardChgs(`prj-${prjId}`))
                dispatch(registerFeedback(lbl.feedback_save_prj_ok.replace('%prj', _prjData.name), false))
                dispatch(actReadyOk(actionsAreas.prjs, actGrp, _actTgt, actionsActs.save))
                return 
            }
 
            throw new Error(lbl.err_save_prj)
        }
        catch(err){
            BugCrash.notify(err)
            dispatch(actReadyPb(actionsAreas.prjs, actGrp, _actTgt, actionsActs.save, err))
        }
    }
}

export const upsertPrjs = (prjs) => {
    return {
        type: actTypes.STORE_UPSERT_PRJS, 
        items: Array.isArray(prjs)?prjs:Object.values(prjs)
    }
}