import { createSelector } from 'reselect'
import { calcAllTntsPath } from './tnts';
import { lbl } from '../../lbls';
import createCachedSelector from 're-reselect';
import { paramCustId } from './params';
import { createDeepEqualSelector } from './createDeepEqualSelector';
import isEqual from 'lodash/isEqual'

//import { MEASURE_TIME } from '../../config/const';

const _cust = (state, custId) => state.custs && state.custs.byId && state.custs.byId[custId]
const _custAdmPrjIds = (state, custId) => state.custsPrjsByAdm.byId[custId]

const _custPrjIds = createSelector(
    [_custAdmPrjIds],
    (custAdmPrjIds) => {
        if (!custAdmPrjIds) {
            return null
        }

        return custAdmPrjIds
    }
)

const _custPrjs = createSelector(
    [_custPrjIds, state => state.prjs.byId],
    (custPrjIds, prjById) => {

        if (!custPrjIds || !prjById) {
            return []
        }

        return Object.keys(custPrjIds).map((id) => prjById[id])
    }
)

const _custShareIds = createSelector(
    [_cust],
    (cust) => {
        if (!cust) {
            return null
        }

        return cust.shrIds
    }
)

const _custShareFromUsIds = createSelector(
    [_cust],
    (cust) => {
        if (!cust) {
            return null
        }

        return cust.fromUsShrIds
    }
)

const _custShares = createSelector(
    [_custShareIds, state => state.shr.byId],
    (custShareIds, shrById) => {

        if (!custShareIds || !shrById) {
            return []
        }

        return Object.keys(custShareIds).map((id) => shrById[id])
    }
)

const _custSharesFromUs = createSelector(
    [_custShareFromUsIds, state => state.shr.byId],
    (custShareFromUsIds, shrById) => {

        if (!custShareFromUsIds || !shrById) {
            return []
        }

        return Object.keys(custShareFromUsIds).map((id) => shrById[id])
    }
)

const _custOwnTenantIds = createSelector(
    [_cust],
    (cust) => {
        if (!cust) {
            return null
        }

        return cust.tntIds
    }
)

const _custAllTenantIds = createSelector(
    [_custOwnTenantIds, _custShares],
    (ownTenantIds, shares) => {

        let _allTntIds = {}

        if (ownTenantIds) {
            _allTntIds = { ...ownTenantIds }
        }

        shares && shares.forEach((shr) => {
            if (!shr || !shr.tntIds) {
                return
            }

            _allTntIds = { ..._allTntIds, ...shr.tntIds }
        })

        return _allTntIds
    }
)

const _custAllTenants = createSelector(
    [_custAllTenantIds, state => state.tnts],
    (custAllTenantIds, tnts) => {

        if (!custAllTenantIds || !tnts) {
            return {}
        }

        const _ret = {}

        Object.keys(custAllTenantIds).forEach(tntId => {
            _ret[tntId] = tnts.byId[tntId]
        })

        return _ret
    }
)

const _custAllTenantsPath = createSelector(
    _custAllTenants,
    (custAllTenants) => {
        return calcAllTntsPath(custAllTenants)
    }
)

const _custAllTenantIdsSrc = createSelector(
    [_custOwnTenantIds, _custShares],
    (ownTenantIds, shares) => {

        const _allTntIdsSrc = {}

        if (ownTenantIds) {
            for (const _tntId of Object.keys(ownTenantIds)) {
                _allTntIdsSrc[_tntId] = 'own'
            }
        }

        shares && shares.forEach((shr) => {
            if (!shr || !shr.tntIds) {
                return
            }

            for (const _tntId of Object.keys(shr.tntIds)) {
                _allTntIdsSrc[_tntId] = shr.id
            }
        })

        return _allTntIdsSrc
    }
)

const _custAllTenantsWithPathGrpBySrc = createSelector(
    [_custAllTenantIdsSrc, _custAllTenantsPath, _custAllTenants, (state) => state.shr.byId],
    (tntIdsSrc, tntIdsPath, allTntsData, shrsById) => {

        if (!tntIdsSrc || !tntIdsPath || !allTntsData || !shrsById) {
            return {}
        }

        const _collector = {}

        Object.keys(tntIdsSrc).forEach(tntId => {

            const _src = tntIdsSrc[tntId]

            if (!_collector[_src]) {
                _collector[_src] = {
                    name: (_src === 'own') ? lbl.own_tnt : lbl.share_name.replace('%cust', shrsById[_src].fromCustName),
                    byId: {},
                }
            }

            _collector[_src].byId[tntId] = {
                ...allTntsData[tntId],
                ...tntIdsPath[tntId],
            }
        })

        return _collector
    }
)

const _custAuthUsrPrjIds = createCachedSelector(
    [
        state => state.custsPrjsByAuthUsr.byId,
        paramCustId
    ],
    (custPrjs, custId) => {
        return custPrjs && custPrjs[custId]
    }
)(
    (_, { custId }) => custId
)

const _custAuthUsrTntIdsGrpByPrj = createCachedSelector(
    [
        _custAuthUsrPrjIds,
        state => state.prjsTnts.byId
    ],
    (custPrjIds, prjTntIds) => {

        const _ret = {}

        if (
            !custPrjIds || isEqual(custPrjIds, {}) ||
            !prjTntIds || isEqual(prjTntIds, {})
        ) {
            return _ret
        }

        Object.keys(custPrjIds).forEach(prjId => {
            _ret[prjId] = { prjId, tntIds: prjTntIds[prjId] }
        })

        return _ret
    }
)(
    (_, { custId }) => custId
)

const _custAuthUsrPrjRoles = createSelector(
    [
        state => state.firebase && state.firebase.auth && state.firebase.auth.uid,
        state => state.prjsRoles.byId
    ],
    (authUsrId, prjsRoles) => {

        const _ret = {}

        Object.keys(prjsRoles).forEach(prjId => {
            if (!prjsRoles[prjId][authUsrId]) {
                return
            }

            _ret[prjId] = prjsRoles[prjId][authUsrId]
        })

        return _ret
    }
)

const _custAuthUsrTntsGrpByPrj = createDeepEqualSelector(
    [
        _custAuthUsrTntIdsGrpByPrj,
        _custAuthUsrPrjRoles,
        state => state.tnts.byId,
        state => state.prjs.byId,
        paramCustId
    ],
    (custTntIdsByGrp, authUsrPrjsRoles, tntsById, prjsById, custId) => {

        const _ret = {}

        if (
            !custTntIdsByGrp || isEqual(custTntIdsByGrp, {}) ||
            !tntsById || isEqual(tntsById, {}) ||
            !custId ||
            !prjsById || isEqual(prjsById, {}) ||
            !authUsrPrjsRoles || isEqual(authUsrPrjsRoles, {})
        ) {
            return _ret
        }

        Object.keys(custTntIdsByGrp).forEach(prjId => {

            const _prjInfo = prjsById[prjId]
            const _prjData = { prjId, roles: authUsrPrjsRoles[prjId], prjName: (_prjInfo && _prjInfo.name), tnts: {} }

            if (custTntIdsByGrp[prjId].tntIds) {

                Object.keys(custTntIdsByGrp[prjId].tntIds).forEach(tntId => {
                    _prjData.tnts[tntId] = { ...tntsById[tntId] }
                })

                const _tntPathInfo = calcAllTntsPath(_prjData.tnts)

                Object.keys(_tntPathInfo).forEach(tntId => {
                    _prjData.tnts[tntId] = {
                        ..._prjData.tnts[tntId],
                        ..._tntPathInfo[tntId],
                    }
                })
            }

            _ret[prjId] = _prjData
        })

        return _ret
    }
)



export {
    _cust as cust,
    _custPrjIds as custPrjIds,
    _custPrjs as custPrjs,
    _custShareIds as custShareIds,
    _custShares as custShares,
    _custSharesFromUs as custSharesFromUs,
    _custOwnTenantIds as custOwnTenantIds,
    _custAllTenantIds as custAllTenantIds,
    _custAllTenants as custAllTenants,
    _custAllTenantsPath as custAllTenantsPath,
    _custAllTenantIdsSrc as custAllTenantIdsSrc,
    _custAllTenantsWithPathGrpBySrc as custAllTenantsWithPathGrpBySrc,

    _custAuthUsrTntsGrpByPrj as custAuthUsrTntsGrpByPrj,
}