import { makeAutoObservable, remove } from "mobx"
import { computedFn } from "mobx-utils"
import Rpt from "./Rpt"

export default class RptStore {

    rootStore

    rpts = {}

    constructor(rootStore) {
        makeAutoObservable(this, { rootStore: false })

        this.rootStore = rootStore
    }

    discardRpt = (prjId, tntId, itmId, rptType, lng, kvm, shortReport) => {
        const _rptKey = this.getRptKey(prjId, tntId, itmId, rptType, lng, kvm, shortReport)

        if (this.rpts[_rptKey]) {
            remove(this.rpts, _rptKey)
        }
    }

    getRptKey = (prjId, tntId, itmId, rptType, lng, kvm, shortReport) => {
        return `${prjId}_${tntId}_${itmId}_${rptType}_${lng}_${kvm}_${shortReport}`
    }

    requireRpt = computedFn((prjId, tntId, itmId, rptType, lng, kvm, shortReport) => {
        const _rptKey = this.getRptKey(prjId, tntId, itmId, rptType, lng, kvm, shortReport)

        if (!this.rpts[_rptKey]) {
            this.rpts[_rptKey] = new Rpt(prjId, tntId, itmId, rptType, lng, kvm, shortReport)
        }

        return this.rpts[_rptKey]
    })
}