/* eslint-disable default-case */
import produce, { original } from 'immer'
import * as actTypes from '../actions/actionTypes'
import has from 'lodash/has'
import isEqual from 'lodash/isEqual'

export default produce((draft, action) => {

    switch (action.type) {

        case actTypes.DISCARD_TENANT:

            const orig = original(draft.byId)

            for (const custId of Object.keys(orig)) {
                const _custObj = orig[custId]

                if (_custObj && _custObj.tntIds && _custObj.tntIds[action.id]) {
                    delete draft.byId[custId].tntIds[action.id]
                }
            }
            break;

        case actTypes.ASSIGN_CUST_TNTS:

            let _curVersAssignTnts = draft.byId[action.custId]
            if (!_curVersAssignTnts) {
                _curVersAssignTnts = { id: action.custId }
                draft.allIds.push(action.custId)
            }

            if (!_curVersAssignTnts.tntIds) {
                _curVersAssignTnts.tntIds = {}
            }

            action.tntIds && action.tntIds.forEach(tntId => {
                _curVersAssignTnts.tntIds[tntId] = true
            })

            draft.byId[action.custId] = _curVersAssignTnts
            break;

        case actTypes.STORE_UPSERT_CUSTS:

            action.items && action.items.forEach(cust => {

                let _curVersion = draft.byId[cust.id]
                if (!_curVersion) { // cust not in store, must be inserted
                    _curVersion = {}
                    draft.allIds.push(cust.id)
                    draft.byId[cust.id] = _curVersion
                }

                ['id', 'name', 'full'].forEach(fld => {
                    if (has(cust, fld) && (_curVersion[fld] !== cust[fld])) { //only update if a change is detected
                        _curVersion[fld] = cust[fld]
                    }
                });

                ['shrIds', 'tntIds', 'fromUsShrIds'].forEach(fld => {
                    if (has(cust, fld) && !isEqual(_curVersion[fld], cust[fld])) { //only update if a change is detected
                        _curVersion[fld] = cust[fld]
                    }
                });

            })
            break;
    }
}, {
    allIds: [],
    byId: {}
})