import React, { useContext } from 'react'

import { toJS, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite'
import { createViewModel } from 'mobx-utils'

import { lbl } from '../../lbls';
import { Modal, Input, Tabs, DatePicker, notification } from 'antd'
import { getMomentFmtByLng, languages } from '../../utils';
import AppContext from '../../AppContext';
import { autoSizeTextArea } from '../../config/const';
import moment from 'moment'
import { BugCrash } from '../../services/BugCrash';

const { TextArea } = Input
const { TabPane } = Tabs

const InfoDateEditor = observer(({ vm, lng }) => {
    return <div className="mb-3">
        <small>{lbl.date}</small><br />
        <DatePicker
            allowClear={false}
            value={moment.unix(vm.infoDate)}
            format={getMomentFmtByLng(lng)}
            onChange={(date, _) => {
                if (date) {
                    vm.infoDate = date.unix()
                }
            }}
        />
    </div>
})

const TextEditor = observer(({ vm, lng }) => {

    return <div className="mb-2">
        <Tabs type="card" defaultActiveKey={lng} size="small">
            {languages.map(L => {
                return <TabPane tab={lbl[L]} key={L}>
                    <small>{lbl.notes}</small>
                    <TextArea allowClear value={vm.text[L]} autoSize={autoSizeTextArea} onChange={(e) => {
                        runInAction(() => {
                            vm.text[L] = e.target.value
                        })
                    }} />
                </TabPane>
            })}
        </Tabs>
    </div>
})

function TntNoteEdt({ note, adm, custId, fromCustId, tntId, type }) {
    const { lng } = useContext(AppContext)

    const vm = createViewModel(note)
    vm.text = toJS(note.text)

    return <Modal
        title={lbl.edt_tnt_note}
        centered
        visible
        onOk={() => {
            vm.submit()

            const _saveTask = (adm) ? note.saveAdmin(custId, fromCustId, tntId, type) : note.save()

            _saveTask.then((ok) => {
                if (ok) {
                    note.setIsEditing(false)
                }
                else {
                    throw new Error("Couldn't save the note! Id=" + note.id)
                }
            })
                .catch((err) => {
                    BugCrash.notifyExtra(err, { noteId: note.id })
                    notification.error({
                        message: lbl.err_title,
                        description: lbl.err_save_tnt_note,
                        placement: 'bottomRight',
                        duration: 0,
                    })
                })
        }}
        onCancel={() => {
            vm.reset()
            note.setIsEditing(false)
        }}
        width={700}
    >
        <InfoDateEditor vm={vm} lng={lng} />
        <TextEditor vm={vm} lng={lng} />
    </Modal>
}

export default observer(TntNoteEdt)