import { makeAutoObservable, set, keys, remove, has, toJS } from "mobx"

import get from 'lodash/get'
import rootStore from ".."
import { orderBy } from "lodash"
import { getUuid } from "../../services/IdGenerator"

export default class Qc {

    id = ''
    type = ''
    name = ''
    testShould = false

    forModule = null

    groups = {}
    localizedName = {}
    countries = {}

    languages = {}

    isReleased = false

    timestamp = 0

    deleted = 0

    versions = {}

    isDirty = false

    constructor(type) {
        makeAutoObservable(this)

        this.type = type
    }

    get isDeleted() {
        return (get(this, ['deleted'], 0) > 0)
    }

    get sortedVersions() {
        return orderBy(keys(this.versions).map(vId => rootStore.qcVersionsStore.versions[vId]), ['sortID'], ['desc'])
    }

    updateFromJson = (json, forceRefresh) => {

        //console.log('Qc::updateFromJson', json)

        if (!forceRefresh && (json.timestamp <= this.timestamp)) { //no change + no need to refresh
            return
        }

        for (const _fld of ['id', 'timestamp', 'isReleased', 'deleted', 'forModule', 'name', 'type', 'testShould']) {
            if (!json.hasOwnProperty(_fld)) {
                continue
            }

            if (this[_fld] !== json[_fld]) {
                set(this, _fld, json[_fld])
            }
        }

        for (const _fld of ['localizedName', 'groups', 'countries', 'languages']) {
            if (!json.hasOwnProperty(_fld)) {
                continue
            }

            //TODO: can I optimize updateFromJson without overwriting everything?
            set(this, _fld, json[_fld])
        }

        this.updateVersions(json)
    }

    get srvJson() {

        const _json = {}

        for (const _fld of ['id', 'name', 'isReleased', 'deleted', 'forModule', 'localizedName', 'groups', 'countries', 'languages', 'type', 'testShould']) {
            _json[_fld] = toJS(this[_fld])
        }

        if (_json.localizedName && !_json.localizedName.id) {
            _json.localizedName.id = getUuid()
        }

        return _json
    }


    updateVersions = (json) => {

        const _fld = 'versions'
        if (!json.hasOwnProperty(_fld)) {
            return
        }

        Object.values(json[_fld]).forEach(v => {
            if (!has(this.versions, v.id)) {
                set(this.versions, v.id, true)
            }

            rootStore.qcVersionsStore.upsertFromJson(this.id, this.type, v, false)
        })

        keys(this.versions).forEach(vId => {
            if (!json[_fld] || !json[_fld][vId]) {
                remove(this.versions, vId)
            }
        })
    }

    addVersionId = (versId) => {
        set(this.versions, versId, true)
    }

    removeVersionId = (versId) => {
        remove(this.versions, versId)
    }

    get dirty() {

        if (this.isDirty) {
            return true
        }

        return false
    }

    setName = (lng, val) => {
        set(this.localizedName, lng, val)
        this.isDirty = true
    }

    setTestShould = (val) => {
        this.testShould = !!val
        this.isDirty = true
    }

    get languagesAr() {
        if (!this.languages) {
            return []
        }

        return keys(this.languages)
    }

    get groupsAr() {
        if (!this.groups) {
            return []
        }

        return keys(this.groups)
    }

    get countriesAr() {
        if (!this.countries) {
            return []
        }

        return keys(this.countries)
    }

    setLanguagesAr = (lngs) => {

        //console.log('setLanguagesAr', lngs)

        set(this, 'languages', (lngs || []).reduce((prv, cur) => ({
            ...prv,
            [cur]: true
        }), {}))

        this.isDirty = true
    }

    setGroupsAr = (grps) => {

        set(this, 'groups', (grps || []).reduce((prv, cur) => ({
            ...prv,
            [cur]: true
        }), {}))

        this.isDirty = true
    }

    setCountriesAr = (countries) => {

        set(this, 'countries', (countries || []).reduce((prv, cur) => ({
            ...prv,
            [cur]: true
        }), {}))

        this.isDirty = true
    }

    get isNew() {
        return this.timestamp === 0
    }

    setIsReleased = (val) => {
        this.isReleased = !!val
        this.isDirty = true
    }
}