import { makeAutoObservable, flow, has } from "mobx"
import rootStore from ".."
import { BugCrash } from "../../services/BugCrash"
import axios from '../../services/Axios'

import Meeting from "./Meeting"

export default class MeetingStore {

    rootStore

    meetings = {}

    isLoading = false

    constructor(rootStore) {
        makeAutoObservable(this, { rootStore: false })

        this.rootStore = rootStore
    }

    fetchOne = flow(function* (prjId, tntId, meetingId, forceRefresh) {

        this.isLoading = true

        const taskId = rootStore.workProgressStore.startLoading(`Load meeting prjId=${prjId}, tntId=${tntId}, meetingId=${meetingId}`)

        try {
            const { status, data } = yield axios.get(`/projects/${prjId}/tenants/${tntId}/doc-prot/meetings/${meetingId}`)
            if (status === 200) {
                if (forceRefresh || !has(this.meetings, data.id)) {
                    this.meetings[meetingId] = new Meeting(this)
                }

                this.meetings[meetingId].updateFromJson(data)
                return true
            }
        }
        catch (err) {
            BugCrash.notifyExtra(err, { prjId, tntId, meetingId })
        }
        finally {
            this.isLoading = false
            rootStore.workProgressStore.doneLoading(taskId)
        }

        return false
    })

    updateFromLight = (itm) => {

        if (!has(this.meetings, itm.id)) {
            this.meetings[itm.id] = new Meeting(this)
        }

        this.meetings[itm.id].updateFromLight(itm)
    }
}