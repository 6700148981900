import { makeAutoObservable, has, set, flow } from "mobx"
import { BugCrash } from "../../services/BugCrash"
import axios from '../../services/Axios'
import Tnt from "./Tnt"

export default class TntStore {

    rootStore

    tnts = {}

    isLoading = false

    constructor(rootStore) {
        makeAutoObservable(this, { rootStore: false })

        this.rootStore = rootStore
    }

    upsertFromJson = (json, forceRefresh) => {
        //console.log('TntStore::upsertFromJson', json)

        if (forceRefresh || !has(this.tnts, json.id)) {
            set(this.tnts, json.id, new Tnt())
        }

        this.tnts[json.id].updateFromJson(json, forceRefresh)
    }

    fetchOne4Admin = flow(function* (custId, fromCustId, tntId, forceRefresh) {

        const _curVers = this.tnts[tntId]
        if (_curVers && _curVers.isNew) { //new tenant, nothing to load from server
            return true
        }

        this.isLoading = true

        const taskId = this.rootStore.workProgressStore.startLoading(`Loading tenant / custId=${custId} / fromCustId=${fromCustId} / tntId=${tntId} (admin)`)

        try {
            const { status, data } = yield axios.get(`/customers/${custId}/tenants-from-cust/${fromCustId}/${tntId}`)
            if (status === 200) {
                this.upsertFromJson(data, forceRefresh)
                return true
            }
        }
        catch (err) {
            BugCrash.notifyExtra(err, { custId, fromCustId, tntId })
        }
        finally {
            this.isLoading = false
            this.rootStore.workProgressStore.doneLoading(taskId)
        }

        return false
    })
}