/* eslint-disable default-case */
import produce from 'immer'
import * as actTypes from '../actions/actionTypes'

import set from 'lodash/set'
import { SyncActSts } from '../../utils/sync'

export default produce((draft, action) => {

    switch (action.type) {

        case actTypes.SYNC_ACT_NEW:

            const { id, desc, data, opType, actType } = action

            draft.byId[id] = {
                id, desc, data, opType, actType,
                sts: SyncActSts.pending,
                created: Date.now(),
            }
            break;

        case actTypes.SYNC_ACT_STS_RUNNING:

            set(draft, ['byId', action.id, 'sts'], SyncActSts.running)
            break;

        case actTypes.SYNC_ACT_STS_OK:

            set(draft, ['byId', action.id, 'sts'], SyncActSts.done)
            break;

        case actTypes.SYNC_ACT_STS_ERR:

            set(draft, ['byId', action.id, 'sts'], SyncActSts.err)
            set(draft, ['byId', action.id, 'msg'], action.msg)
            break;

        case actTypes.SYNC_ACT_DISCARD:

            delete draft.byId[action.id]
            break;
    }
}, {
    byId: {}
})
