import * as actTypes from '../actions/actionTypes'
import { BugCrash } from '../../services/BugCrash'

import Axios from '../../services/Axios'
import {lbl} from '../../lbls'

import { registerFeedback } from './feedbackActions';
import { actStarted, actReadyOk, actReadyPb } from './actionsActions';
import { actionsAreas, actionsActs } from '../reducers/actionsReducer';
import { upsertPrjs } from './projectsActions';

export const loadProjectMounts = (projId, actGrp) => {

    return async (dispatch, getState) => {

        const _actTgt=`mnts-${projId}`

        try{
            dispatch(actStarted(actionsAreas.prjs, actGrp, _actTgt, actionsActs.load))

            const resp = await Axios.get(`/projects/${projId}/mounts`)
            if(resp && (resp.status===200)){

                //TODO: Move mntIds in a root collection!
                const _prjData = {
                    id: projId,
                    mntIds: {}
                }

                resp.data && resp.data.list && resp.data.list.forEach(mnt => {
                    _prjData.mntIds[mnt.id] = (mnt.info && mnt.info.share)?mnt.info.share:'own'
                })

                dispatch(upsertPrjs([_prjData]))
                dispatch(actReadyOk(actionsAreas.prjs, actGrp, _actTgt, actionsActs.load))

                return
            }

            throw new Error(lbl.err_load_prj_mounts)
        }
        catch(err){
            BugCrash.notify(err)
            dispatch(actReadyPb(actionsAreas.prjs, actGrp, _actTgt, actionsActs.load, err))
        }
    }
}

export const saveProjectMount = (custId, projId, tenantSrc, tenantId, actGrp) => {

    return async (dispatch, getState) => {

        const _actTgt=`mnts-${projId}-add-${tenantId}`

        try{
            dispatch(actStarted(actionsAreas.prjs, actGrp, _actTgt, actionsActs.save))

            const resp = await Axios.post(`/projects/${projId}/mounts/${tenantId}`, {
                share: (tenantSrc!=='own')?tenantSrc:null
            })
            if(resp && (resp.status===200)){

                const _state = await getState()
                const _mnt = _state.tnts.byId[tenantId]

                dispatch({type: actTypes.STORE_ASSOC_PRJ_MOUNT, prjId: projId, mntId: tenantId, tntSrc: tenantSrc})
                dispatch(registerFeedback(lbl.feedback_tnt_mount_ok.replace('%tnt', ((_mnt && _mnt.name) || tenantId)), false))
                dispatch(actReadyOk(actionsAreas.prjs, actGrp, _actTgt, actionsActs.save))

                return
            }

            throw new Error(lbl.err_save_prj_mount)
        }
        catch(err){
            BugCrash.notify(err)
            dispatch(actReadyPb(actionsAreas.prjs, actGrp, _actTgt, actionsActs.save, err))
        }
    }
}

export const delProjectMount = (custId, projId, tenantId, actGrp) => {

    return async (dispatch, getState) => {

        const _actTgt=`mnts-${projId}-del-${tenantId}`

        try{
            dispatch(actStarted(actionsAreas.prjs, actGrp, _actTgt, actionsActs.del))

            const resp = await Axios.delete(`/projects/${projId}/mounts/${tenantId}`)
            if(resp && (resp.status===200)){

                dispatch({type: actTypes.STORE_REMOVE_PRJ_MOUNT, prjId: projId, mntId: tenantId})

                const _state = await getState()
                const _mnt = _state.tnts.byId[tenantId]

                dispatch(registerFeedback(lbl.feedback_del_tnt_mount_ok.replace('%tnt', ((_mnt && _mnt.name) || tenantId)), false))
                dispatch(actReadyOk(actionsAreas.prjs, actGrp, _actTgt, actionsActs.del))

                return
            }

            throw new Error(lbl.err_del_prj_mount)
        }
        catch(err){
            BugCrash.notify(err)
            dispatch(actReadyPb(actionsAreas.prjs, actGrp, _actTgt, actionsActs.del, err))
        }
    }
}