import * as actTypes from '../../actions/actionTypes'
import set from 'lodash/set'

export const tntQuestCatExt = (draft, original, action) => {

    // eslint-disable-next-line default-case
    switch (action.type) {

        case actTypes.STORE_TNT_QUEST_CAT_ACT:

            set(draft, ['byId', action.questCatId, 'full', 'versions', action.versId, 'categories', action.catId, 'questions', action.questId, 'actions', action.act.id], action.act)
            break

        case actTypes.STORE_TNT_QUEST_CAT_QUEST:

            set(draft, ['byId', action.questCatId, 'full', 'versions', action.versId, 'categories', action.catId, 'questions', action.quest.id], action.quest)
            break

        case actTypes.STORE_TNT_QUEST_CAT_VERS:

            set(draft, ['byId', action.questCatId, 'full', 'versions', action.vers.id], action.vers)
            break

        case actTypes.STORE_TNT_QUEST_CAT_CAT:

            set(draft, ['byId', action.questCatId, 'full', 'versions', action.versId, 'categories', action.cat.id], action.cat)
            break
    }

}