import authReducer from './authReducer'
import changesReducer from './changesReducer'
import feedbackReducer from './feedbackReducer'

import rolesReducer from './rolesReducer'
import sessionReducer from './sessionReducer';
import myDataReducer from './myDataReducer';

import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase'

import * as actTypes from '../actions/actionTypes'
import settingsReducer from './settingsReducer';
import custLicensesReducer from './custLicensesReducer';

// ========================================
import tntsReducer from './tntsReducer';
import actionsReducer from './actionsReducer';
import shrReducer from './shrReducer';
import custsReducer from './custsReducer';
import prjsReducer from './prjsReducer';
import prjsTntsReducer from './prjsTntsReducer';
import prjsRootsReducer from './prjsRootsReducer';

import usrsReducer from './usrsReducer';

import custsUsrsReducer from './custsUsrsReducer';
import custsUsrsDataReducer from './custsUsrsDataReducer';
import custsAdmsReducer from './custsAdmsReducer';

import * as custsPrjsReducers from './custsPrjsReducers';

import prjsRolesReducer from './prjsRolesReducer';
import custsLicReducer from './custsLicReducer';
import prjsPermissionsReducer from './prjsPermissionsReducer.js';
import lastLoadReducer from './lastLoadReducer';

import wkeqShelfReducer from './wkeqShelfReducer';
import wkeqLadderReducer from './wkeqLadderReducer';
import wkeqGateReducer from './wkeqGateReducer';
import wkeqForkliftReducer from './wkeqForkliftReducer';
import wkeqPalettruckReducer from './wkeqPalettruckReducer';
import wkeqHydrRampReducer from './wkeqHydrRampReducer';
import wkeqLoadRampReducer from './wkeqLoadRampReducer';
import wkeqWorkPlatfReducer from './wkeqWorkPlatfReducer';

import popupReducer from './popupReducer';
import questCatSysReducer from './questCatSysReducer';
import questCatCustReducer from './questCatCustReducer';

import generatorTntDataReducer from '../dyn/generatorTntDataReducer'
import generatorTntDataTypes, { getReducerKeyByDataType } from '../dyn/generatorTntDataTypes'
import reportsReducer from './reportsReducer';
import { perfConfExt } from '../dyn/custom-ext/perfConfExt';
import { tntQuestCatExt } from '../dyn/custom-ext/tntQuestCatExt';
import txtBlockSysReducer from './txtBlockSysReducer';
import txtBlockCustReducer from './txtBlockCustReducer';

import entitiesReducer from './entitiesReducer'
import syncActsReducer from './syncActsReducer'

const appReducer = combineReducers({
  auth: authReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  changes: changesReducer,
  feedback: feedbackReducer,
  myData: myDataReducer,
  roles: rolesReducer,
  session: sessionReducer,
  settings: settingsReducer,

  custLicenses: custLicensesReducer,
  custsLic: custsLicReducer,

  actions: actionsReducer,
  tnts: tntsReducer,
  shr: shrReducer,
  custs: custsReducer,
  prjs: prjsReducer,
  prjsTnts: prjsTntsReducer,
  prjsRoots: prjsRootsReducer,

  usrs: usrsReducer,
  custsUsrs: custsUsrsReducer,
  custsUsrsData: custsUsrsDataReducer,
  custsAdms: custsAdmsReducer,

  custsPrjsByAdm: custsPrjsReducers.byAdmin,
  custsPrjsByAuthUsr: custsPrjsReducers.byAuthUsrRights,

  prjsRoles: prjsRolesReducer,
  prjsPermissions: prjsPermissionsReducer,

  lastLoad: lastLoadReducer,

  wkeqShelf: wkeqShelfReducer,
  wkeqLadder: wkeqLadderReducer,
  wkeqGate: wkeqGateReducer,
  wkeqForklift: wkeqForkliftReducer,
  wkeqPalettruck: wkeqPalettruckReducer,
  wkeqHydrRamp: wkeqHydrRampReducer,
  wkeqLoadRamp: wkeqLoadRampReducer,
  wkeqWorkPlatf: wkeqWorkPlatfReducer,

  popup: popupReducer,

  [getReducerKeyByDataType(generatorTntDataTypes.SPEC_AUDIT)]: generatorTntDataReducer(generatorTntDataTypes.SPEC_AUDIT),
  [getReducerKeyByDataType(generatorTntDataTypes.TPC_BASIC_CARE)]: generatorTntDataReducer(generatorTntDataTypes.TPC_BASIC_CARE),
  [getReducerKeyByDataType(generatorTntDataTypes.TPC_BASIC_CARE_MEDIC)]: generatorTntDataReducer(generatorTntDataTypes.TPC_BASIC_CARE_MEDIC),
  [getReducerKeyByDataType(generatorTntDataTypes.TPC_COMP_SPEC)]: generatorTntDataReducer(generatorTntDataTypes.TPC_COMP_SPEC),
  [getReducerKeyByDataType(generatorTntDataTypes.MEETING)]: generatorTntDataReducer(generatorTntDataTypes.MEETING),


  [getReducerKeyByDataType(generatorTntDataTypes.MATERNITY)]: generatorTntDataReducer(generatorTntDataTypes.MATERNITY),
  [getReducerKeyByDataType(generatorTntDataTypes.PSYCHE)]: generatorTntDataReducer(generatorTntDataTypes.PSYCHE),
  [getReducerKeyByDataType(generatorTntDataTypes.ACCIDENT_ANALYSE)]: generatorTntDataReducer(generatorTntDataTypes.ACCIDENT_ANALYSE),

  [getReducerKeyByDataType(generatorTntDataTypes.PERSON)]: generatorTntDataReducer(generatorTntDataTypes.PERSON),

  [getReducerKeyByDataType(generatorTntDataTypes.EMPL)]: generatorTntDataReducer(generatorTntDataTypes.EMPL),
  [getReducerKeyByDataType(generatorTntDataTypes.REPRESENT)]: generatorTntDataReducer(generatorTntDataTypes.REPRESENT),
  [getReducerKeyByDataType(generatorTntDataTypes.FUNC)]: generatorTntDataReducer(generatorTntDataTypes.FUNC),
  [getReducerKeyByDataType(generatorTntDataTypes.DEPART)]: generatorTntDataReducer(generatorTntDataTypes.DEPART),
  [getReducerKeyByDataType(generatorTntDataTypes.TXT_BLOCK)]: generatorTntDataReducer(generatorTntDataTypes.TXT_BLOCK),

  [getReducerKeyByDataType(generatorTntDataTypes.PERF_CONF)]: generatorTntDataReducer(generatorTntDataTypes.PERF_CONF, perfConfExt),
  [getReducerKeyByDataType(generatorTntDataTypes.QUEST_CAT)]: generatorTntDataReducer(generatorTntDataTypes.QUEST_CAT, tntQuestCatExt),

  questCatSys: questCatSysReducer,
  questCatCust: questCatCustReducer,

  txtBlockSys: txtBlockSysReducer,
  txtBlockCust: txtBlockCustReducer,

  reports: reportsReducer,

  entities: entitiesReducer,
  syncActs: syncActsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === actTypes.AUTH_LOGOUT_READY_OK) {
    state = {
      session: {
        lng: state.session && state.session.lng,
        xs: state.session && state.session.xs,
      }
    }
  }

  return appReducer(state, action)
}

export default rootReducer