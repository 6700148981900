import { generatePath } from "react-router"

const _main = '/p/:prjId/t/:tntId/perf-conf'
const _det = `${_main}/det/:perfConfId`
const _area = `${_det}/a/:area`

const nue = (v) => {
    if (!v) {
        return '-'
    }

    return v
}

const routes = {
    main: _main,
    det: _det,
    area: _area,

    url: {
        main: (prjId, tntId) => generatePath(_main, { prjId: nue(prjId), tntId: nue(tntId) }),
        det: (prjId, tntId, perfConfId) => generatePath(_det, { prjId: nue(prjId), tntId: nue(tntId), perfConfId: nue(perfConfId) }),
        area: (prjId, tntId, perfConfId, area) => generatePath(_area, { prjId: nue(prjId), tntId: nue(tntId), perfConfId: nue(perfConfId), area: nue(area) })
    }
}

export default routes