import { createDeepEqualSelector } from "./createDeepEqualSelector";
import { paramActGrp, paramActArea, paramActTgt, paramAct } from "./params";
import get from 'lodash/get'

const _actGrpSelector = createDeepEqualSelector(
    [
        state => state.actions.byGrp, 
        paramActGrp
    ],
    (actByGrp, actGrp) => {
        return actByGrp[actGrp]
    }
)

const _actSelector = createDeepEqualSelector(
    [
        state => state.actions, 
        paramActArea,
        paramActTgt,
        paramAct,
    ],
    (actions, area, tgt, act) => {
        return get(actions, [area, tgt, act])
    }
)

export {
    _actGrpSelector as actGrpSelector,
    _actSelector as actSelector,
}