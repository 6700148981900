import { makeAutoObservable, set, flow } from "mobx"
import { get } from 'lodash'
import { languages } from "../../utils"
import { BugCrash } from "../../services/BugCrash"
import axios from '../../services/Axios'
import rootStore from ".."

export default class TntNote {

    notesStore

    id
    text = {}

    infoDate = 0
    updatedAt
    updatedBy

    deleted = -1

    isEditing = false
    isNew = false

    constructor(notesStore) {
        makeAutoObservable(this, { notesStore: false })

        this.notesStore = notesStore
    }

    setInfoDate = (val) => {
        //console.log('setInfoDate', this.infoDate, val)
        //set(this, 'infoDate', val)
        this.infoDate = val
    }

    setTextByLng = (lng, val) => {
        set(this.text, lng, val)
    }

    setDeleted = (val) => {
        this.deleted = val
    }

    setIsEditing = (val) => {
        this.isEditing = val
    }

    setIsNew = (val) => {
        this.isNew = val
    }

    setId = (val) => {
        this.id = val
    }

    updateFromJson = (json) => {
        //console.log('TntNote::updateFromJson', json)

        if (this.updatedAt && json.updatedAt && (this.updatedAt >= json.updatedAt)) {
            return
        }

        this.id = json.id

        for (const _fldLvl2 of ['id', ...languages]) {
            this.text[_fldLvl2] = get(json, ['text', _fldLvl2])
        }

        this.updatedAt = json.updatedAt
        this.updatedBy = json.updatedBy

        this.infoDate = json.infoDate
        this.deleted = json.deleted
    }

    saveAdmin = flow(function* (custId, fromCustId, tntId, type) {

        this.isSaving = true

        const taskId = rootStore.workProgressStore.startPushing(`Save tnt-note (admin) id=${this.id} - type=${type}`)

        try {
            const resp = yield axios.patch(`/customers/${custId}/tenants-from-cust/${fromCustId}/${tntId}/note/${type}/${this.id}`, {
                id: this.id,
                text: this.text,
                infoDate: this.infoDate,
                deleted: this.deleted,
            })

            return (resp.status === 200)
        }
        catch (err) {
            BugCrash.notifyExtra(err, { custId, fromCustId, type })
            return false
        }
        finally {
            this.isSaving = false
            rootStore.workProgressStore.donePushing(taskId)
        }
    })

    save = flow(function* () {

        this.isSaving = true

        const taskId = rootStore.workProgressStore.startPushing(`Save tnt-note id=${this.id}`)

        try {
            const resp = yield axios.patch(`/projects/${this.notesStore.prjId}/tenants/${this.notesStore.tntId}/note/${this.notesStore.typ}/${this.id}`, {
                text: this.text,
                infoDate: this.infoDate,
                deleted: this.deleted,
            })

            return (resp.status === 200)
        }
        catch (err) {
            BugCrash.notifyExtra(err, { prjId: this.notesStore.prjId, tntId: this.notesStore.tntId, typ: this.notesStore.typ })
            return false
        }
        finally {
            this.isSaving = false
            rootStore.workProgressStore.donePushing(taskId)
        }
    })
}