const resources = {
    main_adm_dash: "iNoRisk.desk - Verwaltung",
    main_dash: "iNoRisk.desk - Dashboard",
    dash: "Dashboard",
    menu: "Menü",
    back: "Zurück",
    tnt: "Mandant",
    prj: "Projekt",
    prj_data: "Projekt-Daten",
    prj_mnt_create: "Mount erstellen",
    prj_mnts_lst: "Mounts",
    info: "Info",
    details: "Details",
    edit: "ändern",
    save: "Speichern",
    del: "Löschen",
    clone: "Klone erstellen",
    cancel: "Abbrechen",
    email: "E-Mail",
    name: "Name",
    city: "Stadt",
    street: "Straße",
    company: "Firma",
    shortName: "Kürzel",
    zip: "Postleitzahl",
    entries: "Einträge",
    pls_wait: "Bitte warten...",
    load_pls_wait: "Die Daten werden geladen. Bitte warten...",
    save_pls_wait: "Die Daten werden gespeichert. Bitte warten...",
    del_pls_wait: "Die Daten werden gelöscht. Bitte warten...",
    tnt_container: "Typ",
    tnt_container_subtnt: "Mandant",
    tnt_container_daten: "Filiale",

    tnt_contract_sts_not_knwon: "unbekannt",
    tnt_contract_sts_init: "In Vorbereitung...",
    tnt_contract_sts_active: "Aktiv",
    tnt_contract_sts_passiv_plan: "Passiv - Geplant",
    tnt_contract_sts_passiv_closed: "Passiv - Beendet",

    bitte_tnt: "Bitte wählen Sie ein Mandant!",
    bitte_prj: "Bitte wählen Sie ein Projekt!",
    bitte_usr: "Bitte wählen Sie ein Benutzer!",
    bitte_cust: "Bitte wählen Sie ein Konto!",
    feedback_save_tnt_ok: "Mandant %tnt erfolgreich gespeichert.",
    feedback_save_prj_ok: "Projekt %prj erfolgreich gespeichert.",
    feedback_del_prj_ok: "Projekt %prj erfolgreich gelöscht.",
    feedback_tnt_mount_ok: "Mandant %tnt erfolgreich gemounted.",
    feedback_del_tnt_mount_ok: "Mount %tnt erfolgreich gelöscht.",
    feedback_save_usr_roles_ok:
        "Rollen vom Benutzer %usr erfolgreich gespeichert.",
    feedback_del_usr_roles_ok: "Rollen vom Benutzer %usr erfolgreich gelöscht.",
    feedback_del_cust_usr_ok: "Benutzer %usr erfolgreich gelöscht.",
    feedback_save_cust_usr_ok: "Benutzer %usr erfolgreich gespeichert.",
    feedback_new_cust_usr_ok:
        "Benutzer %usr erfolgreich dem Konto hinzugefügt!",
    new_prj: "Neues Projekt",
    del_prj: "Projekt löschen?",
    del_cust_usr: "Benutzer löschen?",
    del_conf: "Wirklich löschen?",
    del_prj_mnt: "Projekt-Mount löschen?",
    del_prj_usr_roles: "Benutzer-Rollen löschen?",
    mnt_tnt: "Mount Mandant...",
    new_usr_role: "Rollen einem anderen Benutzer hinzufügen...",
    usr_roles_lst: "Benutzer-Rollen",
    usr_roles_choose_usr: "Benutzer auswählen...",

    title_prj_lst: "Projekte",
    title_tnt_lst: "Mandanten",
    title_usr_lst: "Benutzer",
    title_sel_tnt: "Mandant-Auswahl",
    title_sel_cust: "Konto-Auswahl",
    title_wkeq_shelf_lst: "Regalprüfungen",
    title_wkeq_ladder_lst: "Leiterprüfungen",
    title_wkeq_gate_lst: "Torprüfungen",
    title_wkeq_forklift_lst: "Flurförderzeugprüfungen",
    title_wkeq_palettruck_lst: "Handhubwagenprüfungen",
    title_wkeq_hydr_ramp_lst: "Hebebühneprüfungen",
    title_wkeq_load_ramp_lst: "Laderampeprüfungen",
    title_wkeq_work_platf_lst: "Arbeitsbühneprüfungen",
    title_audits_spec_lst: "Beurteilungen",
    title_sel_role: "Rolle-Auswahl",
    title_meetings_lst: "Sitzungen",
    title_tpc_basic_care_lst: "Themen der Grundbetreuung",
    title_tpc_basic_care_medic_lst: "Themen der Arbeitsmedizin",
    title_pers_lst: "Personen",
    title_empl_lst: "Mitarbeiter",
    title_represent_lst: "Beauftragte",
    title_shr: "Mandant-Freigaben",
    title_maternity_lst: "Mutterschutz",
    title_accident_lst: "Unfallanalyse",
    title_perf_conf_lst: "Leistungsbestätigungen",
    title_quest_cat_lst: "Fragenkataloge",
    title_txt_block_lst: "Textbausteine",

    tnt_path: "Ausgewählter Mandant",
    tnt_data: "Daten",
    title_tnt_data: "Mandant-Daten",
    title_subtnt: "Abteilungen / Niederlassungen",
    new_tnt: "Neuer Mandant",
    new_usr: "Neuer Benutzer",
    new_tnt_create: "Mandant erstellen",
    del_tnt_quest: "Mandant entfernen?",
    del_tnt_plan_std: "Planung-Eintrag entfernen?",

    title_cust_lic: "Lizenzierte Module",
    title_cust_lic_add_mod: "Modules hinzufügen",

    no_data: "Keine Daten vorhanden.",

    err_load_tnt: "Fehler beim Laden der Mandant-Daten!",
    err_del_tnt: "Fehler beim Löschen der Mandant-Daten!",
    err_load_prj: "Fehler beim Laden der Projekt-Daten!",
    err_del_prj: "Fehler beim Löschen der Projekt-Daten!",

    err_create_tnt_share: "Fehler bei der Freigabe der Mandant-Daten!",
    err_accept_tnt_share: "Fehler bei der Annahme der Mandant-Freigabe!",

    err_load_cust_tnts: "Fehler beim Laden der Mandanten-Daten!",
    err_load_cust_shares_to_us: "Fehler beim Laden der Freigaben!",
    err_load_cust_shares_from_us: "Fehler beim Laden der Konto-Freigaben!",
    err_load_cust_prjs: "Fehler beim Laden der Projekte-Daten!",
    err_load_prj_mounts: "Fehler beim Laden der Projekt-Mounts Daten!",
    err_save_prj_mount: "Fehler beim Speichern der Projekt-Mount Daten!",
    err_save_prj: "Fehler beim Speichern der Projekt-Daten!",
    err_save_tnt: "Fehler beim Speichern der Mandant-Daten!",
    err_del_prj_mount: "Fehler beim Löschen der Projekt-Mount Daten!",
    err_load_cust_users: "Fehler beim Laden der Benutzer-Daten!",
    err_del_cust_user: "Fehler beim Löschen des Benutzers!",
    err_load_prj_roles: "Fehler beim Laden der Projekt-Rollen Daten!",
    err_load_roles: "Fehler beim Laden der Rollen Daten!",
    err_del_usr_roles_mount: "Fehler beim Löschen der Benutzer-Rollen!",
    err_load_cust_admins: "Fehler beim Laden der Administratoren!",
    err_save_cust_usr: "Fehler beim Speichern der Benutzer-Daten!",
    err_new_cust_usr: "Fehler beim Hinzufügen des Benutzers %usr!",
    err_load_my_cust: "Fehler beim Laden der Konto-Daten!",
    err_load_my_cust_projs: "Fehler beim Laden der Projekt-Daten!",
    err_load_my_cust_proj_tnts: "Fehler beim Laden der Mandant-Daten!",
    err_load_user_data: "Fehler beim Laden der Benutzer-Daten!",
    err_load_cust_lst: "Fehler beim Laden der Konten!",
    err_load_cust_lic: "Fehler beim Laden der Lizenz!",
    err_patch_cust_lic: "Fehler beim Speichern der Lizenz!",
    err_save_prj_usr_rollen: "Fehler beim Speichern der Benutzer-Rollen!",
    err_load_tnt_wkeq_shelves: "Fehler beim Laden der Regalprüfungen-Daten!",
    err_load_tnt_wkeq_shelf: "Fehler beim Laden der Regalprüfung-Daten!",
    err_del_tnt_wkeq_shelf: "Fehler beim Löschen der Regalprüfung-Daten!",
    err_gen_report: "Fehler bei der Generierung des Berichtes!",

    err_load_tnt_wkeq_ladders: "Fehler beim Laden der Leiternprüfungen-Daten!",
    err_load_tnt_wkeq_ladder: "Fehler beim Laden der Leiterprüfung-Daten!",
    err_del_tnt_wkeq_ladder: "Fehler beim Löschen der Leiterprüfung-Daten!",

    err_load_tnt_wkeq_gates: "Fehler beim Laden der Torprüfungen-Daten!",
    err_load_tnt_wkeq_gate: "Fehler beim Laden der Torprüfung-Daten!",
    err_del_tnt_wkeq_gate: "Fehler beim Löschen der Torprüfung-Daten!",

    err_load_tnt_spec_audits: "Fehler beim Laden der Beurteilungen-Daten!",
    err_load_tnt_spec_audit: "Fehler beim Laden der Beurteilung-Daten!",
    err_del_tnt_spec_audit: "Fehler beim Löschen der Beurteilung-Daten!",

    err_load_tnt_tpc_basic_cares:
        "Fehler beim Laden der Themen der Grundbetreuung Daten!",
    err_load_tnt_tpc_basic_care:
        "Fehler beim Laden der Thema der Grundbetreuung Daten!",
    err_del_tnt_tpc_basic_care:
        "Fehler beim Löschen der Thema der Grundbetreuung Daten!",
    feedback_save_tpc_basic_care:
        "Thema der Grundbetreuung %title erfolgreich gespeichert!",
    feedback_save_tpc_basic_care_err:
        "Fehler beim Speichern der Thema der Grundbetreuung %title. %later",

    err_load_tnt_tpc_basic_care_medics:
        "Fehler beim Laden der Themen der Arbeitsmedizin Daten!",
    err_load_tnt_tpc_basic_care_medic:
        "Fehler beim Laden der Thema der Arbeitsmedizin Daten!",
    err_del_tnt_tpc_basic_care_medic:
        "Fehler beim Löschen der Thema der Arbeitsmedizin Daten!",
    feedback_save_tpc_basic_care_medic:
        "Thema der Arbeitsmedizin %title erfolgreich gespeichert!",
    feedback_save_tpc_basic_care_medic_err:
        "Fehler beim Speichern der Thema der Arbeitsmedizin %title. %later",

    err_load_tnt_tpc_comp_specs:
        "Fehler beim Laden der Betriebsspezifik-Daten!",
    err_load_tnt_tpc_comp_spec: "Fehler beim Laden der Betriebsspezifik-Daten!",
    err_del_tnt_tpc_comp_spec:
        "Fehler beim Löschen der Betriebsspezifik-Daten!",
    feedback_save_tpc_comp_spec:
        "Betriebsspezifik %title erfolgreich gespeichert!",
    feedback_save_tpc_comp_spec_err:
        "Fehler beim Speichern der Betriebsspezifik %title. %later",

    err_load_tnt_meetings: "Fehler beim Laden der Sitzungen-Daten!",
    err_load_tnt_meeting: "Fehler beim Laden der Sitzung-Daten!",
    err_del_tnt_meeting: "Fehler beim Löschen der Sitzung-Daten!",
    feedback_save_meeting: "Sitzung %title erfolgreich gespeichert!",
    feedback_save_meeting_err:
        "Fehler beim Speichern der Sitzung %title. %later",

    err_load_tnt_maternities: "Fehler beim Laden der Mutterschutz-Daten!",
    err_load_tnt_maternity: "Fehler beim Laden der Mutterschutz-Daten!",
    err_del_tnt_maternity: "Fehler beim Löschen der Mutterschutz-Daten!",
    feedback_save_maternity:
        "Gefährdungsbeurteilung %title erfolgreich gespeichert!",
    feedback_save_maternity_err:
        "Fehler beim Speichern der Gefährdungsbeurteilung %title. %later",

    err_load_tnt_perf_confs:
        "Fehler beim Laden der Leistungsbestätigungen-Daten!",
    err_load_tnt_perf_conf: "Fehler beim Laden der Leistungsbestätigung-Daten!",
    err_del_tnt_perf_conf:
        "Fehler beim Löschen der Leistungsbestätigung-Daten!",
    feedback_save_perf_conf:
        "Leistungsbestätigung %title erfolgreich gespeichert!",
    feedback_save_perf_conf_err:
        "Fehler beim Speichern der Leistungsbestätigung %title. %later",

    err_load_tnt_accidents: "Fehler beim Laden der Unfallanalyse-Daten!",
    err_load_tnt_accident: "Fehler beim Laden der Unfallanalyse-Daten!",
    err_del_tnt_accident: "Fehler beim Löschen der Unfallanalyse-Daten!",
    feedback_save_accident: "Unfallanalyse %title erfolgreich gespeichert!",
    feedback_save_accident_err:
        "Fehler beim Speichern der Unfallanalyse %title. %later",

    err_load_tnt_quest_cats: "Fehler beim Laden der Fragenkatalogen-Daten!",
    err_load_tnt_quest_cat: "Fehler beim Laden der Fragenkatalog-Daten!",
    err_del_tnt_quest_cat: "Fehler beim Löschen der Fragenkatalog-Daten!",
    feedback_save_tnt_quest_cat:
        "Fragenkatalog %title erfolgreich gespeichert!",
    feedback_save_tnt_quest_cat_err:
        "Fehler beim Speichern des Fragenkatalogs %title. %later",

    err_load_tnt_persons: "Fehler beim Laden der Personen-Daten!",
    err_load_tnt_person: "Fehler beim Laden der Person-Daten!",
    err_del_tnt_person: "Fehler beim Löschen der Person-Daten!",
    feedback_save_tnt_person: "Person %title erfolgreich gespeichert!",
    feedback_save_tnt_person_err:
        "Fehler beim Speichern der Person %title. %later",

    err_load_tnt_empls: "Fehler beim Laden der Mitarbeiter-Daten!",
    err_load_tnt_empl: "Fehler beim Laden der Mitarbeiter-Daten!",
    err_del_tnt_empl: "Fehler beim Löschen der Mitarbeiter-Daten!",
    feedback_save_tnt_empl: "Mitarbeiter %title erfolgreich gespeichert!",
    feedback_save_tnt_empl_err:
        "Fehler beim Speichern der Mitarbeiter %title. %later",

    err_load_tnt_represents: "Fehler beim Laden der Beauftragte-Daten!",
    err_load_tnt_represent: "Fehler beim Laden der Beauftragter-Daten!",
    err_del_tnt_represent: "Fehler beim Löschen der Beauftragter-Daten!",
    feedback_save_tnt_represent: "Beauftragter %title erfolgreich gespeichert!",
    feedback_save_tnt_represent_err:
        "Fehler beim Speichern der Beauftragter %title. %later",

    err_load_tnt_funcs: "Fehler beim Laden der Funktionen-Daten!",
    err_load_tnt_func: "Fehler beim Laden der Funktion-Daten!",
    err_del_tnt_func: "Fehler beim Löschen der Funktion-Daten!",
    feedback_save_tnt_func: "Funktion %title erfolgreich gespeichert!",
    feedback_save_tnt_func_err:
        "Fehler beim Speichern der Funktion %title. %later",

    err_load_tnt_departs: "Fehler beim Laden der Abteilungen-Daten!",
    err_load_tnt_depart: "Fehler beim Laden der Abteilung-Daten!",
    err_del_tnt_depart: "Fehler beim Löschen der Abteilung-Daten!",
    feedback_save_tnt_depart: "Abteilung %title erfolgreich gespeichert!",
    feedback_save_tnt_depart_err:
        "Fehler beim Speichern der Abteilung %title. %later",

    err_load_tnt_txt_blocks: "Fehler beim Laden der Textbausteine-Daten!",
    err_load_tnt_txt_block: "Fehler beim Laden der Textbaustein-Daten!",
    err_del_tnt_txt_block: "Fehler beim Löschen der Textbaustein-Daten!",
    feedback_save_tnt_txt_block: "Textbaustein %title erfolgreich gespeichert!",
    feedback_save_tnt_txt_block_err:
        "Fehler beim Speichern des Textbausteins %title. %later",
    err_del_txt_block: "Fehler beim Löschen der Textbaustein-Daten!",

    err_load_cust_txt_blocks: "Fehler beim Laden der Globale-Textbausteine!",
    err_load_cust_txt_block: "Fehler beim Laden eines Globale-Textbausteins!",

    err_load_sys_txt_block: "Fehler beim Laden eines System-Textbausteins!",
    err_load_sys_txt_blocks: "Fehler beim Laden der System-Textbausteine!",

    err_load_sys_quest_cat: "Fehler beim Laden der System-Fragenkatalog!",
    err_load_sys_quest_cats: "Fehler beim Laden der System-Fragenkataloge!",
    err_load_sys_quest_cat_ver:
        "Fehler beim Laden einer Version des System-Fragenkatalogs!",
    err_load_sys_quest_cat_vers:
        "Fehler beim Laden der Versionen eines System-Fragenkatalogs!",
    err_load_sys_quest_cat_ver_categ:
        "Fehler beim Laden einer Kategorie des System-Fragenkatalogs!",
    err_load_sys_quest_cat_ver_categs:
        "Fehler beim Laden der Kategorien eines System-Fragenkatalogs!",
    err_load_sys_quest_cat_ver_categ_quest:
        "Fehler beim Laden einer Frage des System-Fragenkatalogs!",
    err_load_sys_quest_cat_ver_categ_quests:
        "Fehler beim Laden der Fragen eines System-Fragenkatalogs!",

    err_load_cust_quest_cats: "Fehler beim Laden der Globale-Fragenkataloge!",
    err_load_cust_quest_cat: "Fehler beim Laden eines Globale-Fragenkatalogs!",

    err_load_tnt_wkeq_forklifts:
        "Fehler beim Laden der Flurförderzeugprüfungen-Daten!",
    err_load_tnt_wkeq_forklift:
        "Fehler beim Laden der Flurförderzeugprüfung-Daten!",
    err_del_tnt_wkeq_forklift:
        "Fehler beim Löschen der Flurförderzeugprüfung-Daten!",

    err_load_tnt_wkeq_palettrucks:
        "Fehler beim Laden der Handhubwagenprüfungen-Daten!",
    err_load_tnt_wkeq_palettruck:
        "Fehler beim Laden der Handhubwagenprüfung-Daten!",
    err_del_tnt_wkeq_palettruck:
        "Fehler beim Löschen der Handhubwagenprüfung-Daten!",

    err_load_tnt_wkeq_hydr_ramps:
        "Fehler beim Laden der Hebebühneprüfungen-Daten!",
    err_load_tnt_wkeq_hydr_ramp:
        "Fehler beim Laden der Hebebühneprüfung-Daten!",
    err_del_tnt_wkeq_hydr_ramp:
        "Fehler beim Löschen der Hebebühneprüfung-Daten!",

    err_load_tnt_wkeq_load_ramps:
        "Fehler beim Laden der Laderampeprüfungen-Daten!",
    err_load_tnt_wkeq_load_ramp:
        "Fehler beim Laden der Laderampeprüfung-Daten!",
    err_del_tnt_wkeq_load_ramp:
        "Fehler beim Löschen der Laderampeprüfung-Daten!",

    err_load_tnt_wkeq_work_platfs:
        "Fehler beim Laden der Arbeitsbühneprüfungen-Daten!",
    err_load_tnt_wkeq_work_platf:
        "Fehler beim Laden der Arbeitsbühneprüfung-Daten!",
    err_del_tnt_wkeq_work_platf:
        "Fehler beim Löschen der Arbeitsbühneprüfung-Daten!",

    err_save_role: "Fehler beim Speichern der Rolle!",
    err_save:
        "Fehler beim Speichern! Bitte versuchen Sie erneut zu einem späteren Zeitpunkt!",

    search: "Suchen...",
    search_mandanten: "Mandanten suchen...",
    search_fil: "Filialen suchen...",
    search_usr_role: "Benutzer-Rolle suchen...",
    search_usr: "Benutzer suchen...",
    search_prj: "Projekt suchen...",
    search_tnt: "Mandant suchen...",
    search_cust: "Konto suchen...",
    search_wkeq_shelf: "Regalprüfung suchen...",
    search_wkeq_ladder: "Leiterprüfung suchen...",
    search_wkeq_gate: "Torprüfung suchen...",
    search_wkeq_forklift: "Flurförderzeugprüfung suchen...",
    search_wkeq_palettruck: "Handhubwagenprüfung suchen...",
    search_wkeq_hydr_ramp: "Hebebühneprüfung suchen...",
    search_wkeq_load_ramp: "Laderampeprüfung suchen...",
    search_wkeq_work_platf: "Arbeitsbühneprüfung suchen...",
    search_audits_specific: "Beurteilungen suchen...",
    search_role: "Rolle suchen...",
    search_meetings: "Sitzungen suchen...",
    search_person: "Person suchen...",
    search_meeting_tn: "Teilnehmer suchen...",
    search_meeting_thema: "Thema suchen...",
    search_meeting_thema_action: "Maßnahme suchen...",
    search_tpc_basic_care: "Themen der Grundbetreuung suchen...",
    search_tpc_basic_care_medic: "Themen der Arbeitsmedizin suchen...",
    search_pers: "Person suchen...",
    search_empl: "Mitarbeiter suchen...",
    search_represent: "Beauftragter suchen...",
    search_maternity: "Gefährdungsbeurteilung suchen...",
    search_accident: "Unfallanalyse suchen...",
    search_perf_conf: "Leistungsbestätigung suchen...",
    search_quest_cat: "Fragenkatalog suchen...",
    search_quest: "Fragen suchen...",
    search_quest_act: "Maßnahme suchen...",
    search_quest_reg: "Regelwerk suchen...",
    search_quest_cat_cat: "Kategorie suchen...",
    search_txt_blocks: "Textbaustein suchen...",
    search_quest_cat_vers: "Version suchen...",

    usr: "Benutzer",
    usr_data: "Benutzer-Daten",
    usr_new: "Neuer Benutzer",
    admin: "Administrator",

    tab_roles: "Rollen",
    tab_role: "Rolle",
    tab_data: "Daten",
    tab_mounts: "Mounts",
    tab_subtenants: "Niederlassungen",
    tab_share: "Freigabe",
    tab_lic: "Lizenz",
    tab_pdf: "PDF",
    tab_details: "Details",
    tab_imgs: "Bilder",
    tab_rpt: "Berichte",
    tab_notes: "Notizen",
    tab_times: "Einsatzzeiten",
    tab_tpcs: "Themen",

    sel_cust: "Konto",
    pls_sel_cust: "Bitte ein Konto auswählen...",
    pls_sel_prj: "Bitte ein Projekt auswählen...",
    no_sel_cust: "kein Konto ausgewählt",
    no_sel_tnt: "kein Mandant ausgewählt",
    no_sel_prj: "kein Projekt ausgewählt",
    sel_tnt: "Mandant",
    sel_prj: "Projekt",
    pls_sel_rolle: "Bitte eine Rolle auswählen...",
    pls_sel_quest_cat: "Bitte ein Fragenkatalog auswählen...",
    pls_sel_quest_cat_cat: "Bitte eine Kategorie auswählen...",
    pls_sel_quest_cat_vers: "Bitte eine Version auswählen...",
    pls_sel_quest_cat_quest: "Bitte eine Frage auswählen...",

    roles: "Rollen",
    role: "Rolle",
    desc: "Beschreibung",

    sel_cust_title: "Konto auswählen...",

    choose_tnt: "Mandant wählen!",
    pls_sel_tnt: "Bitte ein Mandant auswählen...",

    own_tnt: "Eigene Mandanten",
    share_name: "Freigaben von %cust",

    noModLicensed: "Keine Module lizenziert!",

    bulk_edit: "Massenbearbeitung...",
    no_selection: "Kein Auswahl. Massenbearbeitung nicht möglich.",
    nb_selection: "Anzahl ausgewählte Elemente: %nb",
    add_modules: "Modules hinzufügen...",
    del_modules: "Modules aus der Lizenz entfernen",
    lic_modules_set_date: '"Gültig bis" Datum anpassen',
    lic_valid_until: "lizenziert bis %s",
    lic_valid_free: "lizenziert ohne Einschränkung.",
    lic_modules_bis_date: "Gültig bis",

    del_lic_modules: "Modules aus Lizenz entfernen?",
    feedback_save_lizenz_ok: "Die Lizenz wurde es erfolgreich gespeichert.",
    feedback_save_lizenz_err: "Fehler beim Speichern der Lizenz. %later",
    try_again_later: "Bitte versuchen Sie erneut später!",

    adm_lnk_kontos: "Konten",
    adm_lnk_tnt: "Mandanten",
    adm_lnk_prj: "Projekte",
    adm_lnk_usr: "Benutzer",
    adm_lnk_dash: "Dashboard",
    adm_lnk_roles: "Rollen",
    adm_lnk_shr: "Freigaben",
    adm_lnk_cust_data: "Konto-Daten",
    adm_lnk_qc: "Standard-Fragenkataloge",
    adm_lnk_qc_cust: "Unternehmen-Fragenkataloge",

    adm_dash_hd: "Willkommen zur Verwaltung von iNoRisk DESK!",

    bitte_wkeqs_helf: "Bitte wählen Sie eine Regalprüfung!",
    new_wkeqs_helf: "Neue Regalprüfung",
    wkeq_shelf_data: "Regalprüfung-Daten",
    wkeq_shelf_pdf: "PDF-Berichte",
    wkeq_shelf_mangel_data: "Mangel",
    del_wkeq_shelf: "Regalprüfung entfernen?",
    del_wkeq_shelf_regal: "Regal entfernen?",
    del_wkeq_shelf_regal_mangel: "Mangel entfernen?",
    del_wkeq_shelf_regal_art: "Regalart bzw. Sonderausführung entfernen?",
    del_wkeq_shelf_regal_ladungs: "Ladungsträger bzw. Hilfsmittel entfernen?",
    del_wkeq_shelf_regal_bedienung: "Regalbedienung entfernen?",
    del_audit_gate: "Beurteilung entfernen?",

    wkeq_ladder_data: "Leiterprüfung-Daten",
    new_wkeq_ladder: "Neue Leiterprüfung",
    del_wkeq_ladder: "Leiterprüfung entfernen?",

    bitte_wkeq_ladder: "Bitte wählen Sie eine Leiterprüfung!",

    wkeq_gate_data: "Torprüfung-Daten",
    new_wkeq_gate: "Neue Torprüfung",
    del_wkeq_gate: "Torprüfung entfernen?",

    bitte_wkeq_gate: "Bitte wählen Sie eine Torprüfung!",

    bitte_spec_audit: "Bitte wählen Sie eine Beurteilung!",
    spec_audit_data: "Beurteilung-Daten",
    new_spec_audit: "Neue Beurteilung",

    bitte_meetings: "Bitte wählen Sie eine Sitzung!",
    new_meeting: "Neue Sitzung",
    meeting_data: "Sitzung-Daten",
    del_meeting: "Sitzung entfernen?",

    new_person: "Neue Person",
    bitte_person: "Bitte wählen Sie eine Person!",
    person_data: "Person-Daten",
    del_person: "Person entfernen?",

    new_empl: "Neuer Mitarbeiter",
    bitte_empl: "Bitte wählen Sie einen Mitarbeiter!",
    empl_data: "Mitarbeiter-Daten",
    del_empl: "Mitarbeiter entfernen?",

    new_represent: "Neuer Beauftragter",
    bitte_represent: "Bitte wählen Sie einen Beauftragter!",
    represent_data: "Beauftragter-Daten",
    del_represent: "Beauftragter entfernen?",

    new_tpc_basic_care: "Neue Thema der Grundbetreuung",
    bitte_tpc_basic_care: "Bitte wählen Sie ein Thema der Grundbetreuung!",
    tpc_basic_care_data: "Thema der Grundbetreuung-Daten",
    del_tpc_basic_care: "Thema der Grundbetreuung entfernen?",

    new_tpc_basic_care_medic: "Neue Thema der Arbeitsmedizin",
    bitte_tpc_basic_care_medic:
        "Bitte wählen Sie ein Thema der Arbeitsmedizin!",
    tpc_basic_care_medic_data: "Thema der Arbeitsmedizin-Daten",
    del_tpc_basic_care_medic: "Thema der Arbeitsmedizin entfernen?",

    wkeq_forklift_data: "Flurförderzeugprüfung-Daten",
    wkeq_hydr_ramp_data: "Hebebühneprüfung-Daten",
    wkeq_forklift_load_ramp: "Laderampeprüfung-Daten",
    wkeq_work_platf_data: "Arbeitsbühneprüfung-Daten",

    bitte_wkeq_forklift: "Bitte wählen Sie eine Flurförderzeugprüfung!",

    del_wkeq_forklift: "Flurförderzeugprüfung entfernen?",
    new_wkeq_forklift: "Neue Flurförderzeugprüfung",

    bitte_wkeq_palettruck: "Bitte wählen Sie eine Handhubwagenprüfung!",

    new_wkeq_palettruck: "Neue Handhubwagenprüfung",
    wkeq_palettruck_data: "Handhubwagenprüfung-Daten",
    del_wkeq_palettruck: "Handhubwagenprüfung entfernen?",

    bitte_wkeq_hydr_ramp: "Bitte wählen Sie eine Hebebühneprüfung!",
    del_wkeq_hydr_ramp: "Hebebühneprüfung entfernen?",
    new_wkeq_hydr_ramp: "Neue Hebebühneprüfung",

    bitte_wkeq_load_ramp: "Bitte wählen Sie eine Laderampeprüfung!",
    del_wkeq_load_ramp: "Laderampeprüfung entfernen?",
    new_wkeq_load_ramp: "Neue Laderampeprüfung",

    bitte_wkeq_work_platf: "Bitte wählen Sie eine Arbeitsbühneprüfung!",
    del_wkeq_work_platf: "Arbeitsbühneprüfung entfernen?",
    new_wkeq_work_platf: "Neue Arbeitsbühneprüfung",

    new_maternity: "Neue Gefährdungsbeurteilung",
    bitte_maternity: "Bitte wählen Sie eine Gefährdungsbeurteilung!",
    maternity_data: "Gefährdungsbeurteilung-Daten",
    del_maternity: "Gefährdungsbeurteilung entfernen?",

    new_accident: "Neue Unfallanalyse",
    bitte_accident: "Bitte wählen Sie eine Unfallanalyse!",
    accident_data: "Unfallanalyse-Daten",
    del_accident: "Unfallanalyse entfernen?",

    new_perf_conf: "Neue Leistungsbestätigung",
    bitte_perf_conf: "Bitte wählen Sie eine Leistungsbestätigung!",
    perf_conf_data: "Leistungsbestätigung-Daten",
    perf_conf_details: "Leistungsbestätigung-Details",
    del_perf_conf: "Leistungsbestätigung entfernen?",
    perf_conf_pdf: "PDF-Berichte",
    del_perf_conf_leistung: "Leistung entfernen?",
    del_quest_cat_act: "Maßnahme entfernen?",
    del_quest_cat_quest: "Frage entfernen?",
    del_quest_cat_cat: "Kategorie entfernen?",
    del_quest_cat_vers: "Version entfernen?",
    new_quest_cat_act: "Neue Maßnahme",
    new_quest_cat_quest: "Neue Frage",
    new_quest_cat_cat: "Neue Kategorie",

    bitte_quest_cat: "Bitte wählen Sie einen Fragenkatalog!",
    quest_cat_data: "Fragenkatalog-Daten",
    quest_cat_vers_data: "Version-Daten",
    quest_cat_cat_data: "Kategorie-Daten",
    quest_cat_quest_data: "Frage-Daten",
    quest_cat_act_data: "Maßnahme-Daten",

    bitte_quest_cat_vers: "Bitte wählen Sie eine Fragenkatalog-Version!",

    bitte_txt_blocks: "Bitte wählen Sie einen Textbaustein!",
    txt_block_type: "Typ",
    txt_block_type_cust: "Globale Textbausteine",
    txt_block_type_sys: "System Textbausteine",
    txt_block_type_tnt: "Mandanten Textbausteine",
    txt_block_new: "Neuer Textbaustein",
    del_txt_block_conf: "Textbaustein entfernen?",
    txt_block_data: "Textbaustein-Daten",

    new_psyche: "Neue Psyche-Befragung",
    bitte_psyche: "Bitte wählen Sie eine Psyche-Befragung!",
    psyche_data: "Psyche-Befragung-Daten",
    del_psyche: "Psyche-Befragung entfernen?",

    feedback_save_rolle: "Rolle %title erfolgreich gespeichert!",
    feedback_save_wkeq_shelf: "Regalprüfung %title erfolgreich gespeichert!",
    feedback_save_wkeq_shelf_err:
        "Fehler beim Speichern der Regalprüfung %title. %later",
    feedback_save_wkeq_ladder: "Leiterprüfung %title erfolgreich gespeichert!",
    feedback_save_wkeq_ladder_err:
        "Fehler beim Speichern der Leiterprüfung %title. %later",
    feedback_save_wkeq_gate: "Torprüfung %title erfolgreich gespeichert!",
    feedback_save_wkeq_gate_err:
        "Fehler beim Speichern der Torprüfung %title. %later",
    feedback_save_wkeq_forklift:
        "Flurförderzeugprüfung %title erfolgreich gespeichert!",
    feedback_save_wkeq_forklift_err:
        "Fehler beim Speichern der Flurförderzeugprüfung %title. %later",
    feedback_save_wkeq_palettruck:
        "Handhubwagenprüfung %title erfolgreich gespeichert!",
    feedback_save_wkeq_palettruck_err:
        "Fehler beim Speichern der Handhubwagenprüfung %title. %later",
    feedback_save_wkeq_hydr_ramp:
        "Hebebühneprüfung %title erfolgreich gespeichert!",
    feedback_save_wkeq_hydr_ramp_err:
        "Fehler beim Speichern der Hebebühneprüfung %title. %later",
    feedback_save_wkeq_load_ramp:
        "Laderampeprüfung %title erfolgreich gespeichert!",
    feedback_save_wkeq_load_ramp_err:
        "Fehler beim Speichern der Laderampeprüfung %title. %later",
    feedback_save_wkeq_work_platf:
        "Arbeitsbühneprüfung %title erfolgreich gespeichert!",
    feedback_save_wkeq_work_platf_err:
        "Fehler beim Speichern der Arbeitsbühneprüfung %title. %later",
    feedback_save_spec_audit: "Beurteilung %title erfolgreich gespeichert!",
    feedback_save_spec_audit_err:
        "Fehler beim Speichern der Beurteilung %title. %later",
    feedback_save_quest_cat: "Fragenkatalog %title erfolgreich gespeichert!",
    feedback_save_quest_cat_err:
        "Fehler beim Speichern des Fragenkataloges %title. %later",
    feedback_save_txt_block: "Textbaustein erfolgreich gespeichert!",
    feedback_save_txt_block_err:
        "Fehler beim Speichern von dem Textbausteins. %later",
    feedback_del_txt_block: "Textbaustein erfolgreich gelöscht!",
    feedback_del_txt_block_err:
        "Fehler beim Löschen der Textbaustein-Daten. %later",
    feedback_save_tnt_err: "Fehler beim Speichern der Mandant-Daten. %later",

    feedback_del_tnt:
        "Das Löschen vom Tenant %name wurde erfolgreich gestartet!",
    feedback_err_report: "Fehler bei der Erstellung des Berichtes!",

    old_ts_err_title: "Aktion unterbrochen...",
    old_ts_err_body:
        "Die Aktion wurde unterbrochen, da Sie beabsichtigen, eine neuere Version der Datenbankdaten durch eine ältere Version zu ersetzen. Wie möchten Sie fortfahren?",
    old_ts_err_but_load_srv: "Meine Änderungen verwerfen",
    old_ts_err_but_force_save: "Meine Version speichern!",
    old_ts_err_pls_choose: "Bitte eine Aktion wählen...",
    old_ts_err_body_info1:
        'Beim Speichern des Objektes "%info" ist ein Konflikt aufgetreten.',

    no_rights:
        "Sie verfügen nicht über ausreichende Rechte, um diese Inhalte zu sehen!",

    person: "Personen",
    pdf_download: "PDF herunterladen...",
    pdf_generate: "PDF generieren...",

    tnt_admin: "Tenant-Verwaltung",
    tnt_shr_grp_info: "Freigabe-Code an E-Mail senden",

    senden: "Senden",

    feedback_shr_tnt_ok:
        "Freigabe erfolgreich konfiguriert, E-Mail an %email gesendet!",
    feedback_shr_tnt_accept_ok:
        "Freigabe erfolgreich angenommen. Die freigegebenen Mandanten können ab sofort in Projekte gemounted werden!",

    shr_code: "Einladung-Code",
    shr_to_cust: "Freigaben von anderen Konten",
    shr_from_cust: "Eigene Freigaben für anderen Konten",

    fld_required: "Bitte geben Sie einen gültigen Wert ein!",
    tnt_shr_from_invt_code_info:
        "Haben Sie von einem anderen Konto eine Einladung für eine Mandant-Freigabe?",

    del_share: "Freigabe entfernen?",
    del_conf_shr_info:
        "Wirklich löschen? (die Mandant-Daten selbst werden nicht gelöscht!)",

    err_del_cust_share: "Fehler beim Löschen der Freigabe!",
    feedback_del_shr_ok: "Freigabe erfolgreich gelöscht!",

    phone2: "Telefon2",
    contact: "Kontakt",
    contract_start_date: "Start-Datum",
    contract_end_date: "Ende-Datum (einschließlich)",
    crm_id: "Externe CRM-Id",
    betrieb_nr: "Betrieb-Nr.",
    plan_std_am: "Planung Arbeitsmedizin",
    plan_std_as: "Planung Arbeitssicherheit",
    plan_std_from_date: "Ab Datum",
    plan_std_anz_std: "Anz. Stunden",
    plan_std_care_intv: "Betreuung",
    plan_std_contract_intv: "Vertrag",

    plan_std_contract_intv_1j: "Jährlich",
    plan_std_contract_intv_2j: "Alle 2 Jahre",
    plan_std_contract_intv_3j: "Alle 3 Jahre",
    plan_std_contract_intv_4j: "Alle 4 Jahre",
    plan_std_contract_intv_5j: "Alle 5 Jahre",
    plan_std_care_intv_1j: "Jährlich",
    plan_std_care_intv_2j: "Alle 2 Jahre",
    plan_std_care_intv_3j: "Alle 3 Jahre",
    plan_std_care_intv_4j: "Alle 4 Jahre",
    plan_std_care_intv_5j: "Alle 5 Jahre",
    plan_std_care_intv_1q: "Quartalsweise",
    plan_std_care_intv_1hb: "Halbjährlich",

    plan_std_last_check: "Letzte Prüfung (Planung-Bereich)",
    btn_check_now: "Als geprüft markieren...",
    no_check: "noch nie geprüft",

    tnt_from: "Mandanten von...",
    adm_prj_title: "Projekte",
    hd_prj: "Projekte",
    hd_prj_usr_roles: "Benutzer-Rollen im Projekt",
    hd_prj_tnts: "Mandanten im Projekt",

    adm_tnt_title: "Mandanten",
    hd_tnt: "Mandanten",
    own_tnt_or_from_share: "Eigene Mandanten oder Freigaben von...",

    adm_cust_data_title: "Konto-Daten",
    hd_cust_data: "Konto-Daten",

    cust_tint_color: "Markenfarbe",
    daten_save_ok: "Daten erfolgreich gespeichert!",
    daten_save_err:
        "Fehler beim Speichern der Daten! Bitte versuchen Sie erneut später!",

    hd_meetings: "Sitzungen",
    hd_meeting_tpc: "Themen",
    hd_meeting_tn: "Teilnehmer",
    hd_meeting_tpc_act: "Maßnahme",
    sts_meeting_design: "Entwurf",
    sts_meeting_final: "Final",

    add_meeting_tn: "Teilnehmer hinzufügen",

    de: "Deutsch",
    en: "Englisch",

    vers_nr: "Version-Nr.",
    vers_released: "Freigegeben",
    qc_quest_act_reg: "Vordefinierte Maßnahmen/Regelwerke",
    vers_change_log: "Änderungsprotokoll",
    vers_legal_basis: "Rechtsgrundlage",
    qc_released: "Freigegeben",
    qc_released_info:
        "Nur die freigegebenen Fragenkataloge sind in andere Bereiche sichtbar/verwendbar. Die freigegebenen Fragenkataloge dürfen weiterhin bearbeitet werden. (deren freigegebenen Versionen dagegen nicht!)",
    qc_test_should: "Fragen nach der idealen Soll-Situation?",

    baseModulesCat: "Stammdaten",
    amDienstModulesCat: "Arbeitsmedizinischer Dienst",

    specialEvaluate: "Besondere Beurteilungen",
    effectivenessExamination: "Wirksamkeitskontrolle",
    persons: "Personen",
    documentManagement: "Port.AS",
    documentManagementSubtitle: "Dokumentenmanagement",

    //Questionnaire Categories
    qc_cat_group: "Gruppe/Kategorie",
    qc_cat_hotel: "Gastronomie/Hotellerie",
    qc_cat_freightForwarders: "Speditionen",
    qc_cat_facilitiesForTheElderly: "Senioreneinrichtungen",
    qc_cat_hospitals: "Kliniken",
    qc_cat_retail: "Einzelhandel",
    qc_cat_wholesale: "Großhandel",
    qc_cat_temporaryWork: "Zeitarbeit / AÜ",
    qc_cat_industry: "Industrie",
    qc_cat_productionPlant: "Produktionsbetriebe",
    qc_cat_workEquipment: "Arbeitsmittel",
    qc_cat_workplaces: "Arbeitsstätten",
    qc_cat_healthcare: "Gesundheitswesen (sonstige)",
    qc_cat_warehouseLogistics: "Lager/Logistik",
    qc_cat_othersCat: "Sonstige Branchen/Bereiche",
    qc_cat_constructionSites: "Baugewerbe",
    qc_cat_administration: "Verwaltung",
    qc_cat_countries: "Länder",
    qc_cat_lngs: "Sprachen",
    qc_cat_no_country_limit:
        "Kein Auswahl bedeutet dass der Fragenkatalog in alle Länder gültig ist.",

    sync_desc_sys_qc_vers: "System-Fragenkatalog Version",
    sync_desc_audit_tpc_basic_care: "Themen der Grundbetreuung",

    tnt_info_head: "Mandant-Info",
    tnt_info_no_informations: "keine Notiz vorhanden.",
    more: "mehr...",
    close: "Schließen",
    new_tnt_note: "Neue Notiz",
    own_tnt_notes: "Interne Notizen (für Mandant)",
    mandant_tnt_notes: "Interne Notizen (von Zentrale)",
    tnt_notes_for_filialen: "Interne Notizen (für die Filialen)", // Notizen für die Filialen
    edt_tnt_note: "Notiz bearbeiten...",
    own_fil_notes: "Interne Notizen (für Filiale)",

    err_title: "Fehler!",
    err_load_data: "Fehler beim Laden der Daten!",
    err_push_data: "Fehler beim Synchronisieren der Daten!",

    err_save_tnt_note: "Fehler beim Speichern der Notiz!",
    err_load_tnt_notes: "Fehler beim Laden der Notizen!",

    work_prog: "%nb% beantwortet",
    qc_grp_pls_choose: "Gruppe/Kategorien auswählen...",
    qc_country_pls_choose: "Land/Länder auswählen...",
    qc_lngs_pls_choose: "Sprache(n) auswählen...",

    tenants_root: "Mandanten",
    tenants_child: "Filialen",
    new_tnt_root: "Neuer Mandant",
    new_tnt_child: "Neue Filiale",

    computerWorkStationInspection: "Computer Workstations",
    testPersons: "Probanden",

    new_qc_quest_vers: "Neue Version",
    new_qc_quest_quest: "Neue Frage",
    new_qc_quest_cat: "Neue Kategorie",
    new_qc_quest_act: "Neue Maßnahme",
    new_qc_quest_reg: "Neues Regelwerk",
    regulation: "Regelwerk",

    new_qc_quest_vers_not_released_info:
        "Diese Version wurde noch nicht freigegeben. Deswegen kann sie noch nicht produktiv eingesetzt werden. Wichtiger Hinweis: Sobald eine Version freigegeben wurde, darf sie nicht mehr bearbeitet werden!",
    new_qc_quest_vers_do_release: "Version freigeben!",
    new_qc_quest_vers_do_release_conf:
        "Sind Sie sicher dass Sie diese Version freigeben möchten?",
    qc_quest_vers_do_export: "Version exportieren",
    qc_quest_vers_do_import: "Version importieren...",
    qc_quest_vers_import_from: "Aus Datei importieren...",
    qc_quest_vers_import_json_file: "Version JSON-Datei",
    qc_quest_vers_import_pls_json_file: "Bitte eine JSON-Datei auswählen!",
    qc_quest_answ_style: "Antwort-Typ",
    qc_quest_answ_style_pls: "Bitte einen Antwort-Typ auswählen...",
    qc_quest_answ_style_scalaRelevance: "Relevanz/Handlungsbedarf",
    qc_quest_answ_style_scalaYesNo: "Nein (negativ) bis Ja (positiv)",
    qc_quest_answ_style_scalaYesNoReversed: "Nein (positiv) bis Ja (negativ)",

    pls_choose_report: "Bericht auswählen...",

    rpt_kvm_long: "KVM (lang)",
    rpt_kvm_short: "KVM (kurz)",
    rpt_nokvm_long: "Bericht (lang)",
    rpt_nokvm_short: "Bericht (kurz)",
    rpt_vers_from: "Bericht erhalten am: %from",
    rpt_new_version: "Bericht neu generieren!",
    rpt_lb_normal: "Normal",
    rpt_lb_no_times: "Ohne Zeiten",
    rpt_meeting_long: "Bericht",

    tnt_parent_id: "Parent",
    tnt_choose_parent: "Unter welcher Kopf-Mandant verschieben?",
    tnt_make_root: "Als Kopf-Mandant definieren",

    generalRiskAssessment: "Allgemeine Gefährdungsbeurteilung",
    ehsAudits: "EHS-Audits",
    ehsAuditsModuleDescription: "EHS-Audits Beschreibung",

    orgComp: "Firmen-Adressen",
    orgObj: "Objekte",
};

export default resources;
