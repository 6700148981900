import * as actTypes from '../actions/actionTypes'

const initState = {
}

const custLicensesReducer = (state = initState, action) => {

    switch(action.type){

        case actTypes.PATCH_CUST_LICENSE_STARTED:

            return {
                ...state,
                [action.custId]: {
                    ...(state && state[action.custId]),
                    actions: {
                        ...(state && state[action.custId] && state[action.custId].actions),
                        save: {
                            ...(state && state[action.custId] && state[action.custId].actions && state[action.custId].actions.save),
                            running: true,
                            err: null,
                        } 
                    }
                }
            }

        case actTypes.PATCH_CUST_LICENSE_READY_PB:

            return {
                ...state,
                [action.custId]: {
                    ...(state && state[action.custId]),
                    actions: {
                        ...(state && state[action.custId] && state[action.custId].actions),
                        save: {
                            ...(state && state[action.custId] && state[action.custId].actions && state[action.custId].actions.save),
                            running: false,
                            err: action.err,
                        } 
                    }
                }
            }

        case actTypes.PATCH_CUST_LICENSE_READY_OK:

            return {
                ...state,
                [action.custId]: {
                    ...(state && state[action.custId]),
                    actions: {
                        ...(state && state[action.custId] && state[action.custId].actions),
                        save: {
                            ...(state && state[action.custId] && state[action.custId].actions && state[action.custId].actions.save),
                            running: false,
                            err: null,
                        } 
                    }
                }
            }

        case actTypes.LOAD_CUST_LICENSE_STARTED:

            return {
                ...state,
                [action.custId]: {
                    ...(state && state[action.custId]),
                    data: {},
                    actions: {
                        ...(state && state[action.custId] && state[action.custId].actions),
                        load: {
                            ...(state && state[action.custId] && state[action.custId].actions && state[action.custId].actions.load),
                            running: true,
                            err: null,
                        } 
                    }
                }
            }

        case actTypes.LOAD_CUST_LICENSE_READY_PB:

            return {
                ...state,
                [action.custId]: {
                    ...(state && state[action.custId]),
                    data: {},
                    actions: {
                        ...(state && state[action.custId] && state[action.custId].actions),
                        load: {
                            ...(state && state[action.custId] && state[action.custId].actions && state[action.custId].actions.load),
                            running: false,
                            err: action.err,
                        } 
                    }
                }
            }
    
        case actTypes.LOAD_CUST_LICENSE_READY_OK:

            return {
                ...state,
                [action.custId]: {
                    ...(state && state[action.custId]),
                    data: action.data,
                    actions: {
                        ...(state && state[action.custId] && state[action.custId].actions),
                        load: {
                            ...(state && state[action.custId] && state[action.custId].actions && state[action.custId].actions.load),
                            running: false,
                            err: null,
                        } 
                    }
                }
            }

        default:
            return state
    }
}

export default custLicensesReducer;