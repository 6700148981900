/* eslint-disable default-case */
import { combineReducers } from 'redux'
import produce from 'immer'

const _actGrpReducer = produce((draft, action) => {

    if (!action.grp || !action.act || !action.type.startsWith('ACTSTS~')) {
        return
    }

    let _curGrp = draft[action.grp]
    if (!_curGrp) {
        _curGrp = {}
        Object.values(actionsActs).forEach(_act => _curGrp[_act] = 0)
    }

    _curGrp[action.act] += (action.start) ? 1 : -1

    if (_curGrp[action.act] < 0) {
        console.warn('ActionGrpReder:: counter < 0 !!')
    }

    draft[action.grp] = _curGrp
}, {})

const _createReducer = (area) => {
    return produce((draft, action) => {

        if (action.area !== area) {
            return
        }

        let _curTgt = draft[action.tgt]
        if (!_curTgt) {
            _curTgt = {}
        }

        let _curAct = draft[action.act]
        if (!_curAct) {
            _curAct = {}
        }

        _curAct.running = !!action.start
        _curAct.err = (action.err) ? action.err : null
        _curAct.ts = Date.now()

        _curTgt[action.act] = _curAct
        draft[action.tgt] = _curTgt

    }, {})
}

export const actionsAreas = {
    tnts: 'TNTS',
    cust: 'CUST',
    prjs: 'PRJS',
    shrs: 'SHRS',
    usrs: 'USRS',
    roles: 'ROLES',
    wkeq: 'WKEQ',
    audits: 'AUDITS',
    questCat: 'QUEST_CAT',
    docProt: 'DOC_PROT',
    pers: 'PERS',
    empl: 'EMPL',
    represent: 'REPRESENT',
    func: 'FUNC',
    depart: 'DEPART',
    rep: 'REPORTS',
    maternity: 'MATERNITY',
    perf: 'PERF',
    txtBlock: 'TXL_BLOCK',
}
export const actionsActs = {
    load: 'L',
    save: 'S',
    del: 'D',
}

const _areasReducers = {}
Object.values(actionsAreas).forEach(area => {
    _areasReducers[area] = _createReducer(area)
})

export default combineReducers({
    byGrp: _actGrpReducer,
    ..._areasReducers,
})