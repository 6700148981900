/* eslint-disable default-case */
import produce, {original} from 'immer'
import * as actTypes from '../actions/actionTypes'

export default produce((draft, action) => {

    switch (action.type) {

        case actTypes.SET_USR_JOINED_CUSTS:

            // Step1: make sure that the user is joined with all customers set in action
            const _byActJoinedCusts={}
            if(action.custIds){
                for (const _custId of action.custIds) {
                    _byActJoinedCusts[_custId]=true

                    let _curCustUsrs = draft.byId[_custId]
                    if(!_curCustUsrs){
                        _curCustUsrs={}
                    }

                    if(!_curCustUsrs[action.usrId]){
                        _curCustUsrs[action.usrId]=true
                        draft.byId[_custId]=_curCustUsrs
                    }
                }
            }

            // Step2: if user was joined to other customers as in action specified remove those relations
            for (const _custId of Object.keys(original(draft.byId))) {

                if(!draft.byId[_custId][action.usrId]){ // user not joined this cust -> nothing to do
                    continue
                }
                if(_byActJoinedCusts[_custId]){ // was joined, is still joined -> nothing to do
                    continue
                }

                //user was joined AND is not joined any more! -> remove the relation
                delete draft.byId[_custId][action.usrId]
            }
            break;

        case actTypes.ASSIGN_CUST_USRS:

            let _curVers = draft.byId[action.custId]
            if(!_curVers){ // custId not yet in store
                _curVers={}
            }

            action.usrIds && action.usrIds.forEach(id => {
                _curVers[id]=true
            })

            draft.byId[action.custId]=_curVers
            break

        case actTypes.DETACH_CUST_USRS:

            const _curVers2 = draft.byId[action.custId]
            if(!_curVers2){ // custId not yet in store
                return
            }

            action.usrIds && action.usrIds.forEach(id => {
                delete _curVers2[id]
            })

            draft.byId[action.custId]=_curVers2
            break

        case actTypes.SET_CUST_USRS:

            const _curVers3 = {}

            action.usrIds && action.usrIds.forEach(id => {
                _curVers3[id]=true
            })

            draft.byId[action.custId]=_curVers3
            break
    }
}, {
    byId:{}
})