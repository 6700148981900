import { makeAutoObservable, set, toJS, values, flow, runInAction, has } from "mobx"
import { computedFn } from "mobx-utils"
import rootStore from ".."
import AuditFull from "./AuditFull"

import axios from '../../services/Axios'
import { BugCrash } from "../../services/BugCrash"
import { isTimestampOldErr } from "../../libs/actErrors"

import AuditAnswer from "./AuditAnswer"
import { getUuid } from "../../services/IdGenerator"

import get from 'lodash/get'
export default class Audit {

    auditsStore = null

    id
    tntId
    compact = null
    full = null

    timestamp = 0

    isEditing = false
    isNew = false
    isSaving = false

    constructor(auditsStore) {
        makeAutoObservable(this, { auditsStore: false })

        this.auditsStore = auditsStore
    }

    setIsEditing = (val) => {
        this.isEditing = val
    }

    setIsNew = (val) => {
        this.isNew = val
    }

    setTimestamp = (val) => {
        this.timestamp = val
    }

    updateFromLight = (light) => {
        //console.log('Audit::updateFromLight', light)

        if (!this.id) {
            this.id = light.id
        }
        if (!this.full) {
            this.full = new AuditFull(this)
        }
        this.full.updateFromLight(light)
    }

    updateFromJson = (json) => {
        //console.log('Audit::updateFromJson', json)

        if (this.timestamp && json.timestamp && (this.timestamp >= json.timestamp)) {
            return
        }

        for (const fld of ['id', 'timestamp', 'compact']) {
            set(this, fld, json[fld])
        }

        this.full = new AuditFull(this)
        this.full.updateFromJson(json.full)
    }

    getQcVers = () => {

        if (!this.full) {
            return null
        }

        return rootStore.qcVersionsStore.getByQcAndVersNb(this.full.questionnaireCatalogueID, this.full.questionnaireCatalogueType, this.full.usedQuestionniareVersion)
    }

    get sortedCategories() {

        if (!this.full || !this.full.excludedCategoryIDs) {
            return []
        }

        const _qcVers = this.getQcVers()
        if (!_qcVers) {
            return []
        }

        const _allVersSortedCategs = _qcVers.sortedCategories
        if (!_allVersSortedCategs) {
            return []
        }

        return _allVersSortedCategs.filter(cat => !has(this.full.excludedCategoryIDs, cat.id))
    }

    get isDeleted() {
        return (get(this, ['full', 'deleted'], 0) > 0)
    }

    requireAnswerByQuestId = computedFn((questId) => {

        if (!this.full || !this.full.answers) {
            return null
        }

        for (const _answ of values(this.full.answers)) {
            if (_answ.questionID === questId) {
                return _answ
            }
        }

        const _qcVers = this.getQcVers()
        if (!_qcVers) {
            return null
        }

        const _qcQuest = _qcVers && _qcVers.getQuestionById(questId)
        if (!_qcQuest) {
            return null
        }

        const _answId = getUuid()
        const _answer = new AuditAnswer()

        runInAction(() => {

            const actions = {}
            values(_qcQuest.actions).forEach(act => {
                const { name, sortID } = toJS(act)
                const id = getUuid()
                actions[id] = { id, name, sortID }
            })

            _answer.updateFromJson({
                id: _answId,
                questionID: questId,
                actions
            })

            _answer.setIsNew(true)
            set(this.full.answers, _answId, _answer)
        })

        return _answer
    })

    save = flow(function* (prjId, tntId) {

        this.isSaving = true

        const taskId = rootStore.workProgressStore.startPushing(`save audit prjId=${prjId}, tntId=${tntId}, auditId=${this.id}`)

        try {
            //TODO: topics-basic-care als auditArt... Move to store??
            const { status } = yield axios.post(`/projects/${prjId}/tenants/${tntId}/audits/topics-basic-care/${this.id}`, {
                timestampBefore: this.timestamp,
                full: this.full.toJson()
            })

            if (status === 200) {
                return yield this.auditsStore.fetchOne(prjId, tntId, this.id)
            }
        }
        catch (err) {

            const _newTs = isTimestampOldErr(err)
            if (_newTs) {
                rootStore.oldTsStore.addConflict(this.full.title, this.timestamp, _newTs, (newTs) => {
                    (async () => {
                        this.setTimestamp(newTs)
                        await this.save(prjId, tntId)
                    })()
                }, () => {
                    (async () => {
                        await this.auditsStore.fetchOne(prjId, tntId, this.id, true)
                    })()
                }, null)
            }
            else {
                BugCrash.notifyExtra(err, { prjId, tntId: this.tntId, auditId: this.id })
            }
        }
        finally {
            this.isSaving = false
            rootStore.workProgressStore.donePushing(taskId)
        }

        return false
    })

    get dirty() {
        return this.full && this.full.dirty
    }
}