/* eslint-disable default-case */
import produce, {original} from 'immer'
import * as actTypes from '../actions/actionTypes'

const _handleSetCustPrjs = (draft, action) => {

    const _prjIdsSavedByAct = {}

    // Step1: make sure that all projects from action are linked with the customer
    let _curVersSet = draft.byId[action.custId]
    if(!_curVersSet){
        _curVersSet = {}
        draft.byId[action.custId]=_curVersSet
    }

    action.prjIds && action.prjIds.forEach(id => {
        _prjIdsSavedByAct[id]=true

        if(!_curVersSet[id]){ //current project id not already linked with the customer
            _curVersSet[id]=true
        }
    })

    // Step2: if there were some more projects linked to cust, and they are not any more -> we need to remove those relations
    const _origState = original(draft.byId)
    if(_origState){
        const _origCustState=_origState[action.custId]
        
        _origCustState && Object.keys(_origCustState).forEach(prjId => {
            if(_prjIdsSavedByAct[prjId]){ //prject is still linked by the current action -> nothing to do
                return
            }

            delete _curVersSet[prjId]
        })
    }
}

const _handleAssignCustPrjs = (draft, action) => {

    let _curVersion = draft.byId[action.custId]
    if(!_curVersion){
        _curVersion = {}
        draft.byId[action.custId]=_curVersion
    }

    action.prjIds && action.prjIds.forEach(id => {
        if(!_curVersion[id]){
            _curVersion[id]=true
        }
    })
}

const _handleDetachCustPrjs = (draft, action) => {

    const _curVersDetach = draft.byId[action.custId]
    if(!_curVersDetach){
        return
    }

    action.prjIds && action.prjIds.forEach(id => {
        if(_curVersDetach[id]){
            delete _curVersDetach[id]
        }
    })

}

export const byAuthUsrRights = produce((draft, action) => {

    switch (action.type) {

        case actTypes.SET_CUST_PRJS_BY_AUTH_USR:
            _handleSetCustPrjs(draft, action)
            break

        case actTypes.ASSIGN_CUST_PRJS_BY_AUTH_USR:
            _handleAssignCustPrjs(draft, action)
            break

        case actTypes.DETACH_CUST_PRJS_BY_AUTH_USR:
            _handleDetachCustPrjs(draft, action)
            break
    }

}, {
    byId:{}
})

export const byAdmin = produce((draft, action) => {

    switch (action.type) {

        case actTypes.SET_CUST_PRJS_BY_ADM:
            _handleSetCustPrjs(draft, action)
            break;

        case actTypes.ASSIGN_CUST_PRJS_BY_ADM:
            _handleAssignCustPrjs(draft, action)
            break

        case actTypes.DETACH_CUST_PRJS_BY_ADM:
            _handleDetachCustPrjs(draft, action)
            break
    }
}, {
    byId:{}
})