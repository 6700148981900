import * as actTypes from '../actions/actionTypes'
import { BugCrash } from '../../services/BugCrash'

import Axios from '../../services/Axios'
import { lbl } from '../../lbls'
import { registerFeedback } from './feedbackActions';
import { lgDebug } from '../../utils/logging';
import { discardChgs } from './changesActions';
import { actStarted, actReadyOk, actReadyPb } from './actionsActions';
import { actionsAreas, actionsActs } from '../reducers/actionsReducer';

import get from 'lodash/get'

export const loadAuthUserData = () => {
    return async (dispatch, getState) => {

        try {
            dispatch({ type: actTypes.LOAD_AUTH_USER_DATA_STARTED })

            const resp = await Axios.get(`/my/user-data`)
            if (resp && (resp.status === 200)) {

                const uid = get(resp, ['data', 'id'])
                if (uid) {
                    BugCrash.setUsrId(uid)
                }

                return dispatch({ type: actTypes.LOAD_AUTH_USER_DATA_READY_OK, data: resp.data })
            }

            throw new Error(lbl.err_load_user_data)
        }
        catch (err) {
            BugCrash.notify(err)
            dispatch({ type: actTypes.LOAD_AUTH_USER_DATA_READY_PB, err })
        }
    }
}

export const loadCustUsers = (custId, actGrp) => {
    return async (dispatch, getState) => {

        const _actTgt = `load-users-of-cust-${custId}`

        try {
            dispatch(actStarted(actionsAreas.usrs, actGrp, _actTgt, actionsActs.load))

            const resp = await Axios.get(`/customers/${custId}/users`)
            if (resp && (resp.status === 200)) {

                const _usrs = []
                const _usrIds = []

                if (resp.data.list) {
                    for (const _usr of resp.data.list) {
                        _usrs.push(_usr)
                        _usrIds.push(_usr.id)
                    }
                }

                dispatch({ type: actTypes.STORE_UPSERT_USERS, custId, items: _usrs })
                dispatch({ type: actTypes.SET_CUST_USRS, custId, usrIds: _usrIds })
                dispatch(actReadyOk(actionsAreas.usrs, actGrp, _actTgt, actionsActs.load))
                return
            }

            throw new Error(lbl.err_load_cust_users)
        }
        catch (err) {
            BugCrash.notify(err)
            dispatch(actReadyPb(actionsAreas.usrs, actGrp, _actTgt, actionsActs.load, err))
        }
    }
}

export const loadCustAdmins = (custId, actGrp) => {
    return async (dispatch, getState) => {

        const _actTgt = `load-admins-of-cust-${custId}`

        try {
            dispatch(actStarted(actionsAreas.usrs, actGrp, _actTgt, actionsActs.load))

            const resp = await Axios.get(`/customers/${custId}/admins`)
            if (resp && (resp.status === 200)) {

                const _usrs = []
                const _usrIds = []

                if (resp.data.list) {
                    for (const _usr of resp.data.list) {
                        _usrs.push(_usr)
                        _usrIds.push(_usr.id)
                    }
                }

                dispatch({ type: actTypes.STORE_UPSERT_USERS, custId, items: _usrs })
                dispatch({ type: actTypes.ASSIGN_CUST_USRS, custId, usrIds: _usrIds })
                dispatch({ type: actTypes.SET_CUST_ADMS, custId, admIds: _usrIds })

                dispatch(actReadyOk(actionsAreas.usrs, actGrp, _actTgt, actionsActs.load))
                return
            }

            throw new Error(lbl.err_load_cust_users)
        }
        catch (err) {
            BugCrash.notify(err)
            dispatch(actReadyPb(actionsAreas.usrs, actGrp, _actTgt, actionsActs.load, err))
        }
    }
}

export const loadCustUsrData = (custId, usrId, actGrp) => {
    return async (dispatch, getState) => {

        const _actTgt = `load-cust-${custId}-usr-${usrId}-data`

        try {
            dispatch(actStarted(actionsAreas.usrs, actGrp, _actTgt, actionsActs.load))

            const resp = await Axios.get(`/users/{usrId}/cust-data/{custId}`)
            if (resp && (resp.status === 200)) {

                const _usrData = {
                    [usrId]: {
                        ...resp.data
                    }
                }

                dispatch({ type: actTypes.ASSIGN_CUST_USRS, custId, usrIds: [usrId] })
                dispatch({ type: actTypes.ADD_CUST_USRS_DATA, custId, usrsData: _usrData })
                dispatch(actReadyOk(actionsAreas.usrs, actGrp, _actTgt, actionsActs.load))
                return
            }

            throw new Error(lbl.err_load_cust_users)
        }
        catch (err) {
            BugCrash.notify(err)
            dispatch(actReadyPb(actionsAreas.usrs, actGrp, _actTgt, actionsActs.load, err))
        }
    }
}

export const addUsr = (custId, usrId) => {
    return async (dispatch, getState) => {
        dispatch({ type: actTypes.STORE_UPSERT_USERS, custId, items: [{ id: usrId, new: true }] })
        dispatch({ type: actTypes.ASSIGN_CUST_USRS, custId, usrIds: [usrId] })
    }
}

export const saveCustUser = (custId, usrId, usrInfo, changes, actGrp) => {
    return async (dispatch, getState) => {

        const _actTgt = `save-user-${usrId}-in-cust-${custId}`

        try {
            if (!changes.admin) {
                lgDebug(`Keine Änderung erkannt in den Daten vom Benutzer: ${usrId} ${usrInfo}`)
                return
            }

            dispatch(actStarted(actionsAreas.usrs, actGrp, _actTgt, actionsActs.save))

            let resp;
            if (changes.admin.val) {
                resp = await Axios.post(`/customers/${custId}/admins/${usrId}`)
            }
            else {
                resp = await Axios.delete(`/customers/${custId}/admins/${usrId}`)
            }

            if (resp && (resp.status === 200)) {

                dispatch(loadCustAdmins(custId, actGrp))
                dispatch(discardChgs(`c:${custId}-u:${usrId}`))
                dispatch(registerFeedback(lbl.feedback_save_cust_usr_ok.replace('%usr', usrInfo), false))

                dispatch(actReadyOk(actionsAreas.usrs, actGrp, _actTgt, actionsActs.save))

                return
            }

            throw new Error(lbl.err_save_cust_usr)
        }
        catch (err) {
            BugCrash.notify(err)
            dispatch(actReadyPb(actionsAreas.usrs, actGrp, _actTgt, actionsActs.save, err))
        }
    }
}

export const delCustUser = (custId, usrId, usrInfo, afterDelOk, actGrp) => {
    return async (dispatch, getState) => {

        const _actTgt = `del-user-${usrId}-from-cust-${custId}`

        try {
            dispatch(actStarted(actionsAreas.usrs, actGrp, _actTgt, actionsActs.del))

            const resp = await Axios.delete(`/customers/${custId}/users/${usrId}`)
            if (resp && (resp.status === 200)) {

                dispatch(registerFeedback(lbl.feedback_del_cust_usr_ok.replace('%usr', usrInfo), false))

                dispatch(loadCustUsers(custId, actGrp))
                dispatch(loadCustAdmins(custId, actGrp))
                dispatch(actReadyOk(actionsAreas.usrs, actGrp, _actTgt, actionsActs.del))

                setTimeout(
                    () => { afterDelOk() },
                    50
                )

                return
            }

            throw new Error(lbl.err_del_cust_user)
        }
        catch (err) {
            //console.log(err)
            BugCrash.notify(err)
            dispatch(actReadyPb(actionsAreas.usrs, actGrp, _actTgt, actionsActs.del, err))
        }
    }
}

export const newCustUser = (custId, changes, afterSaveOk, actGrp) => {
    return async (dispatch, getState) => {

        const _actTgt = `new-user-in-cust-${custId}`

        try {
            if (!changes.new_email || !changes.new_email.val) {
                lgDebug(`newCustUser: Keine E-Mail konfiguriert! custId: {custId}`)
                return
            }

            dispatch(actStarted(actionsAreas.usrs, actGrp, _actTgt, actionsActs.save))

            const resp = await Axios.post(`/customers/${custId}/users/new-by-email`, {
                email: changes.new_email.val,
                name: (changes.name && changes.name.val) || '',
            })

            if (resp && (resp.status === 200)) {

                dispatch(registerFeedback(lbl.feedback_new_cust_usr_ok.replace('%usr', changes.new_email.val), false))
                dispatch(loadCustUsers(custId, actGrp))
                dispatch(discardChgs(`c:${custId}-u:new`))
                dispatch(actReadyOk(actionsAreas.usrs, actGrp, _actTgt, actionsActs.save))

                if (afterSaveOk) {
                    setTimeout(
                        () => { afterSaveOk(resp.data.uid) },
                        50
                    )
                }
                return
            }

            throw new Error(lbl.err_new_cust_usr.replace('%usr', changes.new_email.val))
        }
        catch (err) {
            BugCrash.notify(err)
            dispatch(actReadyPb(actionsAreas.usrs, actGrp, _actTgt, actionsActs.save, err))
        }
    }
}