import { makeAutoObservable, flow } from "mobx"
import { BugCrash } from "../../services/BugCrash"
import axios from '../../services/Axios'
import { isNumber } from "lodash"

//import { myDxDefaultPageSize } from "../../config/const"
export default class UiQcSysModStore {

    rootStore

    search = ''
    page = 1
    pageSize = 20 //myDxDefaultPageSize

    total = 0
    lng = 'de'

    ids = []

    isLoading = false

    constructor(rootStore) {
        makeAutoObservable(this, { rootStore: false })

        this.rootStore = rootStore
    }

    chgLng = (val) => {
        if (this.lng !== val) {
            this.search = ''
            this.lng = val
            this.page = 1
            this.load()
        }
    }

    setSearch = (val) => {
        if (this.search !== val) {
            this.search = val
            this.page = 1
            this.load()
        }
    }

    setPage = (val) => {

        if (!isNumber(val) || (val < 1)) {
            return this.setPage(1)
        }
        if (this.page !== val) {
            this.page = val
            this.load()
        }
    }

    setTotal = (val) => {

        if (!isNumber(val) || (val < 1)) {
            return this.setPage(1)
        }
        if (this.total !== val) {
            this.total = val
        }
    }

    start = () => {

        this.page = 1
        this.total = 0
        this.ids = []

        this.load()
    }

    stop = () => {
        this.page = 1
        this.total = 0
        this.ids = []
    }

    load = flow(function* () {

        this.isLoading = true

        const taskId = this.rootStore.workProgressStore.startLoading(`Loading qc-sys light`)
        const _qcStore = this.rootStore.qcStore

        try {
            const { status, data } = yield axios.get(`/questionnaires/light?lng=${this.lng}&page=${this.page}&maxPerPage=${this.pageSize}&search=` + encodeURIComponent(this.search))

            if (status === 200) {
                this.total = data.total
                this.ids = data.list.map(itm => itm.id)
            }

            data.list.forEach(itm => {
                _qcStore.upsertFromJson(itm, 'S', false)
            })

            return true
        }
        catch (err) {
            //TODO: Show error!!
            BugCrash.notifyExtra(err, {})
        }
        finally {
            this.isLoading = false
            this.rootStore.workProgressStore.doneLoading(taskId)
        }

        return false
    })

    addId = (id) => {
        this.ids.push(id)
    }
}