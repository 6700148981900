import { makeAutoObservable, set, toJS } from "mobx"

export default class AuditAction {

    answer

    id = ''
    name = {}
    prefix = ''
    priority = 0
    sortID = -1
    toDoUntil = null
    type = 0
    isSelected = false

    isDirty = false

    constructor(answer) {
        makeAutoObservable(this, { answer: false })

        this.answer = answer
    }

    updateFromJson = (json) => {
        //console.log('AuditAction::updateFromJson', json)

        for (const fld of ['id', 'name', 'prefix', 'priority', 'sortID', 'toDoUntil', 'type', 'isSelected']) {
            set(this, fld, json[fld])
        }
    }

    toJson = () => {

        const _ret = {}
        for (const fld of ['id', 'name', 'prefix', 'priority', 'sortID', 'toDoUntil', 'type', 'isSelected']) {
            _ret[fld] = toJS(this[fld])
        }
        return _ret
    }

    setIsDirty = (val) => {
        if (this.isDirty !== val) {
            this.isDirty = val
            this.answer.setIsDirty(val)
        }
    }
}