import audits from '../components/audits-v2/routing'
import qc from '../components/admin/qc/routing'
import admTnts from '../components/admin/tenants-v3/routing'
import perfConf from '../components/perf-conf-v2/routing'
import meetings from '../components/meetings-v3/routing'

const exp = {
    audits,
    qc,
    admTnts,
    perfConf,
    meetings,
}

export default exp