import { generatePath } from "react-router"

const _main = '/p/:prjId/t/:tntId/audits/:auditArt'
const _det = `${_main}/det/:auditId`
const _area = `${_det}/a/:area`
const _evaluation = `${_area}/eval`
const _evalQuest = `${_evaluation}/q/:quest`
const _evalQuestArea = `${_evalQuest}/qarea/:qarea`
const _evalQuestAct = `${_evaluation}/q/:quest/qarea/:qarea/act/:act`

const nue = (v) => {
    if (!v) {
        return '-'
    }

    return v
}

const routes = {
    main: _main,
    det: _det,
    area: _area,
    evaluation: _evaluation,
    evalQuest: _evalQuest,
    evalQuestArea: _evalQuestArea,
    evalQuestAct: _evalQuestAct,

    url: {
        main: (prjId, tntId, auditArt) => generatePath(_main, { prjId: nue(prjId), tntId: nue(tntId), auditArt: nue(auditArt) }),
        det: (prjId, tntId, auditArt, auditId) => generatePath(_det, { prjId: nue(prjId), tntId: nue(tntId), auditArt: nue(auditArt), auditId: nue(auditId) }),
        area: (prjId, tntId, auditArt, auditId, area) => generatePath(_area, { prjId: nue(prjId), tntId: nue(tntId), auditArt: nue(auditArt), auditId: nue(auditId), area: nue(area) }),
        evaluation: (prjId, tntId, auditArt, auditId, area) => generatePath(_evaluation, { prjId: nue(prjId), tntId: nue(tntId), auditArt: nue(auditArt), auditId: nue(auditId), area: nue(area) }),
        evalQuest: (prjId, tntId, auditArt, auditId, area, quest) => generatePath(_evalQuest, { prjId: nue(prjId), tntId: nue(tntId), auditArt: nue(auditArt), auditId: nue(auditId), area: nue(area), quest: nue(quest) }),
        evalQuestArea: (prjId, tntId, auditArt, auditId, area, quest, qarea) => generatePath(_evalQuestArea, { prjId: nue(prjId), tntId: nue(tntId), auditArt: nue(auditArt), auditId: nue(auditId), area: nue(area), quest: nue(quest), qarea: nue(qarea) }),
        evalQuestAct: (prjId, tntId, auditArt, auditId, area, quest, qarea, act) => generatePath(_evalQuestAct, { prjId: nue(prjId), tntId: nue(tntId), auditArt: nue(auditArt), auditId: nue(auditId), area: nue(area), quest: nue(quest), qarea: nue(qarea), act: nue(act) }),
    }
}

export default routes