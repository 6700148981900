import React, { useMemo, useCallback } from 'react'
import { MODULES } from '../../config/const'
import { lbl } from '../../lbls'
import { lgWarn } from '../../utils/logging'
import Image from 'react-bootstrap/Image'

function Mod({ modId, prjPermissions, disabled, startModule, sa, custAdm }) {

    const _mod = useMemo(() => {
        return MODULES[modId]
    }, [modId])

    const _disabledModule = useCallback((modId) => {
        console.log(`Module id=${modId} is disabled!`)
    }, [])

    const _hasPerm = useMemo(() => {

        if (!_mod) {
            return false
        }

        if (custAdm && _mod.permissions.custAdm) { //auth user is cust-admin, and module is free for cust-admins even with no other rights set
            return true
        }
        if (sa && _mod.permissions.sa) { //auth user is sa, and module is free for sa with no other rights set
            return true
        }

        for (const permSetKey of ['ro', 'rw']) {
            const _permSet = _mod.permissions[permSetKey]
            if (!_permSet || (_permSet.length === 0)) {
                return true
            }

            for (const _perm of _permSet) {
                if (prjPermissions && prjPermissions[_perm]) {
                    return true
                }
            }
        }

        return false

    }, [prjPermissions, _mod, sa, custAdm])

    const _onClick = useCallback((evnt) => {
        if (disabled) {
            return _disabledModule(modId)
        }

        startModule(_mod)
    }, [modId, _mod, startModule, _disabledModule, disabled])

    if (!_mod) {
        lgWarn(`Module ${modId} unknown...`)
        return null
    }

    if (_mod.hidden) {
        return null
    }

    if (!_hasPerm) {
        return null
    }

    const _moduleCss = (disabled) ? 'dash-module mb-3 mr-3 disabled-module' : 'dash-module mb-3 mr-3'

    return <div className={_moduleCss} onClick={_onClick}>
        <Image src={_mod.img} thumbnail rounded alt={_mod.name || lbl[modId]} className="dash-modImg" />
        <div className="dash-mod-title mt-1 text-center text-truncate">{_mod.name || lbl[modId]}</div>
        {_mod.desc && <div className="dash-mod-desc text-center">{_mod.desc}</div>}
    </div>
}

export default React.memo(Mod)