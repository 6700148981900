import * as actTypes from '../actions/actionTypes'
import { BugCrash } from '../../services/BugCrash'

import Axios from '../../services/Axios'
import { lbl } from '../../lbls'
import { actStarted, actReadyOk, actReadyPb } from './actionsActions';
import { actionsAreas, actionsActs } from '../reducers/actionsReducer';
import { registerFeedback } from './feedbackActions';

export const saveRole = (role, actGrp) => {
    return async (dispatch, getState) => {

        const _actTgt = `save-role-${role.id}`

        try {
            const _state = await getState()
            const _curVers = _state.roles.byId[role.id]

            const name = role.name || (_curVers && _curVers.name) || ''
            const desc = role.desc || (_curVers && _curVers.desc) || ''
            const permissions = role.permissions || (_curVers && _curVers.permissions) || {}

            dispatch(actStarted(actionsAreas.roles, actGrp, _actTgt, actionsActs.save))

            const resp = await Axios.post(`/roles/${role.id}`, {
                name,
                desc,
                permissions,
            })

            if (resp.status === 200) { // saved ok
                dispatch(loadRoles(actGrp))
                dispatch(registerFeedback(lbl.feedback_save_rolle.replace('%title', name), false))
                dispatch(actReadyOk(actionsAreas.roles, actGrp, _actTgt, actionsActs.save))
                return
            }

            throw new Error(lbl.err_save_role)
        }
        catch (err) {
            BugCrash.notify(err)
            dispatch(actReadyPb(actionsAreas.roles, actGrp, _actTgt, actionsActs.save, err))
        }
    }
}

export const loadRoles = (actGrp) => {
    return async (dispatch, getState) => {

        const _actTgt = `load-roles`

        try {
            dispatch(actStarted(actionsAreas.roles, actGrp, _actTgt, actionsActs.load))

            const resp = await Axios.get(`/roles`)
            if (resp && (resp.status === 200)) {

                const _rolesById = {}

                if (resp.data.list) {
                    for (const _itm of resp.data.list) {
                        const _rolle = { id: _itm.id, name: _itm.name, desc: _itm.desc, permById: {} }

                        for (const _perm of _itm.permissions) {
                            _rolle.permById[_perm] = true
                        }

                        _rolesById[_rolle.id] = _rolle
                    }
                }

                dispatch({ type: actTypes.SET_ROLES, roles: _rolesById })
                dispatch(actReadyOk(actionsAreas.roles, actGrp, _actTgt, actionsActs.load))

                return
            }

            throw new Error(lbl.err_load_roles)
        }
        catch (err) {
            BugCrash.notify(err)
            dispatch(actReadyPb(actionsAreas.roles, actGrp, _actTgt, actionsActs.load, err))
        }
    }
}