import rootStore from ".."

const FETCH_ROLES_INTV = 10 * 60 * 1000
let rolesFetcherIntv

export const startRolesFetcherCron = () => {

    //console.debug(`START ROLES-FETCHER CRON [every ${FETCH_ROLES_INTV / 1000}s]`)

    rolesFetcherIntv = setInterval(() => {
        rootStore.rolesStore.fetchRoles()
    }, FETCH_ROLES_INTV)
}

export const stopRolesFetcherCron = () => {

    if (rolesFetcherIntv) {
        //console.debug(`STOP ROLES-FETCHER CRON`)

        clearInterval(rolesFetcherIntv)
        rolesFetcherIntv = null
    }
}