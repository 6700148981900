//export const API_BASE_URL = 'https://api.inorisk.cloud/api/'
//export const API_BASE_URL = 'https://inorisk-eu.appspot.com/api/'

export const API_BASE_URL = 'https://inorisk-de.appspot.com/api/'
//export const API_BASE_URL = 'http://localhost:8080/api/'

//export const API_NET_BASE_URL = 'https://norisk-api-3m5u5v4eaq-ew.a.run.app' //BELGIEN!
export const API_NET_BASE_URL = 'https://norisk-net-api-fra-3m5u5v4eaq-ey.a.run.app' // FRANKFURT

//export const REPORTING_BASE_URL = 'https://europe-west1-inorisk-de.cloudfunctions.net/groupReports-rep/'
export const REPORTING_BASE_URL_FRA = 'https://europe-west3-inorisk-de.cloudfunctions.net/groupReports-repV2/'

// https://europe-west1-inorisk-de.cloudfunctions.net/groupReports-rep/projects/${projId}/tenants/${tntId}/perform-confirm/${itmId}?lng=${lng}
//https://europe-west1-inorisk-de.cloudfunctions.net/groupReports-rep/projects/4281821d-e614-4312-a693-899c210c6d25/tenants/4f7adddc-00e7-4ea3-8fc8-80e170f45ae6/doc-prot/meetings/31f88382-5d36-4891-88a1-cdd2dc369530?lng=${lng}
// https://europe-west3-inorisk-de.cloudfunctions.net/groupReports-repV2/projects/78779694-ec86-439f-[…]t/meetings/5fc24bd4-cb06-4c71-bddd-497a5feff72b