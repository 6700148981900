/* eslint-disable default-case */
import produce, {original} from 'immer'
import * as actTypes from '../actions/actionTypes'

export default produce((draft, action) => {

    switch (action.type) {

        case actTypes.SET_PRJ_ROOTS:

            let _curPrjRoots = draft.byId[action.prjId]
            if(!_curPrjRoots){ // prjId not yet in store
                _curPrjRoots={}
                draft.byId[action.prjId]=_curPrjRoots
            }

            const _tntIdsByAct = {}

            // Step1: make sure that all tnts from action are set in _curPrjRoots
            action.tntIds && action.tntIds.forEach(tntId => {
                _tntIdsByAct[tntId]=true

                if(!_curPrjRoots[tntId]){
                    _curPrjRoots[tntId]=true
                }
            })

            const _origPrjState = original(draft.byId[action.prjId])

            // Step2: if _curPrjTnts contains also other tenants als in action specified, those relations must be deleted
            _origPrjState && Object.keys(_origPrjState).forEach(tntId => {
                if(_tntIdsByAct[tntId]){ // tntId is still there -> nothing to do
                    return
                }

                if(_curPrjRoots[tntId]){
                    delete _curPrjRoots[tntId]
                }
            })
            break
    }
}, {
    byId:{}
})
