/* eslint-disable default-case */
import produce from 'immer'
import * as actTypes from '../actions/actionTypes'

export default produce((draft, action) => {

    switch (action.type) {

        case actTypes.SET_LAST_LOAD_TIME:
            draft[action.key]=action.time
            break
    }
}, {
    roles:0, 
    myCust:0,
    authUserData:0,
})