import { makeAutoObservable, set, toJS } from "mobx"
import { getUuid } from "../../services/IdGenerator"

export default class QcRegulation {

    quest

    id = ''
    sortID = -1
    name = {}

    isDirty = false

    constructor(quest) {
        makeAutoObservable(this, { quest: false })

        this.quest = quest
    }

    updateFromJson = (json, cloneIds) => {
        //console.log('QcRegulation::', json)

        for (const fld of ['id', 'sortID', 'name']) {
            set(this, fld, json[fld])
        }

        if (cloneIds) {
            if (this.name) {
                this.name.id = getUuid()
            }
        }
    }

    get srvJson() {

        const _json = {}

        for (const _fld of ['id', 'sortID', 'name']) {
            _json[_fld] = toJS(this[_fld])
        }

        if (_json.name && !_json.name.id) {
            _json.name.id = getUuid()
        }

        return _json
    }

    get dirty() {

        if (this.isDirty) {
            return true
        }

        return false
    }

    setSortID = (val) => {
        this.sortID = val
        this.isDirty = true
    }

    setName = (lng, val) => {
        set(this.name, lng, val)
        this.isDirty = true
    }
}