import { makeAutoObservable, set, toJS, values } from "mobx"

export default class MeetingFull {

    meeting

    title = ''

    answers = {}
    images = {}
    excludedCategoryIDs = {}
    participantIDs = {}
    departmentIDs = {}

    creationDate = 0
    date = 0

    isDirty = false

    constructor(meeting) {
        makeAutoObservable(this, { meeting: false })

        this.meeting = meeting
    }

    toJson = () => {

        const _ret = {
            answers: {}
        }

        for (const fld of ['title', 'departmentIDs', 'participantIDs', 'images', 'creationDate', 'date', 'excludedCategoryIDs']) {
            _ret[fld] = toJS(this[fld])
        }

        /*
        values(this.answers).forEach(answ => {
            if (answ.isNew && !answ.isDirty) {
                return
            }

            _ret.answers[answ.id] = answ.toJson()
        })*/

        return _ret
    }

    updateFromLight = (light) => {
        //console.log('PerfConfFull::updateFromLight', light)

        set(this, 'title', light['title'])
        set(this, 'date', light['date'])
        set(this, 'answeredProgress', light['answeredProgress'])
    }

    updateFromJson = (json) => {
        //console.log('PerfConfFull::updateFromJson', json)

        /*
        const _answers = {}
        json.answers && Object.values(json.answers).forEach(itm => {
            const _answ = new AuditAnswer(this)
            _answ.updateFromJson(itm)
            _answers[_answ.id] = _answ
        })
        set(this.answers, _answers)
        */

        for (const fld of ['title', 'date', 'departmentIDs', 'participantIDs', 'images', 'creationDate', 'date', 'excludedCategoryIDs']) {
            set(this, fld, json[fld])
        }
    }

    setTitle = (val) => {
        this.title = val
        this.isDirty = true
    }

    setDate = (val) => {
        this.date = val
        this.isDirty = true
    }

    get dirty() {

        if (this.isDirty) {
            return true
        }

        for (const _answ of values(this.answers)) {
            if (_answ.isDirty) {
                return true
            }
        }

        return false
    }
}