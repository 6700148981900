import format from 'date-fns/format'
import get from 'lodash/get'
import set from 'lodash/set'
import findIndex from 'lodash/findIndex'

import { getUuid } from '../services/IdGenerator'
import moment from 'moment'

const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const transformTs4Server = (obj, tsKeys) => {

    const ret = { ...obj }

    tsKeys && tsKeys.forEach(key => {
        if (obj.hasOwnProperty(key)) {
            ret[key] = obj[key] / 1000
        }
    })

    return ret
}

const transformTs4Client = (obj, tsKeys) => {

    const ret = { ...obj }

    tsKeys && tsKeys.forEach(key => {
        if (obj.hasOwnProperty(key)) {
            ret[key] = obj[key] * 1000
        }
    })

    return ret
}

const getDateFmtByLng = (lng) => {

    return 'dd.MM.yyyy'
}

const fmtDate = (ts, lng) => {

    if (!ts) {
        return ''
    }

    return format(ts, getDateFmtByLng(lng))
}

const requireId = (obj, path) => {

    if (!get(obj, path)) {
        set(obj, path, getUuid())
    }
}

const languages = ['de', 'en']

const getLngIdxByKey = (lng) => {
    return findIndex(languages, L => (L === lng))
}

const selectTimestamp = (tsObj, tsConflict) => {

    if (tsObj && tsConflict) {
        return Math.max(tsObj, tsConflict)
    }
    else if (tsObj) {
        return tsObj
    }
    else if (tsConflict) {
        return tsConflict
    }
    else {
        return 0
    }
}

const dispatchMergeEntities = (dispatch, path, value) => {
    const _action = { type: 'MERGE_ENTITIES', entities: {} }
    set(_action.entities, path, value)
    dispatch(_action)
}

const getCurTimestamp = () => {
    return Math.floor(Date.now() / 1000)
}

const getMomentFmtByLng = (lng, full) => {

    if (lng === 'en') {
        if (full) {
            return 'MM/DD/YYYY HH:mm:ss'
        }

        return 'MM/DD/YYYY'
    }

    if (full) {
        return "DD.MM.YYYY HH:mm:ss"
    }

    return "DD.MM.YYYY"
}

const fmtUnixTsByLng = (ts, lng) => {
    return moment.unix(ts).format(getMomentFmtByLng(lng))
}

const fmtUnixTsByLngFull = (ts, lng) => {
    return moment.unix(ts).format(getMomentFmtByLng(lng, true))
}


const sortableNow = () => {
    return moment().format('YYYYMMDDHHmmss')
}

export {
    sleep,
    transformTs4Server,
    transformTs4Client,
    fmtDate,
    requireId,
    languages,
    getLngIdxByKey,
    selectTimestamp,
    getDateFmtByLng,
    dispatchMergeEntities,
    getCurTimestamp,

    fmtUnixTsByLng,
    getMomentFmtByLng,
    fmtUnixTsByLngFull,

    sortableNow,
}