/* eslint-disable default-case */
import produce, {original} from 'immer'
import * as actTypes from '../actions/actionTypes'

const _setUsrPrjsRoles = (draft, usrId, prjsRoles) => {

    const _origState = original(draft.byId)
    const _setPrjs = {}

    // Step1: set the rights for each project in prjsRoles
    prjsRoles.forEach(({id, roles}) => {
        _setPrjs[id]=true
        _setPrjUsrRoles(draft, id, usrId, roles)
    })

    // Step2: check if there are some other projects (NOT included in prjsRoles) where usrId had some roles. those roles must be deleted
    _origState && Object.keys(_origState).forEach(prjId => {
        if(!_origState[prjId][usrId]){ // there were no roles of usrId inside prjId
            return
        }
        if(_setPrjs[prjId]){ //usrId still has roles for prjId
            return
        }

        if(draft.byId[prjId] && draft.byId[prjId][usrId]){
            delete draft.byId[prjId][usrId]
        }
    })
}

const _setPrjUsrRoles = (draft, prjId, usrId, roles) => {

    let _curPrjState = draft.byId[prjId]
    if(!_curPrjState){
        _curPrjState={}
        draft.byId[prjId]=_curPrjState
    }

    let _curUsrState = _curPrjState[usrId]
    if(!_curUsrState){
        _curUsrState={}
        _curPrjState[usrId]=_curUsrState
    }

    const _setRoles={}

    // Step1: assign all roles to the project-user relation
    roles.forEach(role => {
        _setRoles[role]=true

        if(!_curUsrState[role]){
            _curUsrState[role]=true
        }
    })

    // Step2: check if the project-user relation had before some roles which are not in parameter roles. Those must be deleted
    const _origState = original(draft.byId)
    if(_origState[prjId] && _origState[prjId][usrId]){ // user usrId had some roles for prjId
        Object.keys(_origState[prjId][usrId]).forEach(oldRole => {
            if(!_setRoles[oldRole]){ //oldRole is not included any more in the new collection of roles 
                delete _curUsrState[oldRole]
            }
        })
    }
}

export default produce((draft, action) => {

    switch (action.type) {

        case actTypes.SET_PRJ_USR_ROLES:

            _setPrjUsrRoles(draft, action.prjId, action.usrId, action.roles)
            break 

        case actTypes.SET_USR_PRJS_ROLES:
            _setUsrPrjsRoles(draft, action.usrId, action.prjsRoles)
            break

        case actTypes.SET_PRJ_USRS_ROLES: 

            //TODO: Optimize SET_PRJ_USRS_ROLES the same like for one single user above
            draft.byId[action.prjId] = action.usrsRoles
            break;
    }
}, {
    byId:{}
})