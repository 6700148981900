import { generatePath } from "react-router"

const nue = (v) => {
    if (!v) {
        return '-'
    }

    return v
}

const _main = '/admin/tenants-v3/:custId/from/:fromCustId'
const _det = `${_main}/det/:tntId`
const _area = `${_det}/a/:area`

const routes = {
    main: _main,
    det: _det,
    area: _area,

    url: {
        main: (custId, fromCustId) => generatePath(_main, { custId: nue(custId), fromCustId: nue(fromCustId) }),
        det: (custId, fromCustId, tntId) => generatePath(_det, { custId: nue(custId), fromCustId: nue(fromCustId), tntId: nue(tntId) }),
        area: (custId, fromCustId, tntId, area) => generatePath(_area, { custId: nue(custId), fromCustId: nue(fromCustId), tntId: nue(tntId), area: nue(area) }),
    }
}

export default routes