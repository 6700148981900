import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import App from './App';
//import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux'
import configureStore from './store/setupStore'
import { PersistGate } from 'redux-persist/integration/react'

import "react-datepicker/dist/react-datepicker.css";

import './scss/custom.scss';
import { appVersion, appEnvironment } from './config/const'

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: "https://28999892fb024917ad3333f1bee3e269@sentry.io/1796245",
        release: "inorisk-desk@" + appVersion,
        environment: appEnvironment,
        tracesSampleRate: 0.1,
    })
}

const { store, persistor } = configureStore({});

store.firebaseAuthIsReady.then(() => {

    ReactDOM.render(<Provider store={store}><PersistGate loading={null} persistor={persistor}> <App /></PersistGate></Provider>, document.getElementById('root'));

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    //serviceWorker.unregister();
})