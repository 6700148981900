import createCachedSelector from 're-reselect';

export const paramCustId = createCachedSelector(
    (_, { custId }) => custId,
    (custId) => {
        //console.log(`Recalc paramCustId ${custId}`)
        return custId
    }
)(
    (_, { custId }) => custId
)

export const paramPrjId = createCachedSelector(
    (_, { prjId }) => prjId,
    (prjId) => {
        //console.log(`Recalc paramPrjId ${prjId}`)
        return prjId
    }
)(
    (_, { prjId }) => prjId
)

export const paramUsrId = createCachedSelector(
    (_, { usrId }) => usrId,
    (usrId) => {
        //console.log(`Recalc paramUsrId ${usrId}`)
        return usrId
    }
)(
    (_, { usrId }) => usrId
)

export const paramTntId = createCachedSelector(
    (_, { tntId }) => tntId,
    (tntId) => {
        //console.log(`Recalc paramTntId ${tntId}`)
        return tntId
    }
)(
    (_, { tntId }) => tntId
)

export const paramLimitDate4License = createCachedSelector(
    (_, { limitDate }) => limitDate,
    (limitDate) => {
        //console.log(`Recalc paramUsrId ${usrId}`)
        return limitDate
    }
)(
    (_, { limitDate }) => limitDate
)

export const paramActGrp = createCachedSelector(
    (_, { actGrp }) => actGrp,
    (actGrp) => {
        //console.log(`Recalc paramActGrp ${actGrp}`)
        return actGrp
    }
)(
    (_, { actGrp }) => actGrp
)

export const paramActArea = createCachedSelector(
    (_, { actArea }) => actArea,
    (actArea) => {
        //console.log(`Recalc paramActArea ${actArea}`)
        return actArea
    }
)(
    (_, { actArea }) => actArea
)

export const paramActTgt = createCachedSelector(
    (_, { actTgt }) => actTgt,
    (actTgt) => {
        //console.log(`Recalc paramActTgt ${actTgt}`)
        return actTgt
    }
)(
    (_, { actTgt }) => actTgt
)

export const paramAct = createCachedSelector(
    (_, { act }) => act,
    (act) => {
        //console.log(`Recalc paramAct ${act}`)
        return act
    }
)(
    (_, { act }) => act
)

export const paramPopupId = createCachedSelector(
    (_, { popupId }) => popupId,
    (popupId) => {
        return popupId
    }
)(
    (_, { popupId }) => popupId
)