import { makeAutoObservable, has, get } from "mobx"

export default class UIStore {

    selCustId = ''
    selPrjIdByCustId = {}
    selTntIdByPrjId = {}

    rootStore

    constructor(rootStore) {
        makeAutoObservable(this, { rootStore: false })

        this.rootStore = rootStore
    }

    get selPrjId() {

        if (!this.selCustId) {
            return null
        }
        if (!has(this.selPrjIdByCustId, this.selCustId)) {
            return null
        }

        return get(this.selPrjIdByCustId, this.selCustId)
    }

    get selTntId() {

        const _selPrjId = this.selPrjId

        if (!_selPrjId) {
            return null
        }
        if (!has(this.selTntIdByPrjId, _selPrjId)) {
            return null
        }

        return get(this.selTntIdByPrjId, _selPrjId)
    }

    chgSelCust = (custId) => {
        //console.debug('chgSelCust::', custId)
        //set(this, 'selCustId', custId)
        this.selCustId = custId
    }

    chgSelCustPrj = (custId, prjId) => {
        //console.debug('chgSelCustPrj::', custId, prjId)
        this.selPrjIdByCustId[custId] = prjId
    }

    chgSelPrjTnt = (prjId, tntId) => {
        //console.debug('chgSelPrjTnt::', prjId, tntId)
        this.selTntIdByPrjId[prjId] = tntId
    }
}