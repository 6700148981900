import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import CustDynTheme from './components/themes/CustDynTheme';
import RouteDispacher from './components/RouteDispacher';
import ActionFeedback from './components/ui/ActionFeedback';
import WorkProgressFeedback from './components/ui/WorkProgressFeedback';

import AppContextProvider from './AppContextProvider';
import { lgDebug } from './utils/logging';
import SyncURLContextMainParams2State from './components/ui/SyncURLContextMainParams2State';

//import FirebNotifications from './components/notifications/FirebNotifications';

import 'devextreme/dist/css/dx.common.css';
//import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.softblue.css';

import 'antd/dist/antd.css'

import deMessages from "devextreme/localization/messages/de.json"
import { locale, loadMessages } from "devextreme/localization"
import ReduxMobxSync from './components/sync/ReduxMobxSync';
import OldTsConflictResolver from './components/ui/OldTsConflictResolver';

function App() {

  lgDebug(`App RENDER`)

  useEffect(() => {
    loadMessages(deMessages)
    locale('de')
  }, [])

  // <FirebNotifications />

  return (
    <BrowserRouter>
      <SyncURLContextMainParams2State>

        <AppContextProvider>
          <ReduxMobxSync />
          <CustDynTheme />
          <RouteDispacher />
          <ActionFeedback />
          <WorkProgressFeedback />
          <OldTsConflictResolver />
        </AppContextProvider>

      </SyncURLContextMainParams2State>
    </BrowserRouter>)
}

export default App;