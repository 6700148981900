import { generatePath } from "react-router"

const nue = (v) => {
    if (!v) {
        return '-'
    }

    return v
}

const _main = '/admin/qc/:type/:ownerId'
const _det = `${_main}/det/:qcId`
const _vers = `${_det}/v/:vId`
const _cat = `${_vers}/c/:cId`
const _quest = `${_cat}/q/:qId`
const _act = `${_quest}/a/:aId`
const _reg = `${_quest}/r/:rId`

const routes = {
    main: _main,
    det: _det,
    vers: _vers,
    cat: _cat,
    quest: _quest,
    act: _act,
    reg: _reg,

    url: {
        main: (type, ownerId) => generatePath(_main, { type: nue(type), ownerId: nue(ownerId) }),
        det: (type, ownerId, qcId) => generatePath(_det, { type: nue(type), ownerId: nue(ownerId), qcId: nue(qcId) }),
        vers: (type, ownerId, qcId, vId) => generatePath(_vers, { type: nue(type), ownerId: nue(ownerId), qcId: nue(qcId), vId: nue(vId) }),
        cat: (type, ownerId, qcId, vId, cId) => generatePath(_cat, { type: nue(type), ownerId: nue(ownerId), qcId: nue(qcId), vId: nue(vId), cId: nue(cId) }),
        quest: (type, ownerId, qcId, vId, cId, qId) => generatePath(_quest, { type: nue(type), ownerId: nue(ownerId), qcId: nue(qcId), vId: nue(vId), cId: nue(cId), qId: nue(qId) }),
        act: (type, ownerId, qcId, vId, cId, qId, aId) => generatePath(_act, { type: nue(type), ownerId: nue(ownerId), qcId: nue(qcId), vId: nue(vId), cId: nue(cId), qId: nue(qId), aId: nue(aId) }),
        reg: (type, ownerId, qcId, vId, cId, qId, rId) => generatePath(_reg, { type: nue(type), ownerId: nue(ownerId), qcId: nue(qcId), vId: nue(vId), cId: nue(cId), qId: nue(qId), rId: nue(rId) }),
    }
}

export default routes