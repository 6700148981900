import { makeAutoObservable, flow, has, values, set } from "mobx"
import { BugCrash } from "../../services/BugCrash"
import axios from '../../services/Axios'
import QcVersion from "./QcVersion"
import { computedFn } from "mobx-utils"
import { get } from "lodash"
import { getUuid } from "../../services/IdGenerator"
import { getCurTimestamp } from "../../utils"

export default class QcVersionsStore {

    rootStore

    versions = {}

    isLoading = false
    isSaving = false

    constructor(rootStore) {
        makeAutoObservable(this, { rootStore: false })

        this.rootStore = rootStore
    }

    upsertFromJson = (qcId, qcType, json, forceRefresh) => {
        //console.log('QcVersionsStore::upsertFromJson', qcId, qcType, json)

        if (forceRefresh || !has(this.versions, json.id)) {
            set(this.versions, json.id, new QcVersion(qcId, qcType))
        }

        this.versions[json.id].updateFromJson(json, forceRefresh)
    }

    createVersion = (qcId, qcType) => {
        const _newVers = new QcVersion(qcId, qcType)
        _newVers.updateFromJson({ id: getUuid(), timestamp: 0, dateCreated: getCurTimestamp() }, true, false)

        set(this.versions, _newVers.id, _newVers)

        return _newVers
    }

    releaseVersion = (vId) => {
        const _vers = get(this.versions, [vId])
        if (_vers) {
            return _vers.doRelease()
        }
    }

    createCateg = (vId) => {
        const _vers = get(this.versions, [vId])
        if (_vers) {
            return _vers.createCateg()
        }
    }

    createQuestion = (vId, cId) => {
        const _cat = get(this.versions, [vId, 'categories', cId])
        if (_cat) {
            return _cat.createQuestion()
        }
    }

    deleteCateg = (vId, cId) => {
        const _vers = get(this.versions, [vId])
        if (_vers) {
            return _vers.deleteCateg(cId)
        }
    }

    deleteQuestion = (vId, cId, qId) => {
        const _cat = get(this.versions, [vId, 'categories', cId])
        if (_cat) {
            return _cat.deleteQuestion(qId)
        }
    }

    createAction = (vId, cId, qId) => {
        const _quest = get(this.versions, [vId, 'categories', cId, 'questions', qId])
        if (_quest) {
            return _quest.createAction()
        }
    }

    deleteAction = (vId, cId, qId, aId) => {
        const _quest = get(this.versions, [vId, 'categories', cId, 'questions', qId])
        if (_quest) {
            return _quest.deleteAction(aId)
        }
    }

    createRegulation = (vId, cId, qId) => {
        const _quest = get(this.versions, [vId, 'categories', cId, 'questions', qId])
        if (_quest) {
            return _quest.createRegulation()
        }
    }

    deleteRegulation = (vId, cId, qId, rId) => {
        const _quest = get(this.versions, [vId, 'categories', cId, 'questions', qId])
        if (_quest) {
            return _quest.deleteRegulation(rId)
        }
    }

    delOneById = flow(function* (ownerId, qcId, qcType, versId) {

        this.isSaving = true

        const taskId = this.rootStore.workProgressStore.startPushing(`DEL qc-version qcId=${qcId}, qcType=${qcType}, versId=${versId}`)

        try {
            let _url
            if (qcType === 'S') {
                _url = `/questionnaires/${qcId}/versions/by-id/${versId}`
            }
            else if (qcType === 'G') {
                _url = `/customers/${ownerId}/questionnaires/${qcId}/versions/by-id/${versId}`
            }

            if (!_url) {
                throw new Error("Cannot del Quest-Cat Version!")
            }

            const { status } = yield axios.delete(_url)

            return (status === 200)
        }
        catch (err) {
            if (err.response) {
                const { status } = err.response

                if (status === 404) {
                    return true
                }
            }

            BugCrash.notifyExtra(err, { qcId, qcType, versId })
        }
        finally {
            this.isSaving = false
            this.rootStore.workProgressStore.donePushing(taskId)
        }

        return false
    })

    fetchOneById = flow(function* (ownerId, qcId, qcType, versId, forceRefresh) {

        this.isLoading = true

        const taskId = this.rootStore.workProgressStore.startLoading(`Load qc-version qcId=${qcId}, qcType=${qcType}, versId=${versId}`)

        try {
            let _url
            if (qcType === 'S') {
                _url = `/questionnaires/${qcId}/versions/by-id/${versId}`
            }
            else if (qcType === 'G') {
                _url = `/customers/${ownerId}/questionnaires/${qcId}/versions/by-id/${versId}`
            }

            if (!_url) {
                throw new Error("Cannot load Quest-Cat Version!")
            }

            const { status, data } = yield axios.get(_url)

            if (status === 200) {

                let refreshLocalCache = true
                if (has(this.versions, data.id)) {
                    if (!forceRefresh) {
                        const _localVers = this.versions[data.id]
                        if (_localVers.timestamp >= data.timestamp) { // local version is not older then the server version
                            refreshLocalCache = false
                        }
                    }
                }
                if (!refreshLocalCache) { // the cache is good enough
                    return true
                }

                const _vers = new QcVersion(qcId, qcType)
                _vers.updateFromJson(data)

                set(this.versions, _vers.id, _vers)

                return true
            }
        }
        catch (err) {
            if (err.response) {
                const { status } = err.response

                if (status === 404) {
                    return true
                }
            }

            BugCrash.notifyExtra(err, { qcId, qcType, versId })
        }
        finally {
            this.isLoading = false
            this.rootStore.workProgressStore.doneLoading(taskId)
        }

        return false
    })

    saveOneByIdIfDirty = flow(function* (ownerId, qcId, qcType, versId) {

        this.isSaving = true

        const taskId = this.rootStore.workProgressStore.startPushing(`SAVE qc-version qcId=${qcId}, qcType=${qcType}, versId=${versId}`)

        try {

            let _url
            if (qcType === 'S') {
                _url = `/questionnaires/${qcId}/versions/by-id/${versId}`
            }
            else if (qcType === 'G') {
                _url = `/customers/${ownerId}/questionnaires/${qcId}/versions/by-id/${versId}`
            }


            if (!_url) {
                throw new Error("Cannot push Quest-Cat Version!")
            }

            const _vers = this.versions[versId]
            if (!_vers || !_vers.dirty) {
                return true
            }

            const { status, data } = yield axios.post(_url, {
                timestampBefore: _vers.timestamp,
                full: _vers.srvJson
            })

            const _qc = this.rootStore.qcStore.qcs[qcId]
            if (_qc && _qc.timestamp < data.timestamp) {
                _qc.updateFromJson({ timestamp: data.timestamp })
            }

            if (status !== 200) {
                throw new Error(`Status ${status} beim Speichern!`)
            }

            return true
        }
        catch (err) {
            BugCrash.notifyExtra(err, { qcId, qcType, versId })
        }
        finally {
            this.isSaving = false
            this.rootStore.workProgressStore.donePushing(taskId)
        }

        return false
    })

    fetchOneByVersNb = flow(function* (ownerId, qcId, qcType, versNb, forceRefresh) {

        this.isLoading = true

        const taskId = this.rootStore.workProgressStore.startLoading(`Load qc-version qcId=${qcId}, qcType=${qcType}, versNb=${versNb}`)

        //console.log('fetchOneByVersNb::', ownerId, qcId, qcType, versNb, forceRefresh)

        try {
            let _url
            if (qcType === 'S') {
                _url = `/questionnaires/${qcId}/versions/by-nb/${versNb}`
            }
            else if (qcType === 'G') {
                console.warn(`fetchOneByVersNb not implemented for qcType === 'G'...`)
                return true
                //_url = `/customers/${ownerId}/questionnaires/${qcId}/versions/by-nb/${versNb}`
            }
            else if (qcType === 'T') {
                console.warn(`fetchOneByVersNb not implemented for qcType === 'T'...`)
                return true
            }

            if (!_url) {
                throw new Error("Cannot load Quest-Cat Version!")
            }

            const { status, data } = yield axios.get(_url)

            if (status === 200) {

                let refreshLocalCache = true
                if (has(this.versions, data.id)) {
                    if (!forceRefresh) {
                        const _localVers = this.versions[data.id]
                        if (_localVers.timestamp >= data.timestamp) { // local version is not older then the server version
                            refreshLocalCache = false
                        }
                    }
                }
                if (!refreshLocalCache) { // the cache is good enough
                    return true
                }

                const _vers = new QcVersion(qcId, qcType)
                _vers.updateFromJson(data)

                set(this.versions, _vers.id, _vers)

                return true
            }
        }
        catch (err) {
            BugCrash.notifyExtra(err, { qcId, qcType, versNb })
        }
        finally {
            this.isLoading = false
            this.rootStore.workProgressStore.doneLoading(taskId)
        }

        return false
    })

    getByQcAndVersNb = computedFn((qcId, qcType, versNb) => {

        for (const v of values(this.versions)) {
            if ((v.qcId === qcId) && (v.qcType === qcType) && (v.versionNumber === versNb)) {
                return v
            }
        }

        return null
    })
}