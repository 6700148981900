import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'

import AppContext from '../../AppContext';
import { analyzeActGroupSts } from '../../libs/loading'
import LoadingOverlay from 'react-loading-overlay';
import { actGrpSelector } from '../../store/selectors/others';

import { setLastLoad } from '../../store/actions/lastLoadActions';
import { loadRoles } from '../../store/actions/rolesActions';
import { loadMyCustomers } from '../../store/actions/permissionsActions';
import { loadAuthUserData } from '../../store/actions/usersActions';

const maxCacheLifetimeMillis = {
    roles: 10*60*1000,
    myCust: 30*1000,
    authUserData: 5*60*1000,
}

function MasterPageBase({actGrp, children}) {

    const _context = useContext(AppContext)

    const _lastLoadState = useSelector(state => state.lastLoad)
    const _actStsByGrp = useSelector(state => actGrpSelector(state, {actGrp}))
    const dispatch = useDispatch();

    const {auth} = _context

    if(!auth || !auth.uid){
        return <Redirect to='/login' />
    }

    const _now = Date.now()

    if(_now>_lastLoadState.myCust+maxCacheLifetimeMillis.myCust){
        dispatch(setLastLoad('myCust', _now))
        //console.warn('MasterPageBase :: loading myCust', _now)
        dispatch(loadMyCustomers(actGrp))
    }

    if(_now>_lastLoadState.roles+maxCacheLifetimeMillis.roles){
        dispatch(setLastLoad('roles', _now))
        //console.warn('MasterPageBase :: loading roles', _now)
        dispatch(loadRoles(actGrp))
    }
    if(_now>_lastLoadState.authUserData+maxCacheLifetimeMillis.authUserData){
        dispatch(setLastLoad('authUserData', _now))
        //console.warn('MasterPageBase :: loading authUserData', _now)
        dispatch(loadAuthUserData(actGrp))
    }

    //console.warn(`MasterPageBase::`, _now, _lastLoadState, _actStsByGrp)

    const _actSts = analyzeActGroupSts(_actStsByGrp)

    return <LoadingOverlay active={_actSts.active} spinner text={_actSts.txt} className="fullHeight">
        {children}            
    </LoadingOverlay>
}

MasterPageBase.propTypes = {
    actGrp: PropTypes.string.isRequired,
}

export default MasterPageBase