import * as actTypes from '../actions/actionTypes'
import { BugCrash } from '../../services/BugCrash'

import Axios from '../../services/Axios'
import {lbl} from '../../lbls'
import format from 'date-fns/format'
import { discardChgs } from './changesActions';
import { registerFeedback } from './feedbackActions';
import { actStarted, actReadyOk, actReadyPb } from './actionsActions';
import { actionsAreas, actionsActs } from '../reducers/actionsReducer';

export const upsertCusts = (custs) => {

    return {
        type: actTypes.STORE_UPSERT_CUSTS,
        items: Array.isArray(custs)?custs:Object.values(custs),
    }
}

export const loadCustomersList = (actGrp) => {
    return async (dispatch, getState) => {

        const _actTgt=`load-cust-lst`

        try{
            dispatch(actStarted(actionsAreas.cust, actGrp, _actTgt, actionsActs.load))

            const resp = await Axios.get(`/customers`)
            if(resp && (resp.status===200)){

                if(resp.data.list){
                    dispatch({type: actTypes.STORE_UPSERT_CUSTS, items: resp.data.list})
                }

                dispatch(actReadyOk(actionsAreas.cust, actGrp, _actTgt, actionsActs.load))
                return
            }

            throw new Error(lbl.err_load_cust_lst)
        }
        catch(err){
            BugCrash.notify(err)
            dispatch(actReadyPb(actionsAreas.cust, actGrp, _actTgt, actionsActs.load, err))
        }
    }
}

export const loadCustomerLicense = (custId) => {
    return async (dispatch, getState) => {

        try{
            dispatch({type: actTypes.LOAD_CUST_LICENSE_STARTED, custId})

            const resp = await Axios.get(`/customers/${custId}/license`)
            if(resp && (resp.status===200)){
                return dispatch({type: actTypes.LOAD_CUST_LICENSE_READY_OK, custId, data: resp.data})
            }

            throw new Error(lbl.err_load_cust_lic)
        }
        catch(err){
            BugCrash.notify(err)
            dispatch({type: actTypes.LOAD_CUST_LICENSE_READY_PB, custId, err})
        }
    }
}

export const patchCustomerLicense = (custId, modules, dateUntil) => {

    const _fmtDate = (dateUntil)?format(dateUntil, 'yyyyMMdd', null):null
    const _modWithDate = {}

    modules && Object.keys(modules).filter(mod => !!modules[mod]).forEach((mod) => {
        _modWithDate[mod]=_fmtDate
    })

    return async (dispatch, getState) => {

        try{
            dispatch({type: actTypes.PATCH_CUST_LICENSE_STARTED, custId})

            const resp = await Axios.patch(`/customers/${custId}/license`, {
                modules: {
                    ..._modWithDate
                }
            })

            if(resp && (resp.status===200)){
                dispatch({type: actTypes.PATCH_CUST_LICENSE_READY_OK, custId, data: resp.data})
                dispatch(registerFeedback(lbl.feedback_save_lizenz_ok, false))
                dispatch(loadCustomerLicense(custId))
                dispatch(discardChgs(`adm-cust-lic-${custId}`))
                return
            }

            throw new Error(lbl.err_patch_cust_lic)
        }
        catch(err){
            dispatch(registerFeedback(lbl.feedback_save_lizenz_err.replace('%later', lbl.try_again_later), true))
            BugCrash.notify(err)
            dispatch({type: actTypes.PATCH_CUST_LICENSE_READY_PB, custId, err})
        }
    }
}