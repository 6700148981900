import React, { PureComponent } from 'react'
import Dashboard from './Dashboard';

import { connect } from 'react-redux'
import { lgDebug } from '../utils/logging';
import { signOut } from '../store/actions/authActions';
import { usrJoinedCusts } from '../store/selectors/usrs';

import { custAuthUsrTntsGrpByPrj } from '../store/selectors/custs';
import { custLicModules } from '../store/selectors/custLicenses';
import format from 'date-fns/format'
import genPath from '../routing/genPath';
import MasterPageBase from './ui/MasterPageBase';

const actGrp = 'main-dashboard'

class DashboardCont extends PureComponent {

    _startModule = (mod) => {

        const { history, custId, prjId, tntId } = this.props

        let _modUrl = mod.url || ''
        if (_modUrl.indexOf('-prjId-')) {
            _modUrl = _modUrl.replace('-prjId-', prjId)
            _modUrl = _modUrl.replace('-tntId-', tntId)
        }
        else {
            _modUrl = genPath(mod.url, null, { c: custId, t: tntId })
        }

        history.push(_modUrl)
    }

    render = () => {

        lgDebug(`DashboardCont RENDER`)

        const { myTntsByPrj, myJoinedCusts, custId, prjId, tntId, signOut, roles, custLicModules, prjPermissions, sa, custAdm } = this.props

        return <MasterPageBase actGrp={actGrp}><Dashboard
            myJoinedCusts={myJoinedCusts}
            myTntsByPrj={myTntsByPrj}
            custId={custId} prjId={prjId} tntId={tntId}
            signOut={signOut}
            roles={roles}
            custLicModules={custLicModules}
            prjPermissions={prjPermissions}
            startModule={this._startModule}
            sa={sa}
            custAdm={custAdm}
        /></MasterPageBase>
    }
}

const mapStateToProps = (state, ownProps) => {

    //console.log(state.session)

    const authUsrId = state.firebase && state.firebase.auth && state.firebase.auth.uid

    const custId = state.session.cust
    const prjId = custId && state.session.projByCust && state.session.projByCust[custId]
    const tntId = prjId && state.session.tntByProj && state.session.tntByProj[prjId]

    const limitDate = format(Date.now(), 'yyyyMMdd', null)

    return {
        roles: state.roles.byId,

        custId,
        prjId,
        tntId,

        sa: state.myData && state.myData.data && state.myData.data.sa,
        custAdm: custId && authUsrId && state.custsAdms.byId[custId] && state.custsAdms.byId[custId][authUsrId],

        actStsByGrp: state.actions.byGrp[actGrp],
        myJoinedCusts: authUsrId && usrJoinedCusts(state, { usrId: authUsrId }),
        myTntsByPrj: custAuthUsrTntsGrpByPrj(state, { custId }),

        custLicModules: custLicModules(state, { custId, limitDate }),
        prjPermissions: state.prjsPermissions.byId[prjId] && state.prjsPermissions.byId[prjId][authUsrId],
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        signOut: () => dispatch(signOut()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardCont)