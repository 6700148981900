import * as actTypes from '../actions/actionTypes'
import { lgInfo } from '../../utils/logging';
import { loadMyCustProjects, loadMyProjTnts } from './permissionsActions';
import { usrJoinedCustIds } from '../selectors/usrs';

const _checkSelectedProjTenant = (prjId, actGrp) => {

    return async (dispatch, getState) => {

        lgInfo('checkSelectedProjTenant dispatched')

        if(!prjId){
            lgInfo('checkSelectedProjTenant: no prjId, aborting action...')
            return
        }

        const _state = await getState()
        const _prjsTnts = _state['prjsTnts'].byId[prjId]

        const _curSelProjTntId = _state && _state.session && _state.session.tntByProj && _state.session.tntByProj[prjId]

        lgInfo(`checkSelectedProjTenant dispatched, cur sel tnt: ${_curSelProjTntId}`)

        if(!_prjsTnts){
            lgInfo('checkSelectedProjTenant dispatched with prjId having no related tenants')
            if(_curSelProjTntId){
                dispatch(_chgSelectedProjTenant(prjId, null, actGrp)) // reset sel tenant
            }
            return
        }

        if(_curSelProjTntId){
            if(!_prjsTnts[_curSelProjTntId]){ // sel tnt not included in prjId (any more)
                lgInfo(`checkSelectedProjTenant: sel tnt ${_curSelProjTntId} not in prjId: ${prjId} - reset sel tnt`)
                const _prjsTntsIds = Object.keys(_prjsTnts)
                const _newSelTntId = (_prjsTntsIds.length===1)?_prjsTntsIds[0]:null
                dispatch(_chgSelectedProjTenant(prjId, _newSelTntId, actGrp)) // reset sel tenant
            }
            else{
                lgInfo(`checkSelectedProjTenant: sel tnt ${_curSelProjTntId} ok...`)
            }
            return
        }

        const _prjsTntsIds = Object.keys(_prjsTnts)
        const _newSelTntId = (_prjsTntsIds.length===1)?_prjsTntsIds[0]:null

        if(_newSelTntId){
            dispatch(_chgSelectedProjTenant(prjId, _newSelTntId, actGrp)) // set sel tenant
        }
    }
}

const _checkSelectedCustProject = (custId, actGrp) => {
    return async (dispatch, getState) => {

        lgInfo('checkSelectedCustProject dispatched')

        if(!custId){
            lgInfo('checkSelectedCustProject, no custId, aborting action...')
            return
        }

        const _state = await getState()
        const _custPrjs = _state.custsPrjsByAuthUsr.byId[custId]

        if(!_custPrjs){
            lgInfo('checkSelectedCustProject dispatched, actually no projects for custId='+custId)
            dispatch(_chgSelectedCustProject(custId, null, true, actGrp))
            return
        }

        const _custPrjsIds = Object.keys(_custPrjs)

        if(!_custPrjsIds || (_custPrjsIds.length===0)){
            lgInfo('checkSelectedCustProject dispatched while myProjsByCust is empty')
            dispatch(_chgSelectedCustProject(custId, null, true, actGrp))
            return
        }

        const _curSelCustPrjId = _state && _state.session && _state.session.projByCust && _state.session.projByCust[custId]
        lgInfo(`checkSelectedCustProject, cur selected prj: ${_curSelCustPrjId}`)

        if(_curSelCustPrjId){ // there is already a project selected for this customer in session
            const _foundCurSelProjInCust = _custPrjs[_curSelCustPrjId]

            if(_foundCurSelProjInCust){ //still have rights for the selected project
                lgInfo(`checkSelectedCustProject; sel prj ${_curSelCustPrjId} ok...`)
                dispatch(loadMyProjTnts(_curSelCustPrjId, actGrp))
            }
            else{ // selected prj-id is not linked with the project any more. => change the selected prj for the current cust
                const _newSelPrjId = (_custPrjsIds.length===1)?_custPrjsIds[0]:null
                lgInfo(`checkSelectedCustProject; old prj not linked with the cust, selecting now prj ${_newSelPrjId}`)
                dispatch(_chgSelectedCustProject(custId, _newSelPrjId, true, actGrp))
            }
            return
        }

        if(_custPrjsIds.length===1){ // if only one project in customer, select it automatically
            lgInfo(`checkSelectedCustProject; only one prj in cust, sel it autom.`)
            dispatch(_chgSelectedCustProject(custId, _custPrjsIds[0], true, actGrp))
        }
    }
}

const _checkSelectedCustomer = (actGrp) => {
    return async (dispatch, getState) => {

        lgInfo('checkSelectedCustomer dispatched')

        const _state = await getState()
        const _curSelCustId = _state && _state.session && _state.session.cust
        const _authUsrId = _state.firebase && _state.firebase.auth && _state.firebase.auth.uid

        lgInfo(`checkSelectedCustomer cur sel cust ${_curSelCustId}`)

        if(_curSelCustId){ // there is already a customer selected in session
            if(_state.custsUsrs.byId[_curSelCustId] && _state.custsUsrs.byId[_curSelCustId][_authUsrId]){ //_authUsrId is (still) joined in _curSelCustId!
                lgInfo(`checkSelectedCustomer cur sel cust ${_curSelCustId} still ok`)
                dispatch(loadMyCustProjects(_curSelCustId, actGrp))
            }
            else{ // _authUsrId is NOT joined in _curSelCustId (any more?). _curSelCustId shouldn't be selected any more.

                lgInfo(`checkSelectedCustomer auth usr ${_authUsrId} NOT joined in cur sel cust ${_curSelCustId} -> must be changed`)

                const _authUsrJoinedCustIds = Object.keys(usrJoinedCustIds(_state, {usrId: _authUsrId}))
                const _newCustId = (_authUsrJoinedCustIds.length===1)?_authUsrJoinedCustIds[0]:null
                dispatch(_chgSelectedCustomer(_newCustId, true, actGrp))
            }

            return
        }

        const _authUsrJoinedCustIds = Object.keys(usrJoinedCustIds(_state, {usrId: _authUsrId}))
        if(_authUsrJoinedCustIds.length===1){
            lgInfo(`checkSelectedCustomer auth usr ${_authUsrId} is only joined one cust ${_authUsrJoinedCustIds[0]}, select it autom...`)
            dispatch(_chgSelectedCustomer(_authUsrJoinedCustIds[0], actGrp))
        }
    }
}

const _setSessionCust = (custId) => {
    return {type: actTypes.SES_SET_CUST, custId}
}

const _chgSelectedCustomer = (custId, withReloadSrvMyCust, actGrp) => {
    return async (dispatch, getState) => {

        dispatch(_setSessionCust(custId))

        if(custId && withReloadSrvMyCust){
            dispatch(loadMyCustProjects(custId, actGrp))
        }
    }
}

const _chgSelectedCustProject = (custId, prjId, withReloadSrvMyProjTnts, actGrp) => {
    return async (dispatch, getState) => {

        dispatch({
            type: actTypes.SES_SET_PROJ,
            custId,
            prjId,
        });

        if(prjId && withReloadSrvMyProjTnts){
            dispatch(loadMyProjTnts(prjId, actGrp))
        }
    }
}

const _chgSelectedProjTenant = (prjId, tntId, actGrp) => {
    return {
        type: actTypes.SES_SET_TNT,
        prjId,
        tntId,
    }
}

const _setSessionAdmCust = (custId) => {
    return {type: actTypes.SES_SET_ADM_CUST, custId}
}



export {
    _chgSelectedCustomer as chgSelectedCustomer, 
    _chgSelectedCustProject as chgSelectedCustProject,
    _chgSelectedProjTenant as chgSelectedProjTenant,
    _checkSelectedCustProject as checkSelectedCustProject,
    _checkSelectedCustomer as checkSelectedCustomer,
    _checkSelectedProjTenant as checkSelectedProjTenant,
    _setSessionAdmCust as setSessionAdmCust,
}