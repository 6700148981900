import { makeAutoObservable, observable, set, remove } from "mobx"
import { getUuid } from "../../services/IdGenerator"

export default class OldTsStore {

    rootStore

    conflicts = observable.map()

    constructor(rootStore) {
        makeAutoObservable(this, { rootStore: false })

        this.rootStore = rootStore

        /*this.addConflict("Saving Audit XXX", 7777, 9999, (newTs) => {
            console.log('TryAgain', newTs)
        }, () => {
            console.log('do refresh')
        }, () => {
            console.log('do cancel!')
        })*/
    }

    addConflict = (actionInfo, clientTs, serverTs, onTryAgain, onRefresh, onCancel) => {

        const id = getUuid()

        set(this.conflicts, id, {
            id,
            actionInfo,
            clientTs,
            serverTs,
            onTryAgain,
            onRefresh,
            onCancel
        })

        return id
    }

    removeConflict = (id) => {
        remove(this.conflicts, id)
    }
}