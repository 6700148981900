const dataTypes = {
    'QUEST_CAT': 'QUEST_CAT',
    'TXT_BLOCK': 'TXT_BLOCK',

    'SPEC_AUDIT': 'SPEC_AUDIT',
    'TPC_BASIC_CARE': 'TPC_BASIC_CARE',
    'TPC_BASIC_CARE_MEDIC': 'TPC_BASIC_CARE_MEDIC',
    'TPC_COMP_SPEC': 'TPC_COMP_SPEC',
    'MEETING': 'MEETING',

    'MATERNITY': 'MATERNITY',
    'PSYCHE': 'PSYCHE',
    'ACCIDENT_ANALYSE': 'ACCIDENT_ANALYSE',

    'PERSON': 'PERSON',

    'EMPL': 'EMPL',
    'REPRESENT': 'REPRESENT',
    'FUNC': 'FUNC',
    'DEPART': 'DEPART',

    'PERF_CONF': 'PERF_CONF',
}

export default dataTypes

export const getReducerKeyByDataType = (dataType) => {

    if (dataType === dataTypes.SPEC_AUDIT) {
        return 'specificAudit'
    }
    if (dataType === dataTypes.TPC_BASIC_CARE) {
        return 'tpcBasicCare'
    }
    if (dataType === dataTypes.TPC_BASIC_CARE_MEDIC) {
        return 'tpcBasicCareMedic'
    }
    if (dataType === dataTypes.TPC_COMP_SPEC) {
        return 'tpcCompSpec'
    }
    if (dataType === dataTypes.MEETING) {
        return 'meetings'
    }
    if (dataType === dataTypes.QUEST_CAT) {
        return 'questCatTnt'
    }
    if (dataType === dataTypes.PERSON) {
        return 'persons'
    }
    if (dataType === dataTypes.EMPL) {
        return 'empl'
    }
    if (dataType === dataTypes.REPRESENT) {
        return 'represent'
    }
    if (dataType === dataTypes.FUNC) {
        return 'func'
    }
    if (dataType === dataTypes.DEPART) {
        return 'depart'
    }

    if (dataType === dataTypes.MATERNITY) {
        return 'maternity'
    }
    if (dataType === dataTypes.PSYCHE) {
        return 'psyche'
    }
    if (dataType === dataTypes.ACCIDENT_ANALYSE) {
        return 'accidentAnalyse'
    }
    if (dataType === dataTypes.PERF_CONF) {
        return 'perfConf'
    }
    if (dataType === dataTypes.TXT_BLOCK) {
        return 'txtBlock'
    }

    throw new Error(`getReducerKeyByDataType doesn't known the data-type ${dataType}`)
}