import axios from 'axios';
import { API_BASE_URL } from '../config/api';
import { getAccessToken } from './TokenService';

const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
});

// Add a request interceptor
axiosInstance.interceptors.request.use( async (config) => {
    
    if(
        config.url 
        && (config.url.indexOf('auth/')>=0)
    ){
        //console.log('axios request interceptor :: auth request, do nothing...')
        return config
    }

    let _accessToken = await getAccessToken(false)
    if(_accessToken){
        //console.log('axios request interceptor :: inject access token ' + _accessToken + ' in request header...')
        config.headers.Authorization = 'Bearer ' + _accessToken
    }

    //console.log('axios request interceptor')
    //console.log(config)

    return config;

  }, (error) => {
    // Do something with request error
    return Promise.reject(error);
  });


// Add a response interceptor
axiosInstance.interceptors.response.use(async (response) => {
    return response;
  }, async (error) => {

    //console.log('axios response interceptor: error!')

    //const { config, response: { status } } = error;
    const { config } = error;
    const originalRequest = config;

    if (error.code !== "ECONNABORTED" && error.response && error.response.status === 401) {
        if(config.url.indexOf('auth/')<0){
            if (!originalRequest._retry) {
                //console.log('axios response interceptor: got status 401. try to get another access token.')
                originalRequest._retry = true
    
                const _newAccessToken = await getAccessToken(true)
                //const _newAccessToken = null
                if(_newAccessToken){
                    //console.log('axios response interceptor: got new access token '+_newAccessToken)
                    originalRequest.headers['Authorization'] = 'Bearer ' + _newAccessToken;
                    return await axiosInstance(originalRequest)
                }
            }
        }
    }

    return Promise.reject(error);
    //throw error
});

export default axiosInstance;