import * as actTypes from '../actions/actionTypes'

const initState = {}

const myDataReducer = (state = initState, action) => {

    switch(action.type){

        case actTypes.LOAD_AUTH_USER_DATA_STARTED:

            return {
                ...state,
                loading: true,
                err: null,
                data: null,
            }

        case actTypes.LOAD_AUTH_USER_DATA_READY_PB:

            return {
                ...state,
                loading: false,
                err: action.err,
                data: null,
            }

        case actTypes.LOAD_AUTH_USER_DATA_READY_OK:

            return {
                ...state,
                loading: false,
                err: null,
                data: action.data,
            }
        
        default:
            return state
    }
}

export default myDataReducer;
