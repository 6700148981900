/* eslint-disable default-case */
import produce, {original} from 'immer'
import * as actTypes from '../actions/actionTypes'
import has from 'lodash/has'
import get from 'lodash/get'
import set from 'lodash/set'
import { getUuid } from '../../services/IdGenerator';

export default produce((draft, action) => {

    switch (action.type) {

        case actTypes.DISCARD_WKEQ_SHELF_BEDIENUNG:

            const orig10 = original(draft.byId[action.id])
            if(!orig10){
                console.warn(`DISCARD_WKEQ_SHELF_BEDIENUNG: ${action.id} not found!`)
                return
            }

            const _curRegal10 = get(orig10, ['full', 'shelfs', action.regalId])
            if(!_curRegal10){
                console.warn(`DISCARD_WKEQ_SHELF_BEDIENUNG: Regal ${action.regalId} not found!`)
                return
            }

            const _curOpts3 = get(_curRegal10, ['operations'], {})
            const _newOpts3 = {}

            for (const _curOpt of Object.values(_curOpts3)) {
                if(_curOpt.identifier !== action.ident){
                    _newOpts3[_curOpt.id]=_curOpt
                }
            }

            set(draft, ['byId', action.id, 'full', 'shelfs', action.regalId, 'operations'], _newOpts3)
            break;

        case actTypes.STORE_WKEQ_SHELF_BEDIENUNG:

            const orig9 = original(draft.byId[action.id])
            if(!orig9){
                console.warn(`STORE_WKEQ_SHELF_BEDIENUNG: ${action.id} not found!`)
                return
            }

            const _curRegal9 = get(orig9, ['full', 'shelfs', action.regalId])
            if(!_curRegal9){
                console.warn(`STORE_WKEQ_SHELF_BEDIENUNG: Regal ${action.regalId} not found!`)
                return
            }

            const _curOpts1 = get(_curRegal9, ['operations'], {})
            const _newOpts1 = {}

            for (const _curOpt1 of Object.values(_curOpts1)) {
                if(_curOpt1.identifier === action.ident){
                    console.debug(`STORE_WKEQ_SHELF_BEDIENUNG Type ${action.ident} already in Regal ${action.regalId}.`)
                    return
                }

                _newOpts1[_curOpt1.id]=_curOpt1                
            }

            const _newId6 = getUuid()
            _newOpts1[_newId6]={
                id: _newId6,
                identifier: action.ident,
            }

            set(draft, ['byId', action.id, 'full', 'shelfs', action.regalId, 'operations'], _newOpts1)
            break;

        case actTypes.STORE_WKEQ_SHELF_LADUNGSTRAEGER:

            const orig8 = original(draft.byId[action.id])
            if(!orig8){
                console.warn(`STORE_WKEQ_SHELF_LADUNGSTRAEGER: ${action.id} not found!`)
                return
            }

            const _curRegal8 = get(orig8, ['full', 'shelfs', action.regalId])
            if(!_curRegal8){
                console.warn(`STORE_WKEQ_SHELF_LADUNGSTRAEGER: Regal ${action.regalId} not found!`)
                return
            }

            const _curLadungs2 = get(_curRegal8, ['chargeCarrieres'], {})
            const _newLadungs2 = {}

            for (const _curLadung2 of Object.values(_curLadungs2)) {
                if(_curLadung2.identifier === action.ident){
                    console.debug(`STORE_WKEQ_SHELF_LADUNGSTRAEGER Type ${action.ident} already in Regal ${action.regalId}.`)
                    return
                }

                _newLadungs2[_curLadung2.id]=_curLadung2                
            }

            const _newId5 = getUuid()
            _newLadungs2[_newId5]={
                id: _newId5,
                identifier: action.ident,
            }

            set(draft, ['byId', action.id, 'full', 'shelfs', action.regalId, 'chargeCarrieres'], _newLadungs2)
            break;

        case actTypes.DISCARD_WKEQ_SHELF_LADUNGSTRAEGER:

            const orig7 = original(draft.byId[action.id])
            if(!orig7){
                console.warn(`DISCARD_WKEQ_SHELF_LADUNGSTRAEGER: ${action.id} not found!`)
                return
            }

            const _curRegal7 = get(orig7, ['full', 'shelfs', action.regalId])
            if(!_curRegal7){
                console.warn(`DISCARD_WKEQ_SHELF_LADUNGSTRAEGER: Regal ${action.regalId} not found!`)
                return
            }

            const _curLadungs3 = get(_curRegal7, ['chargeCarrieres'], {})
            const _newLadungs3 = {}

            for (const _curLadung of Object.values(_curLadungs3)) {
                if(_curLadung.identifier !== action.ident){
                    _newLadungs3[_curLadung.id]=_curLadung
                }
            }

            set(draft, ['byId', action.id, 'full', 'shelfs', action.regalId, 'chargeCarrieres'], _newLadungs3)
            break;

        case actTypes.DISCARD_WKEQ_SHELF_TYPE:

            const orig6 = original(draft.byId[action.id])
            if(!orig6){
                console.warn(`DISCARD_WKEQ_SHELF_TYPE: ${action.id} not found!`)
                return
            }

            const _curRegal6 = get(orig6, ['full', 'shelfs', action.regalId])
            if(!_curRegal6){
                console.warn(`DISCARD_WKEQ_SHELF_TYPE: Regal ${action.regalId} not found!`)
                return
            }

            const _curTypes2 = get(_curRegal6, ['types'], {})
            const _newTypes2 = {}

            for (const _curType of Object.values(_curTypes2)) {
                if(_curType.identifier !== action.ident){
                    _newTypes2[_curType.id]=_curType
                }
            }

            set(draft, ['byId', action.id, 'full', 'shelfs', action.regalId, 'types'], _newTypes2)
            break;

        case actTypes.STORE_WKEQ_SHELF_TYPE:

            const orig5 = original(draft.byId[action.id])
            if(!orig5){
                console.warn(`STORE_WKEQ_SHELF_TYPE: ${action.id} not found!`)
                return
            }

            const _curRegal5 = get(orig5, ['full', 'shelfs', action.regalId])
            if(!_curRegal5){
                console.warn(`STORE_WKEQ_SHELF_TYPE: Regal ${action.regalId} not found!`)
                return
            }

            const _curTypes1 = get(_curRegal5, ['types'], {})
            const _newTypes1 = {}

            for (const _curType of Object.values(_curTypes1)) {
                if(_curType.identifier === action.ident){
                    console.debug(`STORE_WKEQ_SHELF_TYPE Type ${action.ident} already in Regal ${action.regalId}.`)
                    return
                }

                _newTypes1[_curType.id]=_curType                
            }

            const _newId1 = getUuid()
            _newTypes1[_newId1]={
                id: _newId1,
                identifier: action.ident,
            }

            set(draft, ['byId', action.id, 'full', 'shelfs', action.regalId, 'types'], _newTypes1)
            break;

        case actTypes.DISCARD_WKEQ_SHELF_MANGEL:

            const orig4 = original(draft.byId[action.id])
            if(!orig4){
                console.warn(`DISCARD_WKEQ_SHELF_MANGEL: ${action.id} not found!`)
                return
            }

            const _curRegal2 = get(orig4, ['full', 'shelfs', action.regalId])
            if(!_curRegal2){
                console.warn(`DISCARD_WKEQ_SHELF_MANGEL: Regal ${action.regalId} not found!`)
                return
            }

            const _newRegMaengel2 = {}
            const _curRegMaengel2 = get(_curRegal2, ['defects'], {})

            for (const _mangel of Object.values(_curRegMaengel2)) {
                if(_mangel.id!==action.mangelId){
                    _newRegMaengel2[_mangel.id]=_mangel
                }
            }

            set(draft, ['byId', action.id, 'full', 'shelfs', action.regalId, 'defects'], _newRegMaengel2)
            break;

        case actTypes.STORE_WKEQ_SHELF_MANGEL:

            const orig3 = original(draft.byId[action.id])
            if(!orig3){
                console.warn(`STORE_WKEQ_SHELF_MANGEL: ${action.id} not found!`)
                return
            }

            const _curRegal = get(orig3, ['full', 'shelfs', action.regalId])
            if(!_curRegal){
                console.warn(`STORE_WKEQ_SHELF_MANGEL: Regal ${action.regalId} not found!`)
                return
            }

            const _newRegMaengel = {}
            let found2 = false
            const _curRegMaengel = get(_curRegal, ['defects'], {})

            for (const _mangel of Object.values(_curRegMaengel)) {
                if(_mangel.id!==action.mangel.id){
                    _newRegMaengel[_mangel.id]=_mangel
                }
                else{
                    found2=true
                    _newRegMaengel[_mangel.id]=action.mangel
                }

            }
            if(!found2){
                _newRegMaengel[action.mangel.id]=action.mangel
            }

            set(draft, ['byId', action.id, 'full', 'shelfs', action.regalId, 'defects'], _newRegMaengel)
            break;

        case actTypes.DISCARD_WKEQ_SHELF_REGAL:

            const orig2 = original(draft.byId[action.id])
            if(!orig2){
                console.warn(`DISCARD_WKEQ_SHELF_REGAL: ${action.id} not found!`)
                return
            }

            const _curRegale2 = get(orig2, 'full.shelfs') || {}
            const _newRegale2 = {}

            for (const _shelf of Object.values(_curRegale2)) {
                if(_shelf.id!==action.regalId){
                    _newRegale2[_shelf.id]=_shelf
                }
            }

            set(draft, ['byId', action.id, 'full', 'shelfs'], _newRegale2)
            break;

        case actTypes.STORE_WKEQ_SHELF_REGAL:

            const orig1 = original(draft.byId[action.id])
            if(!orig1){
                console.warn(`STORE_WKEQ_SHELF_REGAL: ${action.id} not found!`)
                return
            }

            const _curRegale = get(orig1, 'full.shelfs') || {}

            let found = false
            const _newRegale = {}

            for (const _shelf of Object.values(_curRegale)) {
                if(_shelf.id!==action.regal.id){
                    _newRegale[_shelf.id]=_shelf
                }
                else{
                    found=true
                    _newRegale[_shelf.id]=action.regal
                }
            }
            if(!found){
                _newRegale[action.regal.id]=action.regal
            }

            set(draft, ['byId', action.id, 'full', 'shelfs'], _newRegale)
            break;

        case actTypes.DISCARD_WKEQ_SHELF:

            if (draft.byId[action.id]){
                delete draft.byId[action.id]
            }
            break;

        case actTypes.RESET_NEW_WKEQ_SHELF:

            const _curVersNew = draft.byId[action.id]
            if(_curVersNew && _curVersNew.new){
                delete _curVersNew.new
            }

            break;

        case actTypes.STORE_TNT_WKEQ_SHELF:

            if(!action.item.id){
                console.warn('action.item.id not set!', action.item)
                return
            }

            let _curVersion = draft.byId[action.item.id]

            if(!_curVersion){ // action.item.id not in store
                _curVersion = {tntId: action.tntId}
                draft.byId[action.item.id] = _curVersion
            }

            if(
                has(action.item, 'timestamp') &&
                (action.item.timestamp === _curVersion.timestamp)
            ){ // we have in state the same version as online. only the full-field might be missing in state
                if(
                    has(action.item, 'full') &&
                    !has(_curVersion, 'full')
                ){
                    _curVersion['full']=action.item['full']
                }
                return
            }

            ['id', 'compact', 'full', 'timestamp', 'new'].forEach(fld => {
                if(has(action.item, fld)){
                    _curVersion[fld]=action.item[fld]
                }
                else{
                    if(has(_curVersion, fld)){
                        delete _curVersion[fld]
                    }
                }
            });

            break;

        case actTypes.SET_TNT_WKEQ_SHELVES:

            const tntWkeqIds = {}

            // Step1: make sure that all equipments sent in action are stored in state
            action.items && action.items.forEach(wkeq => {

                tntWkeqIds[wkeq.id]=true

                let _curVersion = draft.byId[wkeq.id]
                
                if(!_curVersion){ // wkeq not in store
                    //draft.allIds.push(wkeq.id)
                    draft.byId[wkeq.id] = {...wkeq, tntId: action.tntId}
                }
                else{ //wkeq already in store
                    if(_curVersion.timestamp===wkeq.timestamp){ // state version and server version seems to be the same. the full-field might need to be added to state.
                        if(has(wkeq, 'full') && !has(_curVersion, 'full')){
                            draft.byId[wkeq.id] = {...wkeq, tntId: action.tntId}
                        }
                    }
                    else{ // the state-version seems to be older, replace it with the server-version
                        draft.byId[wkeq.id] = {...wkeq, tntId: action.tntId}
                    }
                }
            })

            // Step2: make sure that in the tenant specified in action are only the equipments from step1 + the new created and not saved yet. all others should be deleted
            const orig = original(draft.byId)
            orig && Object.values(orig).filter(item => item.tntId === action.tntId).forEach(wkeq => {
                if(tntWkeqIds[wkeq.id]){ //work-equipment also loaded from server, should keep it
                    return
                }
                if(wkeq.new){ //new created by client, keep it
                    return
                }

                if(draft.byId[wkeq.id]){ // it doesn't exist on the server -> unload it
                    delete draft.byId[wkeq.id]
                }
            })

            break
    }
}, {
    byId:{}
})