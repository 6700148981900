import { orderBy } from "lodash"
import { has, makeAutoObservable, remove, set, toJS, values } from "mobx"
import { computedFn } from "mobx-utils"
import get from 'lodash/get'

import QcVersCateg from "./QcVersCateg"
import { getUuid } from "../../services/IdGenerator"

export default class QcVersion {

    qcId = ''
    qcType = ''

    id = ''
    sortID = -1
    changelog = {}
    legalbasis = {}
    isReleased = false
    dateCreated = 0

    versionNumber = 0
    categories = {}

    timestamp = 0

    isDirty = false

    constructor(qcId, qcType) {
        makeAutoObservable(this)

        this.qcId = qcId
        this.qcType = qcType
    }

    updateFromJson = (json, forceRefresh, cloneIds) => {
        //console.log('QcVersion::updateFromJson', json)

        if (!forceRefresh && (json.timestamp <= this.timestamp)) { //no change + no need to refresh
            return
        }

        if (json.hasOwnProperty('categories')) {
            const _categories = {}
            json.categories && Object.keys(json.categories).forEach(itmKey => {

                const itm = json.categories[itmKey]
                itm.id = (cloneIds) ? getUuid() : itmKey

                const _cat = new QcVersCateg(this)
                _cat.updateFromJson(itm, cloneIds)
                _categories[_cat.id] = _cat
            })

            set(this.categories, _categories)
        }

        for (const _fld of ['id', 'sortID', 'changelog', 'isReleased', 'dateCreated', 'versionNumber', 'timestamp', 'legalbasis']) {
            if (!json.hasOwnProperty(_fld)) {
                continue
            }

            if (this[_fld] !== json[_fld]) {
                set(this, _fld, json[_fld])
            }
        }

        if (cloneIds) {
            if (this.changelog) {
                this.changelog.id = getUuid()
            }
            if (this.legalbasis) {
                this.legalbasis.id = getUuid()
            }

            this.isDirty = true
        }
    }

    get srvJson() {

        const _json = {
            categories: {}
        }

        for (const _fld of ['id', 'sortID', 'changelog', 'isReleased', 'dateCreated', 'versionNumber', 'test', 'legalbasis']) {
            _json[_fld] = toJS(this[_fld])
        }

        values(this.categories).forEach(cat => {
            _json.categories[cat.id] = cat.srvJson
        })

        if (_json.changelog && !_json.changelog.id) {
            _json.changelog.id = getUuid()
        }
        if (_json.legalbasis && !_json.legalbasis.id) {
            _json.legalbasis.id = getUuid()
        }

        return _json
    }

    get sortedCategories() {
        return orderBy(values(this.categories), 'sortID')
    }

    getQuestionById = computedFn((questId) => {

        for (const _cat of values(this.categories)) {
            if (has(_cat.questions, questId)) {
                return _cat.questions[questId]
            }
        }

        return null
    })

    getCategoryOfQuestionId = computedFn((questId) => {

        for (const _cat of values(this.categories)) {
            if (has(_cat.questions, questId)) {
                return _cat
            }
        }

        return null
    })

    get isDeleted() {
        return (get(this, ['deleted'], 0) > 0)
    }

    get dirty() {

        if (this.isDirty) {
            return true
        }

        for (const _cat of values(this.categories)) {
            if (_cat.dirty) {
                return true
            }
        }

        return false
    }

    get isNew() {
        return this.timestamp === 0
    }

    deleteCateg = (cId) => {
        remove(this.categories, cId)
        this.isDirty = true
    }

    createCateg = () => {

        const _newCat = new QcVersCateg(this)
        _newCat.updateFromJson({ id: getUuid(), name: {}, legalbasis: {}, questions: {} })

        set(this.categories, _newCat.id, _newCat)
        this.isDirty = true

        return _newCat
    }

    setSortID = (val) => {
        this.sortID = val
        this.isDirty = true
    }

    setVersionNumber = (val) => {
        this.versionNumber = val
        this.isDirty = true
    }

    setChangelog = (lng, val) => {
        set(this.changelog, lng, val)
        this.isDirty = true
    }

    setLegalbasis = (lng, val) => {
        set(this.legalbasis, lng, val)
        this.isDirty = true
    }

    doRelease = () => {
        this.isReleased = true
        this.isDirty = true
    }
}