import { orderBy } from "lodash"
import { makeAutoObservable, set, values, remove, toJS } from "mobx"
import { getUuid } from "../../services/IdGenerator"
import QcAction from "./QcAction"
import QcRegulation from "./QcRegulation"

export default class QcQuest {

    cat

    id = ''
    sortID = -1
    prefix = -1

    levelOfRiskPre = 0
    likelihoodOfRiskPre = 0

    answerStyle = ''

    question = {}
    note = {}

    actions = {}
    regulations = {}

    isDirty = false

    constructor(cat) {
        makeAutoObservable(this, { cat: false })

        this.cat = cat
    }

    updateFromJson = (json, cloneIds) => {
        //console.log('QcQuest::', json)

        const _actions = {}
        json.actions && Object.keys(json.actions).forEach(itmKey => {
            const itm = json.actions[itmKey]
            itm.id = (cloneIds) ? getUuid() : itmKey

            const _act = new QcAction(this)
            _act.updateFromJson(itm, cloneIds)
            _actions[_act.id] = _act
        })
        set(this.actions, _actions)

        const _regulations = {}
        json.regulations && Object.keys(json.regulations).forEach(itmKey => {
            const itm = json.regulations[itmKey]
            itm.id = (cloneIds) ? getUuid() : itmKey

            const _reg = new QcRegulation(this)
            _reg.updateFromJson(itm, cloneIds)
            _regulations[_reg.id] = _reg
        })
        set(this.regulations, _regulations)

        //for (const fld of ['id', 'sortID', 'prefix', 'additionalNote', 'answerStyle', 'chapter', 'comment', 'isMinimumCriterion', 'question']) {
        for (const fld of ['id', 'sortID', 'prefix', 'question', 'note', 'answerStyle', 'levelOfRiskPre', 'likelihoodOfRiskPre']) {
            if (!json.hasOwnProperty(fld)) {
                continue
            }

            set(this, fld, json[fld])
        }

        if (cloneIds) {
            if (this.question) {
                this.question.id = getUuid()
            }
            if (this.note) {
                this.note.id = getUuid()
            }
        }
    }

    get srvJson() {

        const _json = {
            actions: {},
            regulations: {}
        }

        for (const _fld of ['id', 'sortID', 'prefix', 'question', 'note', 'answerStyle', 'levelOfRiskPre', 'likelihoodOfRiskPre']) {
            _json[_fld] = toJS(this[_fld])
        }

        values(this.actions).forEach(act => {
            _json.actions[act.id] = act.srvJson
        })
        values(this.regulations).forEach(reg => {
            _json.regulations[reg.id] = reg.srvJson
        })

        if (_json.question && !_json.question.id) {
            _json.question.id = getUuid()
        }
        if (_json.note && !_json.note.id) {
            _json.note.id = getUuid()
        }

        return _json
    }

    get sortedActions() {
        return orderBy(values(this.actions), ['sortID'])
    }

    get sortedRegulations() {
        return orderBy(values(this.regulations), ['sortID'])
    }

    createAction = () => {
        const _newAct = new QcAction(this)
        _newAct.updateFromJson({ id: getUuid(), name: {} })

        set(this.actions, _newAct.id, _newAct)
        this.isDirty = true

        return _newAct
    }

    deleteAction = (aId) => {

        remove(this.actions, aId)
        this.isDirty = true
    }

    createRegulation = () => {
        const _newReg = new QcRegulation(this)
        _newReg.updateFromJson({ id: getUuid(), name: {} })

        set(this.regulations, _newReg.id, _newReg)
        this.isDirty = true

        return _newReg
    }

    deleteRegulation = (rId) => {

        remove(this.regulations, rId)
        this.isDirty = true
    }

    get dirty() {

        if (this.isDirty) {
            return true
        }

        for (const _act of values(this.actions)) {
            if (_act.dirty) {
                return true
            }
        }

        for (const _reg of values(this.regulations)) {
            if (_reg.dirty) {
                return true
            }
        }

        return false
    }

    setSortID = (val) => {
        this.sortID = val
        this.isDirty = true
    }

    setPrefix = (val) => {
        this.prefix = val
        this.isDirty = true
    }

    setQuestion = (lng, val) => {
        set(this.question, lng, val)
        this.isDirty = true
    }

    setNote = (lng, val) => {
        set(this.note, lng, val)
        this.isDirty = true
    }

    setAnswerStyle = (val) => {
        this.answerStyle = val
        this.isDirty = true
    }

    setLevelOfRiskPre = (val) => {
        this.levelOfRiskPre = val
        this.isDirty = true
    }

    setLikelihoodOfRiskPre = (val) => {
        this.likelihoodOfRiskPre = val
        this.isDirty = true
    }
}