import ActvSpecRiskAssetStore from './audits/ActvSpecRiskAssetStore'
import TpcBasicCareAuditsStore from './audits/TpcBasicCareAuditsStore'
import TpcBasicCareMedicAuditsStore from './audits/TpcBasicCareMedicAuditsStore'
import SpecAuditsStore from './audits/SpecAuditsStore'

import UiActvSpecRiskAssetModStore from './audits/UiActvSpecRiskAssetModStore'
import UiTpcBasicCareModStore from './audits/UiTpcBasicCareModStore'
import UiTpcBasicCareMedicModStore from './audits/UiTpcBasicCareMedicModStore'
import UiSpecAuditsModStore from './audits/UiSpecAuditsModStore'
import UiTpcCompSpecModStore from './audits/UiTpcCompSpecModStore'

import AuthRolesStore from './auth-roles/AuthRolesStore'
import AuthStore from './auth/AuthStore'
import OldTsStore from './old-ts/OldTsStore'
import QcVersionsStore from './qc-vers/QcVersionsStore'
import QcStore from './qc/QcStore'
import UiQcCustModStore from './qc/UiQcCustModStore'
import UiQcSysModStore from './qc/UiQcSysModStore'
import RolesStore from './roles/RolesStore'
import RptStore from './rpt/RptStore'
import TntStore from './tenants/TntStore'
import UiAdmTntModStore from './tenants/UiAdmTntModStore'
import TntNotesByIdStore from './tnt-notes/TntNotesByIdStore'
import TntNotesStore from './tnt-notes/TntNotesStore'
import UIStore from './ui/UIStore'
import WorkProgressStore from './work-progress/WorkProgressStore'
import UiPerfConfModStore from './perf-conf/UiPerfConfModStore'

import PerfConfStore from './perf-conf/PerfConfStore'
import TpcCompSpecAuditsStore from './audits/TpcCompSpecAuditsStore'

import UiMeetingModStore from './meeting/UiMeetingModStore'
import MeetingStore from './meeting/MeetingStore'

export default class RootStore {

    tntNotesStore
    tntNotesByIdStore

    rolesStore
    authStore
    authRolesStore
    uiStore
    workProgressStore

    tpcBasicCareAuditsStore
    tpcBasicCareMedicAuditsStore
    actvSpecRiskAssetStore
    specAuditsStore
    tpcCompSpecAuditsStore

    qcVersionsStore

    oldTsStore

    uiTpcBasicCareMod = null
    uiTpcBasicCareMedicMod = null
    uiActvSpecRiskAssetMod = null
    uiSpecAuditsMod = null
    uiTpcCompSpecMod = null

    uiQcSysMod = null
    uiQcCustMod = null

    tntsStore = null
    uiAdmTntMod = null

    perfConfStore
    meetingStore

    uiPerfConfMod = null
    uiMeetingMod = null

    rptStore = null

    constructor() {
        this.authStore = new AuthStore(this)
        this.authRolesStore = new AuthRolesStore(this)

        this.tntNotesStore = new TntNotesStore(this)
        this.tntNotesByIdStore = new TntNotesByIdStore(this)

        this.rolesStore = new RolesStore(this)
        this.uiStore = new UIStore(this)
        this.workProgressStore = new WorkProgressStore(this)

        this.tpcBasicCareAuditsStore = new TpcBasicCareAuditsStore(this)
        this.tpcBasicCareMedicAuditsStore = new TpcBasicCareMedicAuditsStore(this)
        this.tpcCompSpecAuditsStore = new TpcCompSpecAuditsStore(this)

        this.actvSpecRiskAssetStore = new ActvSpecRiskAssetStore(this)
        this.specAuditsStore = new SpecAuditsStore(this)

        this.qcStore = new QcStore(this)
        this.qcVersionsStore = new QcVersionsStore(this)
        this.oldTsStore = new OldTsStore(this)

        this.uiTpcBasicCareMod = new UiTpcBasicCareModStore(this)
        this.uiTpcBasicCareMedicMod = new UiTpcBasicCareMedicModStore(this)
        this.uiActvSpecRiskAssetMod = new UiActvSpecRiskAssetModStore(this)
        this.uiSpecAuditsMod = new UiSpecAuditsModStore(this)
        this.uiTpcCompSpecMod = new UiTpcCompSpecModStore(this)

        this.uiQcSysMod = new UiQcSysModStore(this)
        this.uiQcCustMod = new UiQcCustModStore(this)

        this.tntsStore = new TntStore(this)
        this.uiAdmTntMod = new UiAdmTntModStore(this)

        this.uiMeetingMod = new UiMeetingModStore(this)
        this.meetingStore = new MeetingStore(this)

        this.uiPerfConfMod = new UiPerfConfModStore(this)
        this.perfConfStore = new PerfConfStore(this)

        this.rptStore = new RptStore(this)
    }
}