/* eslint-disable default-case */
import produce, {original} from 'immer'
import * as actTypes from '../actions/actionTypes'

const _setUsrPrjsPermissions = (draft, usrId, prjsPermissions) => {

    const _origState = original(draft.byId)
    const _setPrjs = {}

    // Step1: set the rights for each project in prjsPermissions
    prjsPermissions.forEach(({id, permissions}) => {
        _setPrjs[id]=true
        _setPrjUsrPermissions(draft, id, usrId, permissions)
    })

    // Step2: check if there are some other projects (NOT included in prjsPermissions) where usrId had some permissions. those roles must be deleted
    _origState && Object.keys(_origState).forEach(prjId => {
        if(!_origState[prjId][usrId]){ // there were no permissions of usrId inside prjId
            return
        }
        if(_setPrjs[prjId]){ //usrId still has permissions for prjId
            return
        }

        if(draft.byId[prjId] && draft.byId[prjId][usrId]){
            delete draft.byId[prjId][usrId]
        }
    })
}

const _setPrjUsrPermissions = (draft, prjId, usrId, permissions) => {

    let _curPrjState = draft.byId[prjId]
    if(!_curPrjState){
        _curPrjState={}
        draft.byId[prjId]=_curPrjState
    }

    let _curUsrState = _curPrjState[usrId]
    if(!_curUsrState){
        _curUsrState={}
        _curPrjState[usrId]=_curUsrState
    }

    const _setPermissions={}

    // Step1: assign all permissions to the project-user relation
    permissions.forEach(perm => {
        _setPermissions[perm]=true

        if(!_curUsrState[perm]){
            _curUsrState[perm]=true
        }
    })

    // Step2: check if the project-user relation had before some permissions which are not in parameter permissions. Those must be deleted
    const _origState = original(draft.byId)
    if(_origState[prjId] && _origState[prjId][usrId]){ // user usrId had some permissions for prjId
        Object.keys(_origState[prjId][usrId]).forEach(oldPerm => {
            if(!_setPermissions[oldPerm]){ //oldPerm is not included any more in the new collection of permissions 
                delete _curUsrState[oldPerm]
            }
        })
    }
}

export default produce((draft, action) => {

    switch (action.type) {

        case actTypes.SET_USR_PRJS_PERMISSIONS:
                _setUsrPrjsPermissions(draft, action.usrId, action.prjsPermissions)
            break

    }
}, {
    byId:{}
})