import { orderBy } from "lodash"
import { makeAutoObservable, set, values, remove, toJS } from "mobx"
import { getUuid } from "../../services/IdGenerator"

import QcQuest from "./QcQuest"

export default class QcVersCateg {

    vers

    id = ''
    sortID = -1
    prefix = -1
    legalbasis = {}
    name = {}

    questions = {}

    isDirty = false

    constructor(vers) {
        makeAutoObservable(this, { vers: false })

        this.vers = vers
    }

    updateFromJson = (json, cloneIds) => {
        //console.log('QcVersCateg::', json)

        const _questions = {}
        json.questions && Object.keys(json.questions).forEach(itmKey => {

            const itm = json.questions[itmKey]
            itm.id = (cloneIds) ? getUuid() : itmKey

            const _quest = new QcQuest(this)
            _quest.updateFromJson(itm, cloneIds)
            _questions[_quest.id] = _quest
        })
        set(this.questions, _questions)

        for (const fld of ['id', 'sortID', 'prefix', 'legalbasis', 'name']) {
            set(this, fld, json[fld])
        }

        if (cloneIds) {
            if (this.name) {
                this.name.id = getUuid()
            }
            if (this.legalbasis) {
                this.legalbasis.id = getUuid()
            }
        }
    }

    get srvJson() {

        const _json = {
            questions: {}
        }

        for (const _fld of ['id', 'sortID', 'prefix', 'legalbasis', 'name']) {
            _json[_fld] = toJS(this[_fld])
        }

        values(this.questions).forEach(quest => {
            _json.questions[quest.id] = quest.srvJson
        })

        if (_json.name && !_json.name.id) {
            _json.name.id = getUuid()
        }
        if (_json.legalbasis && !_json.legalbasis.id) {
            _json.legalbasis.id = getUuid()
        }

        return _json
    }

    get dirty() {

        if (this.isDirty) {
            return true
        }

        for (const _quest of values(this.questions)) {
            if (_quest.dirty) {
                return true
            }
        }

        return false
    }

    get sortedQuestions() {
        return orderBy(values(this.questions), ['sortID'])
    }

    setSortID = (val) => {
        this.sortID = val
        this.isDirty = true
    }

    setPrefix = (val) => {
        this.prefix = val
        this.isDirty = true
    }

    setName = (lng, val) => {
        set(this.name, lng, val)
        this.isDirty = true
    }

    setLegalBasis = (lng, val) => {
        set(this.legalbasis, lng, val)
        this.isDirty = true
    }

    createQuestion = () => {
        const _newQuest = new QcQuest(this)
        _newQuest.updateFromJson({ id: getUuid(), question: {}, actions: {}, regulations: {} })

        set(this.questions, _newQuest.id, _newQuest)
        this.isDirty = true

        return _newQuest
    }

    deleteQuestion = (qId) => {
        remove(this.questions, qId)
        this.isDirty = true
    }
}