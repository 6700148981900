import { makeAutoObservable, has, set, flow } from "mobx"
import { BugCrash } from "../../services/BugCrash"
import axios from '../../services/Axios'
import Qc from "./Qc"
import { getUuid } from "../../services/IdGenerator"

export default class QcStore {

    rootStore

    qcs = {}

    isLoading = false
    isSaving = false

    constructor(rootStore) {
        makeAutoObservable(this, { rootStore: false })

        this.rootStore = rootStore
    }

    upsertFromJson = (json, type, forceRefresh) => {
        //console.log('QcStore::upsertFromJson', json, type)

        if (forceRefresh || !has(this.qcs, json.id)) {
            set(this.qcs, json.id, new Qc(type))
        }

        this.qcs[json.id].updateFromJson(json, forceRefresh)
    }

    fetchOne = flow(function* (type, ownerId, qcId) {

        this.isLoading = true

        const taskId = this.rootStore.workProgressStore.startLoading(`Load QC type=${type}, ownerId=${ownerId}, qcId=${qcId}`)

        let _url = ''
        if (type === 'S') {
            _url = `/questionnaires/light/${qcId}`
        }
        else if (type === 'G') {
            _url = `/customers/${ownerId}/questionnaires/light/${qcId}`
        }

        try {
            const { data } = yield axios.get(_url)
            if (data.id) {
                this.upsertFromJson(data, type, true)
            }
            return true
        }
        catch (err) {
            if (err.response) {
                const { status } = err.response

                if (status === 404) {
                    return true
                }
            }

            BugCrash.notifyExtra(err, { type, ownerId, qcId })
        }
        finally {
            this.isLoading = false
            this.rootStore.workProgressStore.doneLoading(taskId)
        }

        return false
    })

    createQc = (qcType) => {

        const _qc = new Qc(qcType)
        const _id = getUuid()

        _qc.updateFromJson({ id: _id, name: _id, localizedName: { id: getUuid() }, type: qcType, timestamp: 0 }, true)

        set(this.qcs, _qc.id, _qc)

        return _qc
    }

    createVersion = (ownerId, qcId, qcType) => {

        const _vers = this.rootStore.qcVersionsStore.createVersion(qcId, qcType)

        const _qc = this.qcs[qcId]
        if (_qc) {
            if (_qc.isNew) {
                (async () => {
                    await this.saveOneByIdIfDirty(ownerId, qcId, qcType)
                })()
            }
        }

        if (this.qcs[qcId]) {
            this.qcs[qcId].addVersionId(_vers.id)
        }

        return _vers
    }

    deleteVersion = flow(function* (ownerId, qcId, qcType, vId) {

        const _delRemote = yield this.rootStore.qcVersionsStore.delOneById(ownerId, qcId, qcType, vId)
        if (!_delRemote) {
            return false
        }

        const _qc = this.qcs[qcId]
        if (_qc) {
            _qc.removeVersionId(vId)
        }

        return true
    })

    saveOneByIdIfDirty = flow(function* (ownerId, qcId, qcType) {

        this.isSaving = true

        const taskId = this.rootStore.workProgressStore.startPushing(`SAVE qc qcId=${qcId}`)

        try {

            let _url
            if (qcType === 'S') {
                _url = `/questionnaires/${qcId}`
            }
            else if (qcType === 'G') {
                _url = `/customers/${ownerId}/questionnaires/${qcId}`
            }

            if (!_url) {
                throw new Error("Cannot push Quest-Cat!")
            }

            const _qc = this.qcs[qcId]
            if (!_qc || !_qc.dirty) {
                return true
            }

            const { data } = yield axios.post(_url, {
                timestampBefore: _qc.timestamp,
                full: _qc.srvJson
            })

            _qc.updateFromJson({ timestamp: data.timestamp })

            return true
        }
        catch (err) {
            BugCrash.notifyExtra(err, { qcId, qcType })
        }
        finally {
            this.isSaving = false
            this.rootStore.workProgressStore.donePushing(taskId)
        }

        return false
    })
}