import { makeAutoObservable, flow } from "mobx"
import rootStore from ".."
import axios from '../../services/Axios'
import { BugCrash } from "../../services/BugCrash"
import { orderBy, remove } from "lodash"

export default class UiAdmTntNotesStore {

    custId = null
    fromCustId = null
    tntId = null

    ownIds = []
    treeIds = []

    isLoading = false

    constructor() {
        makeAutoObservable(this)
    }

    setContext = (custId, fromCustId, tntId) => {
        this.custId = custId
        this.fromCustId = fromCustId
        this.tntId = tntId
    }

    createAdmNote = (type) => {

        const _newNote = rootStore.tntNotesByIdStore.createNote()

        if (type === 'own') {
            this.ownIds.push(_newNote.id)
        }
        else {
            this.treeIds.push(_newNote.id)
        }

        return _newNote
    }

    removeNoteId = (type, id) => {

        if (type === 'own') {
            remove(this.ownIds, it => it === id)
        }
        else if (type === 'tree') {
            remove(this.treeIds, it => it === id)
        }
    }

    load = flow(function* () {

        this.isLoading = true

        const taskId = rootStore.workProgressStore.startLoading(`Loading tnt-notes in custId=${this.custId} -> fromCustId=${this.fromCustId} -> tntId=${this.tntId}`)

        try {
            const { status, data } = yield axios.get(`/customers/${this.custId}/tenants-from-cust/${this.fromCustId}/${this.tntId}/note`)

            if (status === 200) {
                data.list.forEach(itm => {
                    rootStore.tntNotesByIdStore.upsertFromJson(itm, true)
                })

                this.ownIds = orderBy(data.list.filter(it => it.type === 'own'), ['infoDate'], ['desc']).map(it => it.id)
                this.treeIds = orderBy(data.list.filter(it => it.type === 'tree'), ['infoDate'], ['desc']).map(it => it.id)
            }

            return true
        }
        catch (err) {
            //TODO: Show error!!
            BugCrash.notifyExtra(err, { custId: this.custId, fromCustId: this.fromCustId, tntId: this.tntId })
        }
        finally {
            this.isLoading = false
            rootStore.workProgressStore.doneLoading(taskId)
        }

        return false
    })
}