/* eslint-disable default-case */
import produce from 'immer'
import * as actTypes from '../actions/actionTypes'

export default produce((draft, action) => {

    switch (action.type) {
        case actTypes.STORE_UPSERT_SHARES:
            action.items && action.items.forEach(shr => {

                const _curVersion = draft.byId[shr.id]

                if (!_curVersion) { // share not in store
                    draft.allIds.push(shr.id)
                    draft.byId[shr.id] = shr
                }
                else { //share in store
                    draft.byId[shr.id] = { ..._curVersion, ...shr }
                }
            })
    }
}, {
    allIds: [],
    byId: {}
})