import * as actTypes from '../actions/actionTypes'

const initState = {}

const settingsReducer = (state = initState, action) => {

    switch(action.type){

        case actTypes.SETTINGS_SET:
            return {
                ...state,
                [action.setKey]: {
                    ...(state && state[action.setKey]),
                    ...action.settings,
                }
            }

        default:
            return state
    }
}

export default settingsReducer;
