import React from 'react'
import { observer } from 'mobx-react-lite'

import { Collapse, Button, Divider } from 'antd'
import rootStore from '../../mobx'

import TntNote from './TntNote'
import { lbl } from '../../lbls'
import TntNoteEdt from './TntNoteEdt'
import { runInAction } from 'mobx'

const { Panel } = Collapse

function TntNotesBox({ prjId, tntId, typ, editable, title }) {

    const sortedNotes = rootStore.tntNotesStore.sortedNotesByTntIdAndTyp(tntId, typ)

    if (!editable && (sortedNotes.length === 0)) {
        return null
    }

    return <div className="mt-2">
        <Collapse defaultActiveKey={['1']} expandIconPosition="right" className="nrCollapse" destroyInactivePanel bordered={false} width="100%">
            <Panel header={title} key="1">
                <div className="ml-1 mr-1 mb-1">
                    {
                        editable && <>
                            <Divider className="nrDividerNoSpace" />
                            <div className="d-flex flex-row-reverse">
                                <Button type="link" onClick={(ev) => {
                                    runInAction(() => {
                                        const _note = rootStore.tntNotesStore.createNote(prjId, tntId, typ)
                                        _note.setIsEditing(true)
                                    })
                                }}>{lbl.new_tnt_note}</Button>
                            </div>
                            <Divider className="nrDividerNoSpace mb-1" />
                        </>
                    }
                    {
                        (sortedNotes.length === 0) && <div className="noResTrans">
                            {lbl.tnt_info_no_informations}
                        </div>
                    }
                    {
                        sortedNotes.map((note) => {
                            return <div key={note.id}>
                                <TntNote note={note} editable={editable} />
                                {
                                    editable && note.isEditing && <TntNoteEdt note={note} />
                                }
                            </div>
                        })
                    }
                </div>
            </Panel>
        </Collapse>
    </div>
}

export default observer(TntNotesBox)