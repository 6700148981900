import { makeAutoObservable, flow } from "mobx"
import { BugCrash } from "../../services/BugCrash"
import axios from '../../services/Axios'
import { get, isNumber, orderBy } from "lodash"
import { myDxDefaultPageSize } from "../../config/const"
import { getUuid } from "../../services/IdGenerator"
import UiAdmTntNotesStore from "../tnt-notes/UiAdmTntNotesStore"

const _tntLight2Normal = (itm) => {
    const { id, co, n, ts, crm, cit, nr, pid, sn, str, zip } = itm

    return {
        id,
        parent_id: pid,
        timestamp: ts,
        container: co,
        name: n,
        compact: {
            address: {
                zip,
                city: cit,
                street: str
            },
            shortName: sn,
            basisId: crm,
        },
        full: {
            operationNumber: nr,
        }
    }
}

export default class UiAdmTntModStore {

    rootStore

    custId = null
    fromCustId = null

    idsFromCust = []

    search = ''
    page = 1

    pageSize = myDxDefaultPageSize

    total = 0
    ids = []
    selMan = null

    searchFil = ''
    pageFil = 1

    pageSizeFil = myDxDefaultPageSize

    totalFil = 0
    idsFil = []

    isLoading = false

    uiAdmTntNotesStore = new UiAdmTntNotesStore()

    searchRoot = ''
    pageRoot = 1
    totalRoot = 0
    idsRoot = []
    pageSizeRoot = myDxDefaultPageSize

    constructor(rootStore) {
        makeAutoObservable(this, { rootStore: false })

        this.rootStore = rootStore
    }

    chgSelMan = (idSelMan) => {

        //console.log('in chgSelMan', idSelMan)

        this.searchFil = ''
        this.pageFil = 1
        this.totalFil = 0
        this.idsFil = []

        this.selMan = idSelMan

        this.loadFil()
    }

    setSearch = (val) => {
        if (this.search !== val) {
            this.search = val
            this.page = 1
            this.load()
        }
    }

    setSearchFil = (val) => {
        if (this.searchFil !== val) {
            this.searchFil = val
            this.pageFil = 1
            this.loadFil()
        }
    }

    setSearchRoot = (val) => {
        if (this.searchRoot !== val) {
            this.searchRoot = val
            this.pageRoot = 1
            this.loadRoots()
        }
    }

    setPage = (val) => {

        if (!isNumber(val) || (val < 1)) {
            return this.setPage(1)
        }
        if (this.page !== val) {
            this.page = val
            this.load()
        }
    }

    setPageFil = (val) => {

        if (!isNumber(val) || (val < 1)) {
            return this.setPageFil(1)
        }
        if (this.pageFil !== val) {
            this.pageFil = val
            this.loadFil()
        }
    }

    setPageRoot = (val) => {

        if (!isNumber(val) || (val < 1)) {
            return this.setPageRoot(1)
        }
        if (this.pageRoot !== val) {
            this.pageRoot = val
            this.loadRoots()
        }
    }

    setFromCustId = (val) => {
        this.fromCustId = val
        this.load()
    }

    start = (custId, fromCustId) => {

        this.custId = custId
        this.fromCustId = fromCustId

        this.page = 1
        this.total = 0
        this.ids = []

        Promise.all([
            this.load(),
            this.loadSharesToSelCustId()
        ]).then(() => {
            //console.debug('done loading...')
        })
    }

    stop = () => {
        this.custId = null
        this.fromCustId = null

        this.page = 1
        this.total = 0
        this.ids = []
        this.idsFromCust = []
    }

    resetRoots = () => {
        this.pageRoot = 1
        this.searchRoot = ''
        this.totalRoot = 0
        this.idsRoot = []

        this.loadRoots().then(() => {
            //console.debug('done loading...')
        })
    }

    loadSharesToSelCustId = flow(function* () {

        this.isLoading = true

        const taskId = this.rootStore.workProgressStore.startLoading(`Loading shares to custId=${this.custId}`)

        try {
            // parent GET-Param!
            const { status, data } = yield axios.get(`/customers/${this.custId}/shares/to-us`)

            if (status === 200) {

                const _custs = {}
                for (const _shr of get(data, ['list'], [])) {
                    const _custId = get(_shr, ['fromCust', 'id'], '')
                    if (!_custId || _custs[_custId]) {
                        continue
                    }
                    const _custName = get(_shr, ['fromCust', 'name'], _custId)

                    _custs[_custId] = {
                        id: _custId,
                        name: _custName,
                    }
                }

                orderBy(_custs, ['name']).forEach(itm => {
                    this.idsFromCust.push(itm)
                })
            }

            return true
        }
        catch (err) {
            //TODO: Show error!!
            BugCrash.notifyExtra(err, { custId: this.custId })
        }
        finally {
            this.isLoading = false
            this.rootStore.workProgressStore.doneLoading(taskId)
        }

        return false
    })

    load = flow(function* () {

        this.isLoading = true

        const taskId = this.rootStore.workProgressStore.startLoading(`Loading adm-tnts light in custId=${this.custId} -> fromCustId=${this.fromCustId}`)

        try {
            // parent GET-Param!
            const { status, data } = yield axios.get(`/customers/${this.custId}/tenants-light/${this.fromCustId}?page=${this.page}&parent=all&maxPerPage=${this.pageSize}&search=` + encodeURIComponent(this.search))

            if (status === 200) {
                data.list.forEach(itm => {
                    this.rootStore.tntsStore.upsertFromJson(_tntLight2Normal(itm), false)
                })
                data.p && Object.values(data.p).forEach(itm => {
                    this.rootStore.tntsStore.upsertFromJson(_tntLight2Normal(itm), false)
                })

                this.total = data.total
                this.ids = data.list.map(itm => itm.id)
            }

            return true
        }
        catch (err) {
            //TODO: Show error!!
            BugCrash.notifyExtra(err, { custId: this.custId, fromCustId: this.fromCustId })
        }
        finally {
            this.isLoading = false
            this.rootStore.workProgressStore.doneLoading(taskId)
        }

        return false
    })

    loadFil = flow(function* () {

        this.isLoading = true

        const taskId = this.rootStore.workProgressStore.startLoading(`Loading adm-tnts (filialen) light in custId=${this.custId} -> fromCustId=${this.fromCustId}`)

        try {
            // parent GET-Param!
            const { status, data } = yield axios.get(`/customers/${this.custId}/tenants-light/${this.fromCustId}?page=${this.pageFil}&maxPerPage=${this.pageSizeFil}&parent=${(this.selMan) ? this.selMan : ''}&search=` + encodeURIComponent(this.searchFil))

            if (status === 200) {
                data.list.forEach(itm => {
                    this.rootStore.tntsStore.upsertFromJson(_tntLight2Normal(itm), false)
                })
                data.p && Object.values(data.p).forEach(itm => {
                    this.rootStore.tntsStore.upsertFromJson(_tntLight2Normal(itm), false)
                })

                this.totalFil = data.total
                this.idsFil = data.list.map(itm => itm.id)
            }

            return true
        }
        catch (err) {
            //TODO: Show error!!
            BugCrash.notifyExtra(err, { custId: this.custId, fromCustId: this.fromCustId })
        }
        finally {
            this.isLoading = false
            this.rootStore.workProgressStore.doneLoading(taskId)
        }

        return false
    })

    loadRoots = flow(function* () {

        this.isLoading = true

        const taskId = this.rootStore.workProgressStore.startLoading(`Loading adm-tnts (roots) light in custId=${this.custId} -> fromCustId=${this.fromCustId}`)

        try {
            // parent GET-Param!
            const { status, data } = yield axios.get(`/customers/${this.custId}/tenants-light/${this.fromCustId}?page=${this.pageRoot}&maxPerPage=${this.pageSizeRoot}&search=` + encodeURIComponent(this.searchRoot))

            if (status === 200) {
                data.list.forEach(itm => {
                    this.rootStore.tntsStore.upsertFromJson(_tntLight2Normal(itm), false)
                })
                data.p && Object.values(data.p).forEach(itm => {
                    this.rootStore.tntsStore.upsertFromJson(_tntLight2Normal(itm), false)
                })

                this.totalRoot = data.total
                this.idsRoot = data.list.map(itm => itm.id)
            }

            return true
        }
        catch (err) {
            //TODO: Show error!!
            BugCrash.notifyExtra(err, { custId: this.custId, fromCustId: this.fromCustId })
        }
        finally {
            this.isLoading = false
            this.rootStore.workProgressStore.doneLoading(taskId)
        }

        return false
    })

    createMan = (name) => {

        const _itm = {
            id: getUuid(),
            timestamp: 0,
            container: false,
            name,
            compact: {
                shortName: name,
            }
        }

        this.rootStore.tntsStore.upsertFromJson(_itm, true)
        this.ids.push(_itm.id)

        return _itm.id
    }

    createFil = (name, parentId) => {

        const _itm = {
            id: getUuid(),
            parent_id: parentId,
            timestamp: 0,
            container: false,
            name,
            compact: {
                shortName: name,
            }
        }

        this.rootStore.tntsStore.upsertFromJson(_itm, true)
        this.idsFil.push(_itm.id)

        return _itm.id
    }

    get onShare() {
        return (this.custId !== this.fromCustId)
    }
}