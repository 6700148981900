/* eslint-disable default-case */
import produce from 'immer'
import * as actTypes from '../actions/actionTypes'
import set from 'lodash/set'

export default produce((draft, action) => {

    switch (action.type) {

        case actTypes.REP_SHELF_INSPECT_DONE:

            set(draft, ['byId', action.prjId, action.tntId, 'shelf-inspect', action.lng, ((action.shortReport === 1) ? 1 : 0)], action.rep)
            break;

        case actTypes.REP_PERF_CONF_DONE:

            set(draft, ['byId', action.prjId, action.tntId, 'perf-conf', action.lng], action.rep)
            break;
    }
}, {
    byId: {}
})