import React, { memo } from 'react'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useSelector } from 'react-redux'
import rootStore from '../../mobx'

const _sesCustIdSelector = state => state.session.cust

const _sesPrjByCustIdSelector = {}
const _getSelPrjByCustIdSelector = (custId) => {

    if (!custId) {
        return state => state.nothing
    }

    if (!_sesPrjByCustIdSelector[custId]) {
        _sesPrjByCustIdSelector[custId] = state => state.session.projByCust && state.session.projByCust[custId]
    }

    return _sesPrjByCustIdSelector[custId]
}

const _sesTntByPrjIdSelector = {}
const _getSelTntByPrjIdSelector = (prjId) => {

    if (!prjId) {
        return state => state.nothing
    }
    if (!_sesTntByPrjIdSelector[prjId]) {
        _sesTntByPrjIdSelector[prjId] = state => state.session.tntByProj && state.session.tntByProj[prjId]
    }

    return _sesTntByPrjIdSelector[prjId]
}

const MobxSync = observer(({ custId, prjId, tntId }) => {

    //console.debug(`RENDER MobxSync, custId=${custId}, prjId=${prjId}, tntId=${tntId}`)

    runInAction(() => {
        if (rootStore.uiStore.selCustId !== custId) {
            //console.debug('MobxSync diff cust-id')
            rootStore.uiStore.chgSelCust(custId)
        }
        if (rootStore.uiStore.selPrjIdByCustId[custId] !== prjId) {
            //console.debug('MobxSync diff prj-id')
            rootStore.uiStore.chgSelCustPrj(custId, prjId)
        }
        if (rootStore.uiStore.selTntIdByPrjId[prjId] !== tntId) {
            //console.debug('MobxSync diff tnt-id')
            rootStore.uiStore.chgSelPrjTnt(prjId, tntId)
        }
    })

    return <></>
})

function ReduxMobxSync() {
    const ruxCustId = useSelector(_sesCustIdSelector)
    const ruxPrjId = useSelector(_getSelPrjByCustIdSelector(ruxCustId))
    const ruxTntId = useSelector(_getSelTntByPrjIdSelector(ruxPrjId))

    //console.debug(`RENDER ReduxMobxSync, ruxCustId=${ruxCustId}, ruxPrjId=${ruxPrjId}, ruxTntId=${ruxTntId}`)

    return <MobxSync custId={ruxCustId} prjId={ruxPrjId} tntId={ruxTntId} />
}

export default memo(ReduxMobxSync)