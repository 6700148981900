import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import rootStore from '../../mobx'
import { PERMISSIONS } from '../../config/const'
import { useSelector } from 'react-redux'

import TntNotesBox from './TntNotesBox'
import { BugCrash } from '../../services/BugCrash'
import { lbl } from '../../lbls'
import { notification } from 'antd'

const _parentIdSelectors = {}
const getParentIdSelector = (tntId) => {
    if (!_parentIdSelectors[tntId]) {
        if (!tntId) {
            _parentIdSelectors[tntId] = state => null
        }
        else {
            _parentIdSelectors[tntId] = state => (state.tnts.byId[tntId] && state.tnts.byId[tntId].parent) || null
        }
    }

    return _parentIdSelectors[tntId]
}

function TntNotesMain({ prjId, tntId }) {
    const _tntParentId = useSelector(getParentIdSelector(tntId))

    useEffect(() => {
        (async () => {
            try {
                await rootStore.tntNotesStore.fetchTntNotes(prjId, tntId)
            }
            catch (err) {
                BugCrash.notifyExtra(err, { prjId, tntId })
                notification.error({
                    message: lbl.err_title,
                    description: lbl.err_load_tnt_notes,
                    placement: 'bottomRight',
                    duration: 0,
                })
            }
        })()
    }, [prjId, tntId])

    //console.log(`TntNotesMain RENDER - ${tntId} -> ${_tntParentId}`)

    const _myPermsInPrj = rootStore.authRolesStore.getPermsInPrj(prjId)
    if (!_myPermsInPrj[PERMISSIONS.roTntNotes] && !_myPermsInPrj[PERMISSIONS.rwTntNotes]) {
        console.debug(`TntNotesMain exit - because of no one of the perms ${PERMISSIONS.roTntNotes} or ${PERMISSIONS.rwTntNotes} in prj ${prjId}`)
        return null
    }

    const mayEdit = _myPermsInPrj[PERMISSIONS.rwTntNotes]

    return <>
        {_tntParentId && <TntNotesBox prjId={prjId} tntId={_tntParentId} typ="tree" editable={mayEdit || false} title={lbl.mandant_tnt_notes} />}
        <TntNotesBox prjId={prjId} tntId={tntId} typ="own" editable={mayEdit} title={lbl.own_tnt_notes} />
        {!_tntParentId && <TntNotesBox prjId={prjId} tntId={tntId} typ="tree" editable={mayEdit} title={lbl.tnt_notes_for_filialen} />}
    </>
}

export default observer(TntNotesMain)