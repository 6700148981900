import React, { useCallback, useMemo } from 'react'
import Media from 'react-media';

import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect';

import * as actTypes from './store/actions/actionTypes'
import AppContext from './AppContext';

import PlsWait from './components/ui/PlsWait';
import { custAuthUsrTntsGrpByPrj } from './store/selectors/custs';

import get from 'lodash/get'

const _selector = createSelector(

    state => state.firebase.auth,

    state => state.session && state.session.cust,
    state => state.custs.byId,
    state => state.session && state.session.projByCust,
    state => state.prjs.byId,
    state => state.session && state.session.tntByProj,

    state => (state.session && state.session.lng) || 'de',
    state => state.session && state.session.xs,

    state => state.prjsPermissions.byId,
    state => state.prjsRoles.byId,

    state => state.myData && state.myData.data && state.myData.data,
    state => state.custsAdms && state.custsAdms.byId,

    (auth, custId, custsById, projByCust, prjsById, tntByProj, lng, xs, prjsPermissionsById, prjsRolesById, myData, custsAdms) => {

        const cust = custId && custsById && custsById[custId]

        const prjId = custId && projByCust && projByCust[custId]
        const prj = prjId && prjsById && prjsById[prjId]

        const tntId = prjId && tntByProj && tntByProj[prjId]

        const prjPermissions = prjId && prjsPermissionsById && prjsPermissionsById[prjId] && auth && prjsPermissionsById[prjId][auth.uid]
        const prjRoles = prjId && prjsRolesById && prjsRolesById[prjId] && auth && prjsRolesById[prjId][auth.uid]

        //console.log(`in selector vom AppContextProvider:::`, prjsPermissionsById, prjsRolesById)

        const adm4CustIds = {}
        auth.uid && Object.keys(custsAdms).forEach((custId) => {
            if (custsAdms[custId][auth.uid]) {
                adm4CustIds[custId] = true
            }
        })

        return { auth, custId, cust, prjId, prj, tntId, lng, xs, prjPermissions, prjRoles, sa: myData && myData.sa, adm4CustIds }
    }
)

console.todo = function (msg) {
    console.log(`%c %s %s %s`, `color: yellow; background-color: black;`, `TODO: `, msg, `!`);
}
console.important = function (msg) {
    console.log(`%c %s %s %s`, `color: brown; font-weight: bold; text-decoration: underline;`, `IMPORTANT:`, msg, `!!`);
}

function AppContextProvider({ children }) {

    const _state = useSelector(_selector)
    const _custTntsGrpByPrj = useSelector(state => custAuthUsrTntsGrpByPrj(state, { custId: _state.custId }))
    const tnt = _state.prjId && _state.tntId && get(_custTntsGrpByPrj, [_state.prjId, 'tnts', _state.tntId])

    const dispatch = useDispatch();

    const _chgXs = useCallback(
        (xs) => {
            dispatch({ type: actTypes.SES_SET_XS, xs })
        },
        [dispatch],
    )

    const _providerValue = useMemo(() => {
        return {
            ..._state,
            tnt,
        }

    }, [_state, tnt])

    //console.warn(`AppContextProvider:::`, Date.now(), _providerValue)

    return <>
        <Media query={{ maxWidth: 599 }} onChange={_chgXs}></Media>

        <AppContext.Provider value={_providerValue}>
            {(_state.xs !== null) && children}
            {(_state.xs === null) && <PlsWait />}
        </AppContext.Provider>
    </>
}

export default React.memo(AppContextProvider)