import { BugCrash } from '../../services/BugCrash'
import Axios from '../../services/Axios'
import {lbl} from '../../lbls'
import { checkSelectedCustomer, checkSelectedCustProject, checkSelectedProjTenant } from './sessionActions';
import { lgInfo } from '../../utils/logging';
import { actStarted, actReadyOk, actReadyPb } from './actionsActions';
import { actionsAreas, actionsActs } from '../reducers/actionsReducer';
import { setUsrJoinedCusts } from './custsUsrsActions';
import { setUsrCustsWithAdmRole } from './custsAdmsActions';
import { upsertCusts } from './custActions';
import { upsertPrjs } from './projectsActions';
import { setCustPrjsByAuthUsr } from './custsPrjsActions';
import { setUsrPrjsRoles } from './prjsUsrsRolesActions';
import { upsertTenants } from './tenantsActions';
import { setPrjTntIds } from './prjsTntsActions';
import { setPrjRootIds } from './prjsRootsActions';
import { setCustsLicenses } from './custsLicActions';
import { setUsrPrjsPermissions } from './prjsUsrsPermissionsActions';

export const loadMyProjTnts = (prjId, actGrp) => {
    return async (dispatch, getState) => {

        const _actTgt=`load-my-tnts-prj=${prjId}`

        try{
            if(!prjId){
                lgInfo('No project id, action loadMyProjTnts was aborted.')
                return
            }

            dispatch(actStarted(actionsAreas.tnts, actGrp, _actTgt, actionsActs.load))

            const resp = await Axios.get(`/projects/${prjId}/tenants`)
            if(resp && (resp.status===200)){

                //console.log('loadMyProjTnts resp data:', resp.data)

                const _tnts={}
                const _rootIds={}
                const _tntIds={}

                resp.data.list && resp.data.list.forEach(({mount, subTenants}) => {
                    _rootIds[mount.id]=true
                    _tntIds[mount.id]=true
                    _tnts[mount.id]=mount

                    subTenants && subTenants.forEach(subTnt => {
                        _tnts[subTnt.id]=subTnt
                        _tntIds[subTnt.id]=true
                    })
                })

                // Step1: upsert the tenants
                dispatch(upsertTenants(_tnts))

                // Step2: set project tenant ids
                dispatch(setPrjTntIds(prjId, _tntIds))

                // Step3: set project root ids
                dispatch(setPrjRootIds(prjId, _rootIds))

                // Step4: check if everything all right with the selected tenant in prjId
                dispatch(checkSelectedProjTenant(prjId, actGrp))

                dispatch(actReadyOk(actionsAreas.tnts, actGrp, _actTgt, actionsActs.load))
                return
            }

            throw new Error(lbl.err_load_my_cust_proj_tnts)
        }
        catch(err){
            BugCrash.notify(err)
            dispatch(actReadyPb(actionsAreas.tnts, actGrp, _actTgt, actionsActs.load, err))
        }
    }
}

export const loadMyCustProjects = (custId, actGrp, onLoad) => {
    return async (dispatch, getState) => {

        const _actTgt=`load-my-projs-cust=${custId}`

        try{
            if(!custId){
                lgInfo('No cust id, action loadMyCustProjects was aborted.')
                return
            }

            dispatch(actStarted(actionsAreas.prjs, actGrp, _actTgt, actionsActs.load))

            const _state = await getState()
            const _authUsrId = _state.firebase && _state.firebase.auth && _state.firebase.auth.uid

            const resp = await Axios.get(`/my/customers/${custId}/projects`)
            if(resp && (resp.status===200)){
                const _loadedProjs = (resp.data.list)?resp.data.list:[]

                // 1. Upsert the projects
                dispatch(upsertPrjs(_loadedProjs))

                // 2. SET proj-cust relation for the auth user
                dispatch(setCustPrjsByAuthUsr(custId, _loadedProjs.map(p => p.id)))
                
                // 3. SET proj-usr-roles for auth user
                dispatch(setUsrPrjsRoles(_authUsrId, _loadedProjs.map(prj => {
                    return {
                        id: prj.id,
                        roles: prj.roles,
                    }
                })))

                // 4. SET proj-usr-permissions for auth user
                dispatch(setUsrPrjsPermissions(_authUsrId, _loadedProjs.map(prj => {
                    return {
                        id: prj.id,
                        permissions: prj.permissions,
                    }
                })))

                setTimeout(() => {
                    dispatch(checkSelectedCustProject(custId, actGrp))
                }, 10)

                if(onLoad){
                    onLoad(_loadedProjs.map(p => p.id))
                }

                dispatch(actReadyOk(actionsAreas.prjs, actGrp, _actTgt, actionsActs.load))
                return
            }

            throw new Error(lbl.err_load_my_cust_projs)
        }
        catch(err){
            BugCrash.notify(err)
            dispatch(actReadyPb(actionsAreas.prjs, actGrp, _actTgt, actionsActs.load, err))
        }
    }
}

export const loadMyCustomers = (actGrp, onLoad) => {
    return async (dispatch, getState) => {

        const _actTgt=`load-my-custs`

        try{
            const _state = await getState()

            dispatch(actStarted(actionsAreas.cust, actGrp, _actTgt, actionsActs.load))

            const resp = await Axios.get('/my/customers')
            if(resp && (resp.status===200)){

                const _myCusts = resp.data.list || []

                if(_myCusts.length>0){
                    dispatch(upsertCusts(resp.data.list))
                    dispatch(setCustsLicenses(_myCusts.reduce((prv, cur) => {
                        return {
                            ...prv,
                            [cur.id]: cur.license,
                        }
                    }, {})))
                }

                const _authUsrId = _state.firebase && _state.firebase.auth && _state.firebase.auth.uid
                const _usrJoinedCusts = _myCusts.map(c => c.id)

                dispatch(setUsrJoinedCusts(_authUsrId, _usrJoinedCusts))
                dispatch(setUsrCustsWithAdmRole(_authUsrId, _myCusts.filter(c => c.admin).map(c => c.id)))

                dispatch(checkSelectedCustomer(actGrp))

                if(onLoad){
                    onLoad(_usrJoinedCusts)
                }

                dispatch(actReadyOk(actionsAreas.cust, actGrp, _actTgt, actionsActs.load))
                return
            }

            throw new Error(lbl.err_load_my_cust)
        }
        catch(err){
            BugCrash.notify(err)
            dispatch(actReadyPb(actionsAreas.cust, actGrp, _actTgt, actionsActs.load, err))
        }
    }
}