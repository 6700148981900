import * as actTypes from './actionTypes'

export const setUsrPrjsPermissions = (usrId, prjsPermissions) => {

    return {
        type: actTypes.SET_USR_PRJS_PERMISSIONS,
        usrId,
        prjsPermissions,
    }
}
