import { makeAutoObservable, flow, has } from "mobx"
import rootStore from ".."
import { BugCrash } from "../../services/BugCrash"
import axios from '../../services/Axios'

import PerfConf from "./PerfConf"

export default class PerfConfStore {

    rootStore

    perfConfs = {}

    isLoading = false

    constructor(rootStore) {
        makeAutoObservable(this, { rootStore: false })

        this.rootStore = rootStore
    }

    fetchOne = flow(function* (prjId, tntId, perfConfId, forceRefresh) {

        this.isLoading = true

        const taskId = rootStore.workProgressStore.startLoading(`Load perf-confs prjId=${prjId}, tntId=${tntId}, perfConfId=${perfConfId}`)

        try {
            const { status, data } = yield axios.get(`/projects/${prjId}/tenants/${tntId}/perform/confirmations/${perfConfId}`)
            if (status === 200) {
                if (forceRefresh || !has(this.perfConfs, data.id)) {
                    this.perfConfs[perfConfId] = new PerfConf(this)
                }

                this.perfConfs[perfConfId].updateFromJson(data)
                return true
            }
        }
        catch (err) {
            BugCrash.notifyExtra(err, { prjId, tntId, perfConfId })
        }
        finally {
            this.isLoading = false
            rootStore.workProgressStore.doneLoading(taskId)
        }

        return false
    })

    updateFromLight = (itm) => {

        if (!has(this.perfConfs, itm.id)) {
            this.perfConfs[itm.id] = new PerfConf(this)
        }

        this.perfConfs[itm.id].updateFromLight(itm)
    }
}