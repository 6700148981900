import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import rootStore from '../mobx';

var firebaseConfig = {
    apiKey: "AIzaSyCM_l2QOrkWsWIc1FD_VxfG0WFOOqITtSE",
    authDomain: "inorisk-de.firebaseapp.com",
    databaseURL: "https://inorisk-de.firebaseio.com",
    projectId: "inorisk-de",
    storageBucket: "inorisk-de.appspot.com",
    messagingSenderId: "410074764652",
    appId: "1:410074764652:web:464de2e49ebf91d8e9cf49",
    measurementId: "G-2TYRV9G1H1"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

firebase.auth().onAuthStateChanged((usr) => {
    rootStore.authStore.updateFromFirebaseUser(usr)
})

export default firebase